#UserInquirySucccess {
  display: flex;
  flex-direction: column;
  & .inquiry-success-wrapper {
    width: 360px;
    margin: 80px auto 100px;
    display: flex;
    flex-direction: column;
    & .title {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 32px;
    }
    & .success-body {
      text-align: left;
      & .success-info {
        height: 24px;
        margin-top: 32px;
        font: normal normal bold 16px/40px Noto Sans KR;
      }
      & .success-sub-info {
        height: 20px;
        margin-top: 8px;
        font: normal normal normal 14px/40px Noto Sans KR;
      }
    }
    & .success-container {
      margin-top: 32px;
      width: 368px;
      height: 116px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 8px;
      text-align: center;
      & :nth-of-type(1) {
        height: 24px;
        margin-top: 24px;
        font: normal normal bold 16px/40px Noto Sans KR;
        color: #000000;
      }
      & :nth-of-type(2) {
        height: 20px;
        margin-top: 8px;
        font: normal normal normal 14px/40px Noto Sans KR;
        color: #777777;
      }
    }
    & .button-container {
      margin-top: 32px;
      margin-bottom: 40px;
      width: 368px;
      display: flex;
      & .pw-button {
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 8px;
        color: #1e76af;
      }
      & .in-button {
        margin-left: 16px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        color: white;
      }
      & .pw-button,
      & .in-button {
        width: 176px;
        height: 56px;
        display: flex;
        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
      }
    }
    & .success-pw {
      text-align: left;
      margin-top: 16px;
      & .pw-title {
        margin-top: 16px;
        margin-bottom: 16px;
        font: normal normal normal 16px/40px Noto Sans KR;
      }
      & .pw-input {
        width: 360px;
        height: 56px;
        border-radius: 8px;
      }
      & .helpText {
        margin: 0;
        text-align: center;
        font: normal normal normal 12px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
      }
      & .errorText {
        margin: 0;
        text-align: center;
        font: normal normal normal 12px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ec1f2d;
      }
      & .css-1wc848c-MuiFormHelperText-root {
        margin-left: 0;
      }
    }
    & .submitBtn-container {
      display: flex;
      justify-content: center;
      margin-top: 32px;
      margin-bottom: 40px;
      & .submitbtn {
        width: 368px;
        height: 56px;
        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: white;
        // background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
      }
    }
  }
}
