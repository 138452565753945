#RecommendedBookDetail {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .detail-wrapper {
    display: flex;
    padding-top: 80px;
    @import "./DetailSideMenu.scss";
  }
  & .detail-container {
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;
    & .title-container {
      margin-bottom: 24px;
      & .title {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
      }
    }
    & .middle-category-container {
      max-width: 1164px;
      max-height: 525px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 12px;
      & .middle-category-name-container {
        display: flex;
        flex-wrap: wrap;
        margin: 36px 8px 24px 40px;
        & .middle-category-name {
          cursor: pointer;
          background: #f5f5f5 0% 0% no-repeat padding-box;
          text-align: left;
          width: 340px;
          margin-bottom: 12px;
          margin-right: 32px;
          font: normal normal normal 13px/17px Noto Sans KR;
          &.selected {
            font-weight: bold;
            text-decoration: underline;
          }
        }
      }
    }
    & .sub-title-container {
      margin-top: 32px;
      margin-bottom: 25px;
      & .sub-title {
        text-align: left;
        font: normal normal bold 20px/28px Noto Sans KR;
      }
    }
    & .divider {
      width: 1164px;
      height: 1px;
      border: 1px solid #777777;
      background: #ffffff 0% 0% no-repeat padding-box;
    }

    & .show-sorting-container {
      background: #f3f8fb 0% 0% no-repeat padding-box;
      max-width: 480px;
      min-width: 360px;
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
      & .sorting-name {
        float: right;
        font-size: 15px;
        font-weight: bold;
      }
    }

    & .list-row-container {
      & .title-container.no-search-result {
        font-size: 24px;
        font-weight: bold;
        margin: auto;
        text-align: center;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .side-menu {
      display: flex;
      margin: 0 auto;
      min-width: 360px;
      max-width: 480px;
      & .rec-book-menu-container {
        background: #f5f5f5 0% 0% no-repeat padding-box;
        display: flex;
        min-width: 360px;
        width: 100vw;
        max-width: 480px;
        & .rec-book-menu {
          display: flex;
          min-width: 319px;
          width: calc(100vw - 56px);
          max-width: 374px;
          overflow: hidden;
          flex-wrap: nowrap;
          transition: 0.3s all ease-in-out;
          height: 56px;
          font-size: 12px;
          & .menu-item {
            display: flex;
            justify-content: center;
            align-items: center;
            white-space: nowrap;
            padding: 12px;
            cursor: pointer;
          }
          & .menu-item.selected {
            font-weight: bold;
            padding-bottom: 12px;
            color: #1e76af;
          }
        }
        & .rec-book-menu.opened {
          flex-wrap: wrap;
          height: 130px;
          min-width: 300px;
          width: 100vw;
          max-width: 480px;
        }
        & .arrow {
          width: 56px;
          height: 56px;
          margin: 0 0 -56px auto;
          display: flex;
          z-index: 10000;
          background-color: #f5f5f5;
          position: relative;
          cursor: pointer;
          justify-content: center;
          align-items: center;
          min-width: 56px;
          max-width: 56px;
        }
      }
    }
    & .detail-wrapper {
      padding: 0;
      margin: 0 auto;
      min-width: 360px;
      // width: calc(100% - 40px);
      width: 100%;
      max-width: 480px;
      margin-bottom: 50px;
      & .detail-container {
        width: 100%;
        margin: 0;

        & .title-container {
          margin: 20px 0;
          padding: 0 20px;
          & .title {
            text-align: left;
            font-size: 17.5px;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
        & .middle-category-container {
          width: 100%;
          background: none;
          padding: 0 20px;
          & .MuiInputBase-root {
            width: 100%;
            height: 48px;
            text-align: left;
            font-size: 14px;
            letter-spacing: 0px;
            color: #777777;
            opacity: 1;
            border-radius: 16px;
          }
        }
        & .time-select-box {
          margin: 0;
          margin-top: 12px;
          margin-bottom: 20px;
          background: none;
          padding: 0 20px;
          & .MuiInputBase-root {
            width: 30%;
            height: 48px;
            text-align: left;
            font-size: 14px;
            letter-spacing: 0px;
            color: #777777;
            opacity: 1;
            border-radius: 16px;
            margin: 0;
            border: none;
          }
        }
        & .title-bar-container {
          display: flex;
          justify-content: end;
          align-items: center;
          height: 48px;
          padding: 0;
          border: none;
          background-color: #f3f8fb;

          & .MuiInputBase-root {
            margin-left: auto;
            text-align: left;
            font-size: 14px;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            & .MuiSelect-select {
              padding-top: 0;
              padding-bottom: 0;
            }
            & .MuiOutlinedInput-notchedOutline {
              display: none;
            }
          }
        }

        & .list-row-container {
          border: none;
          & .title-container.no-search-result {
            font-size: 17.5px;
          }
        }

        & .new-book-img {
          padding: 20px 0;
          margin: 0 auto;
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          display: flex;
          border-bottom: 1px solid #e8e8e8;
          & .book-img {
            width: 80px;
            & .LazyLoad {
              & img {
                width: 80px;
                box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1607843137);
                border-radius: 4px;
                cursor: pointer;
              }
            }
          }
          & .book-info {
            margin-left: 25px;
            min-width: 215px;
            width: 100%;
            display: flex;
            flex-direction: column;
            & .book-title-container {
              display: flex;
              font-size: 15px;
              font-weight: bold;
              & .icon-container {
                display: flex;
              }
              & .book-title {
                font-size: 15px;
                font-weight: bold;
              }
              & .heart-icon {
                margin-left: auto;
                margin-right: 24px;
              }
            }
            & .sub-info-container {
              display: flex;
              font-size: 12.5px;
              color: #777777;
              min-width: 200px;
              width: calc(100vw - 160px);
              max-width: 320px;
              margin-top: 16px;
              & .divider {
                height: 8px;
                width: 1px;
                background-color: black;
                opacity: 0.5;
                margin: auto 6px;
              }
            }
            & .price-info-container {
              display: flex;
              margin-top: 14px;

              & .sale-percentage {
                font-size: 11px;
                font-weight: bold;
                color: white;
                background: #1e76af 0% 0% no-repeat padding-box;
                border-radius: 2px;
                padding: 2px 4px;
              }
              & .after-sale {
                margin-left: 12px;
                font-size: 14px;
                font-weight: bold;
              }
              & .before-sale {
                margin-left: 12px;
                text-decoration: line-through;
                color: #777777;
                font-size: 12px;
                margin-top: 2px;
              }
            }
          }
        }
        & .pagination-container {
          margin-top: 20px;
          & .pagination {
            & ul {
              & li {
                display: flex;
                align-items: center;
                & .MuiButtonBase-root {
                  min-width: 22px;
                  height: 22px;
                }
                & button {
                  font-size: 10px;
                  & svg {
                    width: 19px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
