& .main-swiper {
  width: 1920px;
  height: 489px;
  display: flex;
  flex-direction: column;
  & .swiper-wrapper {
    height: 420px;
  }
  & .swiper-slide {
    width: 100%;
    max-width: 1920px;
    height: 420px;
    color: black;
    transform: translate3d(0, 0, 0);
    cursor: pointer;
    & img {
      max-width: 1920px;
      height: 420px;
      width: 100%;
      object-fit: cover;
    }
  }
  & .swiper-button-prev {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    margin-left: 240px;
    margin-top: -60px;
    &:after {
      display: none;
    }
  }
  & .swiper-button-next {
    opacity: 0;
    transition: all 0.3s ease-in-out;
    margin-right: 240px;
    margin-top: -60px;
    &:after {
      display: none;
    }
  }
  &:hover {
    & .swiper-button-next,
    & .swiper-button-prev {
      opacity: 1;
    }
  }
  & .bottom-area-container {
    background-color: #ffffff;
    z-index: 100001;
    display: flex;
    border-bottom: 1px solid #e8e8e8;
    & .pagination {
      height: 68px;
      display: flex;
      width: 900px;
      margin-left: auto;
      & .swiper-pagination-bullet {
        width: 180px;
        height: 68px;
        margin: auto 0px;
        line-height: 68px;
        background-color: white;
        font-weight: bold;
        font-size: 15px;
        display: flex;
        color: #b2b2b2;
        opacity: 1;

        & .divider {
          width: 0;
          height: 20px;
          margin: auto 0;
          border-left: 1px solid #e8e8e8;
        }
        & .bullet-text {
          margin: auto;
        }
        &.swiper-pagination-bullet-active {
          color: #1e76af;
          border-bottom: 3px #1e76af solid;
          border-radius: 0;
        }
      }
    }
    & .controller-wrapper {
      display: flex;
      margin-right: auto;
      & .controller-container {
        margin-right: auto;
        display: flex;
        line-height: 68px;
        border-left: 1px solid #e8e8e8;
        border-right: 1px solid #e8e8e8;
        & div {
          cursor: pointer;
        }

        & .slide-prev-button-container {
          padding-left: 50px;
          display: flex;
        }
        & .slide-next-button-container {
          padding-right: 50px;
          display: flex;
          & span {
            color: #b2b2b2;
          }
        }
        & .swiper-prev-button {
          margin: 4px 15px 0 0;
        }
        & .swiper-next-button {
          margin: 4px 0 0 15px;
        }
        & .divider {
          width: 0;
          height: 15px;
          border-left: 1px solid #bfbfbf;
          margin: auto 10px;
        }
      }
      & .autoplay-button-container {
        width: 68px;
        height: 68px;
        display: flex;
        border-right: 1px solid #e8e8e8;
        cursor: pointer;
        & .autoplay-toggle-button {
          margin: auto;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    //.main-swiper의 css부터 작성하면 됨.
    // 아래에도 필요한 내용을 쭉 적어나가면 적용 성공.
    min-width: 360px;
    width: 100%;
    margin: 0 auto;
    max-width: 480px !important;
    height: auto;
    & .swiper-wrapper {
      height: auto !important;
    }
    & .bottom-area-container {
      display: none;
    }
    & .swiper-slide {
      height: unset;
      & img {
        min-width: 360px;
        width: 100vw;
        max-width: 480px;
        min-height: calc(360px * 940 / 750);
        height: calc(100vw * 940 / 750);
        max-height: calc(480px * 940 / 750);
      }
    }
    & .swiper-button-next,
    & .swiper-button-prev {
      display: none;
    }
  }
}
