.univ-book-quick-search-banner-container {
  display: flex;
  margin-top: 35px;
  height: 48px;
  & .univ-book-quick-search-banner-wrap {
    width: 1400px;
    margin: 0 auto;
    display: flex;
    justify-content: flex-end;
    & .find-book-wrap {
      width: 200px;
      max-width: 200px;
      cursor: pointer;
      user-select: none;
      position: absolute;
      background-color: #fff;
      padding: 9px;
      border-radius: 8px;
      box-shadow: 0 5px 5px 0 rgba(184, 192, 199, 0.2);
      z-index: 2;
      &:has(.select-box.active) {
        box-shadow: 0 5px 5px 0 rgba(184, 192, 199, 0.5);
      }
      & .title {
        display: flex;
        align-items: center;
        justify-content: space-between;
        & .title-text {
          & .book-icon {
            margin-right: 5px;
          }
          display: flex;
          align-items: center;
          text-align: left;
          font-size: 20px;
          font-weight: bold;
        }
      }
      & .select-box {
        display: flex;
        flex-direction: column;
        gap: 5px;
        transition: all 0.3s;
        overflow: hidden;
        visibility: hidden;
        opacity: 0;
        height: 0;
        &.active {
          margin-top: 10px;
          transition: all 0.3s;
          visibility: visible;
          opacity: 1;
          height: auto;
          min-height: 171px;
        }
        & .select {
          height: 32px;
          width: 100%;
          & .custom-select {
            text-align: left;
            height: 100%;
            width: 100%;
          }
        }
        & .two-select {
          height: 32px;
          display: flex;
          gap: 5px;
          width: 100%;
          & .custom-select {
            text-align: left;
            height: 100%;
            width: 50%;
          }
        }
        & .book-nm-search {
          & .book-nm-input {
            height: 32px;
            text-align: left;
          }
        }

        & .btn-find-book {
          & button {
            margin-top: 10px;
            width: 100%;
            height: 50px;
            border-radius: 8px;
            background: #1e76af 0% 0% no-repeat padding-box;
            color: #fff;
            // box-shadow: inset -2px -3px #598bbd;
            font-size: 20px;
          }
        }
      }
    }
  }

  @media (max-width: 1400px) {
    -webkit-tap-highlight-color: transparent;
    margin-top: 21px;
    & .univ-book-quick-search-banner-wrap {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      justify-content: flex-end;
      & .find-book-wrap {
        position: absolute;
        width: 200px;
        max-width: 200px;
        & .title {
          & > svg {
            height: 20px;
          }
          font-size: 17.5px;
        }
        & .select-box {
          & .select,
          & .two-select {
            & > div {
              font-size: 12px;
            }
          }
          & .btn-find-book {
            & > button {
              font-size: 15px;
              & > span {
                margin-right: 3px;
                & > svg {
                  height: 17px;
                }
              }
            }
          }
        }
      }
    }
  }
}
