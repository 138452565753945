//variables
@import "./variable";

// page
@import "./Main/Main.scss";
@import "./SignIn/SignIn.scss";
@import "./SignUp/SignUp.scss";
@import "./UserInquiry/UserInquiry.scss";
@import "./UserInquiry/UserInquirySuccess.scss";
@import "./SearchResult/SearchResult.scss";
@import "./Payment/Payment.scss";
@import "./Payment/PaymentResult.scss";
@import "./ProductDetail/ProductDetail.scss";
@import "./Field/Field.scss";
@import "./Field/FieldDetail.scss";
@import "./Partner/Partner.scss";
@import "./NewBook/NewBook.scss";
@import "./RecommendedBook/RecommendedBook.scss";
@import "./RecommendedBook/RecommendedBookDetail.scss";
@import "./Event/EventMain/EventMain.scss";
@import "./Event/EventPlan/EventPlanMain.scss";
@import "./ModooMall/ModooMall.scss";
@import "./Support/Support.scss";
@import "./Mypage/MyPageMain.scss";
@import "./Mypage/MyPageMileageHistory.scss";
@import "./Mypage/CouponList.scss";
@import "./Mypage/EditProfile.scss";
@import "./Mypage/Widthdraw.scss";
@import "./Mypage/OrderHistory.scss";
@import "./Mypage/Inquiry.scss";
@import "./Mypage/ShippingAddress.scss";
@import "./Mypage/LikeListMain.scss";
@import "./Mypage/Review.scss";
@import "./Mypage/MyPageMobileMenu.scss";
@import "./Mall/Mall.scss";
@import "./MallCommunity/MallCommunity.scss";
@import "./AboutModoo/AboutModoo.scss";
@import "./CartView/CartView.scss";

// common
@import "./SideButtonUI.scss";
@import "./Header.scss";
@import "./Footer.scss";
@import "./WaitError.scss";
@import "./fonts.scss";
@import "./NoSearchData.scss";

// @import "./ConfirmPassword.scss";

// components
@import "./components/SixBookSwiperPresenter.scss";
@import "./components/MainExhibitionWithSwiperPresenter.scss";
@import "./components/RecommendedBookPickerPresenter.scss";
@import "./components/PricePresenter.scss";
@import "./components/BookListPresenter.scss";
@import "./components/BtnBookListPresenter.scss";
@import "./components/RecommendedBookPresenter.scss";
@import "./components/MallBookInfoPresenter.scss";
@import "./components/TemplateBookRecommendation.scss";
@import "./components/MobileBookRowPresenter.scss";
@import "./components/InquiryBooksPresenter.scss";
@import "./components/MallCardPresenter.scss";
@import "./components/FieldPresenter.scss";
@import "./components/MobileStickyFooter.scss";

// modal
@import "./Modal/index.scss"; // modal

// 구내서점
@import "./UnivBook/UnivBook.scss";
@import "./UnivBook/UnivGoods.scss";

.bold {
  font-weight: bold;
}

html,
body {
  // 스크롤 custom
  &::-webkit-scrollbar {
    width: 0px;
    height: 10px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: #1e76af;
    /* 스크롤바 둥글게 설정    */
    border-radius: 10px;
    height: 50px;
    // background-clip: padding-box;
    // border: 2px solid transparent;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  text-align: center;
  // 복사 방지
  // -webkit-user-select: none;
  // -moz-user-select: none;
  // -ms-user-select: none;
  // user-select: none;
  overflow-x: hidden;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  &::-webkit-scrollbar {
    display: none; /* Chrome, Safari, Opera*/
  }
  min-width: 1400px;
  font-family: $notoSansKr !important;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    overflow-x: hidden;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #0070f3;
  border-top-color: transparent;
  border-radius: 50%;
  animation: spinner 1s linear infinite;
  margin: auto;
}

.spinner-container {
  position: fixed;
  top: calc(50% - 25px);
  left: calc(50% - 25px);
  z-index: 100001;
}

.skeleton-container {
  display: flex;
  align-items: center;
  width: 1200px;
  height: 655px;
}

.LazyLoad {
  opacity: 0;
}

.is-visible {
  opacity: 1;
}

.sticky-top {
  position: sticky;
  top: 0;
}

button {
  cursor: pointer;
  border: none;
  background-color: white;
  color: black;
  font-family: "Noto Sans KR", sans-serif !important;
}

.ellipsis {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  width: inherit;
}

.MuiModal-root {
  z-index: 500000 !important;
}

#bg_layer {
  z-index: 999998 !important;
}

#nice_layer {
  z-index: 999999 !important;
}

pre {
  font-family: "Noto Sans KR", sans-serif !important;
  white-space: pre-wrap;
}

.flex {
  display: flex;
}

.MuiTooltip-tooltip {
  font-size: 14px !important;
  word-break: keep-all;
}
