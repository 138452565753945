& .side-menu {
  display: flex;
  flex-direction: column;
  width: 126px;
  margin-left: auto;
  & .title {
    font-size: 24px;
    font-weight: bold;
  }
  & .menu {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 32px;
    & .menu-title {
      font-size: 14px;
      font-weight: bold;
      margin: 4px 0;
    }
    & .menu-item {
      cursor: pointer;
      font-size: 13px;
      margin: 8px 0;
      &.selected {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
  // @media screen and (max-width: 1400px) {
  //   min-width: 335px;
  //   width: auto;
  //   max-width: 430px;
  //   display: none;

  //   & .title {
  //     display: none;
  //   }
  //   & .menu {
  //     display: none;
  //     & .menu-title {
  //       display: none;
  //       & .menu-item {
  //         display: none;
  //         &.selected {
  //           display: none;
  //         }
  //       }
  //     }
  //   }
  // }
}
