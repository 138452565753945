& .information-presenter {
  width: 1400px;
  margin: 0 auto 120px;
  @media screen and (max-width: 1400px) {
    min-width: 320px;
    width: calc(100vw - 40px);
    max-width: 440px;
    margin: 30px auto;
  }
  & .menu-title {
    font-size: 24px;
    line-height: 40px;
    font-weight: bold;
    text-align: left;
    @media screen and (max-width: 1400px) {
      font-size: 17.5px;
      line-height: 25.5px;
    }
  }
  & .menu-body {
    margin-top: 24px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 16px;
    display: flex;
    flex-direction: column;
    padding: 40px;
    @media screen and (max-width: 1400px) {
      background: white 0% 0% no-repeat padding-box;
      padding: 0;
    }
    & .row-container {
      display: flex;
      text-align: left;
      font-size: 14px;
      @media screen and (max-width: 1400px) {
        flex-direction: column;
        font-size: 12px;
      }
      & .title {
        width: 200px;
        font-weight: bold;
        margin-right: 20px;
        @media screen and (max-width: 1400px) {
          width: 230px;
        }
      }
      & .content {
        width: 1058px;
        line-height: 28px;
        margin-bottom: 28px;
        word-break: break-all;
        @media screen and (max-width: 1400px) {
          width: 100%;
          margin-top: 10px;
          padding-bottom: 20px;
          border-bottom: 1px solid #f5f5f5;
        }
      }
    }
  }
}
