& .middle-banner-container {
  cursor: pointer;
  margin: 80px auto 0;
  & img {
    width: 100%;
    max-width: 1920px;
  }
  @media screen and (max-width: 1400px) {
    margin: 50px auto 0;
    & img {
      min-width: 360px;
      width: 100%;
      max-width: 480px;
    }
  }
}
