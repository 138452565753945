#signup-terms-modal {
  width: 540px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 15px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    width: 100%;
    max-width: 480px;
    padding: 15px;
    border-radius: 15px;
  }

  & .terms-header {
    text-align: left;
    border-bottom: 1px solid #777777;
  }
  & .terms-bottom {
    margin-top: 10px;
  }
  & .terms-content {
    margin-top: 20px;
    text-align: left;
    max-height: 60vh;
    overflow-y: auto;
    border-radius: 10px;
    padding: 0 10px 0 5px;

    & pre {
      margin: 0;
      font: normal normal normal 14px/2em Noto Sans KR;
      letter-spacing: 0;
    }

    &::-webkit-scrollbar {
      width: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #eee;
      border-radius: 10px;
    }
  }
}
