#postcode-modal {
  // width: 540px;
  // height: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  & .bottom-area {
    margin-top: 2%;
    & button {
      color: #aaa;
    }
  }
}
