& .notice-wrapper {
  // padding-top: 80px;
  width: 100%;
  // height: 179px;
  & .notice-container {
    // max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .notice-title-container {
      display: flex;
      & .title-text {
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
      }
      & .more-button {
        margin-left: auto;
        font-size: 14px;
        color: black;
      }
    }
    & .body-container {
      height: 207px;
      & .list {
        margin: 8px 0 8px 0;
        color: var(---000000-text-);
        text-align: left;
        font: normal normal normal 15px/28px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        padding: inherit;
        & .css-518kzi-MuiListItemSecondaryAction-root {
          right: 5px;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
        }
        & .title-text {
          cursor: pointer;
        }
      }
    }
  }
}
