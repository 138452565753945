& .faq-main-wrapper {
  margin-top: 80px;
  display: flex;
  @import "./SupportSideBar.scss";
  & .faq-main-container {
    margin-left: 150px;
    margin-right: auto;
    width: 1164px;
    & .faq-main-title {
      text-align: left;
      font: normal normal bold 24px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    & .faq-search-container {
      margin-top: 40px;
      display: flex;
      .text-bar {
        width: 400px;
        height: 56px;
        border: 1px solid var(---bababa-line_darker-);
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #bababa;
        border-radius: 8px;
        opacity: 1;
      }
      .search-btn {
        width: 160px;
        height: 56px;
        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;

        background: var(---1e76af-key-color-) 0% 0% no-repeat padding-box;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        margin-left: 16px;
      }
    }
    & .faq-tab-wrapper {
      margin-top: 32px;
      margin-bottom: 80px;
      & .faq-tab-container {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
      }
      & .MuiBox-root {
        padding: 0;
        border-radius: 8px 8px 0px 0px;
        background: #f3f8fb 0% 0% no-repeat padding-box;
      }
      .tab-active {
        width: 118px;
        height: 56px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 8px 8px 0px 0px;
      }
      .tab-none {
        width: 118px;
        height: 56px;
        background: #ffffff00 0% 0% no-repeat padding-box;
        border-radius: 8px 8px 0px 0px;
      }
      .pagination-btn {
        margin-top: 48px;
        align-items: center;
        text-align: left;
        font: normal normal normal 13px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
      }
      & .table-container {
        margin-top: 32px;
        display: table;
        .thead {
          color: var(---777777-sub-text-);
          text-align: left;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
          width: 10%;
        }
        .tbody {
          text-align: left;
          font: normal normal normal 15px/27px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        .arrow {
          text-align: right;
        }
        .css-1rymryn-MuiTableCell-root {
          border-bottom: 1px;
        }
        .detail-content {
          background: #f5f5f5 0% 0% no-repeat padding-box;
          & .detail-text {
            text-align: left;
            font: normal normal normal 14px/28px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            white-space: pre-wrap;
          }
        }
        .letter-Q {
          text-align: left;
          font: normal normal normal 15px/30px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
          opacity: 1;
        }
        .letter-A {
          text-align: left;
          font: normal normal normal 16px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
        }
      }
    }
  }
  @import "./SupportSideBar.scss";
}
