#Inquiry-list-presenter {
  display: flex;
  flex-direction: column;

  & .divider {
    width: 1164px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #777777;
    @media screen and (max-width: 1400px) {
      width: auto;
    }
  }
  & .select-checkbox {
    height: 32px;
    width: 32px;
  }
  & .title-bar-container {
    height: 64px;
    padding: 16px 0;
    display: flex;
    align-items: center;
    & .text {
      text-align: left;
      font: normal normal normal 16px/24px Noto Sans KR;
      letter-spacing: 0px;
      color: #333333;
      opacity: 1;
    }
    & .sort-button {
      color: #777777;
      &.first {
        margin-left: auto;
      }
      &.selected {
        color: black;
        font-weight: bold;
      }
    }
    & .divider {
      height: 12px;
      width: 1px;
      background-color: #e8e8e8;
      margin: auto 12px;
    }
  }
  & .list-row-container {
    display: flex;
    padding: 40px 0;
    border-top: 1px solid #e8e8e8;
    display: flex;
    & .book-img {
      margin-left: 40px;
      @media screen and (max-width: 1400px) {
        margin-left: 0;
        width: 60px;
        & .is-visible {
          width: 60px !important;
        }
      }
      img {
        background: #f3f8fb 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 10px #00000029;
        border-radius: 8px;
        cursor: pointer;
        @media screen and (max-width: 1400px) {
          width: 60px;
          box-shadow: 0px 2.5px 5px #00000029;
          border-radius: 4px;
        }
      }
    }
    & .book-info-container {
      display: flex;
      flex-direction: column;
      width: 35%;
      margin-left: 48px;
      text-align: left;
      @media screen and (max-width: 1400px) {
        margin-left: 20px;
        width: auto;
        & .inquiry-count-container {
          width: auto;
          margin-top: 10px;
        }
      }
      & .book-title-container {
        font-size: 18px;
        font-weight: bold;
        width: 360px;
        cursor: pointer;
        display: block;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        @media screen and (max-width: 1400px) {
          min-width: 240px;
          width: calc(100vw - 120px);
          max-width: 360px;
        }
      }
      & .book-info-text {
        margin-top: 8px;
        color: #777777;
        font-size: 12px;
        line-height: 24px;
        display: flex;
        & div {
          height: 8px;
          width: 1px;
          background-color: #777777;
          margin: auto 8px;
        }
      }
      & .book-content {
        margin-top: 24px;
        text-align: left;
        font: normal normal normal 14px/23px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
    & .inquiry-count-container {
      display: flex;
      width: 25%;
      margin-top: 45px;
      @media screen and (max-width: 1400px) {
        width: auto;
      }
      & .book-inquiry-middle-btn {
        width: 80px;
        height: 48px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #bababa;
        border-left: 0px;
        border-right: 0px;
        opacity: 1;
      }
      & .book-inquiry-btn-p {
        text-align: center;
        width: 48px;
        height: 48px;
        border-left: 0px;
        border-radius: 0px 8px 8px 0px;
        opacity: 1;
      }
      & .book-inquiry-btn-m {
        text-align: center;
        width: 48px;
        height: 48px;
        border-radius: 8px 0px 0px 8px;
        border-right: 0px;
        opacity: 1;
      }
    }
    & .shipping-type-container {
      & .shipping-type-text {
        margin-left: 130px;
        margin-top: 48px;
        width: 52px;
        height: 20px;
        text-align: left;
        font: normal normal normal 14px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
  }
}
