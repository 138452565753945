& .field-new-book-wrapper {
  padding-top: 80px;

  & .title-container {
    display: flex;
    & .title-text {
      font-size: 24px;
      font-weight: bold;
    }
  }

  @import "./FieldBookSwiperPresenter.scss";

  @media screen and (max-width: 1400px) {
    width: 100vw;

    & .title-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      margin: auto;
      & .title-text {
        font-size: 17.5px;
      }
    }
  }
}
