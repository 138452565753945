& .mall-main-banner {
  display: flex;
  flex-direction: column;
  height: 320px;
  color: white;
  & .title {
    font-size: 32px;
    font-weight: bold;
    margin-top: -250px;
  }
  & .comment {
    font-size: 18px;
    margin-top: 20px;
  }
  & .cheer-count-container {
    display: flex;
    // width: 460px;
    max-width: 1400px;
    height: 72px;
    margin: 26px auto;
    background-color: #00000080;
    box-shadow: 0px 5px 10px #00000040;
    border-radius: 16px;
    padding: 16px 24px;
    font-size: 18px;
    & .smile-icon-container {
      margin-right: 5px;
    }
    & .cheer-text-container {
      width: 100%;
      display: flex;
      justify-content: space-between;
      line-height: 40px;
      & .cheer-text {
        margin-right: 20px;
        max-width: 1250px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      & .cheer-back {
        display: flex;
        line-height: 40px;
        & .cheer-count {
          margin-left: auto;
          margin-right: 8px;
          color: #b7916b;
          font-size: 24px;
          line-height: 38px;
        }
        & .cheer-count-text {
          line-height: 40px;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    height: auto;
    min-height: 240px;
    & > .LazyLoad {
      height: 240px;
      & img {
        width: 100vw;
        aspect-ratio: 2/1;
        min-width: 360px;
        max-width: 480px;
      }
    }
    & .title {
      font-size: 24px;
      margin-top: -170px;
      // mix-blend-mode: difference;
    }
    & .comment {
      font-size: 14px;
      margin-top: 10px;
      // mix-blend-mode: difference;
    }
    & .cheer-count-container {
      width: calc(100vw - 60px);
      min-width: 300px;
      max-width: 420px;
      height: 50px;
      margin: 25px auto;
      padding: 11px 15px;
      border-radius: 8px;
      font-size: 12.5px;
      line-height: 50px;
      & .smile-icon-container {
        height: 28px;
        margin-right: 5px;
        & .LazyLoad {
          line-height: 28px;
          & img {
            width: 28px;
            height: 28px;
          }
        }
      }
      & .cheer-text-container {
        & .cheer-text {
          height: 28px;
          line-height: 28px;
          margin-right: 4px;
          max-width: calc(100vw - 160px);
        }
        & .cheer-back {
          & .cheer-count {
            height: 28px;
            margin: 0;
            margin-right: 3px;
            font-size: 17.5px;
            line-height: 26px;
          }
          & .cheer-count-text {
            height: 28px;
            line-height: 28px;
          }
        }
      }
    }
  }
}
