& .ConfirmPassword {
  // display: flex;
  // flex-direction: column;
  // min-width: 1400px;
  // & .myPage-wrapper {
  //   display: flex;
  //   padding-top: 80px;
  //   // flex-direction: column;
  //   @import "./Mypage/SideMenu.scss";
  // }
  // & .mypage-container {
  //   // margin: 48px 260px 120px 370px;
  //   margin: 0 auto 120px 120px;
  //   display: flex;
  //   width: 1164px;
  //   flex-direction: column;

  & .title-container {
    display: flex;
    margin-bottom: 24px;
    // align-items: flex-end;
    & .title-text {
      text-align: left;
      font: normal normal bold 24px/26px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
  }
  & .body {
    display: flex;
    flex-direction: column;
    text-align: left;
    & .info {
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      margin: 32px 0;
    }
    & .password-container {
      margin-bottom: 80px;
      & .title {
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 12px;
      }
      & .helpText {
        // margin: 12px 0;
        text-align: left;
        font: normal normal normal 14px/28px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
      }
      & .errorText {
        text-align: left;
        font: normal normal normal 13px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ec1f2d;
        margin: 0;
      }
    }
    & .password-input {
      background: #ffffff 0% 0% no-repeat padding-box;
      // border: 1px solid #bababa;
      border-radius: 8px;
      width: 416px;
      height: 56px;
    }
    & .MuiFormControl-root {
      margin: 0;
    }

    & .isEdit-button-container {
      display: flex;
      justify-content: space-around;
      & .button {
        margin-top: 40px;
        width: 160px;
        height: 56px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }

    & .button-container {
      display: flex;
      justify-content: center;
      & .cancel-button {
        margin-top: 40px;
        margin-right: 16px;
        width: 160px;
        height: 56px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 8px;
      }
      & .button {
        margin-top: 40px;
        margin-right: 16px;
        width: 160px;
        height: 56px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }

    & .req-text {
      text-align: left;
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
    }
    & .bottom-info {
      display: flex;
      flex-direction: column;
      text-align: left;
      font: normal normal normal 14px/28px Noto Sans KR;
      letter-spacing: 0px;
      color: #777777;
    }
  }
  // }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    height: auto;

    & .title-container {
      display: flex;
      margin-bottom: 24px;
      // align-items: flex-end;
      & .title-text {
        text-align: left;
        font-size: 17px;
        font-weight: bold;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
    & .body {
      display: flex;
      flex-direction: column;
      text-align: left;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      & .info {
        display: none;
      }
      & .password-container {
        margin: 15px 0 30px 0;
        & .title {
          font-size: 14px;
          letter-spacing: 0px;
          color: #000000;
          margin-bottom: 12px;
        }
        & .helpText {
          // margin: 12px 0;
          text-align: left;
          font-size: 13px;
          letter-spacing: 0px;
          color: #777777;
        }
        & .errorText {
          text-align: left;
          font-size: 13px;
          letter-spacing: 0px;
          color: #ec1f2d;
          margin: 0;
        }
      }
      & .password-input {
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 8px;
        height: 48px;
        width: 100%;
      }
      & .MuiFormControl-root {
        margin: 0;
        width: 100%;
      }

      & .isEdit-button-container {
        display: flex;
        justify-content: space-around;
        & .button {
          margin-top: 40px;
          width: 160px;
          height: 56px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 8px;
          text-align: center;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }

      & .button-container {
        display: flex;
        justify-content: space-between;
        margin-bottom: 50px;
        & .cancel-button {
          margin-top: 40px;
          // margin-right: s16px;
          width: 161px;
          height: 48px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #1e76af;
          border-radius: 8px;
          font-size: 14px;
          font-weight: normal;
        }
        & .button {
          margin-top: 40px;
          width: 161px;
          height: 48px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 8px;
          text-align: center;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #ffffff;
          margin-right: 0;
        }
      }

      & .req-text {
        margin-top: 30px;
        margin-right: 0;
        & .css-1crb08l-MuiButtonBase-root-MuiCheckbox-root {
          margin-top: -24px;
        }
        & .css-j204z7-MuiFormControlLabel-root .MuiFormControlLabel-label {
          font-size: 14px;
        }
      }
      & .bottom-info {
        display: flex;
        flex-direction: column;
        text-align: left;
        font-size: 13px;
        letter-spacing: 0px;
        color: #777777;
        padding: 20px 5px 20px 28px;
      }
    }
  }
}
