#book-list-presenter {
  display: flex;
  flex-direction: column;
  & .select-checkbox {
    height: 32px;
    width: 32px;
  }
  & .title-bar-container {
    border-top: 1px solid #e8e8e8;
    height: 64px;
    padding: 16px 0;
    display: flex;
    & button:first-of-type {
      margin-left: auto;
    }
    & .button {
      width: 80px;
      height: 32px;
      margin-left: 12px;
      cursor: pointer;
      &.cart {
        width: 88px;
      }
      &.excel-download {
        width: 130px;
      }
    }
    & .sort-button {
      color: #777777;
      &.first {
        margin-left: auto;
      }
      &.selected {
        color: black;
        font-weight: bold;
      }
    }
    & .divider {
      height: 12px;
      width: 1px;
      background-color: #010000;
      margin: auto 12px;
    }
  }
  & .list-row-container {
    display: flex;
    padding: 40px 0;
    border-top: 1px solid #e8e8e8;
    & .title-container.no-search-result {
      margin: auto;
      text-align: center;
    }
    & .book-img {
      width: 120px;
      margin-left: 40px;
      cursor: pointer;
      position: relative;
      // 오프라인 전용 도서
      // TODO book-img 클래스를 가지고 있는 오프라인 전용 도서에게 offline-book 클레스를 적용하면된다.
      &.offline-book {
        &::after {
          content: "";
          position: absolute;
          top: -8px;
          right: 50%;
          background: url(../../imgs/off_line.svg) no-repeat;
          width: 55px;
          height: 130px;
        }
      }
      & img {
        width: 120px;
        box-shadow: 0px 5px 10px #00000029;
        border-radius: 8px;
      }
    }
    & .book-info-container {
      display: flex;
      flex-direction: column;
      width: 538px;
      margin: 0 48px;
      text-align: left;
      & .classification {
        height: 27px;
        & .classification-text {
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          width: fit-content;
          padding: 0 12px;
          font: normal normal normal 12px/25px Noto Sans KR;
        }
      }
      & .book-title-container {
        margin-top: 20px;
        font-size: 18px;
        font-weight: bold;
        display: flex;
        cursor: pointer;
        & .more-button {
          width: 24px;
          height: 24px;
          margin: auto 0 auto 20px;
        }
      }
      & .book-info-text {
        margin-top: 8px;
        color: #777777;
        font-size: 12px;
        line-height: 24px;
        display: flex;
        & div {
          height: 8px;
          width: 1px;
          background-color: #777777;
          margin: auto 8px;
        }
      }
      & .bottom-line {
        margin-top: 48px;
        display: flex;
        & .heart-icon {
          width: 24px;
          height: 24px;
          display: flex;
          cursor: pointer;
          & div {
            display: flex;
            margin-top: auto;
          }
        }
        & .text {
          margin-left: 8px;
        }
        & .divider {
          height: 16px;
          width: 1px;
          background-color: #e8e8e8;
          margin: auto 16px;
        }
        & .comment-icon {
          width: 24px;
          height: 24px;
          display: flex;
          & div {
            display: flex;
            margin-top: auto;
          }
        }
      }
    }

    & .book-status-info {
      width: 324px;
      display: flex;
      align-items: center;
      justify-content: end;
      font-size: 22px;
      font-weight: bold;
    }

    & .price-container {
      margin: auto;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      & .cancel-line {
        text-decoration: line-through;
        color: #777777;
        line-height: 20px;
      }
      & .sale-percentage {
        background: #1e76af 0% 0% no-repeat padding-box;
        color: white;
        padding: 4px 8px;
        font-size: 12px;
        width: fit-content;
        margin: 8px auto 8px;
        border-radius: 4px;
      }
      & .price {
        margin-top: 0;
        font-size: 22px;
        font-weight: bold;
      }
    }
    & .button-container {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      width: 156px;
      & button {
        width: 156px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 20px;
        margin-right: 16px;
        border: 1px solid #1e76af;
        color: #1e76af;
        margin: auto 0 8px;
        & .button-text {
          display: flex;
          & .image {
            margin-left: auto;
            margin-top: 3px;
          }
        }
        &:nth-of-type(2) {
          background: #1e76af 0% 0% no-repeat padding-box;
          color: white;
          margin: 8px 0 auto;
        }
      }
    }
  }
  & .pagination-container {
    display: flex;
    & .pagination {
      margin: 16px auto 0;
      & button {
        color: #777777;
      }
      & .Mui-selected {
        background-color: white;
        color: #1e76af;
      }
    }
  }
}
