#review-modal {
  padding: 40px;
  width: 600px;
  background-color: white;
  border-radius: 20px;
  display: flex;
  flex-direction: column;
  text-align: left;
  & .title-container {
    font-size: 24px;
    font-weight: bold;
    display: flex;
    & .exit-button {
      margin-left: auto;
      cursor: pointer;
      & div {
        & img {
          width: 32px;
          height: 32px;
        }
      }
    }
  }
  & .book-container {
    margin-top: 20px;
    background: #f4f4f4 0% 0% no-repeat padding-box;
    border-radius: 8px;
    padding: 16px;
    display: flex;
    & .book-img img {
      width: 100px;
      height: 141px;
      box-shadow: 0px 5px 10px #00000029;
      border-radius: 8px;
      background-color: white;
    }
    & .book-detail {
      display: flex;
      flex-direction: column;
      margin-left: 20px;
      & .title {
        font-weight: bold;
        max-width: 380px;
      }
      & .author-and-publisher {
        margin-top: 12px;
        font-size: 12px;
        color: #777777;
        display: flex;
        & span {
          height: 8px;
          width: 1px;
          margin: auto 8px;
          background-color: #777777;
        }
      }
      & .rating-container {
        display: flex;
        margin-top: 24px;
        & .star-mod {
          display: flex;
          width: 24px;
          height: 24px;
          margin-right: 2px;
          cursor: pointer;
          & div {
            display: flex;
            margin: auto;
            & img {
              width: 18px;
              height: 18px;
              margin: auto;
            }
          }
        }
        & .rating-number {
          color: #1e76af;
          margin-left: 16px;
          & span {
            color: #777777;
          }
        }
      }
    }
  }
  & .write-review-title {
    margin-top: 24px;
  }
  & .input-container {
    margin-top: 16px;
  }
  & .error-text-container {
    display: flex;
    margin-top: 12px;
    font-size: 14px;
    color: #d32f2f;
    & .input-value-length {
      margin-left: auto;
      color: #777777;
    }
  }
  & .write-review-button {
    margin: 40px auto 0;
    width: 160px;
    height: 56px;
    background: #1e76af 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: white;
    font-size: 16px;
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    width: calc(100vw);
    max-width: 480px;
    padding: 20px;
    // width: 600px;
    background-color: white;
    display: flex;
    text-align: left;

    & .title-container {
      font-size: 17px;
      font-weight: bold;
      display: flex;
      & .exit-button {
        margin-left: auto;
        cursor: pointer;
        & div {
          & img {
            width: 24px;
            height: 24px;
          }
        }
      }
    }
    & .book-container {
      margin-top: 20px;
      background: #f4f4f4 0% 0% no-repeat padding-box;
      border-radius: 8px;
      padding: 20px;
      display: flex;
      & .book-img img {
        width: 80px;
        height: 114px;
        box-shadow: 0px 5px 10px #00000029;
        border-radius: 8px;
        background-color: white;
      }
      & .book-detail {
        display: flex;
        flex-direction: column;
        margin-left: 0px;
        max-width: 180px;
        & .title {
          text-align: left;
          font-size: 15px;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        & .author-and-publisher {
          margin-top: 16px;
          font-size: 12px;
          color: #777777;
          display: flex;
          & span {
            height: 8px;
            width: 1px;
            margin: auto 8px;
            background-color: #777777;
          }
        }
        & .rating-container {
          display: flex;
          margin-top: 16px;
          align-items: center;
          & .star-mod {
            display: flex;
            width: 20px;
            height: 20px;
            margin-right: 2px;
            cursor: pointer;
            & div {
              display: flex;
              margin: auto;
              & img {
                margin: auto;
                width: 14px;
                height: 14px;
              }
            }
          }
          & .rating-number {
            color: #1e76af;
            margin-left: 12px;
            font-size: 15px;
            font-weight: bold;
            & span {
              color: #777777;
            }
          }
        }
      }
    }
    & .write-review-title {
      margin-top: 30px;
      font-size: 15px;
      font-weight: normal;
    }
    & .MuiFormControl-root {
      margin-top: 20px;
      & .MuiInputBase-input {
        font-size: 13px;
      }
    }
    & .error-text-container {
      margin-top: 8px;
      & .input-value-length {
        font-size: 12px;
      }
      & .error-text {
        text-align: left;
        font-size: 13px;
        letter-spacing: 0px;
        color: #ec1f2d;
        opacity: 1;
      }
    }
    & .write-review-button {
      margin: 0;
      margin-top: 30px;
      width: 100%;
      height: 48px;
      text-align: center;
      font-size: 14px;
      letter-spacing: 0px;
      color: #ffffff;
      opacity: 1;
    }
  }
}
