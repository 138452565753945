& .story-container {
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  width: 1400px;

  & .body-container {
    margin-top: 32px;
    display: flex;
    & .left {
      display: flex;
      flex-direction: column;
      width: 830px;
      height: 634px;
      margin-right: 40px;
      cursor: pointer;
      & .img img {
        width: 830px;
        border-radius: 16px 16px 0 0;
        object-fit: cover;
      }
      & .text {
        height: 192px;
        border-radius: 0 0 16px 16px;
        padding: 32px;
        color: white;
        text-align: left;
        & .story-title {
          max-width: 766px;
          font-size: 24px;
          font-weight: bold;
        }
        & .story-comment {
          margin-top: 12px;
          font-size: 15px;
          line-height: 30px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;
        }
      }
    }
    & .right {
      display: flex;
      flex-direction: column;
      width: 530px;
      & .title-container {
        display: flex;
        flex-direction: column;
        & .title {
          font-size: 20px;
          text-align: left;
        }
      }
      & .recommended-book-list {
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        & .recommended-book {
          margin-bottom: 30px;
          display: flex;
          cursor: pointer;
          border-bottom: 1px solid #e8e8e8;
          & .img {
            width: 100px;
            margin-right: 24px;
            margin-bottom: 30px;
            & img {
              width: 100px;
              box-shadow: 0px 5px 10px #00000033;
              border-radius: 8px;
            }
          }
          & .book-info {
            display: flex;
            flex-direction: column;
            text-align: left;
            & .class {
              padding: 4px 12px;
              border: 1px solid #eeeeee;
              border-radius: 4px;
              width: fit-content;
              font-size: 12px;
              line-height: 17px;
            }
            & .title {
              margin-top: 16px;
              font-size: 18px;
              line-height: 32px;
              font-weight: bold;
            }
            & .comment {
              margin-top: 8px;
              font-size: 14px;
              line-height: 28px;
              width: 400px;
            }
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    width: calc(100vw - 40px);
    min-width: 320px;
    max-width: 440px;
    margin-top: 50px;

    & .title-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      & .title {
        font-size: 17.5px;
      }
    }

    & .body-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      display: inline;
      margin-top: 20px;
      & .left {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        height: auto;
        min-height: 260px;
        max-height: 320px !important;
        & .img {
          & .LazyLoad {
            width: calc(100vw - 40px);
            min-width: 320px;
            max-width: 440px;
            height: 180px !important;
            & img {
              width: calc(100vw - 40px);
              min-width: 320px;
              max-width: 440px;
              height: 180px !important;
              border-radius: 8px 8px 0 0;
            }
          }
        }
        & .text {
          height: auto !important;
          min-height: 80px !important;
          max-height: 140px !important;
          padding: 15px;
          border-radius: 0 0 8px 8px;
          & .story-title {
            font-size: 17.5px;
          }
          & .story-comment {
            font-size: 12px;
            margin-top: 6px;
          }
        }
      }
      & .right {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        margin-top: 20px;
        & .title-container {
          display: none;
        }
        & .recommended-book-list {
          margin-top: 0;
          // margin-bottom: 20px;
          & .recommended-book {
            // 첫 도서 정보 제외
            &:not(:first-of-type) {
              margin-top: 20px;
            }
            // 마지막 도서 정보
            &:last-of-type {
              border-bottom: none;
            }
            margin-bottom: 0;
            & .img {
              width: 80px;
              margin-right: 20px;
              margin-bottom: 30px;
              margin-bottom: 15px;
              & .LazyLoad {
                width: 80px !important;
                & img {
                  width: 80px;
                  border-radius: 4px;
                }
              }
            }
            & .book-info {
              width: calc(100vw - 100px);
              min-width: 220px;
              max-width: 340px;
              & .class {
                height: 20px;
                line-height: 10px;
                font-size: 10px;
              }
              & .title {
                font-size: 15px;
                margin: 0;
              }
              & .comment {
                width: 100%;
                min-width: 220px;
                max-width: 340px;
                margin-top: 0;
                font-size: 12px;
                line-height: 24px;
                overflow: hidden;
                text-overflow: ellipsis;
                display: -webkit-box;
                -webkit-box-orient: vertical;
                -webkit-line-clamp: 3;
                white-space: pre-line;
                // 메인 상세 소개글 삐져나가지 마시오
                word-break: normal !important;
              }
            }
          }
        }
      }
    }
    & .button-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      height: 48px !important;
      & .more-button {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        height: 48px;
        font-size: 13px;
        line-height: 48px;
        color: #777777;
        border: 1px solid #bababa;
        border-radius: 8px;
      }
    }
  }
}
