& .review-presenter {
  width: 1400px;
  margin: 0 auto 120px;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    width: 100%;
    max-width: 480px;
  }
  & .title-container {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    & .review-button {
      margin-left: auto;
      font-size: 16px;
      background-color: #1e76af;
      color: white;
      width: 155px;
      height: 56px;
      border-radius: 8px;
    }
  }
  & .rating-summary {
    margin-top: 24px;
    display: flex;
    padding: 40px 0;
    width: 1400px;
    height: 240px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    @media screen and (max-width: 1400px) {
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
      margin: 30px auto 0;
      flex-direction: column;
      padding: 20px;
      height: fit-content;
    }
    & .block {
      width: 659px;
      display: flex;
      @media screen and (max-width: 1400px) {
        width: 100%;
      }
      &.left {
        padding-left: 120px;
        border-right: 1px solid #e8e8e8;
        @media screen and (max-width: 1400px) {
          padding-left: 20px;
          padding-right: 20px;
          padding-bottom: 20px;
          border-right: none;
          border-bottom: 1px solid #e8e8e8;
        }
        & .title-text-container {
          display: flex;
          flex-direction: column;
          text-align: left;
          @media screen and (max-width: 1400px) {
            margin-left: auto;
          }
          & .title-text {
            margin-top: 48px;
            font-size: 24px;
            line-height: 35px;
            height: 35px;
            @media screen and (max-width: 1400px) {
              margin-top: 10px;
              font-size: 17.5px;
              line-height: 25.5px;
            }
          }
          & .title-sub-text {
            margin-top: 12px;
            font-size: 14px;
            @media screen and (max-width: 1400px) {
              margin-top: 8px;
              font-size: 12.5px;
            }
          }
        }
        & .rating-container {
          display: flex;
          flex-direction: column;
          margin-top: 40px;
          margin-left: 140px;
          @media screen and (max-width: 1400px) {
            padding: 0;
            margin: 0 0 0 30px;
            margin-right: auto;
          }
          & .rating-number {
            color: #1e76af;
            font-size: 24px;
            & span {
              color: #777777;
            }
          }
          & .rating-star {
            display: flex;
            margin-top: 12px;
            & div {
              width: 36px;
              height: 36px;
              @media screen and (max-width: 1400px) {
                width: 24px;
                height: 24px;
              }
              display: flex;
              & div {
                display: flex;
                margin: auto;
                & img {
                  margin: auto;
                  @media screen and (max-width: 1400px) {
                    width: 18px;
                    height: 18px;
                  }
                }
              }
            }
          }
        }
      }
      &.right {
        @media screen and (max-width: 1400px) {
          margin-top: 22px;
        }
        & .rating-container {
          display: flex;
          flex-direction: column;
          margin: auto;
          @media screen and (max-width: 1400px) {
            margin: 0 auto;
            & .rating:last-of-type {
              margin-bottom: 0;
            }
          }
          & .rating {
            display: flex;
            margin-bottom: 8px;
            & div {
              width: 24px;
              height: 24px;
              @media screen and (max-width: 1400px) {
                width: 18px;
                height: 18px;
              }
              display: flex;
              & div {
                display: flex;
                margin: auto;
                & img {
                  margin: auto;
                  @media screen and (max-width: 1400px) {
                    width: 13.5px;
                    height: 13.5px;
                  }
                }
              }
            }
            & .progress-bar {
              width: 160px;
              border-radius: 2px;
              margin: auto 16px;
              background-color: #bababa;
              @media screen and (max-width: 1400px) {
                width: 120px;
              }
            }
            & .percentage {
              font-size: 20px;
              margin-top: -5px;
              color: #1e76af;
              & span {
                font-size: 13px;
                margin-top: 8px;
              }
              @media screen and (max-width: 1400px) {
                font-size: 15px;
                margin-right: 10px;
                & span {
                  font-size: 12px;
                  margin-top: 3px;
                }
              }
            }
          }
        }
      }
    }
  }
  & .mobile-review-button {
    background: #1e76af 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;
    min-width: 320px;
    width: calc(100vw - 40px);
    max-width: 440px;
    margin: 20px auto 0;
    height: 48px;
    font-size: 15px;
    color: white;
  }
  & .review-container {
    display: flex;
    flex-direction: column;
    width: 1400px;
    @media screen and (max-width: 1400px) {
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
      margin: 30px auto 0;
    }
    & .button-container {
      margin-top: 40px;
      display: flex;
      font-size: 14px;
      padding-bottom: 16px;
      border-bottom: 1px solid #e8e8e8;
      & .sort-button {
        color: #777777;
        &:first-of-type {
          margin-left: auto;
        }
        &.selected {
          color: black;
        }
      }
      & div {
        height: 16px;
        border-left: 1px solid #e8e8e8;
        margin: auto 16px;
      }
    }
    & .comment-row {
      display: flex;
      flex-direction: column;
      padding-bottom: 36px;
      border-bottom: 1px solid #e8e8e8;
      color: #777777;
      @media screen and (max-width: 1400px) {
        padding-bottom: 20px;
      }
      & .first-row {
        display: flex;
        margin-top: 16px;
        @media screen and (max-width: 1400px) {
          margin-top: 20px;
          font-size: 12px;
        }
        & .divider {
          height: 18px;
          width: 1px;
          border-left: 1px solid #e8e8e8;
          margin: auto 16px;
        }
        & div {
          width: 24px;
          height: 24px;
          @media screen and (max-width: 1400px) {
            width: 18px;
            height: 18px;
          }
          display: flex;
          & div {
            display: flex;
            margin: auto;
            & img {
              margin: auto;
              @media screen and (max-width: 1400px) {
                width: 13.5px;
                height: 13.5px;
              }
            }
          }
        }
      }
      & .second-row {
        margin-top: 26px;
        text-align: left;
        color: black;
        white-space: pre-wrap;
        @media screen and (max-width: 1400px) {
          font-size: 12px;
          margin-top: 20px;
        }
      }
    }
    & .pagination-container {
      display: flex;
      & .pagination {
        margin: 40px auto 0;
        & button {
          color: #777777;
        }
        & .Mui-selected {
          background-color: white;
          color: #1e76af;
        }
      }
    }
  }
}
