#RecommendedBook {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .recommende-book-container {
    display: flex;
    flex-direction: column;
    max-width: 1400px;
    margin: auto;
    & .book-container {
      display: flex;
      width: 600px;
      justify-content: flex-start;
      // margin: -267px 120px 200px auto;
      margin: 160px 120px 0px auto;
      min-height: 240px;
      // margin-left: auto;
      // margin-right: 120px;
      // margin-top: -267px;
      // margin-bottom: 200px;
      z-index: 1;
      & .book-img {
        width: 160px;
        margin-right: 60px;
        border-radius: 8px;
        display: flex;
        margin-top: auto;
        & div {
          margin-top: auto;
          display: flex;
        }
        & img {
          width: 160px;
          object-fit: cover;
          border-radius: 8px 8px 0 0;
          cursor: pointer;
        }
      }
    }
    & .main-banner {
      margin: -306px auto 80px;
      width: 1400px;
      height: 306px;
      background: #3371ac 0% 0% no-repeat padding-box;
      border-radius: 20px;
      object-fit: cover;
    }
    & .title-container {
      height: 35px;
      text-align: left;
      & .title-text {
        font: normal normal bold 24px/26px Noto Sans KR;
      }
    }
    & .first-side {
      display: flex;
      width: 1400px;
      margin-top: 40px;
      & .first-book-img-zone {
        width: 400px;
        & .classification {
          margin-bottom: 32px;
          padding: 4px 12px;
          width: fit-content;
          height: 25px;
          line-height: 25px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          font: normal normal normal 12px/17px Noto Sans KR;
        }
        & .title-container {
          display: flex;
          margin-top: 20px;
          height: 26px;
          & .title {
            max-width: 400px;
            font-size: 18px;
            font-weight: bold;
          }
          & .more-icon {
            margin-top: 2px;
            cursor: pointer;
            margin-left: 20px;
          }
        }
        & .sub-title-container {
          // display: flex;
          margin-top: 8px;
          text-align: left;
          & div {
            font-size: 12px;
            color: #777777;
            margin-bottom: 5px;
          }
          & .divider {
            width: 0px;
            height: 10px;
            margin: auto 8px;
            border-left: 1px solid #000000;
            opacity: 0.5;
          }
        }
        & .etc-book {
          display: flex;
          flex-direction: column;
          margin-top: 119px;
          & .etc-title-container {
            display: flex;
            margin-bottom: 16px;
            & .etc-title {
              display: flex;
              text-align: left;
              font: normal normal normal 14px/24px Noto Sans KR;
            }
          }
          & .img-body {
            display: flex;
            & .book-img {
              cursor: pointer;
              width: 100px;
              flex-direction: column;
              margin-right: 16px;
              & img {
                width: 100px;
                box-shadow: 0px 5px 10px #00000029;
                border-radius: 8px;
                opacity: 0.5;
              }
            }
          }
        }
      }
      & .first-image-body {
        display: flex;
        margin-left: 180px;
        & .first-book-image {
          cursor: pointer;
          width: 260px;
          & img {
            width: 260px;
            border-radius: 8px;
          }
        }
      }
      & .first-content-body {
        display: flex;
        flex-direction: column;
        margin: 0 0 0 240px;
        & .first-book-content {
          width: 316px;
          height: 405px;
          & .first-book-onelineComment {
            height: 105px;
            text-align: left;
            font: normal normal bold 20px/38px Noto Sans KR;
            margin-bottom: 24px;
          }
          & .first-book-comment {
            text-align: left;
            font: normal normal normal 14px/28px Noto Sans KR;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          & .price-container {
            display: flex;
            width: auto;
            height: 40px;
            margin-top: 20px;
            & :nth-of-type(1) {
              margin: auto;
              margin-right: 0;
              margin-left: 0;
              width: 42px;
              height: 25px;
              padding: 4px 8px;
              color: white;
              border-radius: 4px;
              background: #1e76af 0% 0% no-repeat padding-box;
              font-size: 12px;
            }
            & :nth-of-type(2) {
              margin-left: 12px;
              font-size: 28px;
              font-weight: bold;
            }
            & :nth-of-type(3) {
              font-size: 14px;
              margin: auto 12px 7px 4px;
            }
            & :nth-of-type(4) {
              text-decoration: line-through;
              font-size: 16px;
              font-weight: normal;
              color: #777777;
              margin-top: 9px;
            }
          }
          & .button-container {
            display: flex;
            & .buy-button {
              margin-left: 16px;
            }
            & button {
              width: 156px;
              height: 56px;
              border-radius: 8px;
              padding: 16px 20px;
              margin-right: 16px;
              border: 1px solid #1e76af;
              color: #1e76af;
              margin: auto auto 8px;
              & .button-text {
                display: flex;
                & .image {
                  margin-left: auto;
                  margin-top: 3px;
                }
              }
              &:nth-of-type(2) {
                margin-top: 80px;
                background: #1e76af 0% 0% no-repeat padding-box;
                color: white;
              }
            }
          }
        }
      }
    }
    & .detail-container {
      display: flex;
      flex-wrap: wrap;
      width: 1400px;
      padding-top: 80px;
      margin-bottom: 120px;
      & .category-container {
        width: 25%;
      }
      & .category-item {
        padding: 0;
        width: 340px;
        height: 60px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        border-radius: 8px;
        margin-right: auto;
        margin-bottom: 8px;
        text-align: center;
        font: normal normal normal 16px/24px Noto Sans KR;
        color: #000000;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .recommende-book-container {
      margin: 0 auto;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      & .book-container {
        width: 100%;
        margin: 0;
        border-radius: 16px;
        min-height: 250px;
        // padding: 20px;
        max-height: 480px;
        overflow-x: hidden;
        justify-content: center;
        & .book-img {
          margin: 0;
          margin-top: auto;
          justify-content: center;
          & .LazyLoad {
            margin: 0;
            & img {
              width: 80px;
              // height: 110px;
              border-radius: 8px;
            }
          }
        }
      }
      & .main-banner {
        width: 100%;
        height: 250px;
        border-radius: 16px;
        margin: -250px auto 30px;
      }
      & .title-container {
        height: auto;
        margin-bottom: 20px;
        & .title-text {
          text-align: left;
          font-size: 17px;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
      & .recommand-book-wrapper {
        width: 100%;
      }

      & .swiper {
        min-width: 320px;
        max-width: 440px;
        height: 500px;
        width: calc(100vw - 40px);
        & .swiper-pagination {
          // margin-top: 20px;
        }
        & .swiper-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: flex;
          transition-property: transform;
          box-sizing: content-box;
          & .swiper-slide {
            & .image-box-wrapper {
              background: #f5f5f5 0% 0% no-repeat padding-box;
              border-radius: 16px;
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;
              & .check-icon {
                display: flex;
                justify-content: end;
                margin-right: 10px;
                margin-top: 10px;
                & .LazyLoad {
                  margin: 0;
                  width: auto;
                  height: auto;
                  min-height: auto;
                  background: none;
                  & img {
                    width: 30px;
                    margin: 0;
                  }
                }
              }
              & .swiper-slide-img {
                margin-bottom: 40px;
                & .LazyLoad {
                  opacity: 1;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-self: center;
                  & img {
                    width: 130px;
                    box-shadow: 0px 4px 8px #00000029;
                    border-radius: 8px;
                  }
                }
              }
            }
            & .event-hashtag-container {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 10px;
              overflow: hidden;
              max-height: 48px;

              & .hashtag-text {
                height: 16px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #e8e8e8;
                border-radius: 4px;
                opacity: 1;
                text-align: left;
                font: normal normal normal 10px/14.5px Noto Sans KR;
                letter-spacing: 0px;
                color: #0a0a0a;
                margin: 0 6px 6px 0;
                padding: 1px 4px;
              }
            }
            & .event-recommand-text {
              text-align: left;
              font: normal normal bold 15px/16px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
              margin-bottom: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            & .event-recommand-name {
              min-width: 80px;
              // display: flex;
              text-align: left;
              font: normal normal normal 12px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #777777;
              opacity: 1;
              margin-bottom: 14px;
              & .text-box {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                height: 17px;
                line-height: 17px;
                margin-bottom: 3px;
              }
              & .line-box {
                margin: auto 8px;
                width: 1px;
                height: 8px;
                background-color: #777777;
              }
            }
            & .event-recommand-priceContainer {
              display: flex;
              align-items: center;
              & .eventPer {
                background: #1e76af 0% 0% no-repeat padding-box;
                border-radius: 4px;
                opacity: 1;
                font: normal normal bold 11px/10px Noto Sans KR;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
                width: 31.5px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              & .eventprice {
                text-align: left;
                font-size: 14px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                font-weight: bold;
                margin-left: 12px;
              }
              & .eventPriceUnit {
                text-align: left;
                font-size: 12px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                margin-left: 2px;
              }
              & .eventBefPrice {
                text-align: center;
                text-decoration: line-through;
                font-size: 12px;
                letter-spacing: 0px;
                color: #777777;
                opacity: 1;
                margin-left: 12px;
              }
            }
            & .swiper-slide-active {
              min-width: 320px;
              width: calc(100vw - 40px);
              max-width: 440px;
              cursor: pointer;
            }
          }
        }
      }
      & .detail-container {
        padding: 0;
        width: 100%;
        margin-bottom: 50px;
        justify-content: space-between;
        margin-top: 25px;
        & .category-container {
          width: 49%;
          & button {
            width: 100%;
            text-align: center;
            font-size: 13px;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
      }
    }
  }
}
