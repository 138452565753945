#SignUp {
  display: flex;
  flex-direction: column;
  & .signup-main-container {
    margin: auto;
    min-height: 55vh;

    & .result-wrapper {
      display: flex;
      flex-direction: column;
      margin: auto;
      padding-top: 84px;

      & > div {
        padding: 0 0 10px 0;
      }
      & .result-header {
        color: #000;
        font-weight: 700;
        font-size: 24px;
      }
    }
    & .check-success {
      color: #1e76af;
      font-size: 12px;
      margin-top: 2px;
      margin-left: 4px;
    }

    .joinWrap {
      width: 100%;
      padding: 84px 0 80px 0;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        width: calc(100% - 40px);
        max-width: 440px;
        margin: 0 auto;
      }
      // @media screen and (max-width: 1400px) {
      //   width: 100%;
      // }
      & * {
        text-align: initial;
      }
      & #quickLimit {
        position: relative;
        width: 100%;

        & .layerInBx {
          position: relative;
          width: 1400px;
          margin: 0 auto;

          @media screen and (max-width: 1400px) {
            width: 100%;
            margin: 0 auto;
          }

          .btn-title {
            display: block;
            height: 40px;
            line-height: 40px;
          }

          .joinFormDx {
            width: 100%;

            @media screen and (max-width: 1400px) {
              width: 100%;
              margin: 0 auto;
            }
            & > .hTiv {
              padding: 0 0 32px 0;
              border-bottom: 1px #bababa solid;
            }
            .hTiv {
              position: relative;
              display: block;
              font-size: 24px;
              color: #000;
              font-weight: 600;
              line-height: 1.2;
            }

            & .jFDx {
              width: 100%;
              padding: 0 0 45px 0;
              border-bottom: 1px #e8e8e8 solid;

              // 부가정보
              &.add {
                & .jFbx {
                  display: flex;
                  gap: 10px;
                  margin-top: 10px;
                  & .additional-certification-select {
                    width: 260px;
                    margin: 0 16px 0 0 !important;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    &::after {
                      content: unset;
                    }
                    &::before {
                      content: unset;
                    }
                    & fieldset {
                      border-radius: 8px;
                    }
                  }
                  & .additional-certification {
                    width: 260px;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                  }
                  > button {
                    margin-left: 0;
                  }
                  @media (max-width: 1400px) {
                    flex-direction: column;
                    > .additional-certification-select {
                      width: 100%;
                    }
                    > button {
                      width: 100%;
                    }
                  }
                }
                & .certification-help {
                  margin: 8px 0 20px 0;
                  span {
                    text-align: left;
                    font: normal normal normal 14px / 28px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #777777;
                  }
                }
              }

              @media screen and (max-width: 1400px) {
                width: 100%;
                margin: 0 auto;
              }
              .jfTt {
                font-size: 16px;
                color: #000;
                line-height: 1.2;
                font-weight: 600;
                padding: 50px 0 5px 0;
                display: flex;
                align-items: center;
              }

              /* flex */
              .flexible {
                display: flex;
                flex-direction: row;

                @media screen and (max-width: 1400px) {
                  flex-direction: column;
                }
                & > div {
                  width: 22vw;
                  min-width: 320px;
                  padding-right: 10px;
                  @media screen and (max-width: 1400px) {
                    margin-top: 16px;
                    padding-right: 0px;
                    width: 100%;
                    & .btn-userId-check {
                      min-width: 320px;
                      width: calc(100vw - 40px);
                      max-width: 400px;
                    }
                  }
                }
              }
              /* 각 항목  */
              .jFbx {
                position: relative;
                margin: 34px 16px 0 0;
                @media screen and (max-width: 1400px) {
                  margin: 34px 0 0;
                }

                .jt {
                  position: relative;
                  display: inline-block;
                  font-size: 16px;
                  color: #000;
                  line-height: 1.2;
                  padding: 0 7px 15px 0;

                  &.required::after {
                    content: "";
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 4px;
                    height: 4px;
                    background: #ff4343;
                    border-radius: 100%;
                  }
                }

                .ji {
                  display: grid;
                  grid-template-columns: 240px;
                  grid-column-gap: 10px;

                  @media screen and (max-width: 1400px) {
                    grid-template-columns: 100%;
                    grid-column-gap: 0px;
                  }

                  &.email {
                    grid-template-columns: 240px 10px 240px 160px;

                    @media screen and (max-width: 1400px) {
                      display: flex;
                      flex-direction: column;
                      grid-template-columns: 240px 0px 240px 160px;
                    }
                    & > span {
                      line-height: 50px;
                    }
                    @media screen and (max-width: 1400px) {
                      & div:nth-of-type(3),
                      div:nth-of-type(4) {
                        margin-top: 16px;
                      }
                    }
                    &.other {
                      grid-template-columns: 240px 10px 240px 240px 160px;
                      @media screen and (max-width: 1400px) {
                        grid-template-columns: 240px 0px 240px 160px;
                      }
                    }
                  }
                  &.contact {
                    grid-template-columns: 240px 160px;
                    @media screen and (max-width: 1400px) {
                      grid-template-columns: 100%;
                    }
                  }
                  &.address {
                    grid-template-columns: 410px 160px 12vw;
                    & > div {
                      &:nth-child(n + 3) {
                        margin-top: 1%;
                      }
                      &:nth-child(3) {
                        grid-column-start: 1;
                        grid-column-end: 4;
                        grid-row-start: 2;
                        grid-row-end: 3;
                      }
                      &:nth-child(4) {
                        grid-column-start: 1;
                        grid-column-end: 4;
                        grid-row-start: 3;
                        grid-row-end: 4;
                      }
                    }
                  }
                  &.gender {
                    & .MuiFormGroup-root {
                      display: flex;
                      & .MuiFormControlLabel-root {
                        margin-right: 30px;
                      }
                    }
                  }
                }
              }
            }

            /* 약관 동의 */
            & .jFAgree {
              width: 1400px;
              @media screen and (max-width: 1400px) {
                min-width: 320px;
                width: 100%;
                max-width: 440px;
                margin: 0 auto;
              }
              overflow: hidden;

              & .jFAgreeBx {
                width: 100%;
                padding: 14px 0;
                border-bottom: 1px #e8e8e8 solid;
                overflow: hidden;

                /* flex */
                .flexible {
                  display: flex;
                  flex-direction: row;
                  justify-content: space-between;

                  & > div {
                    padding-right: 10px;
                    @media screen and (max-width: 1400px) {
                      padding-right: 0px;
                    }
                  }
                }

                .txAgree {
                  font-size: medium;
                  .MuiTypography-root {
                    @media screen and (max-width: 1400px) {
                      width: 200px;
                    }
                  }
                  & .terms-checkbox {
                    &.invisible {
                      opacity: 0;
                      cursor: auto;
                    }
                  }
                }
                .openAgree {
                  line-height: 40px;
                }

                & .agreeCont {
                  font-size: 14px;
                  color: #777;
                  line-height: 2;
                  height: auto;
                  margin-left: 30px;
                }
              }
            }
            .baseBtnArea {
              width: 100%;
              min-height: 56px;
              font-size: 0;
              line-height: 0;
              margin: 47px 0 0 0;
              text-align: center;
              // display: flex;
              overflow: hidden;
              & > div {
                width: 150px;
                display: inline-block;
                margin: 0 10px;
                @media screen and (max-width: 1400px) {
                  width: 120px;
                }
              }
            }
          }

          /*
          */

          .joinSDx {
            width: 640px;
            margin: 0 auto;
            text-align: center;
            overflow: hidden;
            & > .hTiv {
              padding: 0 0 31px 0;
              border-bottom: 1px #bababa solid;
            }
            .hTiv {
              position: relative;
              display: block;
              font-size: 24px;
              color: #000;
              font-weight: 600;
              line-height: 1.2;
            }
            .jtt {
              width: 100%;
              padding: 16px 0 0 0;
              overflow: hidden;

              & p {
                margin: 0;
                font-size: 20px;
                color: #000;
                line-height: 1.2;
                font-weight: 600;
                padding: 17px 0 0 0;
              }
            }

            .jtx {
              font-size: 15px;
              color: #000;
              line-height: 1.2;
              padding: 37px 0 0 0;
            }

            .jBtn {
              width: 368px;
              margin: 40px auto 0;
              overflow: hidden;

              & > div {
                margin: 0 0 16px 0;
                & span {
                  display: inline-block;
                  height: 40px;
                  line-height: 40px;
                }
              }
            }
          }
        }
      }
    }
  }
}
