#Recommended-list-presenter {
  display: flex;
  flex-direction: column;
  & .time-select-box {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    & .years-input {
      width: 167px;
      height: 56px;
      margin-right: 8px;
      border: 1px solid #bababa;
      text-align: left;
    }
  }
  & .divider {
    width: 1164px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #777777;
  }
  & .select-checkbox {
    height: 32px;
    width: 32px;
  }
  & .title-bar-container {
    border-top: 1px solid #e8e8e8;
    height: 64px;
    padding: 16px 0;
    display: flex;
    & .button {
      width: 80px;
      height: 32px;
      margin-left: 12px;
      cursor: pointer;
      &.cart {
        width: 88px;
      }
      &.excel-download {
        width: 130px;
      }
    }
    & .sort-button {
      color: #777777;
      &.first {
        margin-left: auto;
      }
      &.selected {
        color: black;
        font-weight: bold;
      }
    }
    & .divider {
      height: 12px;
      width: 1px;
      background-color: #e8e8e8;
      margin: auto 12px;
    }
  }
  & .list-row-container {
    display: flex;
    padding: 40px 0;
    border-top: 1px solid #e8e8e8;
    & .book-img {
      width: 120px;
      margin-left: 40px;
      cursor: pointer;
      & img {
        width: 120px;
        box-shadow: 0px 5px 10px #00000029;
        border-radius: 8px;
      }
    }
    & .book-info-container {
      display: flex;
      flex-direction: column;
      width: 538px;
      margin-left: 48px;
      text-align: left;
      & .book-title-container {
        font-size: 18px;
        font-weight: bold;
        display: flex;
        cursor: pointer;
        & .more-button {
          width: 24px;
          height: 24px;
          margin: auto 0 auto 20px;
        }
      }
      & .book-info-text {
        margin-top: 8px;
        color: #777777;
        font-size: 12px;
        line-height: 24px;
        display: flex;
        & div {
          height: 8px;
          width: 1px;
          background-color: #777777;
          margin: auto 8px;
        }
      }
      & .book-content {
        width: 538px;
        height: 50px;
        margin-top: 24px;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font: normal normal normal 14px/28px Noto Sans KR;
      }
      & .bottom-line {
        margin-top: 43px;
        display: flex;
        & .heart-icon {
          width: 24px;
          height: 24px;
          display: flex;
          cursor: pointer;
          & div {
            display: flex;
            margin-top: auto;
          }
        }
        & .text {
          margin-left: 8px;
        }
        & .divider {
          height: 16px;
          width: 1px;
          background-color: #e8e8e8;
          margin: auto 16px;
        }
        & .comment-icon {
          width: 24px;
          height: 24px;
          display: flex;
          & div {
            display: flex;
            margin-top: auto;
          }
        }
      }
    }
    & .price-container {
      height: 97px;
      margin-top: 45px;
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      text-align: center;
      & .cancel-line {
        text-decoration: line-through;
        color: #777777;
        line-height: 20px;
      }
      & .sale-percentage {
        background: #1e76af 0% 0% no-repeat padding-box;
        color: white;
        padding: 4px 8px;
        font-size: 12px;
        width: fit-content;
        margin: 8px auto 0;
        border-radius: 4px;
      }
      & .price {
        margin-top: 8px;
        font-size: 22px;
        font-weight: bold;
      }
    }
    & .button-container {
      margin-left: auto;
      margin-inline-end: 0%;
      display: flex;
      flex-direction: column;
      width: 156px;
      & button {
        width: 156px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 20px;
        margin-right: 16px;
        border: 1px solid #1e76af;
        color: #1e76af;
        margin: auto 0 8px;
        & .button-text {
          display: flex;
          & .image {
            margin-left: auto;
            margin-top: 3px;
          }
        }
        &:nth-of-type(2) {
          background: #1e76af 0% 0% no-repeat padding-box;
          color: white;
          margin: 8px 0 auto;
        }
      }
    }
  }
  & .pagination-container {
    display: flex;
    & .pagination {
      margin: 16px auto 0;
      & button {
        color: #777777;
      }
      & .Mui-selected {
        background-color: white;
        color: #1e76af;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    width: 100%;
    & .open-date-info-container {
      font-size: 12.5px;
      text-align: left;
      color: #777777;
      margin-top: 3px;
      // width: 100%;
      min-width: 200px;
      max-width: 320px;
    }
  }
}
