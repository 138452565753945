& .all-choice-wrapper {
  padding-top: 80px;
  height: auto;
  & .all-choice-container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .title-container {
      display: flex;
      & .title-text {
        font-size: 24px;
        font-weight: bold;
      }
    }
    & .first-side {
      display: flex;
      margin-top: 32px;
      width: 692px;
      height: 400px;
      & .first-book-img-zone {
        width: 692px;
        height: 400px;
        background: #d0cfcd 0% 0% no-repeat padding-box;
        border-radius: 8px;
        & .new-tag {
          position: absolute;
          color: #ffffff;
          background: #000000 0% 0% no-repeat padding-box;
          border-radius: 12px 0px;
          width: 36px;
          height: 36px;
          font-size: 16px;
          font-weight: bold;
          line-height: 36px;
        }
        & .first-book-img {
          margin: 57px 246px 57px;
          & img {
            border-radius: 10px;
            box-shadow: 0px 5px 10px #00000029;
          }
        }
      }
      & .first-content-body {
        display: flex;
        margin-left: 60px;
        flex-direction: column;
        & .classification {
          width: fit-content;
          padding: 4px 12px;
          height: 25px;
          line-height: 25px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          font: normal normal normal 12px/17px Noto Sans KR;
        }
        & .title-container {
          margin-top: 20px;
          & .title {
            max-width: 400px;
            font-size: 18px;
            font-weight: bold;
          }
          & .more-icon {
            margin-top: 2px;
            cursor: pointer;
            margin-left: 20px;
          }
        }
        & .sub-title-container {
          margin-top: 8px;
          display: flex;
          & div {
            font-size: 12px;
            color: #777777;
          }
          & .ellipsis {
            &.author {
              max-width: 140px;
            }
            & .pubName {
              max-width: 140px;
            }
          }
          & .divider {
            width: 0px;
            height: 10px;
            margin: auto 8px;
            border-left: 1px solid #000000;
            opacity: 0.5;
          }
        }
        & .content {
          width: 500px;
          height: 80px;
          line-height: 28px;
          margin-top: 24px;
          text-align: left;
          white-space: pre-line;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        & .price-container {
          display: flex;
          width: auto;
          height: 40px;
          margin-top: 20px;
          & :nth-of-type(1) {
            margin: auto;
            margin-right: 0;
            margin-left: 0;
            width: 42px;
            height: 25px;
            padding: 4px 8px;
            color: white;
            border-radius: 4px;
            background: #1e76af 0% 0% no-repeat padding-box;
            font-size: 12px;
          }
          & :nth-of-type(2) {
            margin-left: 12px;
            font-size: 28px;
            font-weight: bold;
          }
          & :nth-of-type(3) {
            font: normal normal medium 14px/142px Noto Sans KR;
            margin: auto 12px 4px 4px;
          }
          & :nth-of-type(4) {
            text-decoration: line-through;
            font-size: 16px;
            font-weight: normal;
            color: #777777;
            margin-top: 9px;
          }
        }
        & .button-container {
          display: flex;
          & .buy-button {
            margin-left: 16px;
          }
          & button {
            width: 156px;
            height: 56px;
            border-radius: 8px;
            padding: 16px 20px;
            margin-right: 16px;
            border: 1px solid #1e76af;
            color: #1e76af;
            margin: 40px 0 8px;
            & .button-text {
              display: flex;
              & .image {
                margin-left: auto;
                margin-top: 3px;
              }
            }
            &:nth-of-type(2) {
              background: #1e76af 0% 0% no-repeat padding-box;
              color: white;
            }
          }
        }
      }
    }
    & .etc-side {
      display: flex;
      height: auto;
      max-width: 1400px;
      margin-top: 40px;
      & .etc-card {
        display: flex;
        flex-direction: column;
        margin-right: 16px;
        @for $i from 0 to 5 {
          & .etc-book-img-zone#{$i} {
            cursor: pointer;
            width: 338px;
            height: 240px;
            // @if ($i == 0) {
            //   background: #32b783 0% 0% no-repeat padding-box;
            // } @else if ($i == 1) {
            //   background: #385769 0% 0% no-repeat padding-box;
            // } @else if ($i == 2) {
            //   background: #a28c62 0% 0% no-repeat padding-box;
            // } @else if ($i == 3) {
            //   background: #f56b69 0% 0% no-repeat padding-box;
            // }
            border-radius: 8px;
            & .new-tag {
              position: absolute;
              color: #ffffff;
              background: #000000 0% 0% no-repeat padding-box;
              border-radius: 12px 0px;
              width: 36px;
              height: 36px;
              font-size: 16px;
              font-weight: bold;
              line-height: 36px;
            }
            & .etc-book-img {
              cursor: pointer;
              & img {
                width: 116px;
                height: 161px;
                border-radius: 8px;
                box-shadow: 0px 5px 10px #00000029;
                margin: 34px 111px 34px;
              }
            }
          }
        }
        & .etc-content-body {
          display: flex;
          margin-top: 28px;
          flex-direction: column;
          & .title-container {
            display: flex;
            & .title {
              max-width: 300px;
              font-size: 18px;
              font-weight: bold;
            }
            & .more-icon {
              cursor: pointer;
              margin-left: 14px;
            }
          }
          & .sub-title-container {
            margin-top: 8px;
            display: flex;
            & div {
              font-size: 12px;
              color: #777777;
            }
            & .ellipsis {
              &.author {
                max-width: 90px;
              }
              &.pubName {
                max-width: 90px;
              }
            }
            & .divider {
              width: 0px;
              height: 10px;
              margin: auto 8px;
              border-left: 1px solid #000000;
              opacity: 0.5;
            }
          }
          & .content {
            width: 338px;
            height: 84px;
            line-height: 28px;
            margin-top: 12px;
            text-align: left;
            white-space: pre-line;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
          & .price-container {
            display: flex;
            width: auto;
            height: 40px;
            margin-top: 20px;
            & :nth-of-type(1) {
              margin: auto;
              margin-right: 0;
              margin-left: 0;
              width: 42px;
              height: 25px;
              padding: 4px 8px;
              color: white;
              border-radius: 4px;
              background: #1e76af 0% 0% no-repeat padding-box;
              font-size: 12px;
            }
            & :nth-of-type(2) {
              margin-left: 12px;
              font-size: 28px;
              font-weight: bold;
            }
            & :nth-of-type(3) {
              font: normal normal medium 14px/142px Noto Sans KR;
              margin: auto 12px 4px 4px;
            }
            & :nth-of-type(4) {
              text-decoration: line-through;
              font-size: 16px;
              font-weight: normal;
              color: #777777;
              margin-top: 9px;
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    padding-top: 0px;
    // padding-top: 30px;
    // min-width: 360px;
    // max-width: 480px;
    margin: 0 auto;
    flex-direction: column;
    & .mobile {
      margin-top: 30px;
      // margin-left: 20px;
      // margin-right: 20px;
      & .title-container {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        margin: auto;
        & .title-text {
          text-align: left;
          font: normal normal bold 17.5px Noto Sans KR;
        }
      }
      & .mobile-all-choice-swiper {
        width: 100vw;
        margin-top: 20px;
        & .swiper-wrapper {
          width: 100vw;
          min-width: 360px;
          max-width: 480px;
          & .swiper-slide {
            width: 320px;
            margin: auto;
            @for $i from 0 to 5 {
              & .book-img-zone#{$i} {
                width: 300px;
                height: 200px;
                display: flex;
                border-radius: 8px;
                // @if ($i == 0) {
                //   background: #d0cfcd 0% 0% no-repeat padding-box;
                // } @else if ($i == 1) {
                //   background: #32b783 0% 0% no-repeat padding-box;
                // } @else if ($i == 2) {
                //   background: #385769 0% 0% no-repeat padding-box;
                // } @else if ($i == 3) {
                //   background: #a28c62 0% 0% no-repeat padding-box;
                // } @else if ($i == 4) {
                //   background: #f56b69 0% 0% no-repeat padding-box;
                // }
                position: relative;
                & .new-tag {
                  position: absolute;
                  color: #ffffff;
                  background: #000000 0% 0% no-repeat padding-box;
                  border-radius: 8px 0px 6px 0px;
                  width: 25px;
                  height: 25px;
                  font-size: 15px;
                  font-weight: bold;
                  line-height: 25px;
                }
                & .book-img {
                  width: 90px;
                  margin: 35px auto auto;
                  & .img {
                    width: 90px;
                    & .LazyLoad {
                      width: 90px;
                      & img {
                        // position: absolute;
                        // top: 50%;
                        // left: 50%;
                        // margin: -50px 0 0 -50px;
                        // height: 125px;
                        width: 90px;
                        border-radius: 4px;
                        box-shadow: 0px 5px 10px #00000029;
                      }
                    }
                  }
                }
              }
            }
            & .content-body {
              width: 300px;
              text-align: left;
              margin-top: 16px;
              & .first-content {
                display: flex;
                & .classification {
                  width: fit-content;
                  padding: 2px 6px;
                  height: auto;
                  background: #ffffff 0% 0% no-repeat padding-box;
                  border: 1px solid #e8e8e8;
                  border-radius: 2px;
                  font: normal normal normal 12px/17px Noto Sans KR;
                }
                & .icon {
                  cursor: pointer;
                }
                & .heart-icon {
                  margin-left: auto;
                  margin-right: 24px;
                }
              }
              & .title {
                margin-top: 10px;
                font-size: 15px;
                font-weight: bold;
              }
              & .sub-title-container {
                margin-top: 8px;
                display: flex;
                & div {
                  font-size: 12px;
                  color: #777777;
                }
                & .divider {
                  width: 0px;
                  height: 8px;
                  margin: auto 6px;
                  border-left: 1px solid #000000;
                  opacity: 0.5;
                }
              }
              & .price-info-container {
                display: flex;
                margin-top: 14px;
                & .sale-percentage {
                  font-size: 11px;
                  font-weight: bold;
                  color: white;
                  background: #1e76af 0% 0% no-repeat padding-box;
                  border-radius: 2px;
                  padding: 2px 4px;
                }
                & .after-sale {
                  margin-left: 12px;
                  font-size: 14px;
                  font-weight: bold;
                  & span {
                    font-size: 12px;
                    font-weight: bold;
                    margin-left: 2px;
                  }
                }
                & .before-sale {
                  margin-left: 12px;
                  text-decoration: line-through;
                  color: #777777;
                  font-size: 12px;
                  margin-top: 2px;
                }
              }
            }
          }
        }
      }
    }
  }
}
