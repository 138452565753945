#signup-cerification-modal {
  width: 540px;
  // height: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1400px) {
    min-width: 320px;
    width: calc(100vw - 40px);
    max-width: 440px;
  }

  .joinSDx {
    margin: 0 auto;
    text-align: center;
    overflow: hidden;
    & > .hTiv {
      padding: 0 0 31px 0;
      border-bottom: 1px #bababa solid;
    }
    .hTiv {
      position: relative;
      display: block;
      font-size: 24px;
      color: #000;
      font-weight: 600;
      line-height: 1.2;
      text-align: start;
      display: flex;
      & .close-icon {
        margin-left: auto;
        cursor: pointer;
      }
    }
    .jtt {
      width: 100%;
      padding: 16px 0 0 0;
      overflow: hidden;

      & p {
        margin: 0;
        font-size: 16px;
        color: #000;
        line-height: 1.2;
        font-weight: 600;
        padding: 17px 0 0 0;
      }
    }

    .jtx {
      font-size: 15px;
      color: #000;
      line-height: 1.2;
      padding: 37px 0 0 0;
    }

    .jBtn {
      width: 368px;
      margin: 40px auto 0;
      overflow: hidden;

      @media screen and (max-width: 1400px) {
        min-width: 268px;
        width: 100%;
      }
      & > div {
        margin: 0 0 16px 0;
        & span {
          display: inline-block;
          height: 40px;
          line-height: 40px;
        }
      }
    }

    .jInfo {
      margin: 40px auto 0;
      text-align: left;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      padding: 20px 20px;
      & p {
        font: normal 14px/30px Noto Sans KR;
        padding: 0;
        margin: 0;
      }
    }
  }
}
