#UserInquiry {
  display: flex;
  flex-direction: column;
  & .user-inquiry-wrapper {
    width: 360px;
    margin: 80px auto 100px;
    display: flex;
    flex-direction: column;
    & .title {
      font-weight: bold;
      font-size: 24px;
      margin-bottom: 32px;
    }
    & .inquiry-container {
      width: 360px;
      height: 56px;
      background: var(---f3f8fb-color-bg-) 0% 0% no-repeat padding-box;
      background: #f3f8fb 0% 0% no-repeat padding-box;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
    }
    & .find-tab-box {
      width: 180px;
      height: 56px;
      background: #ffffff00 0% 0% no-repeat padding-box;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
    }
    & .find-tab-active {
      width: 180px;
      height: 56px;
      border: 1px solid var(---1e76af-key-color-);
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #1e76af;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
    }
    & .sub-title {
      margin-top: 32px;
      margin-bottom: 14px;
      text-align: left;
      font: normal normal normal 16px/28px Noto Sans KR;
      letter-spacing: 0px;
    }
    & .radio-container {
      text-align: left;
      & .radio-checked {
        width: 20px;
        height: 20px;
        margin-right: 8px;
      }
      & .radio-name {
        font: normal normal normal 13px/40px Noto Sans KR;
        letter-spacing: 0px;
        margin-right: 70px;
      }
      & .radio-name2 {
        font: normal normal normal 13px/40px Noto Sans KR;
        letter-spacing: 0px;
      }
    }
    @import "./UserInquiryTab.scss";
  }
}
