#event-main-page {
  display: flex;
  flex-direction: column;
  & .event-main-wrapper {
    width: 1400px;
    margin: 80px auto 0;
    // @media screen and (max-width: 480px) {
    //   margin: 0;
    //   padding: 20px;
    //   overflow-y: auto;
    // }
    & .img img {
      width: 1400px;
      height: 360px;
      border-radius: 20px;
      cursor: pointer;
    }
    & .event-specialPlan-Container {
      margin-top: 40px;
      width: 1400px;
      height: 306px;
      /* UI Properties */
      background: #187454 0% 0% no-repeat padding-box;
      border-radius: 20px;
      opacity: 1;
      display: flex;
      justify-content: space-between;
      // @media screen and (max-width: 480px) {
      //   width: 390px;
      //   height: 250px;
      //   margin: 0px;
      //   position: relative;
      // }
      & .event-specialPlan-text-Container {
        padding: 82px 80px;
        top: 485px;
        width: 405px;
        height: 145px;
        opacity: 1;
        // @media screen and (max-width: 480px) {
        //   padding: 20px;
        //   height: 70px;
        //   margin-top: 90px;
        // }
        & .event-specialPlan-smallBox {
          left: 261px;
          width: 57px;
          height: 25px;

          background: #00000000 0% 0% no-repeat padding-box;
          border: 1px solid #ffffff;
          border-radius: 4px;
          opacity: 1;

          & .event-specialPlan-smallBox-text {
            padding: 4px;
            text-align: center;
            font: normal normal normal 12px/17px Noto Sans KR;
            letter-spacing: 0px;
            text-decoration-line: #e8e8e8;
            color: #ffffff;
            opacity: 1;
          }
        }
        & .event-textbox-large {
          padding: 28px 0px;
          /* Layout Properties */
          top: 538px;
          left: 261px;
          width: 405px;
          height: 46px;
          /* UI Properties */
          text-align: left;
          font: normal normal bold 28px/44px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
        & .event-textbox-small {
          padding: 16px 0px;
          top: 604px;
          left: 261px;
          width: 265px;
          height: 26px;
          text-align: left;
          font: normal normal medium 18px/26px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
      }

      & .event-main-image {
        padding-right: 20px;
        // @media screen and (max-width: 480px) {
        //   padding: 0;
        //   position: absolute;

        //   & img {
        //     height: 185px;
        //     width: 370px;
        //   }
        // }
      }
    }
    // Tab css
    & .event-tab-wrapper {
      margin-top: 40px;
      width: 1400px;
      // @media screen and (max-width: 480px) {
      //   margin-top: 40px;
      //   width: 380px;
      // }
      & .event-main-tab-container {
        width: 1400px;
        height: 56px;
        background: #f3f8fb 0% 0% no-repeat padding-box;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        border-bottom: 1px;
        border-color: divider;
        // @media screen and (max-width: 480px) {
        //   width: 380px;
        //   background: #ffffff 0% 0% no-repeat padding-box;
        //   border-radius: 0px;
        //   &.css-xu71s-MuiTabs-indicator {
        //     display: none;
        //   }
        // }
      }
      & .event-main-tab-box {
        width: 175px;
        height: 56px;
        /* UI Properties */
        background: #ffffff00 0% 0% no-repeat padding-box;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        // @media screen and (max-width: 480px) {
        //   width: 70px;
        //   background: #ffffff 0% 0% no-repeat padding-box;
        //   border: 2px solid #1e76af;
        //   border-radius: 40px;
        //   opacity: 1;
        //   font: normal normal normal 20px/40px Noto Sans KR;
        //   white-space: nowrap;
        //   letter-spacing: 0px;
        //   color: #1e76af;
        //   opacity: 1;
        // }
      }
      & .event-main-tab-active {
        width: 175px;
        height: 56px;
        border: 1px solid var(---1e76af-key-color-);
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 8px 8px 0px 0px;
        opacity: 1;
        // @media screen and (max-width: 480px) {
        //   width: 70px;
        //   background: #1e76af 0% 0% no-repeat padding-box;
        //   border-radius: 40px;
        //   font: normal normal normal 20px/40px Noto Sans KR;
        //   white-space: nowrap;
        //   letter-spacing: 0px;
        //   color: #ffffff;
        //   opacity: 1;
        // }
      }
      @import "./EventMainTab.scss";
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .event-main-wrapper {
      margin: 30px auto;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      // margin-bottom: 30px;
      & .img {
        width: 100%;
        & .LazyLoad {
          & img {
            width: 100%;
          }
        }
      }
      & .event-tab-wrapper {
        width: 100%;
        & .event-main-tab-container {
          width: 100%;
          height: 36px;
          & .MuiTabs-root {
            width: 100%;
            height: 36px;
            & .MuiTabs-scroller {
              height: 36px;
              & .MuiTabs-flexContainer {
                height: 36px;
                & .MuiButtonBase-root {
                  width: 50%;
                  height: 36px;
                  padding: 0;
                  min-height: auto;
                  & .MuiTouchRipple-root {
                    height: 36px;
                  }
                }
              }
            }
          }
        }
        & .MuiBox-root {
          & .MuiTypography-root {
            & .event-tab-text {
              margin: 0;
              margin-top: 17px;
              text-align: left;
              font: normal normal bold 17.5px/13px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              height: auto;
              & span {
                text-align: left;
                font: normal normal bold 17.5px/13px Noto Sans KR;
                letter-spacing: 0px;
                color: #1e76af;
              }
            }
          }
        }
        & .event-tab-wrapper-container {
          margin: 0;
          margin-top: 22px;
          // width: 100%;
          & .event-tab-wrapper-container-box {
            margin: 0;
            width: 100%;
            margin-bottom: 20px;
            height: auto;
            & .event-image-box {
              min-width: 318px;
              width: calc(100vw - 42px);
              max-width: 438px;
              min-height: calc(318px * 240 / 450);
              height: calc((100vw - 42px) * 240 / 450);
              max-height: calc(438px * 240 / 450);
              & .LazyLoad {
                width: 100%;
                & img {
                  min-width: 318px;
                  width: calc(100vw - 42px);
                  max-width: 438px;
                  min-height: calc(318px * 240 / 450);
                  height: calc((100vw - 42px) * 240 / 450);
                  max-height: calc(438px * 240 / 450);
                }
              }
            }
            & .event-tab-tag {
              margin-top: 16px;
              text-align: center;
              text-decoration: underline;
              font-size: 11px;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            & .event-tab-title {
              width: 100%;
              margin-top: 12.5px;
              color: var(---000000-text-);
              text-align: center;
              font: normal normal bold 17.5px/25.5px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
              margin-bottom: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
