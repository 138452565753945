& .hover-menu-wrapper {
  position: absolute;
  display: flex;
  width: 100%;
  min-width: 1400px;
  height: 500px;
  background: #ffffff 0% 0% no-repeat padding-box;
  box-shadow: 0px 8px 8px #0000001a;
  margin: auto;
  margin-top: -1px;
  border-top: 1px solid #e8e8e8;
  flex-direction: column;
  z-index: 1000;
  & .hover-menu-container {
    display: flex;
    height: 400px;
    width: 1400px;
    margin: 0 auto;
    & .first-menu-container {
      display: flex;
      flex-direction: column;
      text-align: left;
      height: 350px;
      margin-top: 40px;
      margin-right: auto;
      width: 30%;
      flex-wrap: wrap;
      & .menu-item {
        width: fit-content;
        padding: 0;
        margin-right: 40px;
        text-align: left;
        font: normal normal bold 13px/28px Noto Sans KR;
        height: 20px;
        margin-bottom: 8px;
        &.selected {
          text-decoration: underline;
        }
      }
    }
    & .divider {
      height: 335px;
      border: 1px solid var(---e8e8e8-line-);
      border: 1px solid #e8e8e8;
      margin: 40px 36px 114px auto;
    }
    & .first-sub-menu-container {
      display: flex;
      flex-direction: column;
      text-align: left;
      height: 350px;
      width: 35%;
      margin-top: 40px;
      margin-right: auto;
      flex-wrap: wrap;
      & .menu-item {
        width: fit-content;
        padding: 0;
        margin-right: 30px;
        text-align: left;
        font: normal normal bold 13px/28px Noto Sans KR;
        color: #777777;
        height: 20px;
        margin-bottom: 8px;
        &.selected {
          text-decoration: underline;
        }
      }
    }
    & .divider-2 {
      height: 335px;
      border: 1px solid var(---e8e8e8-line-);
      border: 1px solid #e8e8e8;
      margin: 40px 36px 114px auto;
    }
    & .all-choice-container {
      display: flex;
      width: 30%;
      flex-direction: column;
      margin-right: auto;
      & .title-container {
        display: flex;
        margin-top: 40px;
        & .title-text {
          text-align: left;
          font: normal normal bold 14px/26px Noto Sans KR;
        }
      }
      & .first-side {
        display: flex;
        & .first-book-img {
          & img {
            width: 120px;
            height: 175px;
            border-radius: 10px;
            box-shadow: 0px 5px 10px #00000029;
            margin: 24px 32px 0px 0px;
            cursor: pointer;
          }
        }
        & .first-content-body {
          display: flex;
          flex-direction: column;
          margin-left: 10px;
          margin-top: 64px;
          width: 300px;
          & .classification {
            padding: 4px 12px;
            width: fit-content;
            height: 25px;
            line-height: 25px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
            text-align: left;
            font: normal normal normal 12px/17px Noto Sans KR;
          }
          & .title-container {
            margin-top: 16px;
            width: 300px;
            & .title {
              max-width: 300px;
              cursor: pointer;
              text-align: left;
              font: normal normal bold 18px/32px Noto Sans KR;
            }
          }
          & .content {
            font-size: 13px;
            height: 60px;
            margin-top: 8px;
            text-align: left;
            white-space: normal;
            overflow: hidden;
          }
        }
      }
    }
  }
  & .btn-container {
    display: flex;
    width: 1400px;
    margin: 0 auto;
    & .all-category {
      padding-left: 16px;
      width: 202px;
      height: 48px;
      background: #e8e8e8 0% 0% no-repeat padding-box;
      border-radius: 8px;
      text-align: left;
      font: normal normal bold 14px/20px Noto Sans KR;
    }
  }
}
& .all-category-wrapper {
  display: flex;
  width: 1400px;
  margin: auto;
  & .all-category-container {
    margin-bottom: 120px;
    display: flex;
    flex-direction: column;
    & .title-container {
      display: flex;
      margin-top: 50px;
      & .title-text {
        text-align: left;
        font: normal normal bold 14px/26px Noto Sans KR;
      }
    }
    & .category-menu-container {
      display: flex;
      margin-top: 25px;
      & .main-menu-container {
        text-align: left;
        padding: 0;
        max-width: 130px;
        min-width: 130px;
        & .main-label {
          margin-bottom: 10px;
          white-space: nowrap;
          font: normal normal bold 13px/28px Noto Sans KR;
        }
        & .main-menu-li {
          cursor: pointer;
          list-style: none;
          text-align: left;
          font: normal normal normal 12px/28px Noto Sans KR;
          color: #777777;
        }
      }
    }
    & .divider {
      height: auto;
      min-height: 650px;
      border: 1px solid #e8e8e8;
      margin: 0px 20px 24px 50px;
    }
    .divider:last-child {
      display: none;
    }
    hr {
      width: 1400px;
    }
    .hr-solid {
      border: 1px solid #e8e8e8;
    }
  }
}
