#field-page {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .field-container {
    // 아이콘 영역
    @import "./FIconList.scss";
    // 모두의 선택
    @import "./AllChoice.scss";
    // 새로 나온 책
    @import "./NewBookList.scss";
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
  }
}
