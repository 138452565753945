#book-info-container {
  display: flex;
  flex-direction: column;
  width: 420px;
  & .class {
    padding: 4px 12px;
    border: 1px solid #eeeeee;
    border-radius: 4px;
    width: fit-content;
    font-size: 12px;
  }
  & .book-info-title-container {
    display: flex;
    margin-top: 20px;
    & .book-info-title {
      font-size: 18px;
      font-weight: bold;
      line-height: 32px;
      text-align: left;
      width: fit-content;
      cursor: pointer;
    }
    & .more-button {
      width: 24px;
      height: 24px;
      margin: 4px 0 0 16px;
      cursor: pointer;
    }
  }
  & .info-container {
    margin-top: 8px;
    display: flex;
    height: 24px;
    font-size: 12px;
    color: #777777;
    & div {
      margin: auto 8px;
      width: 1px;
      height: 8px;
      background-color: #777777;
    }
    & span {
      &:nth-of-type(1) {
        text-align: left;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
        height: 72px;
      }
      &:nth-of-type(2),
      &:nth-of-type(3) {
        min-width: fit-content;
      }
    }
  }
  & .comment {
    margin-top: 24px;
    font-size: 14px;
    line-height: 28px;
    text-align: left;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 2;
  }
  & #price-container {
    margin-top: 24px;
  }
  & .button-container {
    display: flex;
    margin-top: 40px;
    & button {
      width: 156px;
      height: 56px;
      border-radius: 8px;
      padding: 16px 20px;
      margin-right: 16px;
      border: 1px solid #b7916b;
      color: #b7916b;
      & .button-text {
        display: flex;
        & .image {
          margin-left: auto;
          margin-top: 3px;
        }
      }
      &:nth-of-type(2) {
        background: #b7916b 0% 0% no-repeat padding-box;
        color: white;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    & .class {
      height: 10px;
      font-size: 10px;
      line-height: 10px;
    }
    & .book-info-title-container {
      min-width: 280px;
      max-width: 400px;
      margin-top: 10px;
      & .book-info-title {
        font-size: 15px;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
      }
    }
    & .info-container {
      min-width: 280px;
      max-width: 400px;
      height: 17px;
      & span {
        height: 17px !important;
        display: inline-block !important;
        overflow: hidden !important;
        text-overflow: ellipsis !important;
        white-space: nowrap !important;

        &:nth-child(1) {
          max-width: 90px;
        }
        &:nth-child(3) {
          max-width: 70px;
        }
        &:nth-child(5) {
          width: 100%;
          max-width: 117px;
        }
      }
    }
    & .comment {
      margin-top: 20px;
      font-size: 12px;
      line-height: 19px;
    }

    & #price-container {
      margin-top: auto;
      height: 20px;
      line-height: 20px;
      & .sale-percent {
        width: 31.5px;
        height: 20px;
        margin-top: 0;
        padding: 0;
        font-size: 11px;
        line-height: 20px;
      }
      & .price-after {
        line-height: 20px;
        & span {
          font-size: 14px;
        }
      }
      & .price-before {
        margin-top: 0;
        font-size: 12px;
        line-height: 20px;
      }
    }
    & .button-container {
      margin-top: 20px;
      & button {
        width: 84px !important;
        padding-top: 8px;
        padding-bottom: 8px;
      }
    }
  }
}
