#recommended-book-picker-presenter {
  margin-top: 40px;
  display: flex;
  & .left {
    display: flex;
    flex-direction: column;
    & .class {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      padding: 4px 12px;
      width: fit-content;
    }
    & .title {
      margin-top: 32px;
      font-weight: bold;
      font-size: 18px;
      line-height: 32px;
      text-align: left;
    }
    & .book-info {
      // display: flex;
      margin-top: 8px;
      text-align: left;
      & .info {
        color: #777777;
        font-size: 12px;
        line-height: 17px;
        margin-bottom: 5px;
      }
      & .divider {
        height: 8px;
        width: 1px;
        border-left: 1px solid #777777;
        opacity: 0.5;
        margin: auto 8px;
      }
    }
    & .other-recommendation {
      display: flex;
      flex-direction: column;
      & .other-recommendation-title {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        margin-top: 120px;
      }
      & .recommendation-list {
        display: flex;
        margin-top: 16px;
        margin-bottom: 90px;
        & .recommendation-book {
          cursor: pointer;
          width: 100px;
          opacity: 0.5;
          margin-right: 16px;
          & img {
            width: 100px;
            box-shadow: 0px 5px 10px #00000029;
            border-radius: 8px;
          }
        }
      }
    }
  }
  & .img {
    width: 260px;
    margin-left: 240px;
    cursor: pointer;
    & img {
      width: 260px;
      box-shadow: 0px 5px 20px #00000033;
      border-radius: 8px;
    }
  }
  & .right {
    margin-left: 240px;
    width: 326px;
    & .one-line-comment {
      font-size: 20px;
      line-height: 38px;
      text-align: left;
      font-weight: bold;
    }
    & .comment {
      text-align: left;
      font-size: 14px;
      line-height: 28px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 3;
      margin-top: 24px;
    }
    & #price-container {
      margin-top: 24px;
    }
    & .button-container {
      display: flex;
      margin-top: 80px;
      & button {
        width: 155px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 20px;
        margin-right: 16px;
        border: 1px solid #b7916b;
        color: #b7916b;
        & .button-text {
          display: flex;
          & .image {
            margin-left: auto;
            margin-top: 3px;
          }
        }
        &:nth-of-type(2) {
          background: #b7916b 0% 0% no-repeat padding-box;
          color: white;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    margin-top: 20px;
    display: inline;

    & .img {
      width: 180px;
      margin: 0 auto 20px;
      & img {
        width: 180px;
        box-shadow: 0px 5px 20px #00000033;
        border-radius: 8px;
      }
    }

    & .class {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e8e8e8;
      border-radius: 4px;
      padding: 4px 12px;
      height: 20px;
      width: fit-content;
      font-size: 10px;
      line-height: 10px;
    }
    & .title {
      margin-top: 10px;
      font-weight: bold;
      font-size: 15px;
      line-height: 28px;
      text-align: left;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 2;
    }
    & .book-info {
      // display: flex;
      text-align: left;
      margin-top: 8px;
      & .info {
        color: #777777;
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 3px;
      }
      & .divider {
        height: 8px;
        width: 1px;
        border-left: 1px solid #777777;
        opacity: 0.5;
        margin: auto 8px;
      }
    }
    & .one-line-comment {
      font-size: 20px;
      line-height: 38px;
      text-align: left;
      font-weight: bold;
    }
    & .comment {
      text-align: left;
      font-size: 12px;
      line-height: 19px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 5;
      margin-top: 20px;
    }
    & #price-container {
      margin-top: 20px;
      height: 20px;
      line-height: 20px;
      & .sale-percent {
        width: 31.5px;
        height: 20px;
        margin-top: 0;
        padding: 0;
        font-size: 11px;
        line-height: 20px;
      }
      & .price-after {
        line-height: 20px;
        & span {
          font-size: 14px;
        }
      }
      & .price-before {
        margin-top: 0;
        font-size: 12px;
        line-height: 20px;
      }
    }
    & .button-container {
      display: flex;
      margin-top: 20px;
      & button {
        width: 124px;
        height: 40px;
        border-radius: 8px;
        padding: 8px 20px;
        margin-right: 16px;
        border: 1px solid #b7916b;
        color: #b7916b;
        & .button-text {
          display: flex;
          & .image {
            margin-left: auto;
            margin-top: 3px;
          }
        }
        &:nth-of-type(2) {
          background: #b7916b 0% 0% no-repeat padding-box;
          color: white;
        }
      }
    }

    & .other-recommendation {
      display: flex;
      flex-direction: column;
      & .other-recommendation-title {
        text-align: left;
        font-size: 14px;
        line-height: 24px;
        margin-top: 20px;
      }
      & .recommendation-list {
        display: flex;
        margin-top: 16px;
        margin-bottom: 35px;
        & .recommendation-book {
          cursor: pointer;
          width: 100px;
          opacity: 0.5;
          margin-right: 16px;
          & img {
            width: 100px;
            box-shadow: 0px 5px 10px #00000029;
            border-radius: 8px;
          }
        }
      }
    }
  }
}
