#OrderHistory {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .order-history-wrapper {
    display: flex;
    padding-top: 80px;
    @import "./SideMenu.scss";
    & .history-container {
      margin: 0px auto 120px 120px;
      display: flex;
      width: 1164px;
      flex-direction: column;

      .history-main-title {
        width: 191px;
        height: 35px;
        text-align: left;
        font: normal normal bold 24px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        white-space: nowrap;
      }
      & .lookup-container {
        margin: 32px auto 0;
        padding: 32px;
        width: 1164px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #bababa;
        border-radius: 10px;
        opacity: 1;

        & .lookup-peroid-container {
          display: flex;
          padding-left: 107px;
          .lookup-peroid-text {
            text-align: left;
            font: normal normal normal 14px/20px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .lookup-peroid-date {
            margin-left: 16px;
            text-align: left;
            font: normal normal bold 14px/20px Noto Sans KR;
            letter-spacing: 0px;
            color: #1976af;
            opacity: 1;
          }
        }
        & .divider-class {
          margin-top: 24px;
          width: 883px;
          height: 1px;
          border: 1px solid #e8e8e8;
          background: #e8e8e8 0% 0% no-repeat padding-box;
          opacity: 1;
        }
        & .select-date-container {
          display: flex;
          .select-box-container {
            display: flex;
            margin: 0 auto;
            flex-direction: column;
            .text {
              text-align: left;
              font: normal normal normal 16px/40px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            .box-container {
              margin-top: 24px;
              display: flex;
              align-items: baseline;
              .select-box {
                max-width: 180px;
                height: 48px;
                background: #ffffff 0% 0% no-repeat padding-box;

                border-radius: 8px;
                opacity: 1;
              }
              .range-picker-box {
                width: 180px;
                height: 48px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border-radius: 8px;
                opacity: 1;
              }
              .range-picker-text {
                margin: 0px 8px 0px 8px;
                width: 12px;
                height: 1px;
                background: #ffffff 0% 0% no-repeat padding-box;
                color: 1px solid #707070;
                opacity: 1;
              }
              .css-1o2begq-MuiInputBase-root-MuiOutlinedInput-root {
                border-radius: 8px;
                font-size: 14px;
              }
              .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
                font-size: 14px;
              }
              .reset-btn {
                text-align: center;
                font: normal normal normal 16px/40px Noto Sans KR;
                letter-spacing: 0px;
                color: #1e76af;
                opacity: 1;
                margin-left: 20px;
                width: 180px;
                height: 48px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #1e76af;
                border-radius: 8px;
                opacity: 1;
              }
              .search-btn {
                text-align: center;
                font: normal normal normal 16px/40px Noto Sans KR;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
                margin-left: 20px;
                width: 180px;
                height: 48px;
                background: #1e76af 0% 0% no-repeat padding-box;
                border-radius: 8px;
                opacity: 1;
              }
            }
          }
          .order-type-contianer {
            margin-right: auto;
            display: flex;
            flex-direction: column;
            margin-left: 17px;
            .text {
              text-align: left;
              font: normal normal normal 16px/40px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            .select-box {
              margin-top: 24px;
              width: 180px;
              height: 48px;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: #bababa;
              border-radius: 8px;
              opacity: 1;
            }
            .css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input.css-11u53oe-MuiSelect-select-MuiInputBase-input-MuiOutlinedInput-input {
              font-size: 14px;
            }
          }
          // 검색 필드 현재 사용안함
          // .search-field-container {
          //   display: flex;
          //   flex-direction: column;
          //   margin-left: 32px;
          //   .text {
          //     text-align: left;
          //     font: normal normal normal 16px/40px Noto Sans KR;
          //     letter-spacing: 0px;
          //     color: #000000;
          //     opacity: 1;
          //   }
          //   .text-field-box {
          //     width: 288px;
          //     height: 48px;

          //     margin-top: 24px;
          //     background: #ffffff 0% 0% no-repeat padding-box;
          //     border: #bababa;
          //     border-radius: 8px;
          //     opacity: 1;

          //     letter-spacing: 0px;
          //     color: #777777;
          //     .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
          //       font: normal normal normal 14px/40px Noto Sans KR;
          //     }
          //     .css-cyqju5-MuiInputBase-root-MuiOutlinedInput-root {
          //       width: 288px;
          //       border-radius: 8px;
          //       height: 48px;
          //     }
          //   }
          // }
        }
        // & .search-btn-container {
        //   margin-top: 40px;
        //   .reset-btn {
        //     text-align: center;
        //     font: normal normal normal 16px/40px Noto Sans KR;
        //     letter-spacing: 0px;
        //     color: #1e76af;
        //     opacity: 1;
        //     margin-right: 20px;
        //     width: 180px;
        //     height: 56px;
        //     background: #ffffff 0% 0% no-repeat padding-box;
        //     border: 1px solid #1e76af;
        //     border-radius: 8px;
        //     opacity: 1;
        //   }
        //   .search-btn {
        //     text-align: center;
        //     font: normal normal normal 16px/40px Noto Sans KR;
        //     letter-spacing: 0px;
        //     color: #ffffff;
        //     opacity: 1;

        //     width: 180px;
        //     height: 56px;
        //     background: #1e76af 0% 0% no-repeat padding-box;
        //     border-radius: 8px;
        //     opacity: 1;
        //   }
        // }
      }
      & .breadcrumb-container {
        margin-top: 32px;
        padding: 32px 60px 32px 60px;
        width: 1164px;
        height: 126px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #bababa;
        border-radius: 8px;
        opacity: 1;

        display: flex;

        .breadcrumb-text {
          width: 48px;
          height: 19px;
          text-align: center;
          font: normal normal normal 13px/19px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          white-space: nowrap;
          opacity: 1;
          .count {
            margin-top: 8px;
            text-align: center;
            font: normal normal bold 24px/35px Noto Sans KR;
            letter-spacing: 0px;
            color: #777777;
            opacity: 1;
          }
        }
        .breadcrumb-icons {
          margin: 20px 60px 50px 60px;
          width: 20px;
          height: 20px;
          background: #f5f5f5 0% 0% no-repeat padding-box;
          border-radius: 30px;
          opacity: 1;
        }
      }
      & .order-history-container {
        margin-top: 80px;
        .order-history-title {
          width: 191px;
          height: 35px;
          text-align: left;
          font: normal normal bold 24px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          white-space: nowrap;
        }
        .order-history-grid-container {
          margin-top: 32px;
          @media screen and (max-width: 1400px) {
            margin-top: 16px;
          }
          .table-header {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            width: 1164px;
            height: 60px;
            background: #f5f5f5 0% 0% no-repeat padding-box;
            border-radius: 8px;
            opacity: 1;
            .header-text {
              padding: 20px 24px 20px 24px;
              text-align: left;
              font: normal normal normal 14px/20px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            .header-search-data {
              color: var(---1e76af-key-color-);
              text-align: left;
              font: normal normal bold 14px/20px Noto Sans KR;
              letter-spacing: 0px;
              color: #1e76af;
              opacity: 1;
            }
            .header-detail {
              cursor: pointer;
              padding: 20px 24px 20px 24px;
              text-align: right;
              font: normal normal normal 14px/26px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
          }
          .table-body {
            width: 100%;
            height: 60px;
            display: table;
            align-items: center;
            & .image-container {
              width: 80px;
              padding-right: 32px;
              .td-image-container {
                & div {
                  overflow: hidden;
                  border-radius: 8px;
                  & img {
                    cursor: pointer;
                    max-height: 112px;
                    object-fit: contain;
                    border-radius: 8px;
                  }
                }
              }
            }
            & .td-info-container {
              width: 486px;
            }
            .td-image-title-container {
              text-align: left;
              white-space: nowrap;
              .td-info {
                max-width: 474px;
                color: var(---000000-text-);
                text-align: left;
                font: normal normal bold 16px/23px Noto Sans KR;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                cursor: pointer;
              }
              .td-count {
                text-align: left;
                font: normal normal normal 14px/23px Noto Sans KR;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
              }
            }
            & .td-price-td {
              width: 140px;
              padding-right: 88px;
            }
            .td-price-container {
              display: flex;
              align-items: baseline;
              width: 120px;
              .td-price {
                margin-left: auto;
                text-align: left;
                font: normal normal bold 20px/142px Noto Sans KR;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
              }
              .price-unit {
                text-align: left;
                font: normal normal medium 14px/142px Noto Sans KR;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
              }
            }
            .td-send {
              text-align: left;
              font: normal normal normal 14px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            .td-detail-status {
              font: normal normal bold 14px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #1976af;
              opacity: 1;
            }
            .td-detail-date {
              margin-top: 10px;
              text-align: center;
              font: normal normal normal 14px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            .td-detail-btn {
              width: 80px;
              height: 32px;
              margin-top: 8px;
              text-align: left;
              font-size: 13px;
              // font: normal normal normal 13px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #777777;
              opacity: 1;
              padding: 0;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 1px solid #bababa;
              border-radius: 4px;
              opacity: 1;
            }
            .td-btn-box {
              width: 88px;
              margin-left: 88px;
              margin-right: 0;
              display: flex;
              flex-direction: column;
              align-items: flex-end;
              .td-review-btn {
                width: 88px;
                height: 36px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #1e76af;
                border-radius: 4px;
                opacity: 1;

                text-align: center;
                font: normal normal normal 13px/40px Noto Sans KR;
                letter-spacing: 0px;
                color: #1e76af;
                opacity: 1;
              }
              .td-one-btn {
                margin-top: 4px;
                width: 88px;
                height: 36px;
                background: #1e76af 0% 0% no-repeat padding-box;
                border-radius: 4px;
                opacity: 1;

                font: normal normal normal 13px/40px Noto Sans KR;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
              }
            }
          }
          & .pagination-container {
            margin: 48px auto 120px auto;
            height: 24px;
            opacity: 1;
            display: flex;
            justify-content: center;
            & .pagination-container .pagination {
              text-align: center;
              font: normal normal normal 13px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #1e76af;
              opacity: 1;
            }
          }
        }
      }
      & .caution-title {
        margin-top: 83px;
        text-align: left;
        font: normal normal bold 24px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .caution-box-container {
        margin-top: 32px;
        padding: 32px;
        width: 1164px;
        height: 140px;
        background: #f4f4f4 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        & .caution-box-text {
          text-align: left;
          font: normal normal normal 14px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
    }
  }
  @import "./OrderHistoryDetail.scss";

  // 모바일용 css 모음
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .header-wrapper {
      display: none;
    }

    & .order-history-wrapper {
      padding-top: 20px;
      width: 100%;
      & .history-container {
        width: 100%;
        min-width: 360px;
        max-width: 480px !important;
        margin: 0 auto;
        & .mobile-menu-wrapper {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          height: 25px;
          margin: 0 20px 20px;
        }
        & .lookup-container {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          margin: 0 20px;
          padding: 0;
          border: none;
          border-radius: 0;
          border-top: 1px solid #bababa;
          & .lookup-peroid-container {
            margin-top: 20px;
            padding: 0;
            padding-bottom: 20px;
            border-bottom: 1px solid #e8e8e8;
            & .lookup-peroid-text {
              font-weight: bold;
            }
          }
          & .select-date-container {
            width: calc(100vw - 40px);
            min-width: 320px;
            max-width: 440px;
            margin-top: 5px;
            transition: 0.3s all ease-in-out;
            &.opened {
              min-width: 320px;
              width: calc(100vw - 40px);
              max-width: 440px;
              transition: 0.3s all ease-in-out;
            }
            & .select-box-container {
              width: calc(100vw - 40px);
              min-width: 320px;
              max-width: 440px;
              margin: 0;
              & .box-container {
                display: block;
                margin-top: 5px;
                height: 164px;
                & .period-select-box {
                  width: 100%;
                  margin-bottom: 14px;
                  & .MuiInputBase-root {
                    width: calc(100vw - 40px);
                    min-width: 320px;
                    max-width: 440px;
                    text-align: left;
                  }
                }
                & .date-range-picker {
                  width: calc(100vw - 40px);
                  min-width: 320px;
                  max-width: 440px;
                  height: 48px;
                  margin-bottom: 6px;
                  display: flex;
                  justify-content: space-between;
                  & .range-picker-space {
                    width: 5%;
                    margin: 0;
                    line-height: 38px;
                  }
                  & .range-picker-box {
                    width: 80%;
                    min-width: 155px !important;
                    max-width: 215px;
                    height: 48px !important;
                    margin: 0;
                    flex-wrap: nowrap;
                    &.right {
                      width: 80%;
                      min-width: 155px !important;
                      max-width: 215px;
                    }
                    & .MuiInputBase-root {
                      border-radius: 8px;
                    }
                  }
                }
                & .search-button-container {
                  display: flex;
                  justify-content: space-between;
                  & .search-btn {
                    width: 80%;
                    margin-left: 0;
                    min-width: 155px;
                    max-width: 215px;
                  }
                  & .search-button-space {
                    width: 5%;
                  }
                  & .reset-btn {
                    width: 80%;
                    min-width: 155px;
                    max-width: 215px;
                    margin-left: 0 !important;
                  }
                }
              }
            }
          }
        }

        & .order-history-container {
          width: 100%;
          height: auto;
          padding: 50px 0 0;
          margin: 0 auto;
          text-align: center;
          & .order-history-title {
            width: calc(100vw - 40px);
            min-width: 320px;
            max-width: 440px;
            margin: 0 auto;
            height: 22px;
            font-size: 15px;
            line-height: 22px;
            margin-bottom: 10px;
          }
          & .order-history-grid-container {
            min-width: 320px;
            max-width: 440px;
            width: calc(100vw - 40px);
            margin: 0 auto;
            & .MuiTable-root {
              width: calc(100vw - 40px);
              min-width: 320px;
              max-width: 440px;
              margin: 0 auto;
              & .order-history-info {
                min-width: 320px;
                max-width: 440px;
                width: calc(100vw - 40px);
                margin-bottom: 30px;
                & .table-header {
                  width: calc(100vw - 40px);
                  min-width: 320px;
                  max-width: 440px;
                  height: 40px;
                  margin-bottom: 10px;
                  display: flex;
                  justify-content: space-between;
                  & .header-text {
                    padding: 10px;
                    line-height: 15px;
                    font-size: 12px;
                    & .header-search-data {
                      font-size: 12px;
                    }
                  }
                  & .header-detail {
                    padding: 10px;
                    line-height: 15px;
                    font-size: 12px;
                  }
                }
                & .table-body {
                  width: calc(100vw - 40px);
                  min-width: 320px !important;
                  max-width: 440px !important;
                  & .order-history-data {
                    margin-bottom: 10px;
                    & .td-detail-container {
                      width: calc(100vw - 40px);
                      min-width: 320px !important;
                      max-width: 440px !important;
                      height: 35px;
                      border-bottom: 0.5px solid #e8e8e8;
                      display: flex;
                      justify-content: space-between;
                      line-height: 35px;
                      & .td-detail-info-container {
                        display: flex;
                        & .td-detail-status {
                          font-size: 12px;
                          line-height: 35px;
                          margin-right: 15px;
                        }
                        & .td-detail-date {
                          margin: 0;
                          font-size: 12px;
                          line-height: 35px;
                        }
                      }
                      & .td-detail-button-container {
                        height: 35px;
                        & .td-detail-btn {
                          width: 85px;
                          height: 24px;
                          margin: 3px 0 5px 0;
                          font-size: 12px;
                          & svg {
                            margin-right: -8px;
                          }
                        }
                      }
                    }
                    & .product-info-container {
                      min-width: 320px !important;
                      max-width: 440px !important;
                      height: 120px;
                      margin: 10px 0;
                      display: flex;
                      & .image-container {
                        margin-right: 15px;
                        & img {
                          max-height: 120px;
                          object-fit: contain;
                          border-radius: 8px;
                        }
                      }
                      & .product-info {
                        width: 100%;
                        min-width: 225px !important;
                        max-width: 345px !important;
                        & .product-title {
                          height: 25px;
                          margin-bottom: 75px;
                          & .title-text {
                            // overflow: hidden;
                            // text-overflow: ellipsis;
                            // word-wrap: brek-word;
                            // display: -webkit-box;
                            // -webkit-line-clamp: 1;
                            // -webkit-box-orient: vertical;
                            font-size: 15px;
                            font-weight: bold;
                            text-align: left;
                          }
                        }
                        & .order-info {
                          width: 100%;
                          display: flex;
                          justify-content: space-between;
                          & .qty-text {
                            font-size: 13px;
                            color: #777777;
                          }
                          & .price-container {
                            display: flex;
                            & .price-text {
                              font-size: 14px;
                              font-weight: bold;
                              line-height: 20px;
                            }
                            & .price-unit {
                              font-size: 12px;
                              font-weight: bold;
                              line-height: 20px;
                            }
                          }
                        }
                      }
                    }
                    & .btn-container {
                      width: calc(100vw - 40px);
                      min-width: 320px !important;
                      max-width: 440px !important;
                      display: flex;
                      justify-content: space-between;
                      margin: 0;
                      & .review-btn {
                        height: 36px;
                        background: #ffffff;
                        border: 1px solid #1e76af;
                        border-radius: 8px;
                        color: #1e76af;
                        margin-left: 0;
                        width: 80%;
                        min-width: 155px;
                        max-width: 215px;
                      }
                      & .btn-sapce {
                        width: 5%;
                      }
                      & .one-btn {
                        height: 36px;
                        background: #1e76af;
                        border-radius: 8px;
                        color: #ffffff;
                        width: 80%;
                        min-width: 155px;
                        max-width: 215px;
                      }
                    }
                  }
                }
              }
            }
            & .pagination-container {
              width: calc(100vw - 40px);
              min-width: 320px !important;
              max-width: 440px !important;
              margin: 0;
            }
          }
        }

        & .caution-title {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          margin: 0 auto;
          font-size: 15px;
        }
        & .caution-box-container {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          margin: 0 auto 40px;
          padding: 10px;
          & .caution-box-text {
            font-size: 12px;
            line-height: 24px;
          }
        }
      }
    }
  }
}
