& .univ-goods-banner-container {
  // 굿즈 배너 영역 PC용
  padding-top: 80px;
  & .univ-goods-banner-header {
    display: flex;
    justify-content: space-between;
    align-items: end;
    & .goods-title {
      font-size: 24px;
      font-weight: bold;
    }
  }
  & .univ-goods-banner-body {
    margin-top: 32px;
    overflow: hidden;
    border-top: 1px solid #e8e8e8;
    border-bottom: 1px solid #e8e8e8;
    & .univ-goods-swiper {
      height: 100%;
      width: 100%;
      z-index: 0;
      & .swiper-wrapper {
        display: flex;
        width: fit-content;
      }
      & .swiper-slide {
        z-index: 1;
        user-select: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-use-select: none;
        & .univ-goods-slide-wrap {
          height: 160px;
          display: flex;
          align-items: center;
          cursor: pointer;
          padding: 15px 10px 25px 10px;
          & .univ-goods-slide-img {
            width: 160px;
            height: 160px;
            display: flex;
            align-items: center;
            & img {
              width: 160px;
              height: 160px;
              object-fit: cover;
              border-radius: 8px;
            }
          }
          & .univ-goods-slide-content {
            height: 160px;
            width: 50%;
            margin-left: 20px;
            & .univ-goods-title {
              font-size: 20px;
              font-weight: bold;
              height: 21%;
              display: flex;
              align-items: flex-start;
            }
            & .univ-goods-price {
              text-align: left;
              font-size: 16px;
            }
            & .univ-goods-info {
              overflow: hidden;
              text-overflow: ellipsis;
              text-align: left;
              display: -webkit-box;
              line-clamp: 3;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
            }
          }
        }
      }

      // & .autoplay-progress {
      //   position: absolute;
      //   right: 16px;
      //   bottom: 16px;
      //   z-index: 10;
      //   width: 48px;
      //   height: 48px;
      //   display: flex;
      //   align-items: center;
      //   justify-content: center;
      //   font-weight: bold;
      //   color: var(--swiper-theme-color);
      // }

      // & .autoplay-progress svg {
      //   --progress: 0;
      //   position: absolute;
      //   left: 0;
      //   top: 0px;
      //   z-index: 10;
      //   width: 100%;
      //   height: 100%;
      //   stroke-width: 4px;
      //   stroke: var(--swiper-theme-color);
      //   fill: none;
      //   stroke-dashoffset: calc(125.6px * (1 - var(--progress)));
      //   stroke-dasharray: 125.6;
      //   transform: rotate(-90deg);
      // }
    }
  }
}

@media (max-width: 1400px) {
  // 굿즈 배너 영역 모바일용 css
  & .univ-goods-banner-container {
    width: calc(100vw - 40px);
    min-width: 320px;
    max-width: 440px;
    margin-left: auto;
    margin-right: auto;
    padding-top: 20px;
    padding-bottom: 30px;
    & .univ-goods-banner-header {
      & .goods-title {
        font-size: 17.5px;
      }
      & .univ-goods-view-all {
        font-size: 14px;
      }
    }
    & .univ-goods-banner-body {
      margin-top: 16px;
      & .univ-goods-slide-wrap {
        height: 140px !important;
        & .univ-goods-slide-img {
          width: 140px !important;
          height: 140px !important;
          & img {
            width: 140px !important;
            height: 140px !important;
          }
        }
        & .univ-goods-slide-content {
          height: 140px !important;
          & .univ-goods-title {
            font-size: 15px !important;
          }
          & .univ-goods-price {
            text-align: left;
            font-size: 13px !important;
            margin-bottom: 5px;
          }
          & .univ-goods-info {
            font-size: 13px;
          }
        }
      }
    }
  }
}
