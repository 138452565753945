#Review {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .myPage-wrapper {
    display: flex;
    padding-top: 80px;
    // flex-direction: column;
    @import "./SideMenu.scss";
  }
  & .mypage-container {
    // margin: 48px 260px 120px 370px;
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;
    & .title-container {
      display: flex;
      margin-bottom: 24px;
      // align-items: flex-end;
      & .title-text {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }

    & .list-row-container {
      // display: flex;
      padding: 20px 0;
      border-top: 1px solid #e8e8e8;
      & .book-info-container {
        display: flex;
        flex-direction: column;
        text-align: left;
        & .book-info-top {
          display: flex;
          margin-bottom: 20px;
          & .book-img img {
            background: #f3f8fb 0% 0% no-repeat padding-box;
            box-shadow: 0px 5px 10px #00000029;
            border-radius: 8px;
            width: 80px;
            height: 112px;
            cursor: pointer;
          }
          & .classification {
            border: 1px solid #e8e8e8;
            border-radius: 4px;
            width: fit-content;
            padding: 4px 12px;
          }
          & .book-title-container {
            text-align: left;
            margin-top: 20px;
            font-size: 16px;
            font-weight: bold;
            display: flex;
            flex-direction: column;
            margin-left: 32px;
            cursor: pointer;
            & .book-info-text {
              margin-top: 8px;
              // color: #777777;
              display: flex;
              text-align: left;
              font: normal normal normal 14px/23px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;

              & div {
                height: 8px;
                width: 1px;
                background-color: #777777;
                margin: auto 8px;
              }
            }
          }
        }
        & .bottom-line {
          margin-top: 20px;
          display: flex;
          align-items: center;
          margin-bottom: 20px;
          & .buyer-tag {
            width: 64px;
            height: 24px;
            background: #1e76af 0% 0% no-repeat padding-box;
            border-radius: 4px;
            text-align: center;
            font: normal normal normal 13px/28px Noto Sans KR;
            letter-spacing: 0px;
            color: #ffffff;
          }
          & .text {
            margin-left: 24px;
            text-align: left;
            font-size: 13px;
            // letter-spacing: 0px;
            color: #777777;
          }
          & .text-date {
            text-align: left;
            font-size: 13px;
            // letter-spacing: 0px;
            color: #777777;
          }
          & .divider {
            height: 16px;
            width: 1px;
            background-color: #e8e8e8;
            margin: auto 16px;
          }

          & .rating-container {
            display: flex;
            // margin-top: 12px;
            & .star {
              margin: 2px 6px 0 0;
            }
            & .rating {
              font-size: 20px;
              font-weight: bold;
              margin-left: 8px;
            }
          }
        }
      }
      & .review {
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: #000000;
        white-space: pre-wrap;
      }
      & .button-container {
        margin-left: auto;
        display: flex;
        justify-content: flex-end;
        // flex-direction: column;
        // width: 156px;
        & button {
          width: 54px;
          height: 36px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #bababa;
          border-radius: 4px;
          font: normal normal normal 12px/28px Noto Sans KR;
          text-align: center;

          &:nth-of-type(2) {
            letter-spacing: 0px;
            color: #000000;
            margin-left: 8px;
          }
        }
      }
    }
    & .pagination-container {
      margin: 48px auto 120px auto;
      // width: 318px;
      height: 24px;
      opacity: 1;
      display: flex;
      justify-content: center;
      & .pagination-container .pagination {
        text-align: center;
        font-size: 13px;
        // font: normal normal normal 13px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
        opacity: 1;
      }
      & .css-wjh20t-MuiPagination-ul {
        justify-content: center;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .myPage-wrapper {
      padding-top: 20px;
      margin: 0 auto;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      & .mypage-container {
        margin: 0;
        width: 100%;
        & .title-container {
          margin-bottom: 20px;
        }
        & .cont-container {
          min-height: 150px;
        }
        & .pagination-container {
          margin: 0;
          margin-bottom: 50px;
          & .pagination {
            & .MuiPagination-ul {
              & li {
                display: flex;
                align-items: center;
                & .MuiButtonBase-root {
                  min-width: 22px;
                  height: 22px;
                  border-radius: 16px;
                }
                & button {
                  font-size: 10px;
                  & svg {
                    width: 19px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }
        & .list-row-container {
          & .book-info-container {
            & .book-info-top {
              & .book-title-container {
                color: var(---000000-text-);
                text-align: left;
                font: normal normal bold 15px/24px Noto Sans KR;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                & .book-info-text {
                  color: var(---777777-sub-text-);
                  text-align: left;
                  font: normal normal normal 12.5px/12px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #777777;
                  opacity: 1;
                }
              }
            }
            & .bottom-line {
              width: 100%;
              margin-bottom: 15px;
              & .text {
                margin: 0;
                color: var(---777777-sub-text-);
                text-align: left;
                font: normal normal normal 12px Noto Sans KR;
                letter-spacing: 0px;
                color: #777777;
                opacity: 1;
              }
              & .divider {
                color: red;
                margin: auto 10px;
              }
              & .text-date {
                color: var(---777777-sub-text-);
                text-align: left;
                font: normal normal normal 12px Noto Sans KR;
                letter-spacing: 0px;
                color: #777777;
                opacity: 1;
              }
              & .rating-container {
                & .star {
                  margin: 0 2px;
                }
              }
            }
            & .button-container {
              margin-top: 16px;
              & .MuiButtonBase-root {
                color: var(---777777-sub-text-);
                text-align: left;
                font: normal normal normal 11px/14px Noto Sans KR;
                letter-spacing: 0px;
                color: #777777;
                opacity: 1;
                width: 44px;
                height: 34px;
              }
            }
          }
        }
      }
    }
  }

  // @media screen and (max-width: 1400px) {
  //   min-width: 360px;
  //   // width: auto;
  //   // max-width: 430px;

  //   & .myPage-wrapper {
  //     display: flex;
  //     min-width: 360px;
  //     width: 100%;
  //     margin: 0 auto;
  //     max-width: 480px !important;
  //     padding: 0;
  //   }
  //   & .mypage-container {
  //     margin: 50px 20px;
  //     min-width: 360px;
  //     display: flex;
  //     flex-direction: column;

  //     & .mobile-header {
  //       margin: 0 0 20px 0;
  //       & .header {
  //         display: flex;
  //         justify-content: space-between;
  //         .home-ico {
  //           width: 24px;
  //           height: 24px;
  //           color: #000000;
  //         }
  //       }
  //       & .mobile-title {
  //         text-align: left;
  //         font-size: 17px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         margin-top: 20px;
  //       }
  //     }

  //     & .list-row-container {
  //       // display: flex;
  //       padding: 20px 0;
  //       border-top: 1px solid #e8e8e8;
  //       & .book-info-container {
  //         display: flex;
  //         flex-direction: column;
  //         text-align: left;
  //         & .book-info-top {
  //           display: flex;
  //           margin-bottom: 20px;
  //           & .book-img img {
  //             background: #f3f8fb 0% 0% no-repeat padding-box;
  //             box-shadow: 0px 5px 10px #00000029;
  //             border-radius: 8px;
  //             width: 80px;
  //             height: 112px;
  //             cursor: pointer;
  //           }
  //           & .classification {
  //             border: 1px solid #e8e8e8;
  //             border-radius: 4px;
  //             width: fit-content;
  //             padding: 4px 12px;
  //           }
  //           & .book-title-container {
  //             text-align: left;
  //             margin-top: 20px;
  //             font-size: 15px;
  //             font-weight: bold;
  //             display: flex;
  //             flex-direction: column;
  //             margin-left: 32px;
  //             cursor: pointer;
  //             & .book-info-text {
  //               margin-top: 8px;
  //               // color: #777777;
  //               display: flex;
  //               text-align: left;
  //               font-size: 12px;
  //               font-weight: normal;
  //               letter-spacing: 0px;
  //               color: #777777;
  //               opacity: 1;

  //               & div {
  //                 height: 8px;
  //                 width: 1px;
  //                 background-color: #777777;
  //                 margin: auto 8px;
  //               }
  //             }
  //           }
  //         }
  //         & .mobile-review {
  //           & .bottom-line {
  //             margin-top: 20px;
  //             display: flex;
  //             align-items: center;
  //             margin-bottom: 20px;
  //             & .buyer-tag {
  //               width: 64px;
  //               height: 24px;
  //               background: #1e76af 0% 0% no-repeat padding-box;
  //               border-radius: 4px;
  //               text-align: center;
  //               font: normal normal normal 13px/28px Noto Sans KR;
  //               letter-spacing: 0px;
  //               color: #ffffff;
  //             }
  //             & .text {
  //               margin-left: 24px;
  //               text-align: left;
  //               font-size: 13px;
  //               // letter-spacing: 0px;
  //               color: #777777;
  //             }
  //             & .text-date {
  //               text-align: left;
  //               font-size: 13px;
  //               // letter-spacing: 0px;
  //               color: #777777;
  //             }
  //             & .divider {
  //               height: 16px;
  //               width: 1px;
  //               background-color: #e8e8e8;
  //               margin: auto 16px;
  //             }
  //             & .review {
  //               text-align: left;
  //               font-size: 14px;
  //               letter-spacing: 0px;
  //               color: #000000;
  //             }

  //             & .rating-container {
  //               display: flex;
  //               // margin-top: 12px;
  //               & .star {
  //                 margin: 2px 6px 0 0;
  //               }
  //               & .rating {
  //                 font-size: 20px;
  //                 font-weight: bold;
  //                 margin-left: 8px;
  //               }
  //             }
  //           }

  //           & .button-container {
  //             margin-left: auto;
  //             display: flex;
  //             justify-content: flex-end;
  //             & button {
  //               width: 44px;
  //               height: 24px;
  //               background: #ffffff 0% 0% no-repeat padding-box;
  //               border: 1px solid #bababa;
  //               border-radius: 4px;
  //               font-size: 11px;
  //               font-weight: normal;
  //               text-align: center;

  //               &:nth-of-type(2) {
  //                 letter-spacing: 0px;
  //                 color: #000000;
  //                 margin-left: 8px;
  //               }
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
