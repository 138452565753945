#sign-in {
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  & .sign-in-container {
    min-width: 320px;
    margin: 0 auto 100px;
    display: flex;
    flex-direction: column;
    & .title {
      margin: 80px auto 0;
      font-weight: bold;
      font-size: 24px;
    }
    & .email-input {
      margin-top: 32px;
    }
    & .password-input {
      margin-top: 16px;
    }
    & .error-message {
      color: red;
      font-size: 12px;
      margin-top: 12px;
    }
    & .sign-in-button {
      margin-top: 24px;
      min-width: 320px;
      height: 56px;
      background-color: #1e76af;
      border-radius: 8px;
      border: none;
      color: white;
      font-size: 16px;
    }
    & .bottom-container {
      display: flex;
      width: 100%;
      height: 68px;
      padding-top: 16px;
      padding-bottom: 32px;
      border-bottom: 1px solid #e8e8e8;
      font-size: 14px;
      & .find-id-button {
        margin-left: auto;
      }
      & .divider {
        border-right: 1px solid #bababa;
        height: 12px;
        margin: auto 0;
      }
    }
    & .sns-login-title {
      margin-top: 32px;
      font-size: 16px;
    }
    & .sns-button-container {
      height: 90px;
      padding: 24px 0;
      display: flex;
      margin: 0 auto;
      & .sns-login-icon {
        margin: 0 14px;
        cursor: pointer;
        width: 42px;
        height: 42px;
        & img {
          border-radius: 50%;
        }
      }
    }
    & .sign-up-button {
      height: 56px;
      border: 1px solid #1e76af;
      border-radius: 8px;
      color: #1e76af;
      font-size: 16px;
    }
  }
}
