#PaymentResult {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  @media screen and (max-width: 1400px) {
    min-width: unset;
  }
  & .payment-wrapper {
    display: flex;
    padding-top: 80px;
    padding-bottom: 120px;
    justify-content: center;
    @media screen and (max-width: 1400px) {
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
      margin: 0 auto;
      padding: 80px 0 20px;
    }
  }
  & .payment-container {
    display: flex;
    width: 1400px;
    flex-direction: column;
    @media screen and (max-width: 1400px) {
      min-width: 320px;
      width: 100%;
      max-width: 440px;
      margin: 0 auto;
    }

    & .title-container {
      display: flex;
      margin-bottom: 24px;
      justify-content: space-between;
      & .stepIcon {
        margin: 0 32px;
      }
      & .title-text {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
      }

      @media screen and (max-width: 1400px) {
        & .MuiBreadcrumbs-root {
          display: none;
        }
      }
      & .breadcurmb {
        text-align: left;
        font: normal normal normal 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
      }
      & .breadcurmb-select {
        font: normal normal bold 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #1976af;
      }
    }
    & .paymentSuccess-body {
      padding: 50px;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      @media screen and (max-width: 1400px) {
        padding: 30px 0;
      }
      & .text-box {
        display: flex;
        flex-direction: column;
        font: normal normal bold 24px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 32px;
        @media screen and (max-width: 1400px) {
          font-size: 15px;
          line-height: 26px;
          margin-bottom: 20px;
        }
      }
      & .fail-reason {
        text-align: center;
        font: normal normal normal 15px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        @media screen and (max-width: 1400px) {
          font-size: 13px;
          line-height: 20px;
        }
      }
      & .orderNum-container {
        font-size: 15px;
        & .title {
          font-weight: normal;
        }
        & .orderNum {
          font-weight: bold;
        }
      }
    }
    & .order-info {
      width: 560px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 8px;
      margin-top: 40px;
      padding: 36px 40px;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        width: 100%;
        max-width: 440px;
        margin: 20px auto;
        padding: 20px;
        & div {
          font-size: 13px;
        }
      }
      & .orderBook {
        margin-bottom: 20px;
        display: flex;
        justify-content: space-between;
        font: normal normal normal 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        align-items: center;
        @media screen and (max-width: 1400px) {
          margin-bottom: 16px;
        }
        & .orderBook-title {
          font: normal normal bold 16px/20px Noto Sans KR;
          letter-spacing: 0px;
          @media screen and (max-width: 1400px) {
            font-size: 13px;
          }
        }
      }
      & .cost-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 20px;
        font: normal normal normal 16px/24px Noto Sans KR;
        @media screen and (max-width: 1400px) {
          font-size: 13px;
          margin-bottom: 16px;
        }
        letter-spacing: 0px;
        color: #000000;
        align-items: center;
        & .product-cost {
          font: normal normal bold 20px/20px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          @media screen and (max-width: 1400px) {
            font-size: 13px;
          }
        }
      }
      & .discount-info {
        display: flex;
        justify-content: space-between;
        margin-bottom: 30px;
        font: normal normal normal 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        @media screen and (max-width: 1400px) {
          font-size: 13px;
          margin-bottom: 16px;
        }
        color: #000000;
        align-items: center;
        & .discount {
          font: normal normal bold 20px/20px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          @media screen and (max-width: 1400px) {
            font-size: 13px;
          }
        }
      }
      & .totalCost-info {
        display: flex;
        justify-content: space-between;
        margin-top: 30px;
        font: normal normal bold 20px/29px Noto Sans KR;
        letter-spacing: 0px;
        color: #1976af;
        align-items: center;
        @media screen and (max-width: 1400px) {
          margin-top: 20px;
        }
        & .totalCost {
          font: normal normal bold 24px/35px Noto Sans KR;
          letter-spacing: 0px;
          color: #1976af;
        }
      }
      & .payment-info {
        display: flex;
        justify-content: space-between;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        align-items: center;
        color: #000000;
        @media screen and (max-width: 1400px) {
          line-height: 1.2;
          margin-top: 18px;
        }
        & .payment-date {
          font: normal normal normal 12px/30px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 0.8;
        }
      }
    }
    & .addressInfo {
      width: 560px;
      height: 214px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 8px;
      margin-top: 16px;
      padding: 36px 40px;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        width: 100%;
        max-width: 440px;
        margin: 20px auto;
        padding: 20px;
        height: fit-content;
      }
      & .shippingDest {
        display: flex;
        text-align: left;
        font: normal normal bold 20px/20px Noto Sans KR;
        letter-spacing: 0px;
        color: #1976af;
        align-items: center;
        @media screen and (max-width: 1400px) {
          font-size: 13px;
        }
        // & .location-icon {
        //   margin-right: 8.81px;
        //   background: var(---bababa-line_darker-) 0% 0% no-repeat padding-box;
        // }
        .locaIcon {
          margin-right: 10px;
          @media screen and (max-width: 1400px) {
            width: 16px;
            margin-right: 5px;
          }
        }
      }
      & .userInfo {
        text-align: left;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        @media screen and (max-width: 1400px) {
          font-size: 13px;
          margin-top: 12px;
          line-height: 20px;
        }
      }
      & .address {
        text-align: left;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        @media screen and (max-width: 1400px) {
          font-size: 13px;
          margin-top: 12px;
          line-height: 20px;
        }
      }
      & .message {
        max-width: 480px;
        text-align: left;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        @media screen and (max-width: 1400px) {
          font-size: 13px;
          margin-top: 12px;
          line-height: 20px;
        }
      }
    }
    & .btn-container {
      margin-top: 48px;
      @media screen and (max-width: 1400px) {
        display: flex;
        margin-top: 0;
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        justify-content: space-between;
      }
      & .orderDetail-btn {
        width: 188px;
        height: 56px;
        @media screen and (max-width: 1400px) {
          display: flex;
          min-width: 150px;
          width: calc(50vw - 30px);
          max-width: 210px;
          height: 48px;
        }
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 8px;
        text-align: left;
        font: normal normal normal 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
      }
      & .continue-btn {
        width: 188px;
        height: 56px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        text-align: left;
        font: normal normal normal 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 16px;
        @media screen and (max-width: 1400px) {
          min-width: 150px;
          width: calc(50vw - 30px);
          max-width: 210px;
          height: 48px;
          margin-left: 0;
        }
      }
    }
    & .fail-btn-container {
      margin-top: 48px;
      @media screen and (max-width: 1400px) {
        display: flex;
        margin-top: 120px;
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        justify-content: space-between;
      }
      & .main-btn {
        width: 188px;
        height: 56px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 8px;
        text-align: left;
        font: normal normal normal 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
        @media screen and (max-width: 1400px) {
          display: flex;
          min-width: 150px;
          width: calc(50vw - 30px);
          max-width: 210px;
          height: 48px;
        }
      }
      & .back-btn {
        width: 188px;
        height: 56px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        text-align: left;
        font: normal normal normal 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
        margin-left: 16px;
        @media screen and (max-width: 1400px) {
          display: flex;
          min-width: 150px;
          width: calc(50vw - 30px);
          max-width: 210px;
          height: 48px;
        }
      }
    }
  }
  // @media screen and (max-width: 1400px) {
  //   min-width: 360px;
  //   width: auto;
  //   max-width: 430px;
  //   margin-top: 50px;
  //   & .payment-wrapper {
  //     display: flex;
  //     padding-top: 0px;
  //     padding-bottom: 120px;
  //     justify-content: center;
  //   }
  //   & .payment-container {
  //     display: flex;
  //     margin: 20px 20px;
  //     // width: 1400px;
  //     flex-direction: column;
  //     // padding-left: 20px;
  //     & .paymentSuccess-body {
  //       // padding: 50px;
  //       margin-top: 30px;
  //       padding: 0px;
  //       display: flex;
  //       flex-direction: column;
  //       align-items: center;
  //       text-align: center;
  //       & .text-box {
  //         display: flex;
  //         flex-direction: column;
  //         line-height: 2;
  //         font-size: 15px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         margin-bottom: 10px;
  //       }
  //       & .fail-reason {
  //         text-align: center;
  //         font-size: 13px;
  //         font-weight: normal;
  //         letter-spacing: 0px;
  //         color: #000000;
  //       }
  //       & .orderNum-container {
  //         font-size: 12px;
  //         & .orderNum {
  //           font-weight: bold;
  //           margin-left: 3px;
  //         }
  //       }
  //     }
  //     & .order-info {
  //       width: 100%;
  //       height: 227px;
  //       background: #f5f5f5 0% 0% no-repeat padding-box;
  //       border-radius: 8px;
  //       margin-top: 20px;
  //       padding: 20px;
  //       & .orderBook {
  //         margin-bottom: 14px;
  //         display: flex;
  //         justify-content: space-between;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         align-items: center;
  //         & .orderBook-title {
  //           font-size: 13px;
  //           letter-spacing: 0px;
  //         }
  //       }
  //       & .cost-info {
  //         display: flex;
  //         justify-content: space-between;
  //         margin-bottom: 14px;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         align-items: center;
  //         & .product-cost {
  //           font-size: 13px;
  //           letter-spacing: 0px;
  //           color: #000000;
  //         }
  //       }
  //       & .discount-info {
  //         display: flex;
  //         justify-content: space-between;
  //         margin-bottom: 10px;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         align-items: center;
  //         & .discount {
  //           font-size: 13px;
  //           letter-spacing: 0px;
  //           color: #000000;
  //         }
  //       }
  //       & .totalCost-info {
  //         display: flex;
  //         justify-content: space-between;
  //         margin-top: 13px;
  //         font-size: 17px;
  //         letter-spacing: 0px;
  //         color: #1976af;
  //         align-items: center;
  //         & .totalCost {
  //           font-size: 17px;
  //           letter-spacing: 0px;
  //           color: #1976af;
  //         }
  //       }
  //       & .payment-info {
  //         display: flex;
  //         justify-content: space-between;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         align-items: center;
  //         color: #000000;
  //         & .payment-date {
  //           font-size: 11px;
  //           letter-spacing: 0px;
  //           color: #000000;
  //           opacity: 0.8;
  //         }
  //       }
  //     }
  //     & .addressInfo {
  //       width: 100%;
  //       height: 177px;
  //       background: #f5f5f5 0% 0% no-repeat padding-box;
  //       border-radius: 8px;
  //       margin-top: 30px;
  //       padding: 20px;
  //       & .shippingDest {
  //         display: flex;
  //         text-align: left;
  //         font-size: 13px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #1976af;
  //         align-items: center;
  //         .locaIcon {
  //           margin-right: 5px;
  //           font-size: 15px;
  //         }
  //       }
  //       & .userInfo {
  //         text-align: left;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #000000;
  //       }
  //       & .address {
  //         line-height: 1.7;
  //         text-align: left;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #000000;
  //       }
  //       & .message {
  //         text-align: left;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #777777;
  //       }
  //     }
  //     & .btn-container {
  //       width: 100%;
  //       margin-top: 48px;
  //       display: flex;
  //       justify-content: space-between;
  //       & .orderDetail-btn {
  //         width: 162px;
  //         height: 48px;
  //         background: #ffffff 0% 0% no-repeat padding-box;
  //         border: 1px solid #1e76af;
  //         border-radius: 8px;
  //         text-align: left;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #1e76af;
  //       }
  //       & .continue-btn {
  //         width: 162px;
  //         height: 48px;
  //         background: #1e76af 0% 0% no-repeat padding-box;
  //         border-radius: 8px;
  //         text-align: left;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #ffffff;
  //         margin-left: 10px;
  //       }
  //     }
  //     & .fail-btn-container {
  //       width: 100%;
  //       margin-top: 48px;
  //       display: flex;
  //       justify-content: space-between;
  //       & .main-btn {
  //         width: 162px;
  //         height: 48px;
  //         background: #ffffff 0% 0% no-repeat padding-box;
  //         border: 1px solid #1e76af;
  //         border-radius: 8px;
  //         text-align: left;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #1e76af;
  //       }
  //       & .back-btn {
  //         width: 162px;
  //         height: 48px;
  //         background: #1e76af 0% 0% no-repeat padding-box;
  //         border-radius: 8px;
  //         text-align: left;
  //         font-size: 13px;
  //         letter-spacing: 0px;
  //         color: #ffffff;
  //         margin-left: 10px;
  //       }
  //     }
  //   }
  // }
  // & .mobile-header {
  //   margin-bottom: 20px;
  //   & .header {
  //     display: flex;
  //     justify-content: space-between;
  //     .home-ico {
  //       width: 24px;
  //       height: 24px;
  //       color: #000000;
  //     }
  //   }
  //   & .mobile-title {
  //     text-align: left;
  //     font-size: 17px;
  //     font-weight: bold;
  //     letter-spacing: 0px;
  //     color: #000000;
  //     margin-top: 20px;
  //   }
  // }
}
