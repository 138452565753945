#SearchResult {
  & .blue {
    color: #1e76af;
  }
  & .gray {
    color: #808080;
    font-size: 12px;
  }
  @media screen and (max-width: 1400px) {
    & .pagination-container {
      min-width: 360px;
      width: 100vw;
      max-width: 480px;
      margin: 20px 0 40px -20px;
      & button {
        margin: 0;
      }
    }
    & #mobile-book-row-presenter {
      & .book-info {
        & .title-container {
          padding: 0;
        }
      }
    }
  }
  & .show-sorting-container {
    background: #f3f8fb 0% 0% no-repeat padding-box;
    min-width: 360px;
    width: 100vw;
    max-width: 480px;
    margin-left: -20px;
    height: 56px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    & .sorting-name {
      float: right;
      font-size: 15px;
      font-weight: bold;
    }
  }
  & .content {
    width: 1400px;
    margin: 80px auto 100px;
    @media screen and (max-width: 1400px) {
      min-width: 360px;
      width: 100vw;
      max-width: 480px;
      margin: 0 auto;
    }
    & .title-container {
      font-size: 24px;
      font-weight: bold;
      text-align: left;
      @media screen and (max-width: 1400px) {
        padding: 20px;
        font-size: 17.5px;
      }
    }
    & .body-container {
      width: 1400px;
      display: flex;
      margin-top: 32px;
      @media screen and (max-width: 1400px) {
        min-width: 360px;
        width: 100vw;
        max-width: 480px;
        margin: 0 auto;
      }

      & .apply-button {
        width: 164px;
        height: 36px;
        background: #e8e8e8 0% 0% no-repeat padding-box;
        border-radius: 4px;
        margin-top: 16px;
        &.price {
          width: 51px;
          margin: 0 0 0 auto;
        }
      }

      & .filter-container {
        width: 196px;
        display: flex;
        flex-direction: column;
        @media screen and (max-width: 1400px) {
          display: none;
        }
        & .title {
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          &.mt {
            margin-top: 40px;
          }
        }
        & .filter-keyword-input {
          border-radius: 8px;
          margin-top: 24px;
        }
        & .filter-search-button {
          margin: -40px 14px 0 auto;
          cursor: pointer;
          z-index: 1;
          width: 24px;
          height: 24px;
          background-color: white;
        }
        & .MuiAccordion-root {
          border-radius: 8px;
          border: 1px solid #e8e8e8;
          box-shadow: none;
          &::before {
            display: none;
          }
        }
        & .class-accordion {
          &.first {
            margin-top: 30px;
          }
          margin-top: 12px;
          display: flex;
          flex-direction: column;
          & .accordion-detail {
            display: flex;
            flex-direction: column;
            & .select-box-container {
              height: 240px;
              overflow-y: scroll;
              color: black;
              display: flex;
              flex-direction: column;
              &::-webkit-scrollbar {
                width: 4px;
              }
              &::-webkit-scrollbar-thumb {
                background-color: #bababa;
                border-radius: 2px;
              }
              &::-webkit-scrollbar-track {
                background-color: white;
              }
            }
            & .price-input-container {
              display: flex;
              line-height: 36px;
              margin-top: 12px;
              & .text {
                width: 51px;
                margin-left: auto;
              }
              & .price-input {
                width: 100px;
                height: 36px;
                font-size: 12px;
                color: #777777;
              }
            }
          }
        }
      }
      & .search-result-presenter {
        margin-left: 40px;
        display: flex;
        flex-direction: column;
        width: 1164px;
        @media screen and (max-width: 1400px) {
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          margin: 0 auto;
          & #mobile-book-row-presenter {
            margin: 0;
          }
        }
        & .search-result-header-container {
          display: flex;
          font-size: 20px;
          font-weight: bold;
          text-align: left;
          margin-bottom: 24px;
          & .info-text {
            display: flex;
            align-items: end;
            height: 30px;
            margin-left: 30px;
            padding-bottom: 3px;
            // line-height: 37px;
          }
          @media screen and (max-width: 1400px) {
            font-size: 17.5px;
            display: unset;
            margin-bottom: 12px;
            & .info-text {
              height: unset;
              margin-left: unset;
            }
          }
        }
      }
    }
  }
}
