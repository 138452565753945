& .mall-special-exhibition {
  margin: 80px auto 0;
  display: flex;
  flex-direction: column;
  width: 1400px;

  @media screen and (max-width: 1400px) {
    width: calc(100vw - 40px);
    min-width: 320px;
    max-width: 440px;
    margin-top: 50px;
    & .title-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      & .title {
        font-size: 17.5px;
      }
    }

    & .exhibition-swiper {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      height: calc((100vw - 40px) * 260 / 750 + 40px);
      min-height: calc(320px * 260 / 750 + 40px);
      max-height: calc(440px * 260 / 750 + 40px);
      margin-top: 20px;
      & img {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        height: calc((100vw - 40px) * 260 / 750);
        min-height: calc(320px * 260 / 750);
        max-height: calc(440px * 260 / 750);
        border-radius: 8px;
      }
    }

    // & .exhibition-swiper {
    //   width: calc(100vw - 40px);
    //   min-width: 320px;
    //   max-width: 440px;
    //   margin-top: 20px;
    //   & .swiper-wrapper {
    //     width: calc(100vw - 40px);
    //     min-width: 320px;
    //     max-width: 440px;
    //     & .exhibition-swiper-slide {
    //       width: calc(100vw - 40px);
    //       min-width: 320px;
    //       max-width: 440px;
    //     }
    //   }
    //   & img {
    //     width: calc(100vw - 40px);
    //     min-width: 320px;
    //     max-width: 440px;
    //   }
    // }

    & #mobile-book-row-presenter {
      margin: 0 !important;
      & .title-container {
        width: calc(100vw - 145px);
        min-width: 215px;
        max-width: 335px;
        & .book-title {
          width: 100%;
          min-width: 143px;
          max-width: 263px;
          text-align: left;
        }
      }
    }
    & .button-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      height: 48px !important;
      margin: auto;
      & .more-button {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        height: 48px;
        font-size: 13px;
        line-height: 48px;
        color: #777777;
        border: 1px solid #bababa;
        border-radius: 8px;
      }
    }
  }
}
