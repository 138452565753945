.header-wrapper {
  display: flex;
  flex-direction: column;
  z-index: 10000;
  min-width: 1400px;
  & .header-container,
  & .notice-container,
  & .menu-container,
  & .sticky-menu-container {
    display: flex;
    width: 1400px;
    margin: 0 auto;
  }
  & .notice-background {
    background-color: black;
    & .notice-container {
      height: 48px;
      color: white;
      display: flex;
      & .notice-button {
        height: 29px;
        cursor: pointer;
        margin: auto 0;
        background-color: black;
      }
      & .notice {
        font-size: 12px;
        margin: auto 0 auto 24px;
        display: flex;
        & div {
          max-width: 280px;
          text-overflow: ellipsis;
        }
        cursor: pointer;
        & .right-arrow {
          margin-left: 12px;
          margin-top: 1px;
          width: 16px;
          height: 16px;
          color: white;
          border-radius: 50%;
          padding: 0;
          background-color: #5a5a5a;
        }
      }
    }
  }
  & .header-background {
    border-bottom: 1px #e8e8e8 solid;
    & .recent-search {
      position: absolute;
      width: 100%;
      min-width: 1400px;
      height: 360px;
      z-index: 10000;
      background-color: white;
      display: flex;
      flex-direction: column;
      box-shadow: 0px 8px 8px #0000001a;
      @media screen and (max-width: 1400px) {
        min-width: 360px;
        width: 100%;
      }
      & .title-container {
        width: 460px;
        margin: 84px auto 0;
        height: 30px;
        display: flex;
        & .title {
          font-size: 20px;
          font-weight: bold;
        }
        & .delete-all {
          margin: auto 0 0 auto;
          color: #777777;
        }
      }
      & .keyword-not-found {
        margin-top: 30px;
      }
      & .recent-keyword {
        cursor: pointer;
        display: flex;
        width: 460px;
        margin: 16px auto 0;
        & .delete-button {
          margin-left: auto;
          cursor: pointer;
          & img {
            margin: auto;
          }
        }
      }
      // 자동완성
      & .autocomplete-container {
        margin: 90px auto 0;
        width: 460px;
        & .autocomplete-row {
          text-align: left;
          margin-bottom: 16px;
          width: 460px;
          cursor: pointer;
          & span {
            color: #1e76af;
            font-weight: bold;
          }
          &.selected {
            background-color: #c2c2c2;
          }
        }
      }
    }
    & .detail-search {
      position: absolute;
      width: 100%;
      min-width: 1400px;
      height: 540px;
      display: flex;
      flex-direction: column;
      z-index: 10001;
      background-color: white;
      box-shadow: 0px 8px 8px #0000001a;
      & .title-container {
        height: 90px;
        width: 1400px;
        margin: 0 auto;
        padding: 31px 0 24px;
        display: flex;
        border-bottom: 1px solid #e8e8e8;
        & .title {
          font-size: 24px;
          font-weight: bold;
        }
        & .comment {
          color: #1e76af;
          margin-left: 32px;
          margin-top: 9px;
          font-size: 12px;
        }
      }
      & .detail-search-input-body {
        width: 1400px;
        display: flex;
        margin: 40px auto 32px;
        & .detail-input-container {
          display: flex;
          flex-direction: column;
          margin-right: 16px;
          width: 240px;
          & .title {
            text-align: left;
            margin-bottom: 12px;
            font-size: 14px;
            font-weight: bold;
          }
          &:nth-of-type(2) {
            width: 360px;
          }
          & .detail-input {
            height: 48px;
          }
        }
      }
      & .detail-search-button-body {
        width: 1400px;
        display: flex;
        flex-direction: column;
        margin: 0 auto;
        & .title {
          text-align: left;
          margin-bottom: 12px;
          font-size: 14px;
          font-weight: bold;
        }
        & .button-container {
          display: flex;
          & .date-option-button {
            height: 40px;
            background-color: #f5f5f5;
            margin-right: 8px;
            padding: 8px 20px;
            border-radius: 8px;
            cursor: pointer;
            &.selected {
              background-color: black;
              color: white;
            }
          }
          & .date-picker {
            height: 48px;
            & .date-picker-input {
              height: 48px;
            }
          }
          & .range-text {
            line-height: 40px;
            margin: auto 8px;
            font-weight: normal;
          }
          & .checkbox {
            margin-left: auto;
          }
        }
      }
      & .search-button-container {
        margin-top: 92px;
        & button {
          width: 160px;
          height: 56px;
          border-radius: 8px;
        }
        & .outlined {
          border: 1px solid #1e76af;
          color: #1e76af;
          margin-right: 8px;
        }
        & .filled {
          background-color: #1e76af;
          color: white;
          margin-left: 8px;
        }
      }
    }
    & .header-container {
      height: 88px;
      & .logo-img {
        margin: auto 0;
        cursor: pointer;
      }
      & .header-link-button {
        margin: auto 0 auto 30px;
      }
      & .search-input {
        z-index: 10002;
        position: absolute;
        margin-top: 27px;
        margin-left: 470px;
        width: 460px;
        height: 35px;
        border-bottom: 1px solid black;
        display: flex;
        & .input {
          width: 380px;
        }
        & .search-img {
          cursor: pointer;
          display: flex;
          & .is-visible {
            margin: auto;
          }
        }
        & .detail-search-button {
          background: #ebebeb 0% 0% no-repeat padding-box;
          border-radius: 4px;
          font-size: 12px;
          line-height: 17px;
          width: 64px;
          height: 24px;
          margin: auto 0 auto 10px;
        }
      }
      & .close-button {
        width: 24px;
        z-index: 1000002;
        margin: auto auto auto 1236px;
        cursor: pointer;
      }
      & .divider {
        height: 15px;
        border-left: 1px solid #c2c2c2;
        margin: auto 0;
      }
      & .header-button {
        height: 33px;
        border-radius: 4px;
        border: none;
        margin: auto 10px;
        background-color: white;
        color: #b2b2b2;
        cursor: pointer;
        &.first {
          margin-left: auto;
        }
      }
    }
    & .mall-header-wrapper {
      color: white;
      display: flex;
      flex-direction: column;
      background-color: #b7916b;
      & .mall-header-container {
        width: 1400px;
        margin: 0 auto;
        height: 64px;
        display: flex;
        & .button-container {
          display: flex;
          background-color: #b7916b;
          margin-right: auto;
          & .mall-header-link-button {
            color: white;
            background-color: #b7916b;
          }
          & .divider {
            height: 15px;
            width: 1px;
            background-color: white;
            margin: auto 0;
          }
          & .block {
            background-color: #b7916b;
            width: 396px;
            display: flex;
            & .home-logo {
              display: flex;
              background: #ad7e51 0% 0% no-repeat padding-box;
              border-radius: 4px;
              height: 33px;
              margin: auto 0;
              cursor: pointer;
              font-size: 12px;
              padding: 8px 10px;
              & .logo-inside {
                margin-right: 4px;
              }
            }
            & .home-ico {
              width: 48px;
              height: 48px;
              margin: auto 0;
              cursor: pointer;
              background-color: #b7916b;
              & img {
                width: 48px;
                height: 48px;
                border-radius: 50%;
                background-color: white;
                object-fit: cover;
              }
            }
          }
        }
        & .mall-title {
          position: absolute;
          top: 32px;
          left: 50%;
          transform: translate(-50%, -50%);
          margin: auto;
          font-size: 22px;
          font-weight: bold;
          cursor: pointer;
        }
        & .close-button {
          width: 24px;
          z-index: 1000002;
          margin: auto auto auto 980px;
          cursor: pointer;
        }
        & .header-button {
          height: 33px;
          border-radius: 4px;
          border: none;
          margin: auto 10px;
          color: white;
          background-color: #b7916b;
          cursor: pointer;
          &.first {
            margin-left: auto;
          }
        }
        & .divider {
          height: 15px;
          width: 1px;
          background-color: white;
          margin: auto 0;
        }
      }
      & .mall-menu-wrapper {
        background-color: white;
        height: 72px;
        display: flex;
        & .mall-menu-container {
          display: flex;
          width: 1400px;
          margin: 0 auto;
          height: 72px;
          & .mall-menu-button {
            margin-right: 24px;
            font-size: 15px;
            padding: 12px;
            font-weight: bold;
          }
          & .search-button {
            box-shadow: 0px 3px 3px #0000001a;
            border: 1px solid #e8e8e8;
            border-radius: 20px;
            width: 280px;
            height: 40px;
            display: flex;
            margin: auto 0 auto auto;
            cursor: pointer;
            & .search-img {
              margin: auto 8px auto auto;
            }
            & .divider {
              height: 15px;
              width: 1px;
              background-color: #b2b2b2;
              margin: auto 0;
            }
            & .detail-search-button {
              border-radius: 20px;
              height: 24px;
              margin: auto 8px;
              background: #ebebeb 0% 0% no-repeat padding-box;
              z-index: 1;
            }
          }
        }
      }
    }
  }
  & .menu-wrapper {
    background-color: white;
    border-bottom: 1px solid #e8e8e8;
    & .menu-container {
      height: 64px;
      opacity: 1;
      & .header-link-button {
        margin: auto 0;
        &.store-link {
          margin-left: 20px;
        }
        &.modoo-entry {
          margin-left: auto;
        }
      }
      & .menu-button-wrapper {
        position: absolute;
        top: 138px;
        left: max(235px, calc(50vw - 350px));
        & .menu-button-container {
          display: flex;
          margin: 0 auto;
          & .menu-button {
            height: 63px;
            margin: auto 12px 0;
            border: none;
            cursor: pointer;
            background-color: white;
            font-weight: bold;
            &.selected {
              height: 61px;
              border-bottom: 2px solid black;
            }
          }
        }
      }
    }
  }

  // 분야별도서 호버 메뉴
  @import "./FieldHoverMenu.scss";

  & .sticky-menu-wrapper {
    background-color: black;
    height: 64px;
    position: fixed;
    width: 100%;
    transition: top 0.5s ease-in-out;
    top: -64px;
    z-index: 1000000;
    & .sticky-menu-container {
      height: 64px;
      & .menu-button-wrapper {
        position: absolute;
        top: 20px;
        left: max(235px, calc(50vw - 350px));
        & .menu-button-container {
          display: flex;
          margin: 0 auto;
          min-width: 702px;
          & .menu-button {
            margin: auto 12px;
            border: none;
            cursor: pointer;
            font-weight: bold;
            background-color: black;
            color: white;
          }
        }
      }
      & .logo-img {
        margin: auto 30px auto 0;
        cursor: pointer;
      }
      & .menu-icon {
        margin: auto 10px;
        cursor: pointer;
        &.search {
          margin-left: auto;
        }
      }
    }
    &.sticky-header {
      position: fixed;
      top: 0;
      height: 64px;
    }
  }

  & .header-link-button {
    background-color: #f3f8fb;
    height: 33px;
    border-radius: 4px;
    border: none;
    color: #1e76af;
    cursor: pointer;
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .header-link-button {
      display: none;
    }
    & .notice-background {
      display: none;
    }
    & .header-background {
      &:has(.recent-search) {
        &:has(.mall-header-wrapper) {
          & .recent-search {
            margin-top: 55px;
            padding-top: 20px;
            height: 300px;
            & .autocomplete-container {
              margin-top: 0;
            }
            & .title-container {
              margin-top: 0;
            }
          }
          & .search-input {
            border: 1px solid black;
            margin-left: auto !important;
          }
        }
        & .mall-header-wrapper {
          & .mall-header-container {
            z-index: 10002;
            & .close-button {
              margin: 15px;
            }
          }
        }
      }
      & .recent-search {
        height: 330px;
        padding: 0 20px;
        & .autocomplete-row,
        & .title-container,
        & .recent-keyword {
          min-width: 320px;
          width: 100%;
          max-width: 440px;
        }
      }
      & .header-container {
        min-width: 360px;
        width: 100%;
        max-width: 480px;
        & .logo-img {
          margin-left: 12px;
          z-index: 10002;
        }
        & .search-input {
          position: relative;
          margin-left: 0;
          border-bottom: none;
          height: 48px;
          background-color: #f5f5f5;
          width: 100%;
          margin: auto 12px;
          border-radius: 24px;
          & .detail-search-button {
            display: none;
          }
          & .search-img {
            margin-right: 12px;
          }
          & .input {
            width: calc(100% - 60px);
            margin: auto;
            margin-right: 0;
          }
        }
        & .close-button {
          margin: auto 20px auto 0;
        }
        & .cart-icon {
          margin: auto 20px auto auto;
          cursor: pointer;
          display: none;
        }
      }

      & .mall-header-wrapper {
        width: 100%;
        & .mall-header-container {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          height: 55px;
          display: flex;
          justify-content: space-between;
          & .mall-title {
            position: static;
            top: 0;
            left: 0;
            transform: translate(0, 0);
            margin: 0;
            font-size: 17.5px;
            font-weight: bold;
            line-height: 55px;
          }
          & .search-input {
            position: relative;
            max-width: 150px;
            width: 100%;
            height: 32px;
            margin: auto 0;
            border-bottom: none;
            background-color: #fff;
            border-radius: 24px;
            display: flex;
            & .detail-search-button {
              display: none;
            }
            & .search-img {
              margin: auto 12px;
              margin-left: 0;
            }
            & .input {
              width: calc(100% - 60px);
              margin: auto;
              margin-right: 0;
            }
          }
        }
        & .mall-menu-wrapper {
          width: 100%;
          height: 55px;
          overflow-x: scroll;
          display: flex;
          margin: auto;
          &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
          }
          & .mall-menu-container {
            width: auto;
            height: 55px;
            & .mall-menu-button {
              width: 105px !important;
              height: 55px;
              font-size: 14px;
              margin: 0;
              padding: 1px;
            }
          }
        }
      }
    }

    & .menu-wrapper {
      & .menu-container {
        width: auto;
        & .menu-button-wrapper {
          position: relative;
          top: auto;
          left: auto;
          overflow-x: scroll;
          display: flex;
          margin: auto;
          &::-webkit-scrollbar {
            display: none; /* Chrome, Safari, Opera*/
          }
          & .menu-button-container {
            margin: auto;
            & .menu-button {
              font-size: 14px;
              width: 105px;
              margin: 0;
              padding: 1px;
            }
          }
        }
      }
    }
    & .sticky-menu-wrapper {
      display: none;
    }
  }
}
