#six-book-swiper-presenter {
  & .new-book-body {
    margin-top: 28px;
    width: 1400px;
    min-height: 488px;
    & .swiper-slide {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      & .book-image {
        min-height: 200px;
        cursor: pointer;
        & img {
          width: 200px;
          height: auto !important;
          border-radius: 10px;
          box-shadow: 0px 5px 10px #00000029;
        }
      }
      & .book-title {
        margin-top: 16px;
        font-size: 16px;
        font-weight: bold;
        word-break: break-all;
      }
      & .book-author-publisher {
        width: 200px;
        margin-top: 8px;
        font-size: 12px;
        color: #777777;
      }
      & .price-container {
        display: flex;
        width: auto;
        margin-top: 20px;
        & .sale-percentage {
          margin: auto;
          margin-right: 0;
          width: 26px;
          height: 17px;
          padding: 4px 8px;
          color: white;
          border-radius: 4px;
          background-color: #1e76af;
          font-size: 12px;
        }
        & .price {
          margin-left: 12px;
          font-size: 20px;
          font-weight: bold;
        }
        & .price-unit {
          font-size: 14px;
          margin: auto auto 3px 2px;
        }
      }
    }
    & .swiper-button-prev {
      cursor: pointer;
      &::after {
        content: none;
      }
    }
    & .swiper-button-next {
      cursor: pointer;
      &::after {
        content: none;
      }
    }
    & .swiper-pagination {
      position: relative;
      margin-top: 40px;
      & .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        &.swiper-pagination-bullet-active {
          width: 20px;
          border-radius: 10px;
          background-color: black;
        }
      }
    }
  }
  & .button-container {
    display: flex;
    flex-direction: row;
    & .prev-button {
      margin-left: -60px;
      margin-top: -300px;
      background-color: #efebe5;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    & .next-button {
      margin-left: auto;
      margin-right: -60px;
      margin-top: -300px;
      background-color: #efebe5;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1400px) {
    width: 100%;
    min-width: 360px;
    max-width: 480px !important;
    margin: 0 auto;
    height: 464px;
    & .new-book-body {
      width: 100vw;
      min-width: 360px;
      max-width: 480px;
      margin: 20px auto 0;
      height: 464px;
      min-height: 464px;
      & .swiper-wrapper {
        width: 100vw;
        min-width: 360px;
        max-width: 480px;
        height: 430px !important;
        margin: auto;
        margin-bottom: 10px;
        & .swiper-slide {
          width: 100vw;
          min-width: 360px;
          max-width: 480px;
          height: 430px !important;
          & .book-image {
            margin: auto;
          }
          & .book-author-publisher {
            margin: auto;
          }
          & .price-container {
            margin-top: 15px !important;
          }
        }
      }
    }
    & .button-container {
      display: none;
    }
  }
}
