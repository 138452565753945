& .myPage-likeList-wrapper {
  padding-top: 56.8px;
  // height: 179px;
  & .myPage-likeList-container {
    max-width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .myPage-likeList-title-container {
      display: flex;
      & .title-text {
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
      }
      & .more-button {
        margin-left: auto;
        font-size: 14px;
        color: black;
      }
    }
    & .body-container {
      margin-top: 32px;
      // height: 144px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      & .mall-card:nth-child(3n + 1) {
        margin-left: 0px;
      }
      & .mall-card {
        // width: 372px;
        height: 144px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        border-radius: 8px;
        // margin-bottom: 16px;
        display: flex;
        padding: 16px;
        width: 31.9%;
        margin-left: 24px;
        & .mall-img img {
          height: 113.33px;
          width: 80px;
          box-shadow: 0px 5px 10px #00000029;
          border-radius: 8px;
          cursor: pointer;
        }
        & .mall-description-container {
          margin-left: 24px;
          width: 292px;
          & .mall-name-container {
            display: flex;
            cursor: pointer;
            & .mall-name {
              color: var(---000000-text-);
              text-align: left;
              font: normal normal bold 16px/40px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            & .check-icon {
              margin-left: auto;
              cursor: pointer;
            }
          }
          & .book-info {
            & .mall-author {
              text-align: left;
              font: normal normal normal 14px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
          }
          & .book-price-container {
            & .book-price {
              margin-top: 12px;
              text-align: left;
              font: normal normal bold 16px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1400px) {
//   // min-width: 360px;
//   // width: auto;
//   // max-width: 430px;
//   & .myPage-likeList-wrapper {
//     margin-top: 50px;
//     padding-top: 0;
//     // height: 533px;
//     & .mobile-more-button {
//       width: 100%;
//       height: 48px;
//       background: #ffffff 0% 0% no-repeat padding-box;
//       border: 1px solid #bababa;
//       border-radius: 8px;
//     }
//     & .myPage-likeList-container {
//       // max-width: 1400px;
//       margin: auto;
//       display: flex;
//       flex-direction: column;
//       & .myPage-likeList-title-container {
//         display: flex;
//         & .title-text {
//           text-align: left;
//           font-size: 15px;
//           font-weight: bold;
//           letter-spacing: 0px;
//           color: #000000;
//         }
//         & .more-button {
//           margin-left: auto;
//           font-size: 14px;
//           color: black;
//         }
//       }
//       & .body-container {
//         margin-top: 0px;
//         // height: 1px;
//         display: flex;
//         flex-wrap: wrap;
//         & .mall-card:last-of-type {
//           border-bottom: none;
//         }
//         & .mall-card {
//           height: 153px;
//           background: #ffffff 0% 0% no-repeat padding-box;
//           // border-radius: 8px;
//           // margin-bottom: 16px;
//           display: flex;
//           padding: 20px 0 20px 0;
//           width: 100%;
//           margin-left: 0px;
//           border-bottom: 1px solid #f5f5f5;
//           border-radius: 0;
//           & .mall-img img {
//             height: 113px;
//             width: 80px;
//             box-shadow: 0px 5px 10px #00000029;
//             border-radius: 8px;
//             cursor: pointer;
//           }
//           & .mall-description-container {
//             margin-left: 24px;
//             width: 292px;
//             & .mall-name-container {
//               align-items: center;
//               display: flex;
//               cursor: pointer;
//               & .mall-name {
//                 text-align: left;
//                 font-size: 15px;
//                 font-weight: bold;
//                 letter-spacing: 0px;
//                 color: #000000;
//                 opacity: 1;
//               }
//               & .check-icon {
//                 margin-left: auto;
//                 cursor: pointer;
//               }
//               & .cart-icon {
//                 margin-left: 22px;
//                 margin-bottom: 3px;
//               }
//             }
//             & .book-info {
//               display: flex;
//               align-items: center;
//               justify-content: space-between;
//               margin-top: 15px;
//               & .mall-author {
//                 text-align: left;
//                 font-size: 12px;
//                 letter-spacing: 0px;
//                 color: #777777;
//                 opacity: 1;
//               }
//               & .mobile-mall-info {
//                 text-align: left;
//                 font-size: 12px;
//                 letter-spacing: 0px;
//                 color: #777777;
//               }
//               & .divider {
//                 height: 12px;
//                 border-left: 1px solid #c2c2c2;
//                 margin: auto 0;
//               }
//             }
//             & .book-price-container {
//               display: flex;
//               align-items: center;
//               margin-top: 13px;
//               & .mobile-discount {
//                 width: 31px;
//                 height: 20px;
//                 background: #1e76af 0% 0% no-repeat padding-box;
//                 border-radius: 4px;
//                 text-align: left;
//                 font-size: 11px;
//                 font-weight: bold;
//                 letter-spacing: 0px;
//                 color: #ffffff;
//                 display: flex;
//                 justify-content: space-around;
//                 align-items: center;
//               }
//               & .book-price {
//                 margin-left: 12px;
//                 margin-top: 0;
//                 text-align: left;
//                 font-size: 12px;
//                 letter-spacing: 0px;
//                 font-weight: bold;
//                 color: #000000;
//                 opacity: 1;
//               }
//               & .mobile-price {
//                 text-align: center;
//                 text-decoration: line-through;
//                 font-size: 12px;
//                 letter-spacing: 0px;
//                 color: #777777;
//                 margin-left: 10px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
