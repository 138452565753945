& .couInfo {
  & .couDc {
    width: 100%;
    @media screen and (max-width: 1400px) {
      & .MuiGrid-container {
        display: flex;
        flex-wrap: wrap;
        flex-direction: column;
        align-items: flex-start;
        & .MuiGrid-item {
          width: 100%;
          max-width: unset;
          flex-basis: unset;
        }
        & .MuiFormControl-root {
          width: 100%;
          margin: 0;
        }
      }
    }
    & .tt {
      font-size: 16px;
      color: #000;
      line-height: 1.2;
      font-weight: 600;
      @media screen and (max-width: 1400px) {
        margin-top: 24px;
        margin-bottom: 10px;
      }
    }
    & .tt2 {
      margin-top: 24px;
    }

    & .couponInquiry {
      display: inline-block;
      width: 140px;
      height: 32px;
      margin: 12px 0 0 0;
      text-align: left;
      border-radius: 4px;
      background: url(../../imgs/btn_coupon_inquiry_arrow.png) right 12px top
        50% #d0d0d0 no-repeat;
    }

    .baseBtn {
      display: inline-block;
      width: 155px;
      height: 56px;
      text-align: center;
      background: #fff;
      border: 1px #1e76af solid;
      border-radius: 8px;
      overflow: hidden;
      font-size: 17px;
      font-weight: bold;
      color: #1e76af;
    }
    & .lm {
      margin-top: 10px;
      width: 150px;
      @media screen and (max-width: 1400px) {
        width: 100%;
      }
    }

    .amount {
      & span {
        color: #1e76af;
      }
    }
  }
}
