#shipping-info-modal {
  display: flex;
  flex-direction: column;
  width: 480px;
  padding: 24px;
  border-radius: 16px;
  background-color: white;
  & .header-container {
    display: flex;
    font-size: 20px;
    font-weight: bold;
    & .close-button {
      margin-left: auto;
      cursor: pointer;
    }
  }
  & .sub-title {
    font-size: 14px;
    text-align: left;
    margin: 16px 0;
    padding-bottom: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  & .shipping-info {
    font-size: 14px;
    margin-top: 12px;
    text-align: left;
    line-height: 24px;
    & .blue {
      color: #1e76af;
      font-weight: bold;
    }
  }
  & table {
    width: 100%;
    table-layout: fixed;
    border-top: 1px solid #000;
    border-bottom: 1px solid #eaeaea;
    margin-top: 16px;
    padding-bottom: 12px;
    & colgroup {
      & col {
        width: 50%;
      }
    }
    & thead tr {
      color: #777777;
      font-size: 13px;
      height: 48px;
    }
    & tbody {
      & tr {
        height: 36px;
        font-size: 13px;
      }
    }
  }
  & .footer-text {
    color: #777777;
    text-align: left;
    font-size: 12px;
    margin: 12px 0;
  }
}
