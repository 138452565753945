& .book-info-presenter {
  padding: 65px 230px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  width: 1400px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  margin-bottom: 80px;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    max-width: 480px;
    width: 100%;
    padding: 0 20px;
    height: fit-content;
    background-color: white;
  }
  & .title-blue {
    color: #1e76af;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-top: 56px;
    border-top: 1px solid #777777;
    padding-top: 37px;
    &.border-none {
      border-top: none;
    }
    @media screen and (max-width: 1400px) {
      border-top: none;
      margin-top: 30px;
      padding-top: 0;
      font-size: 17.5px;
    }
  }
  & .info-container {
    // display: flex;
    text-align: left;
    flex-wrap: wrap;
    margin-top: 24px;
    & .info-row {
      display: flex;
      width: 528px;
      @media screen and (max-width: 1400px) {
        width: 372px;
        font-size: 13px;
      }
      margin-bottom: 12px;
      & .column {
        width: 100px;
        border-right: 1px solid #e8e8e8;
        margin-right: 16px;
      }
    }
  }
  & .classification-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 24px;
    padding: 0;
    @media screen and (max-width: 1400px) {
      padding: 0;
      border-top: none;
      font-size: 13px;
      margin-top: 0;
    }
    & .class-container {
      font-size: 14px;
      margin-top: 12px;
      display: flex;
      @media screen and (max-width: 1400px) {
        font-size: 13px;
        flex-wrap: wrap;
      }
      & .class-detail {
        display: flex;
        @media screen and (max-width: 1400px) {
          &:first-of-type {
            display: none;
          }
          &:nth-of-type(2) .right-arrow {
            display: none;
          }
        }
        cursor: pointer;
        & .right-arrow {
          margin: 0 12px;
          @media screen and (max-width: 1400px) {
            opacity: 0;
            margin: 0 8px;
          }
        }
      }
    }
  }
  & .card-news-container {
    height: 300px;
    margin-top: 20px;
    background-color: white;
    border-radius: 8px;
  }
  & .book-comment-container {
    text-align: left;
    margin-top: 24px;
    font-size: 15px;
    line-height: 30px;
    word-break: break-all;
    @media screen and (max-width: 1400px) {
      font-size: 13px;
      line-height: 21px;
    }
  }
  & .book-info-img-container {
    margin-top: 56px;
    width: 940px;
    @media screen and (max-width: 1400px) {
      min-width: 320px;
      max-width: 440px;
      width: calc(100vw - 40px);
      & .book-info-img {
        min-width: 320px;
        max-width: 440px;
        width: calc(100vw - 40px);
        & img {
          min-width: 320px;
          max-width: 440px;
          width: calc(100vw - 40px);
        }
      }
    }
    & .book-info-img {
      min-height: 300px;
      width: 940px;
      background-color: white;
      & img {
        width: 940px;
      }
    }
  }
  & .author-info-container {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 36px;
    @media screen and (max-width: 1400px) {
      margin-top: 20px;
    }
    & .author-name {
      & span {
        font-size: 24px;
        line-height: 35px;
        font-weight: bold;
        color: black;
        margin-right: 12px;
        @media screen and (max-width: 1400px) {
          font-size: 17.5px;
        }
      }
      color: #999999;
      &.second {
        margin-top: 48px;
      }
    }
    & .description {
      margin-top: 24px;
      font-size: 15px;
      line-height: 30px;
      text-align: left;
      @media screen and (max-width: 1400px) {
        margin-top: 12px;
        font-size: 13px;
        line-height: 21px;
      }
    }
  }
  & .foreword-container {
    margin-top: 24px;
    text-align: left;
    @media screen and (max-width: 1400px) {
      margin-top: 12px;
      font-size: 13px;
      line-height: 21px;
    }
    & .foreword-title {
      font-weight: bold;
    }
    & .foreword-comment {
      margin-top: 12px;
      margin-bottom: 36px;
    }
  }
  & .publisher-comment {
    margin-top: 24px;
    text-align: left;
    @media screen and (max-width: 1400px) {
      margin-top: 12px;
      font-size: 13px;
    }
  }
  & .index {
    margin-top: 24px;
    text-align: left;
    @media screen and (max-width: 1400px) {
      margin-top: 12px;
      font-size: 13px;
    }
  }
}
