& .address-modal {
  width: 768px;
  height: 740px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 46px 40px 34px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  @media screen and (max-width: 1400px) {
    height: 80vh;
    overflow: scroll;
  }

  // @media screen and (max-width: 1400px) {
  //   min-width: 360px;
  //   width: 100vw;
  //   height: 100vh;
  //   max-width: 430px;
  //   padding: 50px 20px;
  //   background-color: white;
  //   border-radius: 0px;
  //   display: flex;
  //   flex-direction: column;
  //   text-align: left;
  // }
  & .modal-text {
    display: flex;
    justify-content: space-between;
    text-align: left;
    font-weight: bold;
    font-size: 24px;
    // font: normal normal normal 16px/32px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    // margin: 0 19px;
    & .close-icon {
      align-self: end;
      color: #bababa;
      margin-bottom: 32px;
      cursor: pointer;
    }
  }
  & .form-container {
    display: flex;
    flex-direction: column;
    & .element-title {
      text-align: left;
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
    }
    & .name {
      width: 336px;
      @media screen and (max-width: 1400px) {
        width: 100%;
      }
      height: 56px;
      background: #ffffff 0% 0% no-repeat padding-box;
      // border: 1px solid #BABABA;
      border-radius: 8px;
    }
    & .name-container {
      display: flex;
      justify-content: space-between;
      @media screen and (max-width: 1400px) {
        flex-direction: column;
      }
    }
    & .contact-title {
      text-align: left;
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
    }
    & .adress-title {
      text-align: left;
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      margin-top: 24px;
    }
    & .adress-container {
      display: flex;
      // justify-content: space-between;
      & .search-btn {
        width: 160px;
        height: 56px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 8px;
        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
        margin-left: 16px;
      }
    }
    & .modify-address {
      width: 512px;
      height: 56px;
      background: #ffffff 0% 0% no-repeat padding-box;
      // border: 1px solid #bababa;
      border-radius: 8px;
    }
    & .more-adress {
      width: 688px;
      @media screen and (max-width: 1400px) {
        width: 100%;
      }
      height: 56px;
      background: #ffffff 0% 0% no-repeat padding-box;
      // border: 1px solid #bababa;
      border-radius: 8px;
      margin-top: 16px;
    }
  }
  & .btn-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    & .cancel-button {
      width: 155px;
      height: 56px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #1e76af;
      border-radius: 8px;
      margin-right: 12px;
    }
    & .button {
      width: 155px;
      height: 56px;
      background: #1e76af 0% 0% no-repeat padding-box;
      border-radius: 8px;
      text-align: center;
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}

@media screen and (max-width: 1400px) {
  & .address-modal {
    width: 100vw;
    min-width: 360px;
    max-width: 480px;
    height: 80vh !important;
    padding: 20px;
    background-color: white;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
    text-align: left;

    & .header {
      width: 100%;
      height: 70px !important;
      display: flex;
      justify-content: flex-end;
      // padding-top: 40px;
      margin-bottom: 10px;
      & .exit-button {
        width: 40px;
        height: 70px;
        padding: 40px 0 10px 16px;
      }
    }

    & .mobile-body {
      overflow: scroll;
      height: 100%;
      &::-webkit-scrollbar {
        display: none;
      }
      & .modal-text {
        display: flex;
        justify-content: space-between;
        text-align: left;
        font-weight: bold;
        font-size: 24px;
        letter-spacing: 0px;
        color: #000000;
        margin-bottom: 10px;
      }
      & .form-container {
        display: flex;
        flex-direction: column;
        & .element-title {
          text-align: left;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #000000;
        }
        & .name {
          width: 100%;
          height: 48px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 8px;
        }
        & .name-container {
          display: flex;
          flex-direction: column;
          & .errorText {
            margin: 3px 0 0 0;
          }
        }
        & .contact-title {
          text-align: left;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
        }
        & .adress-title {
          text-align: left;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #000000;
          margin-top: 24px;
        }
        & .adress-container {
          display: flex;
          // justify-content: space-between;
        }
        & .search-btn {
          width: 100%;
          height: 48px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #1e76af;
          border-radius: 8px;
          text-align: center;
          font-size: 14px;
          font-weight: normal;
          letter-spacing: 0px;
          color: #1e76af;
          margin: 12px auto auto 0;
        }
        & .modify-address {
          width: 100%;
          height: 48px;
          background: #ffffff 0% 0% no-repeat padding-box;
          // border: 1px solid #bababa;
          border-radius: 8px;
        }
        & .more-adress {
          width: 100%;
          height: 48px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 8px;
          margin-top: 16px;
        }
      }
      & .btn-container {
        display: flex;
        justify-content: space-between;
        margin-top: 40px;
        & .cancel-button {
          width: 80%;
          min-width: 155px;
          max-width: 215px;
          height: 48px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #1e76af;
          border-radius: 8px;
          margin-right: 0;
        }
        & .btn-sapce {
          width: 5%;
        }
        & .button {
          width: 80%;
          min-width: 155px;
          max-width: 215px;
          height: 48px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 8px;
          text-align: center;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
    }
  }
}
