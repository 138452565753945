#Widthdraw {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .myPage-wrapper {
    display: flex;
    padding-top: 80px;
    // flex-direction: column;
    @import "./SideMenu.scss";
  }
  & .mypage-container {
    // margin: 48px 260px 120px 370px;
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;
    // & .title-container {
    //   display: flex;
    //   margin-bottom: 24px;
    //   // align-items: flex-end;
    //   & .title-text {
    //     text-align: left;
    //     font: normal normal bold 24px/26px Noto Sans KR;
    //     letter-spacing: 0px;
    //     color: #000000;
    //     opacity: 1;
    //   }
    // }
    @import "../ConfirmPassword.scss";
  }

  & .withdraw-wrapper {
    display: flex;
    padding-top: 80px;
    flex-direction: column;
    min-height: 615px;
    justify-content: center;
    & .WithdrawSuccess-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      & .main-title {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
      }
      & .btn {
        margin-top: 120px;
        width: 160px;
        height: 56px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    height: auto;
    & .myPage-wrapper {
      margin: 0 auto 25px;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      padding-top: 20px;
      & .mypage-container {
        margin: 0 auto;
        & .mobile-menu-wrapper {
          margin-bottom: 25px;
        }
        & .ConfirmPassword {
          min-width: 320px;
          width: 100%;
          max-width: 440px;
          & .body {
            width: 100%;
            & .password-container {
              margin: 0;
              margin: 25px 0 35px;
              & .title {
                font: normal normal normal 14px/20px Noto Sans KR;
              }
              & .MuiFormLabel-root {
                height: 20px;
              }
              & .MuiFormControl-root {
                min-width: 320px;
                width: 100%;
                max-width: 440px;
              }
            }
            & .req-text {
              margin: 35px 0 0;
              font: normal normal normal 14px/25px Noto Sans KR;
              & .MuiButtonBase-root {
                margin: 0;
                padding: 0 12px 0 0;
              }
            }
            & .bottom-info {
              padding: 12px 0 20px 36px;
              // padding-left: 36px;
            }
          }
          & .button-container {
            margin: 0;
            margin-top: 30px;
            width: 100%;
            & .btn-cancel {
              width: 100%;
              margin-right: 12px;
              & .MuiButtonBase-root {
                margin: 0;
                padding: 0;
                width: 100%;
              }
            }
            & .btn-submit {
              width: 100%;
              & .MuiButtonBase-root {
                margin: 0;
                padding: 0;
                width: 100%;
              }
            }
          }
        }
      }
    }
  }
}
