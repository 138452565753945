& .modoo-mall-wrapper {
  & .modoo-mall-container {
    padding-top: 48px;
    // max-width: 1400px;
    width: 100%;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .title-container {
      display: flex;
      // align-items: flex-end;
      & .title-text {
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .more-button {
        margin-left: auto;
        font-size: 14px;
        color: black;
      }
    }
    & .body-container {
      margin-top: 32px;
      // height: 431px;
      // height: 172px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      & .mall-card:nth-child(2n + 1) {
        margin-left: 0px;
      }
      & .mall-card {
        // width: 572px;
        height: 172px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        border-radius: 8px;
        margin-bottom: 20px;
        display: flex;
        padding: 16px;
        width: 48.9%;
        margin-left: 24px;
        & .mall-img img {
          height: 140px;
          width: 140px;
          box-shadow: 0px 5px 10px #00000029;
          border-radius: 8px;
          opacity: 1;
          cursor: pointer;
        }
        & .new-badge {
          position: absolute;
          margin-left: 0px;
          margin-top: 112px;
          color: white;
          background-color: black;
          border-radius: 0px 8px;
          width: 47px;
          height: 28px;
          font-size: 14px;
          font-weight: normal;
          line-height: 28px;
        }
        & .mall-description-container {
          margin-left: 24px;
          width: 376px;
          & .mall-name-container {
            display: flex;
            cursor: pointer;
            & .mall-name {
              text-align: left;
              font: normal normal bold 16px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            & .check-icon {
              margin-left: auto;
              cursor: pointer;
            }
          }
          & .mall-visit-container {
            cursor: auto;
            display: flex;
            text-align: left;
            font: normal normal normal 12px/22px Noto Sans KR;
            letter-spacing: 0px;
            color: #999999;
            & .vl {
              border-left: 1px solid#BABABA;
              width: 0;
              height: 12px;
              margin: auto 8.5px;
            }
          }
          & .mall-author {
            color: var(---000000-text-);
            text-align: left;
            font: normal normal normal 14px/24px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .mall-price {
            margin-top: 12px;
            color: var(---000000-text-);
            text-align: left;
            font: normal normal bold 16px/24px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .mall-description {
            color: var(---000000-text-);
            text-align: left;
            font: normal normal normal 14px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            // margin-top: 16px;
          }
          & .location-container {
            display: flex;
            color: var(---000000-text-);
            text-align: left;
            font: normal normal normal 14px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            & .location-icon {
              margin-right: 8.81px;
              background: var(---bababa-line_darker-) 0% 0% no-repeat
                padding-box;
            }
          }
          & .hashtag-container {
            display: flex;
            margin-top: 10px;
            & .hashtag {
              height: 23px;
              line-height: 17px;
              margin-right: 8px;
              border-radius: 4px;
              padding: 2px 8px;
              background-color: white;
              font-size: 11px;
              cursor: pointer;
            }
          }
        }
      }
    }
  }
}
// @media screen and (max-width: 1400px) {
//   // min-width: 360px;
//   // width: auto;
//   // max-width: 430px;
//   & .modoo-mall-wrapper {
//     & .modoo-mall-container {
//       padding-top: 48px;
//       // max-width: 1400px;
//       width: 100%;
//       margin: auto;
//       display: flex;
//       flex-direction: column;
//       & .title-container {
//         display: flex;
//         & .title-text {
//           text-align: left;
//           font-size: 15px;
//           font-weight: bold;
//           letter-spacing: 0px;
//           color: #000000;
//           opacity: 1;
//         }
//       }
//       & .body-container {
//         margin-top: 0px;
//         display: flex;
//         flex-wrap: wrap;
//         // justify-content: space-between;
//         & .mobile-more-button {
//           width: 100%;
//           height: 48px;
//           background: #ffffff 0% 0% no-repeat padding-box;
//           border: 1px solid #bababa;
//           border-radius: 8px;
//         }
//         & .mall-card:last-of-type {
//           border-bottom: none;
//         }
//         & .mall-card {
//           height: 172px;
//           background: #ffffff 0% 0% no-repeat padding-box;
//           border-radius: 0px;
//           padding: 20px 0 20px 0;
//           display: flex;
//           width: 100%;
//           margin: 0px;
//           border-bottom: 1px solid #f5f5f5;
//           & .mall-img img {
//             height: 100px;
//             width: 100px;
//             box-shadow: 0px 5px 10px #00000029;
//             border-radius: 8px;
//             opacity: 1;
//             cursor: pointer;
//           }
//           & .new-badge {
//             position: absolute;
//             margin-left: 0px;
//             margin-top: 112px;
//             color: white;
//             background-color: black;
//             border-radius: 0px 8px;
//             width: 47px;
//             height: 28px;
//             font-size: 14px;
//             font-weight: normal;
//             line-height: 28px;
//           }
//           & .mall-description-container {
//             margin-left: 24px;
//             width: 376px;
//             & .mall-name-container {
//               display: flex;
//               cursor: pointer;
//               & .mall-name {
//                 text-align: left;
//                 font-size: 15px;
//                 font-weight: bold;
//                 letter-spacing: 0px;
//                 color: #000000;
//                 opacity: 1;
//               }
//               & .check-icon {
//                 margin-left: auto;
//                 cursor: pointer;
//               }
//             }
//             & .mall-visit-container {
//               cursor: auto;
//               display: flex;
//               text-align: left;
//               font: normal normal normal 12px/22px Noto Sans KR;
//               letter-spacing: 0px;
//               color: #999999;
//               & .vl {
//                 border-left: 1px solid#BABABA;
//                 width: 0;
//                 height: 12px;
//                 margin: auto 8.5px;
//               }
//             }
//             & .mall-author {
//               text-align: left;
//               font: normal normal normal 14px/24px Noto Sans KR;
//               letter-spacing: 0px;
//               color: #000000;
//               opacity: 1;
//             }
//             & .mall-price {
//               margin-top: 12px;
//               text-align: left;
//               font: normal normal bold 16px/24px Noto Sans KR;
//               letter-spacing: 0px;
//               color: #000000;
//               opacity: 1;
//             }
//             & .mall-description {
//               text-align: left;
//               font-size: 13px;
//               letter-spacing: 0px;
//               color: #000000;
//               opacity: 1;
//               line-height: 1.6;
//               margin-top: 8px;
//             }
//             & .location-container {
//               display: flex;
//               text-align: left;
//               font: normal normal normal 14px/40px Noto Sans KR;
//               letter-spacing: 0px;
//               color: #000000;
//               & .location-icon {
//                 margin-right: 8.81px;
//                 background: var(---bababa-line_darker-) 0% 0% no-repeat
//                   padding-box;
//               }
//             }
//             & .hashtag-container {
//               display: flex;
//               margin-top: 10px;
//               & .hashtag {
//                 height: 23px;
//                 line-height: 17px;
//                 margin-right: 8px;
//                 border-radius: 4px;
//                 padding: 2px 8px;
//                 background-color: white;
//                 font-size: 11px;
//                 cursor: pointer;
//               }
//               & .mobile-hashtag {
//                 height: 18px;
//                 background: #ffffff 0% 0% no-repeat padding-box;
//                 border: 1px solid #cccccc;
//                 border-radius: 2px;
//                 text-align: left;
//                 font-size: 11px;
//                 letter-spacing: 0px;
//                 color: #000000;
//                 padding: 1px 4px;
//                 margin-right: 6px;
//               }
//             }
//           }
//         }
//       }
//     }
//   }
// }
