#EditProfile {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .myPage-wrapper {
    display: flex;
    padding-top: 80px;
    @import "./SideMenu.scss";
  }
  & .mypage-container {
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;
    & .element-title {
      text-align: left;
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      display: flex;
      align-items: center;
    }

    & .title-container {
      display: flex;
      margin-bottom: 24px;
      & .title-text {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }

    & .form-container {
      margin-top: 20px;
      display: flex;
      text-align: left;
      flex-direction: column;
      & .basicInfo {
        display: flex;
        text-align: left;
        font: normal normal bold 16px/20px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        flex-direction: column;

        & .button {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #1e76af;
          border-radius: 8px;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
          width: 160px;
          height: 56px;
          margin: 0 0 24px 0;
        }
        & .title {
          margin-bottom: 24px;
        }
        & .id-field {
          width: 416px;
          height: 56px;
          background: #f5f5f5 0% 0% no-repeat padding-box;
          margin: 16px 0 16px 0;
          border-radius: 8px;
        }
        & .password {
          display: flex;
          text-align: left;
          margin: 16px 0;
          & .password-input {
            width: 416px;
            margin: 16px 16px 0px auto;
            border-radius: 8px;
            &.Mui-disabled {
              background: #f5f5f5 0% 0% no-repeat padding-box;
            }
          }
          & .helpText {
            // margin: 12px 0;
            text-align: left;
            font: normal normal normal 14px/28px Noto Sans KR;
            letter-spacing: 0px;
            color: #777777;
          }
          & .errorText {
            text-align: left;
            font: normal normal normal 14px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #ec1f2d;
          }
          .css-sp27ke-MuiFormHelperText-root {
            margin-left: 0;
          }
        }
      }
      & .moreInfo {
        display: flex;
        flex-direction: column;
        margin-bottom: 24px;
        & .title {
          // margin-bottom: 24px;
          text-align: left;
          font: normal normal bold 16px/20px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
        }
        margin: 20px 0 24px 0;
        & .element-title {
          text-align: left;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
        }
        & .name-field {
          width: 416px;
          height: 56px;
          background: #f5f5f5 0% 0% no-repeat padding-box;
          margin: 16px 0 16px 0;
          border-radius: 8px;
        }
        & .team-name {
          width: 416px;
          background: #ffffff 0% 0% no-repeat padding-box;
          // border: 1px solid #bababa;
          border-radius: 8px;
          margin: 16px 0 16px 0;
        }
        & .radio-group {
          margin: 16px 0 16px 0;
          & .radio {
            display: flex;
            align-items: center;
            margin: 0 0 16px 0;
            & .corp-num {
              width: 200px;
              height: 56px;
              background: #ffffff 0% 0% no-repeat padding-box;
              // border: 1px solid #bababa;
              border-radius: 8px;
            }
          }
        }
        & .email-container {
          display: flex;
          align-items: center;
          margin: 16px 0;
          & .at {
            margin: 0 10px;
          }
          & .email-field {
            width: 240px;
            // margin: 16px 8px 16px 0;
            margin-right: 8px;
            border-radius: 8px;
            & .MuiInputBase-root {
              border-radius: 8px;
            }
            &.disabled {
              background: #f5f5f5 0% 0% no-repeat padding-box;
            }
          }
          & .email-field:not(:first-of-type) {
            margin: 0 8px;
          }
          & .button {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #1e76af;
            border-radius: 8px;
            font: normal normal normal 16px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #1e76af;
            width: 160px;
            margin-left: 8px;
          }
        }
        & .errorText {
          text-align: left;
          font: normal normal normal 14px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #ec1f2d;
          margin-bottom: 15px;
        }
        & .availableText {
          text-align: left;
          font: normal normal normal 14px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
          margin-bottom: 15px;
        }
        & .css-sp27ke-MuiFormHelperText-root {
          margin-left: 0;
        }
        // & .contact-container {
        //   display: flex;
        //   align-items: center;
        & .contact-field {
          margin: 16px 8px 0 0;
          // margin: 16px 8px 16px 0;
          width: 240px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 8px;
        }
        & .contact-field-disabled {
          margin: 16px 8px 16px 0;
          width: 240px;
          background: #f5f5f5 0% 0% no-repeat padding-box;
          border-radius: 8px;
        }
        & .button {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #1e76af;
          border-radius: 8px;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
          width: 160px;
          height: 56px;
          margin-left: 8px;
        }
        // }
        & .address-field {
          width: 416px;
          height: 56px;
          background: #ffffff 0% 0% no-repeat padding-box;
          margin: 16px 8px 0 0;
          // margin: 16px 8px 16px 0;
          border-radius: 8px;
        }
        & .address-more {
          width: 848px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 8px;
          // margin: 16px 8px 0 0;
          margin: 16px 8px 0 0;
        }

        & .address-more:last-of-type {
          margin: 16px 8px 16px 0;
        }

        & .css-79ws1d-MuiModal-root {
          top: 300px;
        }
        // 추가 인증
        & .additional-certification-wrap {
          margin: 16px 8px 0px 0;
          display: flex;
          & .additional-certification-select {
            width: 260px;
            margin: 0 16px 0 0 !important;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            &::after {
              content: unset;
            }
            &::before {
              content: unset;
            }
            & fieldset {
              border-radius: 8px;
            }
          }
          & .additional-certification {
            width: 260px;
            height: 56px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border-radius: 8px;
            margin-right: 8px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          // 소속기관·단체
          & .aprv-info-wrap {
            border-radius: 8px;
            > .aprv-info {
              display: flex;
              > .aprv-info-title,
              .aprv-info-content {
                height: 100%;
                display: flex;
                align-items: center;
                padding: 8px 10px;
              }
              > .aprv-info-title {
                width: 180px;
                display: flex;
                align-items: center;
              }
              > .aprv-info-content {
                width: 350px;
                font-weight: 300;
              }
            }
          }
        }
        & .certification-help {
          margin: 8px 0 20px 0;
          span {
            text-align: left;
            font: normal normal normal 14px / 28px Noto Sans KR;
            letter-spacing: 0px;
            color: #777777;
          }
        }
        & .adult-certification-wrap {
          & .adult-certification {
            width: 416px;
            height: 56px;
            background: #ffffff 0% 0% no-repeat padding-box;
            margin: 16px 8px 16px 0;
            border-radius: 8px;
          }
        }

        & .bottom-info {
          margin-top: 24px;
          text-align: left;
          font: normal normal normal 14px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          & .info-btn {
            border: 1px solid var(---bababa-line_darker-);
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #bababa;
            border-radius: 4px;
            width: 101px;
            height: 32px;
            margin-left: 8px;
          }
        }
        & .submitBtn-container {
          display: flex;
          justify-content: center;
          margin-top: 48px;
          & .cancel-btn {
            width: 160px;
            height: 56px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #1e76af;
            border-radius: 8px;
          }
          & .submibtn {
            width: 160px;
            height: 56px;
            margin-left: 16px;
            background: #1e76af 0% 0% no-repeat padding-box;
            border-radius: 8px;
            text-align: center;
            font: normal normal normal 16px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }
      }
    }
    @import "../ConfirmPassword.scss";
  }
  @import "./SearchAddressModal.scss";
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    height: auto;
    & .myPage-wrapper {
      padding-top: 20px;
      margin: 0 auto 50px;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      & .mypage-container {
        width: 100%;
        margin: 0 auto;
        max-width: 440px;
        & .mobile-menu-wrapper {
          margin-bottom: 20px;
        }
        & .ConfirmPassword {
          min-width: 320px;
          width: 100%;
          max-width: 440px;
          margin: 0 auto;
          & .body {
            margin: 0 auto;
            width: 100%;
            & .password-container {
              margin: 30px 0 20px;
              width: 100%;
              & .title {
                font: normal normal normal 14px/20px Noto Sans KR;
              }
              & .MuiFormLabel-root {
                height: 20px;
                padding: 0;
                width: 100%;
                margin: 0;
                margin-bottom: 12px;
                & .MuiFormLabel-asterisk {
                  padding: 0;
                }
              }
              & .MuiFormControl-root {
                min-width: 320px;
                width: 100%;
                max-width: 440px;
                margin: 0;
                & .password-input {
                  min-width: 320px;
                  width: 100%;
                  max-width: 440px;
                  margin: 0;
                }
              }
            }
            & .isEdit-button-container {
              & .MuiButtonBase-root {
                margin: 0;
                width: 100%;
                height: 48px;
              }
            }
          }
        }
        & .form-container {
          width: 100%;
          margin: 0 auto;
          min-width: 320px;
          max-width: 440px;
          & .basicInfo {
            width: 100%;
            min-width: 320px;
            max-width: 440px;
            & .title {
              width: 100%;
              height: 22px;
              margin: 20px auto 10px;
              font-size: 15px;
            }
            & .MuiFormControl-root {
              width: 100%;
              min-width: 320px;
              max-width: 440px;
              & .MuiFormLabel-root {
                display: flex;
                align-items: center;
                font-size: 14px;
                margin: 10px auto 6px;
                width: 100%;
                height: 20px;
                min-width: 320px;
                max-width: 440px;
              }
              & .MuiInputBase-root {
                margin: 6px auto 10px;
                width: 100%;
                height: 48px;
                min-width: 320px;
                max-width: 440px;
              }
              & .MuiFormHelperText-root {
                margin: 0;
                height: 48px;
                margin-bottom: 10px;
                text-align: left;
                font-size: 13px;
              }
            }
            & .password {
              margin: 0;
              width: 100%;
              min-width: 320px;
              max-width: 440px;
              display: flex;
              flex-direction: column;
            }
            & .MuiButtonBase-root {
              width: 100%;
              margin-bottom: 15px;
              height: 48px;
              font-size: 14px;
            }
            & .MuiFormControl-root {
              & .MuiInputBase-root {
                & .MuiInputAdornment-root {
                  & .MuiButtonBase-root {
                    margin: 0;
                  }
                }
              }
            }
          }
          & .moreInfo {
            width: 100%;
            & .title {
              height: 22px;
              font-size: 15px;
              margin: 15px 0 10px;
            }
            & .MuiFormControl-root {
              & .MuiFormLabel-root {
                height: 20px;
                font-size: 14px;
                display: flex;
                align-items: center;
              }
              & .MuiInputBase-root {
                margin: 12px 0 20px;
                width: 100%;
                height: 48px;
                & .MuiInputBase-input {
                }
              }
              & .email-container {
                margin: 0;
                min-width: 320px;
                max-width: 440px;
                flex-direction: column;
                & span {
                  width: 18px;
                  text-align: center;
                }
                & .email-box {
                  display: flex;
                  width: 100%;
                  height: 48px;
                  margin: 12px auto;
                  align-items: center;
                  & .MuiInputBase-root {
                    min-width: 142px;
                    max-width: 200px;
                    margin: 0;
                    & .MuiInputBase-input {
                      width: 100%;
                      height: 48px;
                      padding: 0;
                      padding-left: 10px;
                    }
                  }
                  & .MuiAutocomplete-root {
                    margin: 0;
                    min-width: 142px;
                    max-width: 200px;
                    height: 48px;
                    & .MuiFormControl-root {
                      min-width: 142px;
                      max-width: 200px;
                      height: 48px;
                      & .MuiInputBase-root {
                        min-width: 142px;
                        max-width: 200px;
                        height: 48px;
                        padding: 0;
                        & .MuiInputBase-input {
                          min-width: 142px;
                          max-width: 200px;
                          height: 48px;
                        }
                      }
                    }
                  }
                }
                & .email-button {
                  width: 100%;
                  & .MuiButtonBase-root {
                    height: 48px;
                    margin-bottom: 20px;
                  }
                }
              }
              & .contact-container {
                display: flex;
                min-width: 320px;
                max-width: 440px;
                margin: 12px 0 20px;

                & .MuiInputBase-root {
                  max-width: 337px;
                  min-width: 217px;
                  height: 48px;
                  margin: 0;
                  margin-right: 12px;
                }
                & .MuiButtonBase-root {
                  max-width: 91px;
                  min-width: 64px;
                  height: 48px;
                  font-size: 14px;
                  margin: 0;
                }
              }
              & .MuiButtonBase-root {
                width: 100%;
                margin: 0 auto;
                height: 48px;
              }
              & .adult-certification-wrap {
                > button {
                  margin-bottom: 20px;
                }
              }
              & .additional-certification-wrap {
                flex-direction: column;
                > .additional-certification-select {
                  width: 100%;
                  > div {
                    > div {
                      margin: 0 !important;
                    }
                  }
                }
              }
            }
            & .bottom-info {
              & span {
                font-size: 12px;
              }
              & .mobile-onetoone {
                margin-bottom: 8px;
                & .MuiButtonBase-root {
                  width: 100px;
                  height: 24px;
                  display: flex;
                  margin: 0;
                }
              }
              & .mobile-withdraw {
                margin-top: 8px;
                & .MuiButtonBase-root {
                  width: 82.5px;
                  height: 24px;
                  display: flex;
                  margin: 0;
                }
              }
            }
            & .submitBtn-container {
              margin-top: 30px auto 50px;
              & .MuiButtonBase-root {
                width: 100%;
              }
            }
          }
        }
      }
    }
    & .footer-wrapper {
      height: auto;
    }
  }

  //   & .myPage-wrapper {
  //     display: flex;
  //     padding: 0;
  //     min-width: 360px;
  //     width: 100%;
  //     margin: 0 auto;
  //     max-width: 480px !important;
  //   }
  //   & .mypage-container {
  //     margin: 50px 20px;
  //     min-width: 360px;
  //     display: flex;
  //     flex-direction: column;

  //     & .mobile-header {
  //       margin: 0 0 20px 0;
  //       & .header {
  //         display: flex;
  //         justify-content: space-between;
  //         .home-ico {
  //           width: 24px;
  //           height: 24px;
  //           color: #000000;
  //         }
  //       }
  //       & .mobile-title {
  //         text-align: left;
  //         font-size: 17px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         margin-top: 20px;
  //       }
  //     }

  //     & .form-container {
  //       margin-top: 20px;
  //       display: flex;
  //       text-align: left;
  //       flex-direction: column;
  //       & .basicInfo {
  //         & .element-title {
  //           text-align: left;
  //           font-size: 14px;
  //           font-weight: normal;
  //           letter-spacing: 0px;
  //           color: #000000;
  //         }
  //         display: flex;
  //         flex-direction: column;
  //         & .title {
  //           font-size: 15px;
  //           font-weight: bold;
  //           margin-bottom: 24px;
  //         }
  //         & .id-field {
  //           width: 100%;
  //           height: 48px;
  //           background: #f5f5f5 0% 0% no-repeat padding-box;
  //           border-radius: 8px;
  //           margin: 0;
  //         }
  //         & .password {
  //           display: flex;
  //           flex-direction: column;
  //           & .password-input {
  //             width: 100%;
  //             height: 48px;
  //             border-radius: 8px;
  //             margin: 0;
  //           }
  //           & .helpText {
  //             text-align: left;
  //             font-size: 13px;
  //             font-weight: normal;
  //             letter-spacing: 0px;
  //             color: #777777;
  //           }
  //           & .errorText {
  //             text-align: left;
  //             font-size: 13px;
  //             font-weight: normal;
  //             letter-spacing: 0px;
  //             color: #ec1f2d;
  //           }
  //           // .css-sp27ke-MuiFormHelperText-root {
  //           //   margin-left: 0;
  //           // }
  //         }
  //       }
  //       & .moreInfo {
  //         display: flex;
  //         flex-direction: column;
  //         margin-bottom: 24px;
  //         & .title {
  //           font-size: 15px;
  //           font-weight: bold;
  //           margin-bottom: 24px;
  //         }
  //         & .element-title {
  //           text-align: left;
  //           font-size: 14px;
  //           font-weight: normal;
  //           letter-spacing: 0px;
  //           color: #000000;
  //         }
  //         & .name-field {
  //           width: 100%;
  //           height: 48px;
  //           background: #f5f5f5 0% 0% no-repeat padding-box;
  //           border-radius: 8px;
  //           margin: 0 0 20px 0;
  //         }

  //         & .email-container {
  //           display: flex;
  //           //flex-direction: column;
  //           & .mobile-email {
  //             width: 100%;
  //             display: flex;
  //             justify-content: space-between;
  //             align-items: center;
  //             & .at {
  //               margin: 0 10px;
  //             }
  //             & .email-field {
  //               width: 45%;
  //               height: 48px;
  //               border-radius: 8px;
  //             }
  //             & .email-field-disabled {
  //               width: 45%;
  //               height: 48px;
  //               background: #f5f5f5 0% 0% no-repeat padding-box;
  //               border-radius: 8px;
  //             }
  //           }
  //           & .button {
  //             display: block;
  //             //flex-direction: column;
  //             background: #ffffff 0% 0% no-repeat padding-box;
  //             border: 1px solid #1e76af;
  //             border-radius: 8px;
  //             font-size: 14px;
  //             font-weight: normal;
  //             letter-spacing: 0px;
  //             color: #1e76af;
  //             width: 100%;
  //             height: 48px;
  //             margin-top: 12px;
  //           }
  //         }
  //         & .errorText {
  //           text-align: left;
  //           font-size: 13px;
  //           font-weight: normal;
  //           letter-spacing: 0px;
  //           color: #ec1f2d;
  //           margin-bottom: 15px;
  //         }
  //         // & .css-sp27ke-MuiFormHelperText-root {
  //         //   margin-left: 0;
  //         // }
  //         & .contact-container {
  //           display: flex;
  //           align-items: center;
  //           justify-content: space-between;
  //           & .contact-field {
  //             width: 65%;
  //             height: 48px;
  //             background: #ffffff 0% 0% no-repeat padding-box;
  //             border-radius: 8px;
  //             margin: 0;
  //           }
  //           & .contact-field-disabled {
  //             width: 65%;
  //             height: 48px;
  //             background: #f5f5f5 0% 0% no-repeat padding-box;
  //             border-radius: 8px;
  //             margin: 0 0 20px 0;
  //           }
  //           & .button {
  //             background: #ffffff 0% 0% no-repeat padding-box;
  //             border: 1px solid #1e76af;
  //             border-radius: 8px;
  //             font-size: 14px;
  //             font-weight: normal;
  //             letter-spacing: 0px;
  //             color: #1e76af;
  //             width: 30%;
  //             height: 48px;
  //             margin: 0 0 20px 0;
  //           }
  //         }

  //         & .adult-certification {
  //           width: 100%;
  //           height: 48px;
  //           background: #ffffff 0% 0% no-repeat padding-box;
  //           border-radius: 8px;
  //           margin-bottom: 20px;
  //           margin: 0;
  //         }
  //         & .button {
  //           background: #ffffff 0% 0% no-repeat padding-box;
  //           border: 1px solid #1e76af;
  //           border-radius: 8px;
  //           font: normal normal normal 16px/40px Noto Sans KR;
  //           letter-spacing: 0px;
  //           color: #1e76af;
  //           width: 100%;
  //           height: 48px;
  //           margin: 20px 0;
  //         }
  //         & .bottom-info {
  //           margin-top: 24px;
  //           text-align: left;
  //           font-size: 12px;
  //           font-weight: normal;
  //           letter-spacing: 0px;
  //           color: #777777;
  //           & .mobile-onetoone {
  //             display: flex;
  //             flex-direction: column;
  //             margin-bottom: 10px;
  //           }
  //           & .mobile-withdraw {
  //             display: flex;
  //             flex-direction: column;
  //           }
  //           & .info-btn {
  //             background: #ffffff 0% 0% no-repeat padding-box;
  //             border: 1px solid #bababa;
  //             border-radius: 2px;
  //             width: 100px;
  //             height: 24px;
  //             color: #777777;
  //             font-size: 11px;
  //             font-weight: normal;
  //             margin: 0;
  //           }
  //         }
  //         & .submitBtn-container {
  //           display: flex;
  //           justify-content: space-between;
  //           margin-top: 30px;
  //           font-size: 14px;
  //           font-weight: normal;
  //           text-align: center;
  //           & .cancel-btn {
  //             width: 160px;
  //             height: 48px;
  //             background: #ffffff 0% 0% no-repeat padding-box;
  //             border: 1px solid #1e76af;
  //             border-radius: 8px;
  //           }
  //           & .submibtn {
  //             width: 160px;
  //             height: 48px;
  //             background: #1e76af 0% 0% no-repeat padding-box;
  //             border-radius: 8px;
  //             letter-spacing: 0px;
  //             color: #ffffff;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
