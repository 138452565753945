#ModooMall {
  display: flex;
  flex-direction: column;
  & .modoomall-main-container {
    padding: 84px 0 80px 0;
    margin: 0 auto;
    width: 1400px;

    @media screen and (max-width: 1400px) {
      margin: 0 auto;
      padding: 20px;
      width: 100vw;
    }

    @media screen and (max-width: 320px) {
      margin: 0;
    }

    /* top banner*/
    & .togBanner {
      position: relative;
      width: 100%;
      height: 320px;
      border-radius: 20px;
      overflow: hidden;

      & .togT {
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        text-align: center;
        z-index: 2;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;

        & .togTt {
          font-size: 32px;
          color: #fff;
          line-height: 1.2;
          font-weight: 600;
          padding: 7px 0 16px 0;
        }

        & .togTx {
          font-size: 15px;
          color: #fff;
          line-height: 1.8;
        }
      }
      & .togI {
        position: relative;
        z-index: 1;
        height: 100%;
        width: 100%;
      }
    }

    /* togSector2 */
    & .togSector2 {
      width: 100%;
      overflow: hidden;
      & * {
        text-align: initial;
      }

      & .layerInBx {
        position: relative;
        width: 100%;
        margin: 0 auto;

        & .baseTabs {
          margin: 40px 0 30px 0;
          width: 100%;
          height: 56px;
          font-size: 0;
          line-height: 0;

          @media screen and (max-width: 1400px) {
            margin: 20px 0 10px 0;
          }
          @media screen and (min-width: 1401px) {
            background: #f3f8fb;
            border-radius: 8px 8px 0 0;
          }

          overflow: hidden;
          & button {
            background: inherit;
          }

          & > button {
            position: relative;
            width: 175px;
            @media screen and (max-width: 1400px) {
              width: 33%;
              min-width: 79px;
              margin-right: 13px;
            }
            text-align: center;
            border: 1px #f3f8fb solid;
            @media screen and (max-width: 1400px) {
              border: 1px solid $primaryColor;
              background: #ffffff 0% 0% no-repeat padding-box;
              opacity: 1;
            }
            border-radius: 8px 8px 0 0;

            @media screen and (min-width: 1401px) {
              &:nth-child(n + 2)::after {
                content: "";
                position: absolute;
                left: 0;
                top: 50%;
                width: 1px;
                height: 20px;
                margin: -10px 0 0 0;
                background: #c6d4dd;
              }
            }

            & span {
              display: inline-block;
              font-size: 16px;
              color: #000;
              line-height: 54px;
            }

            @media screen and (max-width: 1400px) {
              border-radius: 40px;
              & span {
                line-height: 40px;
                color: #1e76af;
                font-size: 1em;
              }
            }
          }

          & .on {
            background: #fff;
            border-color: $primaryColor;
            border-bottom: 1px #fff solid;

            & span {
              display: inline-block;
              font-size: 16px;
              color: $primaryColor;
              line-height: 54px;
              font-weight: 600;
              letter-spacing: 0px;
            }
          }

          @media screen and (max-width: 1400px) {
            & .on {
              background: $primaryColor;
              border-color: $primaryColor;
              border-bottom: none;

              & span {
                color: #fff;
                line-height: 40px;
                font-size: 1em;
              }
            }
          }
        }

        & .letterSearch {
          display: flex;
          justify-content: space-between;
          flex-flow: wrap;
          flex-direction: row;
          align-items: flex-end;

          position: relative;
          width: 100%;
          margin: 0 0 27px 0;
          overflow: hidden;

          // @media screen and (max-width: 425px) {
          //   & > div:nth-child(n + 2) {
          //     margin-top: 20px;
          //   }
          // }
          // @media screen and (max-width: 375px) {
          //   justify-content: left;
          // }

          & .lSForm {
            position: relative;
            display: flex;
            width: 524px;
            min-width: 236px;

            height: 56px;
            padding: 10px;
            margin: 0 auto;
            background: #fff;
            border: 1px #bababa solid;
            border-radius: 8px;
            overflow: hidden;

            // @media screen and (max-width: 768px) {
            //   width: calc(80% - 260px);
            // }
            // @media screen and (min-width: 281px) and (max-width: 425px) {
            //   width: 96%;
            // }
            // @media screen and (max-width: 280px) {
            //   width: min-content;
            //   margin: 0;
            // }

            & button.search {
              // position: absolute;
              // right: 1px;
              // top: 0;
              width: 54px;
              height: 54px;
              border-left: 1px solid #ddd;
              padding-left: 15px;
              background-size: 18px;
            }
          }

          & .lSBmark {
            order: -1;

            @media screen and (max-width: 425px) {
              order: unset;
            }

            & button {
              height: 48px;
              padding: 0 17px;
              text-align: left;
              background: #fffcef;
              border: 1px #fed831 solid;
              border-radius: 30px;
              overflow: hidden;

              & .icon-star {
                display: inline-block;
                vertical-align: middle;
              }

              & span.text-favorite {
                font-size: 16px;
                color: rgb(53, 39, 15);
                line-height: 46px;
                padding-right: 10px;
              }
            }
          }

          & .lSTotal {
            font-size: 18px;
            color: #000;
            font-weight: 600;

            & strong {
              color: #1e76af;
            }

            @media screen and (max-width: 375px) {
              padding-right: 4px;

              // & > span {
              //   display: block;
              //   margin: 12px;
              // }
            }
          }

          // & .lSletter {
          //   display: flex;
          //   justify-content: space-between;
          //   width: 524px;
          //   margin: 40px auto 9px;
          //   font-size: 0;
          //   line-height: 0;
          //   overflow: hidden;
          //   & > button {
          //     width: 30px;
          //     height: 30px;
          //     background: #fff;
          //     border: 1px #e8e8e8 solid;
          //     border-radius: 4px;
          //     text-align: center;
          //     overflow: hidden;
          //   }
          //   .on {
          //     background: #1e76af;
          //     border-color: #1e76af;
          //     & span {
          //       color: #fff;
          //     }
          //   }
          // }
        }
      }
    }

    /* togSector3 */
    & .togSector3 {
      & * {
        text-align: initial;
      }
      .layerInBx {
        position: relative;
        width: 1400px;
        margin: 0 auto;

        @media screen and (max-width: 1400px) {
          width: 90vw;
        }

        .mvStoreList {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-start;
          margin: 0 0 -16px 0;
          & #mall-card {
            margin-bottom: 16px;
            margin-right: 16px;
            &:nth-of-type(3n) {
              margin-right: 0;
            }
          }

          .item {
            position: relative;
            width: 456px;
            @media screen and (max-width: 768px) {
              width: 100%;
            }
            padding: 0 0 16px 0;
            .ibx {
              min-height: 148px;
              padding: 16px;
              background: #f4f4f4;
              @media screen and (max-width: 425px) {
                background: none;
              }
              border-radius: 8px;
              .img {
                position: absolute;
                left: 16px;
                top: 16px;
                width: 116px;
                height: 116px;
                border-radius: 8px;
                overflow: hidden;
                text-align: center;
                background-color: #aaa;
                & img {
                  vertical-align: top;
                }
                & span {
                  position: absolute;
                  left: 0;
                  bottom: 0;
                  width: 47px;
                  height: 28px;
                  font-size: 14px;
                  color: #fff;
                  line-height: 28px;
                  font-weight: 500;
                  background: #000;
                  border-radius: 0 8px 0 0;
                  text-align: center;
                }
              }
              .info {
                position: relative;
                min-height: 116px;
                margin: 0 0 0 132px;
                &::after {
                  content: "";
                  display: block;
                  clear: both;
                }

                & .bName {
                  position: relative;
                  font-size: 16px;
                  color: #000;
                  font-weight: 600;
                  line-height: 1.2;
                  padding: 4px 24px 15px 0;
                  & > button {
                    background: none;
                  }

                  .mark {
                    position: absolute;
                    right: 0;
                    top: 0;
                    width: 24px;
                    height: 24px;
                    color: #aaa;
                    // background: url(../images/bg/bg_mv_store_mark.png) 50% 50% no-repeat;
                    // background-size: 24px;
                  }
                  .on {
                    color: $favoriteColor;
                  }
                }

                & .bTxt {
                  font-size: 12px;
                  color: #000;
                  line-height: 1.2;
                  padding: 0 0 6px 0;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
                & .bLocation {
                  display: inline-flex;
                  font-size: 13px;
                  color: #000;
                  padding: 0 0 6px 0;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                  overflow: hidden;
                }
                & .bHash {
                  max-height: 80px;
                  overflow: hidden;
                  & span {
                    display: inline-block;
                    font-size: 13px;
                    color: #000;
                    line-height: 23px;
                    padding: 0 8px;
                    margin: 0 8px 0 0;
                    background: #fff;
                    border-radius: 4px;
                  }
                }
              }
            }
          }
        }
      }

      & .pagination-container {
        display: flex;
        & .pagination {
          margin: 16px auto 0;
          & button {
            color: #777777;
          }
          & .Mui-selected {
            background-color: white;
            color: #1e76af;
          }
        }
      }
    }
  }

  // 모바일용 css 모음
  @media screen and (max-width: 1400px) {
    min-width: 360px;

    & .modoomall-main-container {
      width: 100%;
      min-width: 360px;
      max-width: 480px !important;

      & .togBanner {
        height: fit-content;
        border-radius: 0;
        & .togI {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          height: auto;
          & .LazyLoad {
            width: calc(100vw - 40px);
            min-width: 320px;
            max-width: 440px;
            height: auto;
            & img {
              width: calc(100vw - 40px);
              min-width: 320px;
              max-width: 440px;
              height: auto;
              border-radius: 16px;
            }
          }
        }
      }

      & [title~="Tabs"] {
        & .togSector2 {
          & .layerInBx {
            & .baseTabs {
              height: 60px;
              & button {
                margin-right: 8px !important;
                width: 90px;
              }
            }
            & .letterSearch {
              & .lSForm {
                height: 48px !important;
                margin-bottom: 26px;
                display: flex;
                justify-content: space-between;
                & .MuiInputBase-input {
                  width: 100%;
                  min-width: 254px !important;
                  max-width: 369px !important;
                }
              }

              & .lsRow {
                width: 100%;
                display: flex;
                justify-content: space-between;
                & .lSBmark {
                  & button {
                    height: 43px !important;
                    & .text-favorite {
                      line-height: 43px;
                    }
                  }
                }
                & .lSTotal {
                  line-height: 43px;
                }
              }
            }
          }
        }
      }

      & .layerInBx {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        & .mvStoreList {
          margin-bottom: 20px !important;
          & #mall-card {
            width: calc(100vw - 40px);
            min-width: 320px;
            max-width: 440px;
            margin: 0 !important;
          }
        }
        & .pagination-container {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
        }
      }
    }
  }
}
