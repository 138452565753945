& .singular-wrapper {
  height: 570px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  & .singular-container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .title-container {
      display: flex;
      margin-top: 48px;
      & .title-text {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
      }
    }
    & .body {
      padding-top: 32px;
      display: flex;
      flex-wrap: wrap;
      width: 1400px;
      justify-content: flex-start;
      & .book-img {
        flex-direction: column;
        margin-right: 40px;
        cursor: pointer;
        &:nth-of-type(6) {
          margin-right: 0;
        }
        & .book-image {
          width: 200px;
          & img {
            width: 200px;
            border-radius: 8px;
            box-shadow: 0px 5px 10px #00000029;
          }
        }
        & .book-title {
          margin-top: 16px;
          width: 200px;
          text-align: center;
          font: normal normal bold 16px/24px Noto Sans KR;
        }
        & .book-author-publisher {
          width: 200px;
          margin-top: 8px;
          text-align: center;
          font: normal normal normal 12px/24px Noto Sans KR;
          color: #777777;
        }
        & .price-container {
          display: flex;
          width: auto;
          margin-top: 20px;
          margin-bottom: 55px;
          & :nth-of-type(1) {
            margin: auto;
            margin-right: 0;
            width: 42px;
            height: 25px;
            padding: 4px 8px;
            color: white;
            border-radius: 4px;
            background-color: #1e76af;
            font-size: 12px;
          }
          & :nth-of-type(2) {
            margin-left: 12px;
            font-size: 28px;
            font-weight: bold;
          }
          & :nth-of-type(3) {
            font-size: 14px;
            margin: auto auto 7px 4px;
          }
        }
      }
    }
  }
  // @media screen and (max-width: 1400px) {
  //   padding-top: 25px;
  //   min-width: 360px;
  //   margin: 0 auto;
  //   height: auto;
  //   background: #f5f5f5 0% 0% no-repeat padding-box;
  //   & .singular-container {
  //     max-width: 480px;
  //     height: auto;
  //     margin: 0 auto;
  //     & .mobile {
  //       margin-left: 20px;
  //       margin-right: 20px;
  //       & .title-container {
  //         margin-top: 0;
  //         & .title-text {
  //           text-align: left;
  //           font: normal normal bold 17.5px Noto Sans KR;
  //         }
  //       }
  //       & .mobile-singular-swiper {
  //         min-width: 360px;
  //         width: 100vw;
  //         max-width: 480px;
  //         margin-bottom: 35px;
  //         & .swiper-slide {
  //           display: flex;
  //           flex-direction: column;
  //           width: 180px;
  //           margin-top: 35px;
  //           margin-right: 20px;
  //           & .book-cover {
  //             display: flex;
  //             & img {
  //               width: 125px;
  //               box-shadow: 0px 2.5px 5px #00000029;
  //               border-radius: 4px;
  //             }
  //           }
  //           & .book-title {
  //             margin-top: 16px;
  //             font-size: 15px;
  //             font-weight: bold;
  //             text-align: left;
  //             overflow: hidden;
  //             text-overflow: ellipsis;
  //             display: -webkit-box;
  //             -webkit-box-orient: vertical;
  //             -webkit-line-clamp: 2;
  //           }
  //           & .sub-title-container {
  //             margin-top: 12px;
  //             font-size: 12.5px;
  //             display: flex;
  //             & div {
  //               overflow: hidden;
  //               text-overflow: ellipsis;
  //               display: -webkit-box;
  //               -webkit-box-orient: vertical;
  //               -webkit-line-clamp: 1;
  //               font-size: 12px;
  //               color: #777777;
  //             }
  //             & .divider {
  //               width: 0px;
  //               height: 8px;
  //               margin: auto 6px;
  //               border-left: 1px solid #000000;
  //               opacity: 0.5;
  //             }
  //           }
  //           & .price-info-container {
  //             display: flex;
  //             margin-top: 14px;
  //             & .sale-percentage {
  //               font-size: 11px;
  //               font-weight: bold;
  //               color: white;
  //               background: #1e76af 0% 0% no-repeat padding-box;
  //               border-radius: 2px;
  //               padding: 2px 4px;
  //             }
  //             & .after-sale {
  //               margin-left: 12px;
  //               font-size: 14px;
  //               font-weight: bold;
  //               & span {
  //                 font-size: 12px;
  //                 font-weight: bold;
  //                 margin-left: 2px;
  //               }
  //             }
  //             & .before-sale {
  //               margin-left: 12px;
  //               text-decoration: line-through;
  //               color: #777777;
  //               font-size: 12px;
  //               margin-top: 2px;
  //             }
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
}
