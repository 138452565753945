& .event-tab-text {
  min-width: 102px;
  height: 26px;
  text-align: left;
  font: normal normal bold 18px/32px Noto Sans KR;
  letter-spacing: 0px;
  opacity: 1;
  margin-top: 32px;
  margin-bottom: 24px;
}
& .event-tab-wrapper-container {
  display: flex;
  margin-bottom: 120px;
  justify-content: flex-start;
  flex-wrap: wrap;
  cursor: pointer;
  @media screen and (max-width: 480px) {
    display: flex;
    flex-direction: column;
    margin: 10px;
    flex-wrap: wrap;
    cursor: pointer;
    margin-bottom: 10px;
  }
  & .event-tab-wrapper-container-box {
    // margin-left: 25px;
    margin-top: 32px;
    width: 450px;
    height: 403px;
    margin-right: 25px;
    &:nth-of-type(3n) {
      margin-right: 0;
    }
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #e8e8e8;
    border-radius: 16px;
    opacity: 1;
    @media screen and (max-width: 480px) {
      margin-top: 30px;
      width: 370px;
      height: 350px;
      overflow-y: scroll;
    }
    & .event-image-box {
      width: 450px;
      height: 240px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e8e8e8;
      border-radius: 16px 16px 0px 0px;
      opacity: 1;
      overflow: hidden;
      margin: 0 auto;
      & img {
        width: 450px;
        height: 240px;
        object-fit: cover;
      }
      @media screen and (max-width: 480px) {
        margin: auto;
        align-items: center;
      }
    }

    & .event-tab-tag {
      margin-top: 24px;
      color: var(---000000-text-);
      text-align: center;
      text-decoration: underline;
      font: normal normal medium 14px/20px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      @media screen and (max-width: 480px) {
        margin-top: 5px;
      }
    }
    & .event-tab-title {
      margin-top: 16px;
      color: var(---000000-text-);
      text-align: center;
      font: normal normal bold 24px/35px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      @media screen and (max-width: 480px) {
        margin-top: 5px;
      }
    }
    & .event-tab-contents {
      margin-top: 12px;
      color: var(---000000-text-);
      text-align: center;
      font: normal normal normal 16px/24px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      @media screen and (max-width: 480px) {
        margin-top: 7px;
      }
    }
  }
}
