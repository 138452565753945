& .find-tab {
  display: flex;
  flex-direction: column;
  & .error-message {
    text-align: left;
    color: #ec1f2d;
    font: normal normal normal 14px/40px Noto Sans KR;
  }
  & .id-input {
    margin-top: 24px;
    border-radius: 8px;
  }
  & .name-input {
    margin-top: 16px;
    border-radius: 8px;
  }
  & .phone-number-input {
    margin-top: 16px;
    border-radius: 8px;
  }
  & .email-input {
    margin-top: 16px;
    border-radius: 8px;
  }
  & .confirm-number-container {
    display: flex;
    margin-top: 16px;
    & .confirm-number-input {
      height: 56px;
      width: 248px;
      border-radius: 8px;
      & .endAdornment-input {
        font: normal normal normal 14px/40px Noto Sans KR;
        color: #ec1f2d;
      }
    }
    & .resend-button {
      height: 56px;
      width: 104px;
      margin-left: 16px;
      border-radius: 8px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #1e76af;
      // font
      text-align: center;
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #1e76af;
    }
  }
  & .submit-button {
    margin-top: 32px;
    margin-bottom: 0;
    width: 360px;
    height: 56px;
    // background-color: #1e76af;
    border-radius: 8px;
    border: none;
    color: white;
    font-size: 16px;
  }
}
