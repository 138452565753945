& .purchase-steps-wrapper {
  width: 350px;
  // margin-right: auto;
  // margin-left: 56px;
  @media screen and (max-width: 1400px) {
    margin: 0;
    min-width: 320px;
    width: calc(100vw - 40px);
    max-width: 440px;
  }
  & .purchase-steps {
    @media screen and (max-width: 1400px) {
      display: none;
    }
    margin-top: 11px;
    display: grid;
    box-sizing: border-box;
    padding-left: 5%;
    grid-template-columns: repeat(2, 2fr 1fr) 2fr;
    position: relative;

    .content-align {
      text-align: center;
    }

    .next-icon {
      background: url(../../imgs/bg_gray_step_arrow.png) left top no-repeat;
      background-size: 20px;
      margin-top: 5px;
    }

    .on {
      color: $primaryColor;
      font-weight: 600;
    }

    .off {
      color: #000;
    }
  }

  & .purchase-predict {
    text-align: initial;
    margin-top: 8%;
    width: 100%;
    border: 1px #e8e8e8 solid;
    border-radius: 8px;
    overflow: hidden;

    & ul {
      list-style: none;
      padding: 0;
      margin: 0;
      & li {
        position: relative;
        font-size: 16px;
        color: #000;
        line-height: 1.2;
        margin: 26px 0 0 0;

        & span {
          position: absolute;
          right: 0;
          top: 0;
          font-size: 20px;
          color: #000;
          line-height: 1.2;
        }

        &.predict {
          font-size: 20px;
          line-height: 1.2;
          & strong {
            font-weight: 600;
          }
        }

        &.predict,
        &.predict * {
          color: $primaryColor;
        }
      }
    }

    & .pptt {
      position: relative;
      width: 100%;
      height: 64px;
      background: #f3f8fb;

      span {
        font-size: 16px;
        color: #000;
        line-height: 62px;
        padding: 0 24px;
      }
    }

    & .ppbx {
      padding: 0 24px 48px;
      @media screen and (max-width: 1400px) {
        padding: 0 20px 30px;
      }

      & .pptd {
        width: 100%;
        padding: 0 0 31px 0;
        border-bottom: 1px #e8e8e8 solid;
        overflow: hidden;
      }
      & .ppte {
        width: 100%;
        padding: 0 0 35px 0;
        overflow: hidden;
      }
      & .pptb {
        width: 100%;
        overflow: hidden;

        & .btn-full-width {
          width: 100%;
          display: inline-block;
          height: 56px;
          text-align: center;
          // background: #ddd;
          // border: 1px $primaryColor solid;
          border-radius: 8px;
          overflow: hidden;
        }

        & .whiteBtn {
          // display: inline-block;
          // height: 56px;
          // text-align: center;
          border: 1px #d0d0d0 solid;
          // border-radius: 8px;
          // overflow: hidden;
        }

        & .blueBtn {
          // display: inline-block;
          // height: 56px;
          // text-align: center;
          background: $primaryColor;
          border: 1px $primaryColor solid;
          // border-radius: 8px;
          // overflow: hidden;
          & span {
            font-size: 17px;
            color: #fff;
            line-height: 54px;
          }
        }
      }
    }
  }
}
