.univ-banner-wrap {
  margin-top: 21px;
  // & .univ-banner-title {
  //   text-align: left;
  //   font-size: 24px;
  //   font-weight: bold;
  //   width: 1400px;
  //   margin: 0px auto;
  //   margin-top: 21px;
  // }
  & .univ-banner-box {
    width: 1400px;
    margin: 0px auto;
    position: relative;
    display: flex;
    justify-content: space-between;
    z-index: 10;
    // justify-content: flex-end;
    // outline: 1px solid red;
    & .left {
      width: 1400px;
      position: relative;
      &:hover {
        & .banner-content-wrap {
          visibility: visible;
          transition: all 1s ease;
          // opacity: 1;
          // height: 120px;
          max-height: 500px;
          // border-top: 1px solid rgba(184, 192, 199, 0.2);
          cursor: pointer;
          // 드래그 방지
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-use-select: none;
          user-select: none;
          & .banner-content-box {
            & .banner-content-dep,
            & .banner-content-dep2 {
              & div {
                &:hover {
                  color: #b7916b;
                  // cursor: alias;
                }
              }
            }
          }
        }
        & .banner-menu {
          & .title-list {
            &.Mui-selected {
              transition: all 0.3s;
              border-radius: 25px 25px 0px 0px;
              border-bottom: #fff;
            }
          }
        }
      }
      // 왼쪽 단과대학 list가 클릭 했을때 높이
      // height: 231px;
      & .banner-menu {
        & .title {
          // 사이드에 있는 화살표
          & .Mui-disabled {
            opacity: 0.3;
          }
          & .MuiTabs-indicator {
            display: none;
          }
          // 메뉴 list
          & .MuiTabs-flexContainer {
            display: flex;
            gap: 10px;
          }
          & .title-list {
            color: #b3b3b3;
            border: 1px solid rgba(88, 92, 95, 0.2);
            border-radius: 25px;
            transition: all 0.3s;
            &.Mui-selected {
              transition: all 0.3s;
              color: #000;
            }
          }
        }
        & .MuiTabs-scroller {
          margin: 0 20px;
        }
      }
      & .banner-content-wrap {
        // border-top: 1px solid rgba(184, 192, 199, 0.2);
        width: 100%;
        max-height: 0;
        background-color: #fff;
        box-shadow: 0 5px 5px 0 rgba(184, 192, 199, 0.2);
        overflow: hidden;
        position: absolute;
        border-radius: 0px 0px 15px 15px;
        // font-size: 14px;
        font-size: 12px;
        visibility: hidden;
        transition: all 0.5s ease;
        // opacity: 0;
        & .banner-content-box {
          width: 100%;
          gap: 30px;
          text-align: start;
          display: flex;
          flex-wrap: wrap;
          padding: 20px 60px;
          & .banner-content-dep {
            &:has(.banner-content-dep2) {
              & > .banner-content-dep-item {
                &:hover {
                  color: rgba(0, 0, 0, 0.87);
                  cursor: default;
                }
              }
            }
            & > .banner-content-dep-item {
              padding: 5px 0px;
            }
            & .banner-content-dep2 {
              & > div {
                margin-left: 10px;
                color: #b3b3b3;
              }
            }
          }
        }
      }
    }
    & .right {
      // display: flex;
      // justify-content: center;
      // align-items: center;
      width: 200px;
      max-width: 200px;
      z-index: -10;
      & .find-book-wrap {
        width: 200px;
        max-width: 200px;
        cursor: pointer;
        position: absolute;
        user-select: none;
        background-color: #fff;
        padding: 9px;
        border-radius: 8px;
        box-shadow: 0 5px 5px 0 rgba(184, 192, 199, 0.2);
        &:has(.select-box.active) {
          box-shadow: 0 5px 5px 0 rgba(184, 192, 199, 0.5);
        }
        & .title {
          display: flex;
          align-items: center;
          justify-content: space-between;
          & .title-text {
            & .book-icon {
              margin-right: 5px;
            }
            display: flex;
            align-items: center;
            text-align: left;
            font-size: 20px;
            font-weight: bold;
          }
        }
        & .select-box {
          display: flex;
          flex-direction: column;
          gap: 5px;
          transition: all 0.3s;
          overflow: hidden;
          visibility: hidden;
          opacity: 0;
          height: 0;
          &.active {
            margin-top: 10px;
            transition: all 0.3s;
            visibility: visible;
            opacity: 1;
            height: auto;
            min-height: 171px;
          }
          & .select {
            height: 32px;
            width: 100%;
            & .custom-select {
              text-align: left;
              height: 100%;
              width: 100%;
            }
          }
          & .two-select {
            height: 32px;
            display: flex;
            gap: 5px;
            width: 100%;
            & .custom-select {
              // outline: 1px solid red;
              text-align: left;
              height: 100%;
              width: 50%;
            }
          }
          & .btn-find-book {
            & button {
              margin-top: 10px;
              width: 100%;
              height: 50px;
              border-radius: 8px;
              background: #1e76af 0% 0% no-repeat padding-box;
              color: #fff;
              // box-shadow: inset -2px -3px #598bbd;
              font-size: 20px;
            }
          }
        }
      }
    }
  }
}
