#book-preview-modal {
  display: flex;
  flex-direction: column;
  padding: 40px;
  border-radius: 16px;
  background-color: white;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    width: 100vw;
    max-width: 480px;
    min-height: 660px;
    max-height: 100vh;
    padding: 10px 20px;
  }
  & .header-container {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    padding-bottom: 24px;
    border-bottom: 1px solid #bababa;
    @media screen and (max-width: 1400px) {
      border-bottom: none;
      padding-bottom: 0;
    }
    & .comment {
      font-size: 14px;
      font-weight: normal;
      margin-top: 12px;
      &.blue {
        margin: 12px 16px 0 16px;
        color: #1e76af;
      }
    }
    & .close-button {
      margin-left: auto;
      cursor: pointer;
    }
  }
  & .body-container {
    height: 480px;
    display: flex;
    margin-top: 24px;
    @media screen and (max-width: 1400px) {
      flex-direction: column;
      height: 100%;
      margin-top: 0;
    }
    & .viewer {
      display: flex;
      border: 1px solid #e8e8e8;
      width: 882px;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        border: none;
      }
      & .nav-button {
        margin: auto 0;
        cursor: pointer;
        @media screen and (max-width: 1400px) {
          display: none;
        }
      }
      & .preview {
        width: 400px;
        height: 480px;
        & img {
          width: 400px;
          height: 480px;
        }
        margin: auto;
        @media screen and (max-width: 1400px) {
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          height: fit-content;
          & img {
            min-width: 320px;
            width: calc(100vw - 40px);
            max-width: 440px;
            height: auto;
          }
        }
      }
    }
    & .controller {
      margin-left: 24px;
      width: 168px;
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        margin: auto;
        margin-bottom: 0;
      }
      & .button-controller {
        display: flex;
        @media screen and (max-width: 1400px) {
          display: none;
        }
        & .button {
          width: 80px;
          height: 32px;
          background: #f4f4f4 0% 0% no-repeat padding-box;
          border-radius: 4px;
          &.first {
            margin-right: auto;
          }
        }
      }
      & .number-controller {
        margin-top: 16px;
        border: 1px solid #bababa;
        border-radius: 8px;
        height: 44px;
        display: flex;
        padding: 10px 16px;
        font-size: 14px;
        color: #777777;
        @media screen and (max-width: 1400px) {
          width: fit-content;
          margin: 15px auto;
          border: none;
          padding: 0;
          height: 20px;
        }
        & span {
          color: black;
          font-weight: bold;
        }
        & .nav-button {
          cursor: pointer;
          margin: auto;
          &.left {
            margin-left: 0;
            margin-right: 14px;
          }
          &.right {
            margin-left: 14px;
            margin-right: 0;
          }
        }
      }
      & .view-mode-controller {
        margin-top: 16px;
        display: flex;
        justify-content: space-between;
        & div {
          cursor: pointer;
        }
        @media screen and (max-width: 1400px) {
          display: none;
        }
      }
      & .like-share-container {
        display: flex;
        margin-top: auto;
        & .like-share-button {
          border: 1px solid #1e76af;
          border-radius: 8px;
          width: 36px;
          height: 36px;
          cursor: pointer;
          & img {
            margin-top: 8px;
            margin-left: 8px;
          }
          @media screen and (max-width: 1400px) {
            width: 48px;
            height: 48px;
            display: flex;
            & div {
              margin: auto;
            }
            & img {
              margin: 0;
            }
          }
          &.like {
            margin-left: 8px;
            @media screen and (max-width: 1400px) {
              margin-left: auto;
            }
          }
        }
      }
      & .cart-button {
        margin-top: 16px;
        border: 1px solid #1e76af;
        border-radius: 8px;
        height: 56px;
        padding: 16px 20px;
        color: #1e76af;
        @media screen and (max-width: 1400px) {
          margin-top: 0;
          height: 48px;
          padding: 0 20px;
          margin-left: 10px;
          margin-right: auto;
          & .rarr {
            margin-left: 18px;
          }
          &.buy {
            background-color: #1e76af;
            color: white;
          }
        }
        & div {
          display: flex;
          & div {
            margin-left: auto;
            margin-top: 1px;
          }
        }
      }
    }
  }
}
