#mobile-sticky-footer-wrapper {
  //   opacity: 0;
  //   z-index: -2147483648;
  display: none;
  @media screen and (max-width: 1400px) {
    display: block;
    // opacity: 1;
    z-index: 10000;
    position: fixed;
    width: 100%;
    height: 80px;
    background-color: black;
    bottom: 0;
    display: flex;
    justify-content: center;
    & .top-button {
      position: fixed;
      width: 40px;
      height: 40px;
      cursor: pointer;
      z-index: 10001;
      bottom: 96px;
      right: 16px;
    }
    & .mobile-sticky-footer-container {
      margin: 15px 20px auto;
      min-width: 320px;
      max-width: 440px;
      width: 100%;
      display: flex;
      justify-content: space-between;
      & .footer-icon-container {
        min-width: 46px;
        cursor: pointer;
        display: flex;
        flex-direction: column;
        color: white;
        font-size: 10px;
        & img {
          height: 23px;
          margin: 0 auto 8px;
        }
      }
    }
  }
}
