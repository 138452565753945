#side-button-ui {
  display: flex;
  flex-direction: column;
  position: fixed;
  top: calc(100vh - 400px);
  left: calc(100vw - 120px);
  z-index: 100;
  & .cart-and-recent-container {
    width: 88px;
    height: 214px;
    padding: 10px 0 0 0;
    background: #fff;
    border-radius: 80px;
    box-shadow: 0px 3px 6px #00000029;
    text-align: center;
    margin-bottom: 10px;
    display: flex;
    flex-direction: column;
    & .cart-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 68px;
      height: 68px;
      border-radius: 100%;
      display: flex;
      background-color: #efebe5;
      margin: 0 auto;
      cursor: pointer;
      & .cart-badge {
        width: 48px;
        height: 48px;
        & .MuiBadge-badge {
          width: 16px;
          height: 16px;
          min-width: 16px;
        }
        & .cart-icon {
          margin: auto;
          width: 48px;
          height: 48px;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
    }
    & .recent-view-container {
      & .recent-text {
        padding: 13px 0px 23px;
        font-size: 15px;
        font-weight: bold;
        & .red {
          color: red;
          margin-left: 7px;
        }
      }
      overflow-x: visible;
      & .recent-book-icon-container {
        display: flex;
        width: fit-content;
        overflow-x: hidden;
        flex-direction: row;
        position: absolute;
        right: 10px;
        & .recent-book-icon {
          width: 68px;
          height: 68px;
          border-radius: 100%;
          background-color: #dadada;
          margin-left: 12px;
          display: none;
          &:last-of-type {
            display: flex;
          }
          cursor: pointer;
          & .book-icon {
            margin: auto;
            margin-left: 21px;
            & .is-visible {
              display: flex;
              & img {
                margin: auto;
                background-color: white;
                box-shadow: 0px 5px 10px #00000033;
                border-radius: 4px;
              }
            }
          }
          & .del-icon {
            margin: 3px 3px 0px 0px;
          }
        }
        &:hover {
          & .recent-book-icon {
            display: flex;
          }
        }
      }
    }
  }

  & .button {
    width: 64px;
    height: 64px;
    border-radius: 100%;
    background-color: black;
    color: white;
    margin: 5px 12px;
    box-shadow: 0px 3px 6px #00000029;
    cursor: pointer;
    font-size: 12px;
    display: flex;
    flex-direction: column;
    &.up {
      & .ico {
        width: fit-content;
        margin: 2px auto 0;
        display: flex;
        height: auto;
        & div {
          height: 32px;
          margin-top: auto;
          margin-bottom: 12px;
        }
      }
      & div {
        height: 32px;
        line-height: 32px;
        margin: 2px auto;
      }
    }
    &.down {
      & div {
        height: 32px;
        line-height: 32px;
        margin: 10px auto 0;
      }
      & .ico {
        width: fit-content;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        height: auto;
        & div {
          margin-top: 0;
        }
        & img {
          margin-bottom: 40px;
        }
      }
    }
  }
}
