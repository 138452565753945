#field-presenter {
  display: flex;
  flex-direction: column;
  & .select-checkbox {
    height: 32px;
    width: 32px;
    & .years-input {
      width: 167px;
      height: 56px;
      margin-right: 8px;
      border: 1px solid #bababa;
      text-align: left;
    }
  }
  & .time-select-box {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    & .grade-input {
      width: 120px;
      height: 56px;
      margin-right: 8px;
      border: 1px solid #bababa;
      text-align: left;
    }
    & .semester-input {
      width: 120px;
      height: 56px;
      margin-right: 8px;
      border: 1px solid #bababa;
      text-align: left;
    }
  }
  & .divider {
    width: 1164px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #777777;
  }
  & .title-bar-container {
    border-top: 1px solid #e8e8e8;
    height: 64px;
    padding: 16px 0;
    display: flex;
    & .button {
      width: 80px;
      height: 32px;
      margin-left: 12px;
      cursor: pointer;
      &.cart {
        width: 88px;
      }
      &.excel-download {
        width: 130px;
      }
    }
    & .sort-button {
      color: #777777;
      &.first {
        margin-left: auto;
      }
      &.selected {
        color: black;
        font-weight: bold;
      }
    }
    & .divider {
      height: 12px;
      width: 1px;
      background-color: #e8e8e8;
      margin: auto 12px;
    }
  }
  & .list-row-container {
    display: flex;
    padding: 40px 0;
    border-top: 1px solid #e8e8e8;
    @media screen and (max-width: 1400px) {
      padding: 20px;
      & .MuiCheckbox-root {
        display: none;
      }
    }

    & .title-container.no-search-result {
      font-size: 24px;
      font-weight: bold;
      margin: auto;
      text-align: center;

      @media screen and (max-width: 1400px) {
        font-size: 17.5px;
      }
    }

    & .book-img {
      width: 120px;
      margin-left: 40px;
      @media screen and (max-width: 1400px) {
        margin-left: 0;
        width: 80px;
        & .LazyLoad {
          width: 80px !important;
        }
      }
      cursor: pointer;
      & img {
        width: 120px;
        box-shadow: 0px 5px 10px #00000029;
        border-radius: 8px;
        @media screen and (max-width: 1400px) {
          width: 80px;
        }
      }
    }
    & .book-info-container {
      display: flex;
      flex-direction: column;
      width: 538px;
      margin-left: 48px;
      text-align: left;
      @media screen and (max-width: 1400px) {
        margin-left: 24px;
      }
      & .book-title-container {
        font-size: 18px;
        font-weight: bold;
        display: flex;
        cursor: pointer;
        & .more-button {
          width: 24px;
          height: 24px;
          margin: auto 0 auto 20px;
        }
      }
      & .book-info-text {
        margin-top: 8px;
        color: #777777;
        font-size: 12px;
        line-height: 24px;
        display: flex;
        & div {
          height: 8px;
          width: 1px;
          background-color: #777777;
          margin: auto 8px;
        }
      }
      & .book-content {
        width: 538px;
        height: 50px;
        margin-top: 24px;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font: normal normal normal 14px/28px Noto Sans KR;
      }
      & .bottom-line {
        margin-top: 43px;
        display: flex;
        & .heart-icon {
          width: 24px;
          height: 24px;
          display: flex;
          cursor: pointer;
          & div {
            display: flex;
            margin-top: auto;
          }
        }
        & .text {
          margin-left: 8px;
        }
        & .divider {
          height: 16px;
          width: 1px;
          background-color: #e8e8e8;
          margin: auto 16px;
        }
        & .comment-icon {
          width: 24px;
          height: 24px;
          display: flex;
          & div {
            display: flex;
            margin-top: auto;
          }
        }
      }
    }
    & .price-container {
      height: 97px;
      margin-top: 45px;
      margin-left: 60px;
      display: flex;
      flex-direction: column;
      text-align: center;
      & .cancel-line {
        text-decoration: line-through;
        color: #777777;
        line-height: 20px;
      }
      & .sale-percentage {
        background: #1e76af 0% 0% no-repeat padding-box;
        color: white;
        padding: 4px 8px;
        font-size: 12px;
        width: fit-content;
        margin: 8px auto 0;
        border-radius: 4px;
      }
      & .price {
        margin-top: 8px;
        font-size: 22px;
        font-weight: bold;
      }
    }
    & .button-container {
      margin-left: auto;
      margin-inline-end: 0%;
      display: flex;
      flex-direction: column;
      width: 156px;
      & button {
        width: 156px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 20px;
        margin-right: 16px;
        border: 1px solid #1e76af;
        color: #1e76af;
        margin: auto 0 8px;
        & .button-text {
          display: flex;
          & .image {
            margin-left: auto;
            margin-top: 3px;
          }
        }
        &:nth-of-type(2) {
          background: #1e76af 0% 0% no-repeat padding-box;
          color: white;
          margin: 8px 0 auto;
        }
      }
    }
  }
  & .pagination-container {
    display: flex;
    @media screen and (max-width: 1400px) {
      & .MuiPaginationItem-root {
        margin: 0 !important;
      }
    }
    & .pagination {
      margin: 16px auto 0;
      & button {
        color: #777777;
      }
      & .Mui-selected {
        background-color: white;
        color: #1e76af;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    max-width: 480px;
    margin: 0 auto;
    & .time-select-box {
      margin: 20px 20px 12px 20px;
      display: flex;
      min-width: 320px;
      max-width: 440px;
      & .MuiInputBase-root {
        border: none;
        border-radius: 16px;
      }
      & .grade-input {
        width: 30%;
        height: 48px;
        margin-right: 8px;
        text-align: left;
      }
      & .semester-input {
        width: 30%;
        height: 48px;
        margin-right: 8px;
        text-align: left;
      }
    }
    & .new-book-menu-container {
      display: flex;
      margin: 0px 20px 12px;
    }

    & .presenter-header-container {
      height: 56px;
      width: 100vw;
      min-width: 360px;
      max-width: 480px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background: #f3f8fb 0% 0% no-repeat padding-box;
      & .cart-container {
        display: flex;
        margin-left: 20px;
        & .MuiCheckbox-root {
          padding-left: 0;
        }
      }
      & .show-sorting-container {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        & .sorting-name {
          float: right;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
    & .new-book-img {
      padding: 20px 0;
      margin: 0 20px;
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
      display: flex;
      border-bottom: 1px solid #e8e8e8;
      & .book-img {
        width: 80px;
        & img {
          width: 80px;
          box-shadow: 0px 2.5px 5px #00000029;
          border-radius: 4px;
          cursor: pointer;
        }
      }
      & .book-info {
        margin-left: 25px;
        min-width: 215px;
        width: 100%;
        display: flex;
        flex-direction: column;
        & .book-title-container {
          display: flex;
          font-size: 15px;
          font-weight: bold;
          & .book-title-text {
            text-align: left;
            font: normal normal bold 17.5px Noto Sans KR;
          }
          & .icon {
            cursor: pointer;
          }
          & .heart-icon {
            margin-left: auto;
            margin-right: 24px;
          }
        }
        & .sub-info-container {
          display: flex;
          font-size: 12.5px;
          color: #777777;
          min-width: 200px;
          width: calc(100vw - 160px);
          max-width: 320px;
          margin-top: 16px;
          & .divider {
            height: 8px;
            width: 1px;
            background-color: black;
            opacity: 0.5;
            margin: auto 6px;
          }
          & div {
            white-space: nowrap;
          }
        }
        & .price-info-container {
          display: flex;
          margin-top: 14px;
          & .sale-percentage {
            font-size: 11px;
            font-weight: bold;
            color: white;
            background: #1e76af 0% 0% no-repeat padding-box;
            border-radius: 2px;
            padding: 2px 4px;
          }
          & .after-sale {
            margin-left: 12px;
            font-size: 14px;
            font-weight: bold;
            & span {
              font-size: 12px;
              font-weight: bold;
              margin-left: 2px;
            }
          }
          & .before-sale {
            margin-left: 12px;
            text-decoration: line-through;
            color: #777777;
            font-size: 12px;
            margin-top: 2px;
          }
        }
      }
      &:last-of-type {
        border-bottom: none;
      }
    }
    & .more-button {
      height: 48px;
      margin: 20px auto 0;
      width: calc(100% - 40px);
      width: 440px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #aaaaaa;
      border-radius: 8px;
    }
  }
}
