& .inquiry-main-wrapper {
  margin-top: 80px;
  padding: 0px 260px 0px 260px;
  width: 1920px;
  height: 1117px;
  display: flex;
  @import "./SupportSideBar.scss";
  & .inquiry-main-container {
    margin-left: 150px;
    width: 100%;
    height: auto;
    & .main-title {
      text-align: left;
      font: normal normal bold 24px/26px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    .inquiry-divider {
      width: 1164px;
      margin-top: 32px;
    }
    & .form-container {
      margin-top: 24px;
      .form {
        display: flex;
        flex-direction: column;
      }
      .text-size {
        text-align: left;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      .name-box {
        margin-top: 16px;
        width: 180px;
        height: 56px;
        background: #f4f4f4 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
      }
      .email-wrapper {
        display: flex;
        align-items: flex-end;

        .email-box {
          margin-top: 16px;
          width: 269px;
          height: 56px;

          background: #ffffff 0% 0% no-repeat padding-box;

          border-radius: 8px;
          opacity: 1;
        }
        .email-btn {
          width: 160px;
          height: 56px;

          margin-left: 16px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #1e76af;
          border-radius: 8px;
          opacity: 1;

          text-align: center;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
        }
      }
      .select-btn {
        margin-top: 16px;

        width: 180px;
        height: 56px;
        background: #ffffff 0% 0% no-repeat padding-box;

        opacity: 1;
        & .select-menu {
          margin-bottom: 8px;
          text-align: left;
          font: normal normal normal 14px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
      .qna-title-field {
        width: 1164px;
        height: 56px;

        border: 1px solid var(---bababa-line_darker-);
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #bababa;
        border-radius: 8px;
        opacity: 1;
      }
      .qna-text-area {
        margin-top: 42px;
        width: 1164px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #bababa;
        border-radius: 8px;
        opacity: 1;
        text-align: left;
        font: normal normal normal 16px/28px Noto Sans KR;
        letter-spacing: 0px;
      }
      .img-container {
        display: flex;
        align-items: baseline;
        .img-upload-btn {
          margin-top: 16px;
          width: 160px;
          height: 56px;
          background: #e8e8e8 0% 0% no-repeat padding-box;
          border-radius: 8px;
          opacity: 1;
          text-align: center;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
        }
        .img-waring {
          margin-left: 16px;
          white-space: nowrap;
          color: var(---777777-sub-text-);
          text-align: left;
          font: normal normal normal 14px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
        }
      }
      .submit-btn {
        margin-left: 16px;
        width: 160px;
        height: 56px;
        background: var(---1e76af-key-color-) 0% 0% no-repeat padding-box;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
      .cancel-btn {
        margin-left: auto;
        margin-top: 36px;
        width: 160px;
        height: 56px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 8px;
        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
        opacity: 1;
      }
      .required-text {
        font: normal normal normal 14px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ec1f2d;
      }
    }
  }
}
