#field-book-swiper-presenter {
  & .new-book-body {
    margin-top: 28px;
    min-height: 488px;
    @media screen and (max-width: 1400px) {
      min-height: 300px;
    }
    & .swiper-slide {
      display: flex;
      flex-direction: column;
      @media screen and (max-width: 1400px) {
        width: 125px !important;
      }
      cursor: pointer;
      & .book-image {
        min-height: 200px;
        cursor: pointer;
        @media screen and (max-width: 1400px) {
          width: 125px;
          & .LazyLoad {
            width: 125px !important;
          }
        }
        & img {
          width: 200px;
          height: auto !important;
          border-radius: 10px;
          box-shadow: 0px 5px 10px #00000029;
          @media screen and (max-width: 1400px) {
            width: 125px;
          }
        }
      }
      & .book-title {
        max-width: 200px;
        margin-top: 16px;
        font-size: 16px;
        font-weight: bold;
      }
      & .book-author-publisher {
        margin-top: 8px;
        font-size: 12px;
        color: #777777;
      }
      & .price-container {
        display: flex;
        width: auto;
        margin-top: 20px;
        @media screen and (max-width: 1400px) {
          display: none;
        }
        & .sale-percentage {
          margin: auto;
          margin-right: 0;
          width: 26px;
          height: 17px;
          padding: 4px 8px;
          color: white;
          border-radius: 4px;
          background-color: #1e76af;
          font-size: 12px;
        }
        & .price {
          margin-left: 12px;
          font-size: 20px;
          font-weight: bold;
        }
        & .price-unit {
          font-size: 14px;
          margin: auto auto 3px 2px;
        }
      }
    }
    & .swiper-button-prev {
      cursor: pointer;
      &::after {
        content: none;
      }
    }
    & .swiper-button-next {
      cursor: pointer;
      &::after {
        content: none;
      }
    }
    & .swiper-pagination {
      position: relative;
      margin-top: 40px;
      & .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        &.swiper-pagination-bullet-active {
          width: 20px;
          border-radius: 10px;
          background-color: black;
        }
      }
    }
  }
  & .button-container {
    display: flex;
    flex-direction: row;
    @media screen and (max-width: 1400px) {
      display: none;
    }
    & .prev-button {
      margin-left: -60px;
      margin-top: -300px;
      background-color: #efebe5;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
    & .next-button {
      margin-left: auto;
      margin-right: -60px;
      margin-top: -300px;
      background-color: #efebe5;
      border-radius: 4px;
      width: 40px;
      height: 40px;
      cursor: pointer;
    }
  }

  @media screen and (max-width: 1400px) {
    width: 100vw;
    & .new-book-body {
      width: calc(100vw - 20px);
      min-width: 340px;
      max-width: 460px;
      & .swiper-wrapper {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        & .swiper-slide {
          padding-left: 10px;
          padding-right: 10px;
        }
      }

      & .swiper-pagination {
        top: 0;
        bottom: 0;
        margin-top: 10px !important;
      }
    }
  }
}
