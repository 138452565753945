& .new-book-banner-wrapper {
  padding-top: 80px;
  & .new-book-banner-container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .title-container {
      display: flex;
      & .title-text {
        font-size: 24px;
        font-weight: bold;
      }
      & .query-mode-button {
        width: 64px;
        height: 36px;
        margin-right: 8px;
        border-radius: 8px;
        font-size: 16px;
        background-color: #e8e8e8;
        &:first-of-type {
          margin-left: 44px;
        }
        &.selected {
          background-color: black;
          color: white;
        }
      }
      & .more-button {
        margin-left: auto;
        font-size: 14px;
        color: black;
      }
    }
    & .new-book-menu-container {
      height: 56px;
      border-bottom: 1px solid #bababa;
      display: flex;
      margin-top: 32px;
      & .prev-button {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin: auto 0 auto 0;
        background-color: #efebe5;
        border-radius: 4px;
      }
      & .next-button {
        width: 24px;
        height: 24px;
        cursor: pointer;
        margin: auto 0 auto 0;
        background-color: #efebe5;
        border-radius: 4px;
      }
      & .new-book-menu {
        width: 1300px;
        margin: 0 auto;
        & .menu-item {
          width: fit-content;
          padding: 16px;
          height: 24px;
          cursor: pointer;
          &.selected {
            font-weight: bold;
            padding-bottom: 12px;
            border-bottom: 4px solid black;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    padding-top: 50px;
    min-width: 360px;
    max-width: 480px;
    margin: 0 auto;
    & .title-container {
      & .menu {
        margin-left: auto;
        margin-right: 12px;
        & .MuiSelect-select {
          padding-top: 0;
          padding-bottom: 0;
        }
        & fieldset {
          display: none;
        }
      }
    }

    & .arrow {
      width: 56px;
      height: 56px;
      margin: 20px 0 -56px auto;
      display: flex;
      z-index: 10000;
      background-color: #f5f5f5;
      position: relative;
      cursor: pointer;
      & div {
        margin: auto;
      }
    }
    & .new-book-menu-container {
      background: #f5f5f5 0% 0% no-repeat padding-box;
      display: flex;
      min-width: 360px;
      width: 100vw;
      max-width: 480px;
      & .new-book-menu {
        display: flex;
        min-width: 319px;
        width: calc(100vw - 56px);
        max-width: 374px;
        overflow: hidden;
        flex-wrap: nowrap;
        transition: 0.3s all ease-in-out;
        height: 56px;
        & .menu-item {
          height: 56px;
          white-space: nowrap;
          padding: 16px;
          cursor: pointer;
          &.selected {
            font-weight: bold;
            padding-bottom: 12px;
            color: #1e76af;
          }
        }
        &.opened {
          flex-wrap: wrap;
          height: 280px;
          min-width: 360px;
          width: 100vw;
          max-width: 480px;
        }
      }
    }
    & .new-book-swiper {
      margin-top: 30px;
      min-height: 300px;
      & .new-book-slide {
        display: flex;
        flex-direction: column;
        width: 125px;
        padding: 0 30px;
        height: 300px;
        & .book-cover {
          margin: 0 auto;
          & img {
            max-width: 125px;
            max-height: 195px;
            box-shadow: 0px 2.5px 5px #00000029;
            border-radius: 4px;
          }
        }
        & .book-title {
          margin-top: 8px;
          font-size: 15px;
          font-weight: bold;
          text-align: left;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 2;
        }
        & .book-info-row {
          margin-top: 5px;
          font-size: 12.5px;
          display: flex;
          flex-direction: column;
          & :first-child {
            margin-left: auto;
          }
          & .info-row-item {
            width: 120px;
            text-align: left;
            margin: 0;
          }
          & .divider {
            height: 8px;
            width: 1px;
            margin: auto 6px;
            background-color: #000000;
            opacity: 0.5;
          }
          & :last-child {
            margin-right: auto;
          }
        }
      }
    }
  }
}
