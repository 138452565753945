#shipping-address-selection-modal {
  width: 768px;
  height: 840px;
  background-color: white;
  padding: 40px;
  border-radius: 20px;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    width: 100vw;
    height: 80vh;
    max-width: 480px;
    padding: 20px 24px;
    border-radius: 8px;
    display: flex;
    flex-direction: column;
  }
  & .title-container {
    padding-bottom: 24px;
    display: flex;
    border-bottom: 1px solid #707070;
    & .title {
      font-weight: bold;
      font-size: 20px;
    }
    & .x-icon {
      margin-left: auto;
      cursor: pointer;
    }
  }
  & .shipping-list-container {
    display: flex;
    flex-direction: column;
    overflow-y: scroll;
    border-bottom: 1px solid #777777;
    height: 608px;
    margin-left: 5px; // 스크롤바 두께 보정

    @media screen and (max-width: 1400px) {
      height: 100%;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bababa;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
    }
    & .shipping-list-row {
      display: flex;
      padding: 24px 0;
      border-bottom: 1px solid #d1d1d1;
      cursor: pointer;
      & .radio {
        margin: auto 30px auto 0;
      }
      & .address-container {
        display: flex;
        flex-direction: column;
        & .address-title-container {
          display: flex;
          & .address-title {
            font-weight: bold;
            text-align: left;
            @media screen and (max-width: 1400px) {
              font-size: 13px;
            }
          }
          & .default-badge {
            margin-left: 16px;
            padding: 2px 6px;
            color: white;
            background: #777777 0% 0% no-repeat padding-box;
            border-radius: 4px;
            font-size: 13px;
            height: fit-content;
            @media screen and (max-width: 1400px) {
              font-size: 11px;
            }
          }
        }
        & .address-body-container {
          margin-top: 16px;
          font-size: 15px;
          text-align: left;
          @media screen and (max-width: 1400px) {
            font-size: 13px;
          }
        }
        & .address-info {
          margin-top: 16px;
          text-align: left;
          @media screen and (max-width: 1400px) {
            font-size: 13px;
          }
        }
      }
      & .crud-button-container {
        display: flex;
        flex-direction: column;
        margin-left: auto;
        & button {
          width: 88px;
          height: 36px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 4px;
          color: white;
          border: 1px solid #1e76af;
          margin-bottom: auto;
          &.outlined {
            color: #1e76af;
            background-color: white;
            margin-top: auto;
            margin-bottom: 8px;
          }
        }
        @media screen and (max-width: 1400px) {
          flex-direction: row;
          height: 24px;
          & button,
          & button.outlined {
            margin: 0;
            border-radius: 4px;
            width: 48px;
            font-size: 11px;
            height: 100%;
            background-color: white;
            border: 1px solid #bababa;
            color: #bababa;
          }
          & button:nth-of-type(2) {
            margin-left: 8px;
          }
        }
      }
    }
  }
  & .select-button {
    background: #1e76af 0% 0% no-repeat padding-box;
    border-radius: 8px;
    color: white;
    margin-top: 48px;
    width: 162px;
    height: 56px;
    &.outlined {
      color: #1e76af;
      background-color: white;
      border: 1px solid #1e76af;
      margin: 40px auto;
    }
  }
  & .button-container {
    display: flex;
    justify-content: space-between;
    margin-top: 30px;
    & .select-button {
      width: 100%;
      margin: 0 0 0 10px;
      &.outlined {
        margin: 0;
      }
    }
  }
}
