& .modoo-mall-container {
  width: 1400px;
  display: flex;
  flex-direction: column;
  margin: 80px auto 120px;
  & .body-container {
    margin-top: 32px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    & #mall-card {
      margin-bottom: 16px;
      margin-right: 16px;
      &:nth-of-type(3n) {
        margin-right: 0;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    width: calc(100vw - 40px);
    min-width: 320px;
    max-width: 440px;
    margin-top: 50px;

    & .title-container {
      margin-bottom: 2px;
      & .title-text {
        margin: 0;
      }
    }

    & .body-container {
      flex-direction: column;
      & #mall-card {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        margin: 0 !important;
      }
    }
  }
}
