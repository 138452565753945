& .event-banner-wrapper {
  height: 430px;
  padding-top: 80px;
  & .event-banner-container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .title-container {
      display: flex;
      margin-bottom: 0px;
      & .title-text {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
      }
      & .more-button {
        margin-left: auto;
        text-align: left;
        font: normal normal normal 16px/26px Noto Sans KR;
      }
    }
    & .event-banner-body {
      display: flex;
      margin-top: 32px;
      & .event-banner {
        cursor: pointer;

        border-radius: 16px;
        & img {
          width: 574px;
          height: 166px;
          border-radius: 16px;
        }
        &:first-of-type {
          margin-right: auto;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    max-width: 480px;
    height: auto;
    padding: 50px 0 0;
    margin: 0 auto 40px;
    & .title-container {
      & .title-text {
        padding: 0 20px;
        text-align: left;
        font-size: 17.5px;
        font-weight: bold;
      }
    }
    & .event-swiper {
      margin-top: 20px;
      min-width: 360px;
      width: 100vw;
      max-width: 480px;
      height: 180px;
      & .swiper-slide {
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        margin: 0 20px;
        cursor: pointer;
        & img {
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          border-radius: 8px;
          max-height: 153px;
        }
      }
    }
    & .more-button {
      display: none;
    }
  }
}
