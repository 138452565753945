& .deliInfo {
  & .sHTiv {
    border-bottom: none;
  }
  & .mobile-wrapper {
    @media screen and (max-width: 1400px) {
      border-radius: 8px;
      background-color: #f5f5f5;
      padding: 20px;
    }
  }
  & .dh {
    position: relative;
    width: 100%;
    margin: 24px 0 0 0;
    @media screen and (max-width: 1400px) {
      margin: 0;
      display: flex;
    }

    & * {
      vertical-align: middle;
    }

    .name {
      font-size: 20px;
      color: $primaryColor;
      font-weight: 600;
      line-height: 24px;
      padding: 0 12px 0 33px;
      background: url(../../imgs/bg_dh_location.png) left 50% no-repeat;
      @media screen and (max-width: 1400px) {
        font-size: 13px;
        padding: 0 6px 0 24px;
      }
    }

    .base {
      display: inline-block;
      text-align: center;
      font-size: 13px;
      padding: 4px 8px;
      color: #777;
      background: #e8e8e8;
      border-radius: 4px;
      @media screen and (max-width: 1400px) {
        font-size: 11px;
        padding: 4px 8px;
      }
    }

    .deliChBtn {
      position: absolute;
      right: 0;
      top: -4px;
      text-align: center;
      background: #000;
      border-radius: 4px;
      overflow: hidden;
      font-size: 13px;
      color: #fff;
      height: fit-content;
      @media screen and (max-width: 1400px) {
        font-size: 11px;
        display: flex;
        position: relative;
        top: unset;
        margin-left: auto;
        width: fit-content;
        line-height: normal;
      }
    }
  }

  & .dl {
    position: relative;
    width: 100%;
    padding: 15px 0 0 0;
    @media screen and (max-width: 1400px) {
      padding: 0;
    }

    & ul {
      list-style: none;
      padding: 0;

      & li {
        display: block;
        font-size: 16px;
        color: #000;
        line-height: 1.2;
        margin: 17px 0 0 0;
        @media screen and (max-width: 1400px) {
          font-size: 13px;
        }

        & span {
          padding: 0 16px 0 0;
        }
        & fieldset {
          margin-top: 5px;
          & .notranslate {
            display: none;
          }
        }
      }
    }
  }
}
