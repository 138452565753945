& .recommended-book-wrapper {
  width: auto;
  height: 560px;
  padding: 80px 0;
  display: flex;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  & .recommended-book-container {
    max-width: 1400px;
    height: 400px;
    margin: auto;
    display: flex;
    & .left-side {
      width: 712px;
      height: 400px;
      display: flex;
      flex-direction: column;
      & .title-container .more-button {
        background-color: #f5f5f5;
      }
      & .left-body {
        display: flex;
        justify-content: space-between;
        margin-top: 74px;
        & .book-card {
          width: 160px;
          & .book-img {
            cursor: pointer;
            width: 160px;
            & img {
              width: 160px;
              box-shadow: 0px 5px 10px #00000029;
              border-radius: 8px;
              max-height: 300px;
              overflow: hidden;
            }
          }
          & .book-title {
            margin-top: 14px;
            font-size: 16px;
            font-weight: bold;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            max-width: 160px;
          }
          & .book-sub-info {
            display: flex;
            margin-top: 8px;
            & div {
              font-size: 12px;
              &:first-of-type {
                margin-left: auto;
                max-width: 90px;
              }
              &:nth-of-type(2) {
                width: 0px;
                height: 10px;
                border-left: 1px solid #000000;
                opacity: 0.5;
                margin: auto 8px;
              }
              &:last-of-type {
                margin-right: auto;
                max-width: 53px;
              }
            }
          }
        }
      }
    }
    & .right-side {
      width: 618px;
      min-height: 220px;
      margin-left: auto;
      display: flex;
      margin-left: 70px;
      & .book-img {
        width: 260px;
        cursor: pointer;
        & img {
          width: 260px;
          box-shadow: 0px 5px 10px #00000029;
          border-radius: 8px;
          max-height: 600px;
          overflow: hidden;
        }
      }
      & .right-body {
        display: flex;
        margin-left: 32px;
        flex-direction: column;
        height: fit-content;
        & .classification {
          width: fit-content;
          height: 28px;
          padding: 4px 8px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          font-size: 12px;
        }
        & .title-container {
          margin-top: 30px;
          & .title {
            max-width: 266px;
            font-size: 18px;
            font-weight: bold;
          }
          & .more-icon {
            cursor: pointer;
            margin-left: 20px;
          }
        }
        & .sub-title-container {
          margin-top: 8px;
          display: flex;
          & div {
            font-size: 12px;
            color: #777777;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            &:first-of-type {
              max-width: 100px;
            }
            &:nth-of-type(0n + 3) {
              max-width: 70px;
            }
          }
          & .divider {
            width: 0px;
            height: 10px;
            margin: auto 8px;
            border-left: 1px solid #000000;
            opacity: 0.5;
          }
        }
        & .content {
          font-size: 14px;
          width: 310px;
          line-height: 28px;
          margin-top: 24px;
          text-align: left;
          white-space: normal;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
        }
        & .button-container {
          display: flex;
          margin-top: 54px;
          opacity: 0.5;
          & .prev-button {
            margin-right: 24px;
            cursor: pointer;
          }
          & .next-button {
            margin-left: 24px;
            cursor: pointer;
          }
          & div {
            font-size: 24px;
            &:nth-of-type(2) {
              color: black;
            }
          }
          & .divider {
            width: 0px;
            height: 19px;
            border-left: 1px solid black;
            margin: 10px 8px auto;
          }
        }
        & .cart-buy-container {
          margin-top: 44px;
          display: flex;
          & .cart-button {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #1e76af;
            border-radius: 8px;
            color: #1e76af;
          }
          & .buy-button {
            margin-left: 16px;
            background: #1e76af 0% 0% no-repeat padding-box;
            border-radius: 8px;
            color: white;
          }
          & .cart-button,
          & .buy-button {
            width: 155px;
            height: 56px;
            display: flex;
            justify-content: space-between;
            & div {
              &:first-of-type {
                margin: auto 0 auto 20px;
              }
              &:last-of-type {
                margin: auto 10px auto auto;
              }
            }
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    max-width: 480px;
    margin: 0 auto;
    padding: 0;
    height: auto;
    flex-direction: column;
    background-color: white;
    & .grey-background {
      background-color: #f4f4f4;
      height: 250px;
      min-width: 360px;
      width: 100%;
      max-width: 480px;
      margin: 0 auto;
      position: absolute;
    }
    & .title-container {
      z-index: 1;
      padding-top: 32px;
      & .title-text {
        margin: 0 auto 20px;
      }
    }
    & .recommended-book-main-swiper {
      min-width: 360px;
      width: 100vw;
      max-width: 480px;
      height: 330px;
    }
    & .swiper-slide {
      width: 221px;
      display: flex;
      flex-direction: column;
      & .book-title {
        margin-top: 20px;
        font-size: 15px;
        font-weight: bold;
      }
      & .swiper-slide-img {
        width: 221px;
        & img {
          width: 130px;
          box-shadow: 0px 2.5px 5px #00000033;
          border-radius: 4px;
          cursor: pointer;
          opacity: 0.4;
        }
      }
      &.swiper-slide-active img {
        opacity: 1;
      }
      & .book-info-row {
        margin-top: 12px;
        font-size: 12.5px;
        display: flex;
        & :first-child {
          margin-left: auto;
        }
        & .divider {
          height: 8px;
          width: 1px;
          margin: auto 6px;
          background-color: #000000;
          opacity: 0.5;
        }
        & :last-child {
          margin-right: auto;
        }
      }
    }
  }
}
