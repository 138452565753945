.error-page {
  flex-direction: column;
  min-width: 1400px;
  & .error-page-wrapper {
    width: 100%;
    height: 620px;
    position: relative;
    & .error-page-container {
      width: 300px;
      height: 300px;
      position: absolute;
      left: 50%;
      top: 50%;
      margin-left: -150px;
      margin-top: -150px;
      & :nth-of-type(1) {
        opacity: 0.5;
        margin: 0 auto;
      }
      & :nth-of-type(2) {
        margin-top: 30px;
        font: normal normal bold 26px/40px Noto Sans KR;
        color: #1e76af;
      }
      & :nth-of-type(3) {
        margin-top: 8px;
        font: normal normal normal 13px/40px Noto Sans KR;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
  }
}
