#LikeListMain {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .myPage-wrapper {
    display: flex;
    padding-top: 80px;
    // flex-direction: column;
    @import "./SideMenu.scss";
  }
  & .mypage-container {
    // margin: 48px 260px 120px 370px;
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;

    & .title-container {
      display: flex;
      margin-bottom: 24px;
      // align-items: flex-end;
      & .title-text {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }

    & .event-tab-wrapper {
      margin-top: 40px;
      // height: 1200px;
      // width: 1400px;
      & .event-main-tab-container {
        // width: 1400px;
        height: 56px;
        background: #f3f8fb 0% 0% no-repeat padding-box;
        border-radius: 8px 8px 0px 0px;
        // opacity: 1;
      }
      & .event-main-tab-box {
        width: 175px;
        height: 56px;
        background: #ffffff00 0% 0% no-repeat padding-box;
        border-radius: 8px 8px 0px 0px;
        // opacity: 1;
        text-align: center;
        font: normal normal normal 16px/20px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
      }
      & .event-main-tab-active {
        width: 175px;
        height: 56px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 8px 8px 0px 0px;

        text-align: center;
        font: normal normal bold 16px/20px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
        // opacity: 1;
      }
      & .css-19kzrtu {
        padding: 0;
      }
      @import "./LikeList.scss";
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    height: auto;
    & .myPage-wrapper {
      padding-top: 20px;
      & .mypage-container {
        margin: 0 auto 30px;
        min-width: 320px;
        width: calc(100% - 40px);
        max-width: 440px;
        & .mobile-menu-wrapper {
          margin-bottom: 20px;
          & .mobile-title {
            width: 101px;
            height: 25.5px;
            text-align: left;
            font: normal normal bold 17.5px Noto Sans KR;
            color: #000000;
            padding: 0;
            margin-bottom: 20px;
          }
        }
        & .event-tab-wrapper {
          margin: 0;
          width: 100%;
          & .top-container {
            margin: 20px 0;
            & .title-text {
              margin: 0;
              margin-bottom: 4px;
              padding: 0;
              border-bottom: 0;
            }
            & .MuiInputBase-root {
              height: 24px;
              & .MuiSelect-select {
                height: 24px;
                padding: 0;
              }
            }
          }
          & .btn-container {
            max-height: 30px;
            & .title-bar-container {
              max-height: 30px;
              & .MuiButtonBase-root {
                width: 16px;
                height: 16px;
                margin-right: 12px;
                padding: 0;
                & .PrivateSwitchBase-input {
                  width: 16px;
                  height: 16px;
                }
                & .MuiSvgIcon-root {
                  width: 16px;
                  height: 16px;
                }
              }
              & .deleteBtn {
                max-width: 64px;
                max-height: 30px;
                & .LazyLoad {
                  max-width: 64px;
                  max-height: 30px;
                  & img {
                    width: 64px;
                    height: 30px;
                  }
                }
              }
            }
          }
          & .event-main-tab-container {
            background-color: #fff;
            border-bottom: 0;
            height: 36px;
            & .MuiTabs-root {
              min-height: 36px;
              & .MuiTabs-scroller {
                width: 100%;
                height: 36px;
                position: initial;
                & .MuiTabs-flexContainer {
                  width: 100%;
                  height: 36px;
                  min-width: 320px;
                  max-width: 440px;
                  & .MuiButtonBase-root {
                    width: 119.5px;
                    min-height: 36px;
                    padding: 8px 16px;
                    font-size: 14px;
                    border-radius: 20px;
                    border: 1px solid #1e76af;
                    margin-right: 8px;
                    & .MuiTouchRipple-root {
                      height: 36px;
                    }
                  }
                }
                & .MuiTabs-indicator {
                  background-color: #fff;
                  position: inherit;
                }
              }
            }
          }
          & .event-main-tab-active {
            width: 119.5px;
            height: auto;
            padding: 8px 16px;
            font-size: 14px;
            border-radius: 8px;
            border: 1px solid #1e76af;
          }
          & .event-main-tab-box {
            width: 119.5px;
            height: auto;
            padding: 8px 16px;
            font-size: 14px;
            border-radius: 8px;
            border: 1px solid #1e76af;
          }
          & .title-text {
            margin: 0;
            margin-top: 30px;
            padding-bottom: 20px;
            border-bottom: 1px solid #e8e8e8;
          }
          & .body-container {
            margin: 0;
            min-height: 15vh;
            & .mall-card {
              min-width: 320px;
              max-width: 440px;
              height: auto;
              border-bottom: 1px solid #e8e8e8;
              margin: 0;
              padding: 0;
              padding: 20px 0;
              background-color: #fff;
              & .check-card {
                display: flex;
                margin: 0;
                padding: 0;
                margin-right: 12px;
                width: 16px;
                height: 16px;
                & .MuiButtonBase-root {
                  width: 16px;
                  height: 16px;
                  margin-right: 12px;
                  padding: 0;
                  & .PrivateSwitchBase-input {
                    width: 16px;
                    height: 16px;
                  }
                  & .MuiSvgIcon-root {
                    width: 16px;
                    height: 16px;
                  }
                }
              }
              & .book-img {
                width: 80px;
                height: 115px;
                & .LazyLoad {
                  max-width: 80px;
                  max-height: 115px;
                  margin: 0;
                  padding: 0;
                  & img {
                    width: 80px;
                    height: 115px;
                    align-items: flex-start;
                  }
                }
              }
              & .mall-description-container {
                width: 100%;
                margin-left: 25px;
                & .mall-name-container {
                  width: 100%;
                  height: 46px;
                  display: flex;
                  justify-content: space-between;
                  & .mall-name {
                    display: flex;
                    width: 100%;
                    text-align: left;
                    font: normal normal bold 15px/24px Noto Sans KR;
                  }
                  & .icon-container {
                    display: flex;
                    & .cart-icon {
                      margin: 0;
                      margin-right: 16px;
                    }
                    & .MuiSvgIcon-root {
                      align-items: flex-end;
                    }
                  }
                }
                & .book-info {
                  margin-top: 12px;
                  & .mall-author {
                    text-align: left;
                    font: normal normal normal 13px/12px Noto Sans KR;
                  }
                }
                & .book-price-container {
                  display: flex;
                  margin-top: 12px;
                  & .mobile-discount {
                    min-width: 31.5px;
                    min-height: 20px;
                    text-align: left;
                    font: normal normal bold 11px Noto Sans KR;
                    color: #ffffff;
                    background-color: #1e76af;
                    padding: 2px 4px;
                    border-radius: 4px;
                    text-align: center;
                    margin-right: 12px;
                  }
                  & .book-price {
                    min-width: 69px;
                    min-height: 20px;
                    font: normal normal bold 14px Noto Sans KR;
                    margin-right: 12px;
                  }
                  & .mobile-price {
                    min-height: 15.5px;
                    text-align: center;
                    text-decoration: line-through;
                    font: normal normal normal 12px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #777777;
                    opacity: 1;
                  }
                }
              }
              & .mall-img {
                width: 100px;
                height: 100px;
                & .LazyLoad {
                  max-width: 100px;
                  max-height: 100px;
                  margin: 0;
                  padding: 0;
                  & img {
                    width: 100px;
                    height: 100px;
                    align-items: flex-start;
                  }
                }
              }
              & .desc-container {
                min-width: 168px;
                min-height: 130px;
                margin-left: 16px;
                & .mall-name-container {
                  width: 100%;
                  min-height: 22px;
                  max-height: 44px;
                  margin-bottom: 12px;
                  & .mall-name-box {
                    width: 100%;
                    min-height: 22px;
                    max-height: 44px;
                    & .mall-name {
                      font-size: 12px;
                      min-height: 22px;
                      max-height: 44px;
                    }
                    & .mall-visit-container {
                      font-size: 11px;
                      min-height: 22px;
                      max-height: 44px;
                      & .vl {
                        margin: auto 4px;
                      }
                    }
                  }
                }
                & .mall-description {
                  width: 100%;
                  height: 23px;
                  margin-bottom: 7px;
                  font-size: 12px;
                }
                & .location-container {
                  width: 100%;
                  height: 22px;
                  font: normal normal normal 12px Noto Sans KR;
                  & .location-icon {
                    margin: 2px 4px 0;
                  }
                }
                & .hashtag-container {
                  margin-top: 7px;
                  flex-wrap: wrap;
                  & .hashtag {
                    padding: 2px 4px;
                    margin: 0;
                    margin-right: 6px;
                    border: 1px solid #cccccc;
                    min-width: 39px;
                    height: 23px;
                    line-height: none;
                    font-size: 11px;
                  }
                }
              }
            }
          }
          & .pagination-container {
            margin: 20px 0 0;
          }
        }
      }
    }
  }
}
