& .recommended-book-a-container {
  background-color: #f5f5f5;
  display: flex;
  margin-top: 80px;
  & .recommended-book-a {
    width: 1400px;
    margin: 80px auto 0;
    display: flex;
    flex-direction: column;
  }

  @media screen and (max-width: 1400px) {
    width: 100vw;
    margin-top: 50px;
    & .recommended-book-a {
      width: calc(100vw - 40px) !important;
      min-width: 320px !important;
      max-width: 440px !important;
      margin-top: 20px !important;
      margin: auto;
      & .title-container {
        width: calc(100vw - 40px) !important;
        min-width: 320px !important;
        max-width: 440px !important;
        & .title {
          font-size: 17.5px;
        }
      }
    }
  }
}

& .recommended-book-b {
  margin: 80px auto 0;
  width: 1400px;
  & .swiper-pagination {
    display: none;
  }
  & .next-button,
  & .prev-button {
    margin-top: -240px !important;
  }

  & .six-book-swiper-presenter {
    height: 430px !important;
  }

  @media screen and (max-width: 1400px) {
    width: 100vw;
    margin-top: 50px;

    & .title-container {
      width: calc(100vw - 40px) !important;
      min-width: 320px !important;
      max-width: 440px !important;
      margin: auto;
      & .title {
        font-size: 17.5px;
      }
    }

    & .swiper-pagination {
      display: inline;
      top: 0;
      bottom: 0;
    }
  }
}

& .recommended-book-c {
  margin: 80px auto 0;
  & .title-container {
    width: 1400px;
    margin: 0 auto;
  }
  & .swiper-auto {
    margin-top: 24px;
    height: 600px;
    & .swiper-slide {
      margin-top: 8px;
      width: fit-content;
      height: 528px;
      box-shadow: 0px 5px 20px #0000001a;
      border-radius: 16px;
      display: flex;
      padding: 0 100px 0 160px;
      & .book-img {
        width: 260px;
        margin: auto 0;
        cursor: pointer;
        & img {
          width: 260px;
          height: 400px;
          box-shadow: 0px 5px 10px #00000029;
          border-radius: 8px;
        }
      }
      & #book-info-container {
        margin-top: 100px;
        margin-left: 140px;
        & button {
          width: 113px;
          height: 22px;
        }
      }
    }
    & .swiper-pagination {
      & .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        &.swiper-pagination-bullet-active {
          width: 20px;
          border-radius: 10px;
          background-color: black;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    width: 100vw;
    margin-top: 50px;

    & .title-container {
      width: calc(100vw - 40px) !important;
      min-width: 320px !important;
      max-width: 440px !important;
      margin: auto;
      & .title {
        font-size: 17.5px;
      }
    }

    & .swiper-auto {
      width: 100vw !important;
      min-width: 360px !important;
      max-width: 480px !important;
      margin: auto;
      margin-top: 12px;
      & .swiper-slide {
        width: calc(100vw - 80px) !important;
        min-width: 280px !important;
        max-width: 400px !important;
        height: 500px;
        padding: 20px;
        display: inline;
        & .book-img {
          width: 140px;
          margin: 0 auto 20px;
          & img {
            width: 140px;
            height: auto;
            box-shadow: 0px 5px 10px #00000029;
            border-radius: 8px;
          }
        }
        & #book-info-container {
          width: calc(100vw - 80px) !important;
          min-width: 280px !important;
          max-width: 400px !important;
          margin: 0;
          height: 265px;
        }
      }
    }
  }
}

& .recommended-book-d {
  margin: 80px auto 0;
  width: 1400px;
  & .curation-container {
    margin-top: 32px;
    display: flex;
    & .curation-card {
      padding: 30px;
      width: 450px;
      height: 605px;
      margin-right: 25px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 16px;
      cursor: pointer;
      & .book-card {
        width: 390px;
        height: 390px;
        border-radius: 12px;
        background-color: white;
        display: flex;
        flex-direction: column;
        & .number-tag {
          width: 32px;
          height: 48px;
          background-color: #b7916b;
          color: white;
          font-weight: bold;
          padding: 12px 11px;
          border-radius: 0 0 4px 4px;
          margin-left: 16px;
        }
        & .book-img {
          width: 160px;
          margin: 0 auto;
          & img {
            width: 160px;
            height: 240px;
            box-shadow: 0px 5px 10px #00000029;
            border-radius: 8px;
          }
        }
        & .title {
          margin-top: 24px;
          font-weight: bold;
          font-size: 16px;
        }
        & .info-container {
          margin: 8px auto;
          display: flex;
          width: fit-content;
          color: #777777;
          font-size: 12px;
          & div {
            height: 8px;
            width: 1px;
            border-left: 1px solid #777777;
            margin: auto 8px;
          }
        }
      }
      & .comment-title {
        margin-top: 30px;
        color: #b7916b;
        font-size: 13px;
      }
      & .comment {
        width: 338px;
        margin: 20px auto 0;
        font-size: 14px;
        line-height: 28px;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 3;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    width: 100vw !important;
    min-width: 360px !important;
    max-width: 480px !important;
    margin-top: 50px;

    & .title-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      margin: auto;
      & .title {
        font-size: 17.5px;
      }
    }

    & .curation-container {
      margin-top: 20px;
      & .swiper-auto {
        width: 100vw !important;
        min-width: 360px !important;
        max-width: 480px !important;
        margin-top: 0;
        & .swiper-wrapper {
          height: 600px;
          & .swiper-slide {
            & .curation-card {
              width: calc(100vw - 80px);
              min-width: 280px;
              max-width: 400px;
              margin: auto;
              height: auto;
              padding: 20px;
              & .book-card {
                width: calc(100vw - 80px);
                min-width: 280px;
                max-width: 400px;
                & .number-tag {
                  width: 10px;
                  height: 25px;
                }
                & .title {
                  font-size: 15px;
                  margin-left: 10px;
                  margin-right: 10px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
              }
              & .comment-title {
                margin-top: 20px;
              }
              & .comment {
                width: calc(100vw - 80px);
                min-width: 280px;
                max-width: 400px;
                height: 75px;
                margin-top: 15px;
                font-size: 12px;
                line-height: 25px;
              }
            }
          }
        }
      }
    }

    & .swiper-pagination {
      & .swiper-pagination-bullet {
        width: 8px;
        height: 8px;
        &.swiper-pagination-bullet-active {
          width: 20px;
          border-radius: 10px;
          background-color: black;
        }
      }
    }
  }
}

& .recommended-book-e {
  margin-top: 40px;
  @media screen and (max-width: 1400px) {
    min-width: 320px;
    width: calc(100vw - 40px);
    max-width: 440px;
    margin: 0 auto;
  }
  & .title-container {
    margin-bottom: 40px;
  }
  & .rcm-book-container {
    display: flex;
    flex-wrap: wrap;
    width: 1400px;
    margin: 20px auto;
    @media screen and (max-width: 1400px) {
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
      margin: 0 auto;
    }
    & .swiper-slide {
      display: flex;
      flex-direction: column;
      width: 200px;
      margin-right: 40px;
      margin-bottom: 40px;
      cursor: pointer;
      &:nth-of-type(6n) {
        margin-right: 0;
        @media screen and (max-width: 1400px) {
          margin: 20px auto;
        }
      }
      @media screen and (max-width: 1400px) {
        margin: 20px auto;
      }
      cursor: pointer;
      & .book-image {
        min-height: 200px;
        cursor: pointer;
        & img {
          width: 200px;
          height: auto !important;
          border-radius: 10px;
          box-shadow: 0px 5px 10px #00000029;
        }
      }
      & .book-title {
        margin-top: 16px;
        font-size: 16px;
        font-weight: bold;
        word-break: break-all;
      }
      & .book-author-publisher {
        width: 200px;
        margin-top: 8px;
        font-size: 12px;
        color: #777777;
      }
      & .price-container {
        display: flex;
        width: auto;
        margin-top: 20px;
        & .sale-percentage {
          margin: auto;
          margin-right: 0;
          width: 26px;
          height: 17px;
          // padding: 4px 8px;
          color: white;
          border-radius: 4px;
          background-color: #1e76af;
          font-size: 12px;
        }
        & .price {
          margin-left: 12px;
          font-size: 20px;
          font-weight: bold;
        }
        & .price-unit {
          font-size: 14px;
          margin: auto auto 3px 2px;
        }
      }
    }
  }
  & .pagination-container {
    width: 1400px;
    @media screen and (max-width: 1400px) {
      width: auto;
    }
    margin: 20px auto;
    display: flex;
    & .pagination {
      margin: auto;
    }
  }
}
