& .event-another-wrapper {
  width: 1400px;
  margin: 46px auto;

  & .event-another-wrapper-title {
    margin: auto;
    min-width: 211px;
    height: 29px;
    /* UI Properties */
    color: var(---000000-text-);
    text-align: center;
    font: normal normal bold 20px/28px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 25px;
  }
  & .event-another-wrapper-container {
    display: flex;
    justify-content: space-between;
    margin-bottom: 120px;
    & .event-another-wrapper-container-box {
      width: 452px;
      height: 403px;
      cursor: pointer;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #e8e8e8;
      border-radius: 16px;
      opacity: 1;
      & .event-image {
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 16px 16px 0px 0px;
        opacity: 1;
        & img {
          border-radius: 16px 16px 0px 0px;
          object-fit: cover;
          height: 240px;
        }
        @media screen and (max-width: 1400px) {
          min-height: calc(318px * 240 / 450);
          height: calc((100vw - 42px) * 240 / 450);
          max-height: calc(438px * 240 / 450);
          & img {
            min-height: calc(318px * 240 / 450);
            height: calc((100vw - 42px) * 240 / 450);
            max-height: calc(438px * 240 / 450);
          }
        }
      }
      & .event-tag {
        margin-top: 24px;
        color: var(---000000-text-);
        text-align: center;
        text-decoration: underline;
        font: normal normal medium 14px/20px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .event-title {
        margin-top: 16px;
        color: var(---000000-text-);
        text-align: center;
        font: normal normal bold 24px/35px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .event-contents {
        margin-top: 12px;
        color: var(---000000-text-);
        text-align: center;
        font: normal normal normal 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
  }
}
