& .partner-wrapper {
  height: auto;
  padding-top: 40px;
  padding-bottom: 120px;
  & .partner-container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .title-container.no-search-result {
      font-size: 24px;
      font-weight: bold;
      margin: auto;
      text-align: center;
      @media screen and (max-width: 1400px) {
        font-size: 17.5px;
      }
    }
  }
  // @media screen and (max-width: 1400px) {
  //   padding-top: 0px;
  //   min-width: 360px;
  //   max-width: 480px;
  //   margin: 0 auto;
  // }
}
