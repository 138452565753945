#ShippingAddress {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .myPage-wrapper {
    display: flex;
    padding-top: 80px;
    // flex-direction: column;
    @import "./SideMenu.scss";
  }
  & .mypage-container {
    // margin: 48px 260px 120px 370px;
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;

    & .title-container {
      display: flex;
      // margin-bottom: 24px;
      // align-items: flex-end;
      & .title-text {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
    & .myPage-body {
      margin-top: 40px;

      & .empty-address {
        width: 100%;
        height: 250px;
        border-top: 1px solid #f5f5f5;
        border-bottom: 1px solid #f5f5f5;
        font-size: 16px;
        font-weight: normal;
        color: #000000;
        letter-spacing: 0px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      // overflow: hidden;
      // height: auto;

      // .ag-ltr .ag-body-horizontal-scroll {
      //   display: none;
      // }
      // .ag-center-cols-viewport {
      //   height:
      // }

      .ag-body-viewport.ag-layout-normal {
        overflow-y: overlay;
        // -webkit-overflow-scrolling: touch;
      }

      & .ag-theme-alpine {
        --ag-borders: none;
        --ag-borders-row: 2px solid;
        --ag-row-border-color: #f5f5f5;
        --ag-odd-row-background-color: #ffffff;
        --ag-fontsize: 15px;
        --ag-row-height: 100px;

        & .noData {
          & .ag-pivot-off {
            display: none;
          }
        }

        & .ag-header-viewport {
          background-color: #ffffff;
          border-top: 2px solid #f5f5f5;
          border-bottom: 2px solid #f5f5f5;
          & .ag-header-cell-label {
            // justify-content: center;
            font: normal normal medium 14px/20px Noto Sans KR;
          }
          & .ag-header-cell-resize::after {
            width: 0;
          }
          & .ag-header-cell {
            padding: 0;
          }
        }
        & .ag-body-viewport {
          // border-bottom: 2px solid #f5f5f5;
          text-align: left;
          & .ag-cell {
            padding: 20px 0;
            // display: flex;
            // align-items: center;
          }
        }

        & .noRowStyle {
          text-align: center;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
        }

        // & .ag-pivot-off {
        //   height: 70px;
        // }
      }

      & .btn-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        // align-items: flex-end;
        margin: 10px 0;
        & .mod-btn {
          width: 88px;
          height: 36px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #1e76af;
          border-radius: 4px;
          text-align: center;
          font: normal normal normal 13px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
        }
        & .del-btn {
          width: 88px;
          height: 36px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 4px;
          text-align: center;
          font: normal normal normal 13px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          margin-top: 8px;
        }
      }
      & .addressBtn-container {
        display: flex;
        margin-top: 50px;
        justify-content: space-between;
        & .main-addAdress-btn {
          width: 221px;
          height: 56px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #1e76af;
          border-radius: 8px;
          text-align: center;
          font: normal normal normal 16px/24px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
        }
        & .addAdress-btn {
          width: 180px;
          height: 56px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 8px;
          text-align: left;
          font: normal normal normal 16px/24px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }
      & .empty-addressBtn-container {
        display: flex;
        margin-top: 50px;
        justify-content: flex-end;
        & .addAdress-btn {
          width: 180px;
          height: 56px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 8px;
          text-align: left;
          font: normal normal normal 16px/24px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
        }
      }

      & .mainAdressTag {
        width: 76px;
        height: 24px;
        background: #e8e8e8 0% 0% no-repeat padding-box;
        border-radius: 4px;
        text-align: center;
        font: normal normal normal 13px/28px Noto Sans KR;
        line-height: 24px;
        letter-spacing: 0px;
        color: #777777;
      }
      & .mainAddress {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 96px;
        text-align: left;
        font: normal normal normal 15px/32px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        // height: 53px;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;

    & .myPage-wrapper {
      width: 100%;
      padding-top: 20px;
      & .mypage-container {
        width: 100%;
        min-width: 360px;
        max-width: 480px !important;
        margin: 0 auto 100px auto;
        display: flex;
        & .mobile-menu-wrapper {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          height: 25px;
          margin: 0 20px 20px;
        }
        & .myPage-body {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          margin: 0 20px;
          padding: 0;
          border-top: 1px solid #bababa;
          & .emptyShip {
            width: 100%;
            height: 150px;
            border-top: 1px solid #f5f5f5;
            border-bottom: 1px solid #f5f5f5;
            font-size: 13px;
            font-weight: normal;
            color: #000000;
            letter-spacing: 0px;
            display: flex;
            justify-content: center;
            align-items: center;
          }

          & .card {
            padding: 20px 0px 20px;
            border-bottom: 1px solid #e8e8e8;
            display: flex;
            & .radio-checked {
              padding: 0 9px 9px 0;
              display: flex;
              align-items: flex-start;
            }
            & .right-side {
              width: 100%;
              & .name-container {
                display: flex;
                justify-content: space-between;
                margin-top: 2px;
                margin-bottom: 8px;
                & .name-left {
                  display: flex;
                  // align-items: center;
                  & .ship_name {
                    text-align: left;
                    font-size: 13px;
                    font-weight: bold;
                    letter-spacing: 0px;
                    color: #000000;
                  }
                  & .main-adress-tag {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    width: 64px;
                    height: 20px;
                    background: #777777 0% 0% no-repeat padding-box;
                    border-radius: 2px;
                    font-size: 11px;
                    font-weight: normal;
                    letter-spacing: 0px;
                    color: #ffffff;
                    margin-left: 12px;
                  }
                }
                & .btn-container {
                  display: flex;
                  flex-direction: row;
                  margin: 0;
                  & .mod-btn {
                    width: 44px;
                    height: 24px;
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #bababa;
                    border-radius: 2px;
                    text-align: left;
                    font-size: 11px;
                    font-weight: normal;
                    letter-spacing: 0px;
                    color: #777777;
                  }
                  & .del-btn {
                    width: 44px !important;
                    height: 24px;
                    background: #ffffff 0% 0% no-repeat padding-box;
                    border: 1px solid #bababa;
                    border-radius: 2px;
                    text-align: left;
                    font-size: 11px;
                    font-weight: normal;
                    letter-spacing: 0px;
                    color: #777777;
                    margin: 0 0 0 8px;

                    & .MuiTouchRipple-root {
                      width: 44px !important;
                    }
                  }
                }
              }
              & .name-contact {
                text-align: left;
                font-size: 13px;
                font-weight: normal;
                letter-spacing: 0px;
                margin-bottom: 10px;
              }
            }
            & .address {
              text-align: left;
              font-size: 13px;
              font-weight: normal;
              letter-spacing: 0px;
              color: #000000;
            }
          }
        }
        & .addressBtn-container {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          justify-content: space-between;
          margin: 0 auto;
          margin-top: 30px;
          display: flex;
          & .main-addAdress-btn {
            width: 80%;
            min-width: 155px;
            max-width: 215px;
            height: 48px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #1e76af;
            border-radius: 8px;
            text-align: center;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #1e76af;
          }
          & .btn-sapce {
            width: 5%;
          }
          & .addAdress-btn {
            width: 80%;
            min-width: 155px;
            max-width: 215px;
            height: 48px;
            background: #1e76af 0% 0% no-repeat padding-box;
            border-radius: 8px;
            text-align: center;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #ffffff;
          }
          & .empty-addAdress-btn {
            width: 100%;
            height: 48px;
            background: #1e76af 0% 0% no-repeat padding-box;
            border-radius: 8px;
            text-align: center;
            font-size: 14px;
            font-weight: normal;
            letter-spacing: 0px;
            color: #ffffff;
          }
        }
      }
    }
  }
}
