& .field-icon-wrapper {
  display: flex;
  // height: 300px;
  height: auto;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  & .icon-container {
    margin: auto;
    & .btn-icon-container {
      display: flex;
      width: 1400px;
      height: auto;
      flex-wrap: wrap;
      margin: 48px auto 26px;
      & .btn-icon-div {
        flex-basis: 8.33%;
        margin-bottom: 26px;
        & .btn-icon {
          border-radius: 8px;
          width: 70px;
          height: 60px;
        }
        & .btn-name {
          margin-top: 11px;
          font: normal normal bold 13px/20px Noto Sans KR;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    margin: 0 auto;
    height: auto;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    & .icon-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      margin-top: 24px;
      margin-bottom: 24px;
      & .btn-icon-container {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        margin: 0 auto;
        height: auto;
        & .btn-icon-div {
          flex-basis: 25%;
          margin-top: 16px;
          & .btn-icon {
            border-radius: 8px;
          }
          & .btn-name {
            margin-top: 16px;
            font: normal normal bold 13px/20px Noto Sans KR;
          }
        }
      }
    }
  }
}
