$primaryColor: #1e76af;

#Payment {
  display: flex;
  flex-direction: column;
  & .payment-main-container {
    padding: 84px 0 80px 0;
    margin: 0 auto;
    width: 1400px;
    display: flex;
    @media screen and (max-width: 1400px) {
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
      padding: 20px 0;
      flex-direction: column;
    }

    .title {
      font-size: 24px;
      color: #000;
      font-weight: 600;
      line-height: 1.2;
      padding: 0 0 37px 0;
      border-bottom: 1px #bababa solid;
      @media screen and (max-width: 1400px) {
        font-size: 17.5px;
        padding: 0 0 20px 0;
      }
    }

    & * {
      text-align: initial;
    }

    & .payment-order-info {
      width: 995px;
      margin-right: auto;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        & .MuiList-root {
          padding: 0;
          background-color: #f5f5f5;
          border-radius: 8px;
        }
        & .MuiListItem-root {
          padding-left: 0;
          padding-right: 0;
          margin-left: 20px;
          margin-right: 20px;
          min-width: 280px;
          width: calc(100vw - 80px);
          max-width: 400px;
          &:last-of-type {
            border-bottom: none;
          }
        }
        & .title-list-item {
          padding: 24px 20px;
          margin: 0;
          height: 72px;
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          border-bottom: 1px solid rgba(0, 0, 0, 0.12);
          & span {
            color: #1e76af;
            font-weight: bold;
          }
          & .btn-open {
            margin-left: auto;
            height: 24px;
            cursor: pointer;
          }
        }
      }
      & .shop-selection {
        & .title {
          font-size: 20px;
          color: #000;
          font-weight: 600;
          line-height: 1.2;
          padding: 0 0 18px 0;
          margin: 64px 0 0 0;
          border-bottom: none;
          @media screen and (max-width: 1400px) {
            padding: 0 0 20px 0;
            margin: 32px 0 0 0;
            font-size: 15px;
          }
        }
        & .cheers {
          width: 280px;
          background-color: #1e76af;
          color: white;
          margin: auto 0 auto auto;
          font-size: 17px;
          font-weight: bold;
          text-align: center;
          height: 48px;
          border-radius: 8px;
          @media screen and (max-width: 1400px) {
            width: 100%;
            margin-top: 20px;
          }
        }
        & .after-selection {
          height: 120px;
          padding: 20px;
          background-color: #f5f5f5;
          display: flex;
          border-radius: 8px;
          @media screen and (max-width: 1400px) {
            flex-wrap: wrap;
            height: auto;
          }
          & .mall-img img {
            border-radius: 8px;
          }
          & .shop-info {
            display: flex;
            flex-direction: column;
            margin: auto 20px;
            @media screen and (max-width: 1400px) {
              margin: 0;
              padding: 0 0 0 12px;
              min-width: 200px;
              width: calc(100vw - 160px);
              max-width: 320px;
            }
            & .shop-title {
              font-size: 18px;
              font-weight: bold;
              text-align: left;
            }
            & .location-container {
              display: flex;
              margin-top: 12px;
              font-size: 14px;
              text-align: left;
              & .location-icon {
                margin-right: 8px;
                margin-top: 3px;
              }
            }
          }
        }
        & .shop-selection-container {
          display: flex;
          background-color: #f3f8fc;
          border-radius: 8px;
          padding: 30px;
          @media screen and (max-width: 1400px) {
            flex-direction: column;
            padding: 20px;
          }
          & .smiley {
            margin-top: auto;
            margin-bottom: auto;
            @media screen and (max-width: 1400px) {
              margin: auto;
            }
          }
          & .text-area {
            display: flex;
            flex-direction: column;
            margin-left: 16px;
            @media screen and (max-width: 1400px) {
              margin: 10px auto 0;
            }
            & .main {
              color: #2375a7;
              font-size: 20px;
              font-weight: bold;
              line-height: 48px;
              @media screen and (max-width: 1400px) {
                font-size: 15px;
                text-align: center;
              }
            }
            & .sub {
              color: #777;
              font-size: 14px;
              margin-top: 12px;
              @media screen and (max-width: 1400px) {
                font-size: 12px;
                text-align: center;
              }
            }
          }
        }
      }

      & .sHTiv {
        font-size: 20px;
        color: #000;
        font-weight: 600;
        line-height: 1.2;

        padding: 0 0 18px 0;
        margin: 64px 0 0 0;
        border-bottom: 1px #bababa solid;
        @media screen and (max-width: 1400px) {
          font-size: 15px;
          padding: 0 0 20px 0;
          margin: 32px 0 0 0;
          border-bottom: none;
        }
      }

      & .oBdx {
        display: flex;
        width: 100%;
        padding: 20px 0;
        align-items: center;
        justify-content: space-between;
        @media screen and (max-width: 1400px) {
          align-items: unset;
          justify-content: unset;
        }

        .imgArea {
          & img {
            margin-left: 10px;
            width: 80px;
            border-radius: 8px;
            box-shadow: 0px 5px 10px rgb(0 0 0 / 29%);
            cursor: pointer;
            @media screen and (max-width: 1400px) {
              margin-left: 0;
            }
          }
        }

        a {
          outline: 0;
          color: #a7a7a7;
          text-decoration: none;
        }
        & .mobile-item-container {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-left: 20px;
        }
        .txtArea {
          width: 400px;
          margin: 0 0 0 32px;
          display: flex;
          flex-direction: column;
          @media screen and (max-width: 1400px) {
            width: fit-content;
            margin: 0;
          }
          & .shop-banner-title {
            color: #1e76af;
            border: 1px solid #1e76af;
            width: fit-content;
            border-radius: 4px;
            font-size: 14px;
            margin-bottom: 16px;
            padding: 2px 8px;
            cursor: pointer;
            @media screen and (max-width: 1400px) {
              font-size: 11px;
              margin-bottom: 8px;
            }
            &.modoo {
              color: #777777;
              border: 1px solid #bababa;
            }
          }
          .ctt {
            font-size: 20px;
            color: #000;
            line-height: 1.2;
            font-weight: 600;
            @media screen and (max-width: 1400px) {
              font-size: 15px;
            }
          }
        }

        .pnum {
          width: 100px;
          padding: 0 10px;
          font-size: 16px;
          color: #000;
          line-height: 1.2;
          text-align: end;
          @media screen and (max-width: 1400px) {
            margin-top: 12px;
            text-align: left;
            padding: 0;
            font-size: 12.5px;
          }
        }

        .pdeli {
          font-size: 16px;
          color: #000;
          line-height: 1.2;
          padding: 0 10px;
        }
        & .price-wrapper {
          display: flex;
          flex-direction: column;
          width: 240px;
        }

        & .amount {
          font-size: 14px;
          margin-top: 6px;
          text-align: right;
          @media screen and (max-width: 1400px) {
            font-size: 12px;
            margin-top: 0;
          }
        }
        & .price {
          padding: 0;
          display: flex;
          justify-content: end;
          @media screen and (max-width: 1400px) {
            display: flex;
            height: 22px;
            margin-top: 12px;
            padding: 0;
          }
          & * {
            vertical-align: middle;
          }
          & > div {
            display: inline-block;
          }

          & .dc {
            // padding: 4px 8px;
            height: fit-content;
            margin: 0 12px 0 0;
            width: 42px;
            height: 28px;
            display: flex;
            & span {
              margin: auto;
            }
            background: $primaryColor;
            border-radius: 4px;
            font-size: 12px;
            color: #fff;
            font-weight: 600;
            @media screen and (max-width: 1400px) {
              font-size: 11px;
              // padding: 2px 4px;
              width: 34px;
              height: 24px;
            }
          }
          & .totalWon {
            font-size: 20px;
            font-weight: 600;
            line-height: 1.2;
            // min-width: 96px;
            // padding: 0 12px 0 0;
            @media screen and (max-width: 1400px) {
              font-size: 14px;
              margin-top: 2px;
              min-width: 0;
              // margin-left: auto;
              & span {
                font-size: 12px;
                margin-top: -1px;
              }
            }
          }
          & .preWon {
            font-size: 16px;
            color: #777;
            line-height: 28px;
            // min-width: 64px;
            margin-left: 12px;
            text-decoration: line-through;
            @media screen and (max-width: 1400px) {
              font-size: 12px;
              margin-top: 2px;
              // margin-left: auto;
              min-width: 0;
              line-height: unset;
            }
          }
        }
      }
    }
    & .perchase {
      width: 350px;
      position: sticky;
      height: fit-content;
      top: 80px;
    }

    /* 배송지정보 */
    @import "./ShippingAddressInfo.scss";

    /**/
    @import "./AddVoucher.scss";
    /**/
    @import "./PaymentMethod.scss";

    /* order steps container*/
    @import "../CartView/Purchase.scss";

    .purchase-steps-wrapper {
      width: 350px;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
      }
      .ppta {
        // position: relative;
        width: 100%;
        padding: 10px 0 0 0;
        margin: 0 0 36px 0;
        overflow: hidden;
        & .checkbox-tooltip-text {
          -webkit-user-select: none;
          -moz-user-select: none;
          -ms-user-select: none;
          user-select: none;
          line-height: 38px;
        }
        & .fmChk + .fmChk-i {
          font-size: 13px;
          line-height: 21px;
          font-weight: 500;
          cursor: pointer;
        }
        & .agree-wrapper {
          & .fmChk + .fmChk-i {
            font-size: 13px;
            line-height: 21px;
            font-weight: 500;
            -webkit-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
            // padding-left: 28px;
          }

          & .agOpen {
            vertical-align: middle;
            width: 20px;
            height: 20px;
            background: url(../../imgs/btn_agree_down_arrow.png) left top
              no-repeat;
          }
        }

        & .agBx {
          font-size: 11px;

          & li {
            margin: 0;
            font-size: 13px;
            color: #777;
            line-height: 1.2;
            padding: 16px 0 0 38px;
            & a {
              color: #777;
              text-decoration: underline;
            }
          }
        }
      }
    }
  }
}
