#detail-book-recommendation-container {
  display: flex;
  flex-direction: column;
  padding-bottom: 80px;
  background-color: white;
  & .recommend-title-container {
    display: flex;
    margin-top: 80px;
    padding-bottom: 32px;
    border-bottom: 1px solid #e8e8e8;
    & .recommend-title {
      font-size: 24px;
      font-weight: bold;
    }
    & .recommend-comment {
      width: 960px;
      font-size: 18px;
      line-height: 30px;
      margin-left: auto;
      text-align: left;
      white-space: pre-wrap;
    }
  }
  & .recommendation-card {
    display: flex;
    padding: 24px 0;
    border-bottom: 1px solid #e8e8e8;
    & .left {
      display: flex;
      width: 692px;
      height: 400px;
      background: #f8f8f8 0% 0% no-repeat padding-box;
      border-radius: 8px;
      & .img {
        width: 200px;
        margin: auto;
        & img {
          width: 200px;
          box-shadow: 0px 5px 10px #00000029;
          border-radius: 8px;
          cursor: pointer;
        }
      }
    }
    & #book-info-container {
      margin-left: 40px;
      margin-top: 40px;
    }
  }
}
