& .event-banner-wrapper {
  height: 430px;
  padding-top: 80px;
  & .event-banner-container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .title-container {
      display: flex;
      & .title-text {
        font-size: 24px;
        font-weight: bold;
      }
      & .more-button {
        margin-left: auto;
        font-size: 14px;
        color: black;
      }
    }
    & .event-banner-body {
      display: flex;
      margin-top: 32px;
      & .event-banner {
        cursor: pointer;
        width: 692px;
        height: 200px;
        border-radius: 16px;
        overflow: hidden;
        & img {
          border-radius: 16px;
          width: 692px;
          height: 200px;
          object-fit: cover;
        }
        &:first-of-type {
          margin-right: auto;
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    max-width: 480px;
    height: auto;
    padding: 50px 0 0;
    margin: 0 auto;
    & .event-swiper {
      margin-top: 20px;
      min-width: 360px;
      width: 100vw;
      max-width: 480px;
      min-height: calc(320px * 230 / 670 + 40px);
      height: calc((100vw - 40px) * 230 / 670 + 40px);
      max-height: calc(440px * 230 / 670 + 40px);
      & .swiper-slide {
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        margin: 0 20px;
        cursor: pointer;
        & img {
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          min-height: calc(320px * 230 / 670);
          height: calc((100vw - 40px) * 230 / 670);
          max-height: calc(440px * 230 / 670);
          border-radius: 8px;
        }
      }
    }
  }
}
