& .myPage-orderHist-wrapper {
  padding-top: 71px;
  & .myPage-orderHist-container {
    max-width: 1164px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .myPage-orderHist-title-container {
      display: flex;
      align-items: flex-end;
      & .title-text {
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
      }
      & .title-sub {
        height: 20px;
        color: var(---777777-sub-text-);
        text-align: left;
        font: normal normal normal 14px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        padding-left: 16px;
      }
      & .more-button {
        margin-left: auto;
        font-size: 14px;
        color: black;
      }
    }
    & .myPage-orderHist-info-container {
      margin-top: 32px;
      display: flex;
      & .myPage-orderHist-info {
        width: -webkit-fill-available;
        height: 67px;
        border: 1px solid var(---bababa-line_darker-);
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #bababa;
        border-radius: 8px;
        opacity: 1;
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        & .histTitle {
          color: var(---000000-text-);
          text-align: left;
          font: normal normal normal 14px/20px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        & .histContent {
          color: var(---000000-text-);
          text-align: left;
          font: normal normal bold 24px/35px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        & .histEmpty {
          color: var(---bababa-line_darker-);
          text-align: left;
          font: normal normal bold 24px/35px Noto Sans KR;
          letter-spacing: 0px;
          color: #bababa;
          opacity: 1;
        }
        & .vl:not(:last-of-type) {
          border-left: 1px solid#BABABA;
          width: 0;
          height: 20px;
          margin: auto 0;
        }
      }
    }
  }
}
// @media screen and (max-width: 1400px) {
//   & .myPage-orderHist-wrapper {
//     padding-top: 71px;
//     & .myPage-orderHist-container {
//       max-width: 1164px;
//       margin: auto;
//       display: flex;
//       flex-direction: column;
//       & .myPage-orderHist-title-container {
//         display: flex;
//         align-items: flex-end;
//         & .title-text {
//           text-align: left;
//           font-size: 15px;
//           font-weight: bold;
//           letter-spacing: 0px;
//           color: #000000;
//         }
//       }
//       & .moblie-orderHist {
//         margin-top: 20px;
//         width: 100%;
//         height: 162px;
//         background: #ffffff 0% 0% no-repeat padding-box;
//         border-radius: 8px;
//         border: 1px solid #bababa;
//         display: flex;
//         flex-wrap: wrap;
//         align-content: stretch;
//         padding: 16px;
//         & .item:nth-child(1) {
//           border-bottom: 1px solid #e8e8e8;
//           border-right: 1px solid #e8e8e8;
//           padding-bottom: 16px;
//         }
//         & .item:nth-child(2) {
//           border-bottom: 1px solid #e8e8e8;
//           border-right: 1px solid #e8e8e8;
//           padding-bottom: 16px;
//         }
//         & .item:nth-child(3) {
//           border-bottom: 1px solid #e8e8e8;
//           padding-bottom: 16px;
//         }
//         & .item:nth-child(4) {
//           border-right: 1px solid #e8e8e8;
//           padding-top: 16px;
//         }
//         & .item:nth-child(5) {
//           border-right: 1px solid #e8e8e8;
//           padding-top: 16px;
//         }
//         & .item:nth-child(6) {
//           padding-top: 16px;
//         }
//         & .item {
//           width: 33.33%;
//           display: flex;
//           flex-direction: column;
//           align-items: center;
//           justify-content: space-between;
//           // border: 1px solid #e8e8e8;
//           & .histTitle {
//             text-align: left;
//             font-size: 12px;
//             font-weight: bold;
//             letter-spacing: 0px;
//             color: #000000;
//             opacity: 1;
//           }
//           & .histContent {
//             text-align: left;
//             font-size: 17px;
//             font-weight: bold;
//             letter-spacing: 0px;
//             color: #000000;
//             opacity: 1;
//           }
//           & .histEmpty {
//             text-align: left;
//             font-size: 17px;
//             font-weight: bold;
//             letter-spacing: 0px;
//             color: #bababa;
//             opacity: 1;
//           }
//         }
//       }
//     }
//     // }
//   }
// }
