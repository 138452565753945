#mall-card {
  width: 456px;
  height: 148px;
  background: #f5f5f5 0% 0% no-repeat padding-box;
  border-radius: 8px;
  display: flex;
  padding: 16px;
  & .mall-img img {
    border-radius: 8px;
    // width: 100px;
    // height: 100px !important;
    object-fit: cover;
    cursor: pointer;
  }
  & .mall-description-container {
    margin-left: 16px;
    width: 292px;
    & .mall-name-container {
      display: flex;
      cursor: pointer;
      & .mall-name {
        font-size: 16px;
        font-weight: bold;
      }
      & .check-icon {
        margin-left: auto;
        cursor: pointer;
      }
    }
    & .mall-description {
      margin-top: 12px;
      font-size: 12px;
      text-align: left;
    }
    & .location-container {
      display: flex;
      margin-top: 10px;
      font-size: 13px;
      max-width: 290px;
      & .location-icon {
        opacity: 0.5;
        & img {
          margin-top: 3px;
        }
      }
      & .ellipsis {
        margin-left: 10px;
      }
    }
    & .hashtag-container {
      display: flex;
      flex-wrap: wrap;
      margin-top: 10px;
      height: 23px;
      overflow: hidden;
      & .hashtag {
        height: 23px;
        line-height: 17px;
        margin-right: 8px;
        border-radius: 4px;
        padding: 2px 8px;
        background-color: white;
        font-size: 13px;
        margin-bottom: 16px;
        cursor: pointer;
      }
    }
  }

  @media screen and (max-width: 1400px) {
    width: calc(100% - 40px);
    min-width: 320px;
    max-width: 440px;
    height: auto;
    background-color: white;
    padding: 18px 0;
    margin: 0;
    border-bottom: 1px solid #e8e8e8;
    border-radius: 0;
    & .mall-img {
      width: 100px;
      height: 100px;
      & img {
        width: 100px;
        height: 100px !important;
        object-fit: cover;
      }
    }
    & .mall-description-container {
      width: calc(100vw - 156px);
      min-width: 204px;
      max-width: 324px;
      display: flex;
      flex-direction: column;
      & .mall-description {
        width: calc(100vw - 156px);
        min-width: 204px;
        max-width: 324px;
        color: #777777;
        margin-top: 8px;
        white-space: normal;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      & .location-container {
        display: none;
      }
      & .hashtag-container {
        margin-top: 16px;
        flex-wrap: wrap;
        height: auto;
        & .hashtag {
          font-size: 11px;
          border: 1px solid #cccccc;
          border-radius: 2px;
          padding: 1px 4px;
          margin: 0 6px 6px 0;
        }
      }
    }
  }
}
