#event-plan-page {
  flex-direction: column;
  // width: 1400px;
  // @media screen and (max-width: 480px) {
  //   max-width: 480px;
  //   margin: 0;
  // }
  & .event-page-wrapper {
    margin-top: 80px;
    // @media screen and (max-width: 480px) {
    //   margin: 0;
    // }
    & .event-title-container {
      display: flex;
      padding: 0px 260px;
      // @media screen and (max-width: 480px) {
      //   padding: 20px;
      // }
      & .event-title-text {
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
    & .main-banner-img {
      margin: 40px auto 80px;
      & img {
        width: 100%;
      }
    }
    & .template-detail-container {
      display: flex;
      padding: 80px auto;
      flex-direction: column;
      & .template-detail {
        width: 960px;
        padding-bottom: 80px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        & .template-title {
          font-weight: bold;
          font-size: 32px;
        }
        & .template-content {
          margin-top: 32px;
          font-size: 20px;
          line-height: 30px;
        }
        & .template-img,
        & .template-text,
        & .video {
          margin-top: 32px;
          font-size: 20px;
          white-space: pre-wrap;
          word-break: break-all;
          text-align: left;
          &.half {
            width: 464px;
            & img {
              width: 464px;
            }
          }
        }
        & .template-text {
          padding: 0 20px;
        }
        & .template-img {
          & img {
            width: 960px;
          }
          &.one-third img {
            width: 306.666666px;
          }
        }
        & .template-row {
          display: flex;
          justify-content: space-between;
        }
      }
    }
    & .main-content-img {
      width: 1400px;
      margin: 0 auto 80px;
    }
    /* 기획전 */
    & .event-specialPlan-Container {
      margin-top: 40px;
      width: 1920px;
      height: 420px;
      background: #187454 0% 0% no-repeat padding-box;
      opacity: 1;
      & .event-specialPlan-text-Container {
        padding: 130px 261px;
        top: 485px;
        width: 405px;
        height: 145px;
        opacity: 1;
        & .event-specialPlan-smallBox {
          left: 261px;
          width: 57px;
          height: 25px;

          background: #00000000 0% 0% no-repeat padding-box;
          border: 1px solid #ffffff;
          border-radius: 4px;
          opacity: 1;

          & .event-specialPlan-smallBox-text {
            padding: 4px;
            text-align: center;
            font: normal normal normal 12px/17px Noto Sans KR;
            letter-spacing: 0px;
            text-decoration-line: #e8e8e8;
            color: #ffffff;
            opacity: 1;
          }
        }
        & .event-textbox-large {
          padding: 32px 0px;
          /* Layout Properties */
          top: 538px;
          left: 261px;
          width: 405px;
          height: 46px;
          /* UI Properties */
          text-align: left;
          font: normal normal bold 28px/44px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
        & .event-textbox-small {
          padding: 23px 0px;
          top: 604px;
          left: 261px;
          width: 265px;
          height: 26px;
          text-align: left;
          font: normal normal medium 18px/26px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
      }

      & .event-specialPlan-picture {
        /* Layout Properties */
        top: 355px;
        left: 973px;
        width: 688px;
        height: 420px;
        /* UI Properties */
        opacity: 1;
      }
    }
    /* 기획 상품 */
    & .event-goods-wrapper {
      padding: 80px 260px;
      & .event-goods-Container {
        top: 855px;
        width: 1400px;
        height: 1278px;
        background: #134b38 0% 0% no-repeat padding-box;
        opacity: 1;
        & .event-goods-title {
          margin: 0px 361px;
          padding-top: 100px;
          /* Layout Properties */
          top: 955px;
          left: 621px;
          width: 678px;
          height: 110px;
          /* UI Properties */
          text-align: center;
          font: normal normal bold 60px/44px Bangla MN;
          letter-spacing: 0px;
          color: #f2d588;
          opacity: 1;
        }
        & .event-goods-contents {
          margin: 0px 414px;
          padding-top: 117px;
          padding-bottom: 30px;
          top: 1072px;
          left: 674px;
          width: 572px;
          height: 98px;
          text-align: center;
          font: normal normal normal 18px/36px Noto Sans KR;
          letter-spacing: 0px;
          color: #f2d588;
          opacity: 1;
        }
        & .event-goods-image {
          margin: 0px 520px;
          padding-top: 30px;
          top: 1200px;
          left: 780px;
          width: 360px;
          height: 531px;
          /* UI Properties */
          box-shadow: 0px 3px 6px #00000033;
          border-radius: 16px;
          opacity: 1;
        }
        & .event-goods-preview .title {
          padding-top: 40px;
          text-align: center;
          font: normal normal bold 16px/24px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
        & .event-goods-preview .name {
          padding-top: 8px;
          text-align: center;
          font: normal normal normal 12px/24px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }

        /* 가격표 */
        & .event-goods-preview .price {
          margin: 0px 646px 0px 654px;
          padding-top: 13px;
          display: flex;
          color: #ffffff;
          opacity: 1;
        }
        & .event-goods-preview .price .box {
          width: 42px;
          height: 25px;
          text-align: center;
          /* UI Properties */
          background: var(---1e76af-key-color-) 0% 0% no-repeat padding-box;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 4px;
          opacity: 1;
        }

        & .event-goods-preview .price .text {
          width: 54px;
          height: 29px;
          margin-left: 12px;
          text-align: left;
          font: normal normal bold 20px/21px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
        & .event-goods-preview .price .won {
          width: 13px;
          height: 20px;
          text-align: left;
          font: normal normal medium 14px/142px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }

        & .event-goods-preview .contents {
          margin: 0px 237px;
          padding-top: 50px;
          text-align: center;
          font: normal normal normal 18px/36px Noto Sans KR;
          letter-spacing: 0px;
          color: #f2d588;
          opacity: 1;
        }
      }

      /* 쿠폰 */
      & .event-coupon-Container {
        padding: 99px 260px;
        width: 1400px;
        height: 690px;
        background: #187454 0% 0% no-repeat padding-box;
        opacity: 1;
        & .event-coupon-lineBox {
          margin: auto;
          padding: 12px;
          max-width: 518px;
          height: 272px;
          background: #e6ce8400 0% 0% no-repeat padding-box;
          border: 1px solid #f8e197;
          opacity: 1;
        }

        & .event-coupon-lineBox .couponBox {
          padding: 13px;
          width: 492px;
          height: 246px;
          background: #f8e197 0% 0% no-repeat padding-box;

          opacity: 1;
        }
        & .event-coupon-lineBox .couponBox .text-title {
          min-width: 226px;
          height: 37px;
          text-align: center;
          font: normal normal bold 20px/44px Bangla MN;
          letter-spacing: 0px;
          color: #134b38;
          opacity: 1;
        }
        & .event-coupon-lineBox .couponBox .text-name {
          min-width: 270px;
          height: 92px;
          text-align: center;
          font: normal normal bold 50px/44px Bangla MN;
          letter-spacing: 0px;
          color: #134b38;
          opacity: 1;
        }
        & .event-coupon-lineBox .couponBox .text-content {
          min-width: 232px;
          height: 26px;
          text-align: center;
          font: normal normal normal 18px/36px Noto Sans KR;
          letter-spacing: 0px;
          color: #134b38;
          opacity: 1;
        }
      }
      & .event-caution {
        min-width: 723px;
        height: 175px;
        opacity: 1;
      }
      & .event-caution .title {
        padding: 59px;
        margin: 0px 317px;
        min-width: 88px;
        height: 35px;
        text-align: left;
        font: normal normal bold 24px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
      & .event-caution .contents {
        margin: auto;
        min-width: 723px;
        height: 120px;
        text-align: left;
        font: normal normal normal 14px/25px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
        opacity: 1;
      }
    }
    /* 기획 추천도서 */
    & .event-recommand-wrapper {
      margin: 0 auto;
      display: flex;
      width: 1400px;
      border-radius: 8px 8px 0px 0px;
      opacity: 1;
      padding-bottom: 30px;
      border-bottom: 1px solid #e8e8e8;
      margin-bottom: 20px;

      & .event-recommand-title {
        /* Layout Properties */
        min-width: 210px;
        height: 35px;
        /* UI Properties */
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .event-recommand-contents {
        /* Layout Properties */
        width: 960px;
        margin-left: auto;
        /* UI Properties */
        color: var(---000000-text-);
        text-align: left;
        font: normal normal normal 15px/30px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        white-space: pre-wrap;
      }
    }

    & .sort-container {
      display: flex;
      width: 1400px;
      margin: 0px auto;
      & button {
        margin: 0 8px;
      }
      & .first {
        margin-left: auto;
      }
      & .selected {
        font-weight: bold;
      }
    }

    /* line */
    & .event-line {
      margin: 20px 0px 34px;
      border: 1px solid #e8e8e8;
    }

    /* 추천도서 Wrapper */
    & .event-recommand-book-wrapper {
      margin: 0px auto;
      width: 1400px;

      & .event-recoomand-container {
        display: flex;
      }

      & .event-recommand-box {
        display: flex;
        padding: 24px;
        width: 692px;
        height: 400px;
        background: #f8f8f8 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        position: relative;
        & .event-recommand-image {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          cursor: pointer;
          & img {
            box-shadow: 0px 5px 10px #00000029;
            border-radius: 8px;
          }
        }
      }
      & .event-recommand-smallBox {
        width: 48px;
        height: 48px;
        margin-right: 10px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #2577ad;
        border-radius: 8px;
        opacity: 1;
        & div {
          display: flex;
          & .is-visible {
            margin: auto;
          }
        }
      }
      .fav-box {
        display: flex;
        flex-direction: column;
      }
      & .event-recommand-smallCount {
        width: 48px;
        text-align: center;
        font: normal normal normal 12px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .event-recommand-commentContainer {
        margin-left: 60px;
        width: 538px;
        max-height: 48px;
        opacity: 1;
        & .event-hashtag-container {
          width: fit-content;
          height: 25px;
          & .hashtag-text {
            padding: 0 12px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #e8e8e8;
            border-radius: 4px;
            opacity: 1;
            text-align: center;
            font: normal normal normal 12px/25px Noto Sans KR;
            letter-spacing: 0px;
            color: #0a0a0a;
            opacity: 1;
          }
        }
        & .event-recommand-text {
          min-width: 103px;
          min-height: 26px;
          margin-top: 20px;
          color: var(---000000-text-);
          text-align: left;
          font: normal normal bold 18px/32px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          display: flex;
          align-items: center;
          cursor: pointer;
          & .book-title-text {
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }
        & .more-icon {
          margin-top: 2px;
          cursor: pointer;
          margin-left: 20px;
        }
        & .event-recommand-name {
          min-width: 80px;
          display: flex;
          height: 24px;
          margin-bottom: 48px;
          // flex-wrap: wrap;
          /* UI Properties */
          color: var(---777777-sub-text-);
          text-align: left;
          font: normal normal normal 12px/24px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
          & div {
            margin: auto 8px;
            width: 1px;
            height: 8px;
            background-color: #777777;
          }
          & span {
            &:nth-of-type(1) {
              white-space: normal;
              display: -webkit-box;
              -webkit-line-clamp: 3;
              -webkit-box-orient: vertical;
              overflow: hidden;
              height: 72px;
            }
            &:nth-of-type(2),
            &:nth-of-type(3) {
              min-width: fit-content;
            }
          }
        }
        & .event-recommand-contents {
          margin: 24px 0;
          min-width: 538px;
          overflow: hidden;
          text-overflow: ellipsis;
          display: -webkit-box;
          -webkit-box-orient: vertical;
          -webkit-line-clamp: 3;

          color: var(---000000-text-);
          text-align: left;
          font: normal normal normal 14px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        & .event-recommand-priceContainer {
          display: flex;
          min-width: 239px;
          margin-top: 47px;
          & .eventPer {
            width: 42px;
            height: 25px;
            padding: 4px 8px;
            color: white;
            border-radius: 4px;
            background-color: #1e76af;
            font-size: 12px;
          }
          & .eventprice {
            margin: -11% 0px 0px 12px;
            width: 92px;
            height: 40px;
            /* UI Properties */
            color: var(---000000-text-);
            text-align: left;
            font: normal normal bold 28px/142px Noto Sans KR;
            letter-spacing: 0px;

            opacity: 1;
          }
          & .eventPriceUnit {
            width: 13px;
            height: 20px;
            /* UI Properties */
            color: var(---000000-text-);
            text-align: left;
            font: normal normal medium 14px/142px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .eventBefPrice {
            margin-left: 8px;
            width: 64px;
            height: 24px;
            /* UI Properties */
            color: var(---777777-sub-text-);
            text-align: center;
            text-decoration: line-through;
            font: normal normal normal 16px/20px Noto Sans KR;
            letter-spacing: 0px;
            color: #777777;
            opacity: 1;
          }
        }
        & .event-button-container {
          display: flex;
          margin-top: 40px;
          & .event-carryButton {
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #1e76af;
            border-radius: 8px;
            color: #1e76af;
            justify-content: space-between;
          }
          & .event-buyButton {
            margin-left: 24px;
            /* UI Properties */
            margin-left: 16px;
            background: #1e76af 0% 0% no-repeat padding-box;
            border-radius: 8px;
            color: white;
          }
          & .event-carryButton,
          & .event-buyButton {
            width: 155px;
            height: 56px;
            display: flex;
            justify-content: space-between;
            & div {
              &:first-of-type {
                margin: auto 0 auto 20px;
              }
              &:last-of-type {
                margin: auto 10px auto auto;
              }
            }
          }
        }
      }
    }

    & .event-another-wrapper {
      & .event-another-wrapper-container {
        & .event-image {
          & .LazyLoad {
            & img {
              width: 450px;
            }
          }
        }
      }
    }

    /* 다른 기획전 */
    @import "./EventAnother.scss";
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .event-page-wrapper {
      margin: 0 auto;
      min-width: 360px;
      width: 100%;
      max-width: 480px;
      & .main-banner-img {
        margin: 0;
        & .LazyLoad {
          display: flex;
          & img {
            width: 100%;
          }
        }
      }
      & .template-detail-container {
        margin-bottom: 50px;
        & .template-detail {
          padding: 0;
          width: 100%;
          & .template-img {
            margin: 0;
            & .LazyLoad {
              display: flex;
              width: 100%;
              & img {
                max-width: 480px;
                width: 100%;
              }
            }
          }
          & .template-row {
            flex-direction: column;
          }
          & .template-text {
            // width: 100%;
            padding: 0 20px;
            margin: 20px 0;
            text-align: left;
            font: normal normal normal 13px/21px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .template-swiper {
            height: 280px;
          }
        }
      }

      & .event-recommand-wrapper {
        flex-direction: column;
        width: 100%;
        padding: 0 20px;
        margin-bottom: 30px;
        & .event-recommand-title {
          text-align: left;
          font: normal normal bold 17.5px/13px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          height: auto;
          margin-bottom: 20px;
        }
        & .event-recommand-contents {
          width: 100%;
          margin: 0;
          text-align: left;
          font: normal normal normal 13px/21px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }

      & .swiper {
        min-width: 320px;
        max-width: 440px;
        height: 500px;
        width: calc(100vw - 40px);
        & .swiper-pagination {
          // margin-top: 20px;
        }
        & .swiper-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: flex;
          transition-property: transform;
          box-sizing: content-box;
          & .swiper-slide {
            & .image-box-wrapper {
              background: #f5f5f5 0% 0% no-repeat padding-box;
              border-radius: 16px;
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;
              & .icon-wrapper {
                display: flex;
                flex-direction: row-reverse;
                & .cart-icon {
                  margin-right: 10px;
                  margin-top: 10px;
                }
              }
              & .check-icon {
                display: flex;
                justify-content: end;
                margin-right: 10px;
                margin-top: 10px;
                & .LazyLoad {
                  margin: 0;
                  width: auto;
                  height: auto;
                  min-height: auto;
                  background: none;
                  & img {
                    width: 30px;
                    margin: 0;
                  }
                }
              }
              & .swiper-slide-img {
                margin-bottom: 40px;
                & .LazyLoad {
                  opacity: 1;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-self: center;
                  & img {
                    width: 130px;
                  }
                }
              }
            }
            & .event-hashtag-container {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 10px;
              overflow: hidden;
              height: 25px;
              max-height: 48px;
              & .hashtag-text {
                height: 16px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #e8e8e8;
                border-radius: 4px;
                opacity: 1;
                text-align: center;
                font: normal normal normal 10px/14.5px Noto Sans KR;
                letter-spacing: 0px;
                color: #0a0a0a;
                margin: 0 6px 6px 0;
                padding: 1px 4px;
              }
            }
            & .event-recommand-text {
              text-align: left;
              font: normal normal bold 15px/16px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
              margin-bottom: 16px;
              & .book-title-text {
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
              }
            }
            & .event-recommand-name {
              min-width: 80px;
              display: flex;
              text-align: left;
              font: normal normal normal 12px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #777777;
              opacity: 1;
              margin-bottom: 14px;
              & .text-box {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 20%;
              }
              & .text-box:nth-child(3) {
                max-width: 30%;
              }
              & .text-box:last-child {
                max-width: 42%;
              }
              & .line-box {
                margin: auto 8px;
                width: 1px;
                height: 8px;
                background-color: #777777;
              }
            }
            & .event-recommand-priceContainer {
              display: flex;
              align-items: center;
              & .eventPer {
                background: #1e76af 0% 0% no-repeat padding-box;
                border-radius: 4px;
                opacity: 1;
                font: normal normal bold 11px/10px Noto Sans KR;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
                width: 31.5px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              & .eventprice {
                text-align: left;
                font-size: 14px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                font-weight: bold;
                margin-left: 12px;
              }
              & .eventPriceUnit {
                text-align: left;
                font-size: 12px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                margin-left: 2px;
              }
              & .eventBefPrice {
                text-align: center;
                text-decoration: line-through;
                font-size: 12px;
                letter-spacing: 0px;
                color: #777777;
                opacity: 1;
                margin-left: 12px;
              }
            }
            & .swiper-slide-active {
              min-width: 320px;
              width: calc(100vw - 40px);
              max-width: 440px;
              cursor: pointer;
            }
          }
        }
      }

      & .event-recommand-book-wrapper {
        width: 100%;
        margin-bottom: 50px;
        & .event-line {
          margin: 30px 20px;
        }
      }
      & .event-another-wrapper {
        width: 100%;
        margin: 0;
        padding: 0 20px;

        & .event-another-wrapper-title {
          text-align: left;
          font: normal normal bold 17.5px/13px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          height: auto;
        }
        & .event-another-wrapper-container {
          width: 100%;
          flex-direction: column;
          margin-bottom: 50px;
          & .event-another-wrapper-container-box {
            margin: 0;
            width: 100%;
            margin-bottom: 20px;
            height: auto;
            & .event-image {
              & .LazyLoad {
                width: 100%;
                & img {
                  width: 100%;
                }
              }
            }
            & .event-tag {
              margin-top: 16px;
              text-align: center;
              text-decoration: underline;
              font-size: 11px;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            & .event-title {
              width: 100%;
              margin-top: 12.5px;
              color: var(---000000-text-);
              text-align: center;
              font: normal normal bold 17.5px/25.5px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
              margin-bottom: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
    }
  }
}
