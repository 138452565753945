& .paymentMethod {
  position: relative;
  & .sHTiv {
    & .chk {
      position: absolute;
      right: 0;
      top: 0;

      // & input[type="checkbox"] {
      //   opacity: 0;
      // }
      & .fmRad {
        position: absolute;
        width: 17px;
        height: 17px;
        padding: 0;
        margin: 0;
        opacity: 0;
        box-sizing: border-box;
      }

      & .fmChk-i {
        font-weight: normal;
        display: inline-block;
        position: relative;
        width: auto;
        cursor: pointer;
        font-size: 16px;
        color: #000;
        line-height: 24px;
        box-sizing: border-box;
      }

      // & .fmChk-i::before {
      //   left: 0;
      //   top: 1px;
      //   width: 24px;
      //   height: 24px;
      //   border: 1px #bababa solid;
      //   background: #fff;
      //   border-radius: 3px;
      //   content: "";
      //   position: absolute;
      //   box-sizing: border-box;
      // }
      // & .fmChk-i::after {
      //   left: 6px;
      //   top: 6px;
      //   width: 12px;
      //   height: 9px;
      //   border: 0 #bababa solid;
      //   border-width: 0 0 2px 2px;
      //   -webkit-transform: rotate(-45deg);
      //   transform: rotate(-45deg);
      //   content: "";
      //   position: absolute;
      //   box-sizing: border-box;
      // }
    }
  }

  & ul {
    list-style: none;
    margin: 20px 0 0 0;
    @media screen and (max-width: 1400px) {
      display: flex;
      flex-wrap: wrap;
      padding: 0;
    }
    & li {
      float: left;
      margin: 0 16px 0 0;
      @media screen and (max-width: 1400px) {
        margin: 0;
        &:nth-of-type(2) {
          margin-left: auto;
        }
      }
      & button {
        width: 236px;
        @media screen and (max-width: 1400px) {
          min-width: 154px;
          width: calc(50vw - 26px);
          max-width: 208px;
        }
      }
      .whiteBtn {
        display: inline-block;
        height: 56px;
        text-align: center;
        border: 1px #d0d0d0 solid;
        border-radius: 8px;
        overflow: hidden;
      }
      .active {
        background: #1e76af;
        & span {
          color: #fff;
        }
      }
    }
  }
}
