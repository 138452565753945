#MallCommunity {
  display: flex;
  flex-direction: column;
  & .primary {
    color: #b7916b;
  }
  & .tab-bar {
    height: 100px;
    border-bottom: 1px #e8e8e8 solid;
    display: flex;
    min-width: 1400px;
    & .tab-item {
      margin-top: auto;
      height: 99px;
      width: 200px;
      font-size: 20px;
      &.first {
        margin-left: auto;
      }
      &.last {
        margin-right: auto;
      }
      &.selected {
        height: 97px;
        font-weight: bold;
        border-bottom: 2px solid #b7916b;
      }
    }
  }
  & .community-container {
    padding-top: 80px;
    width: 1400px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    & .title {
      font-size: 24px;
      font-weight: bold;
      padding-bottom: 40px;
    }
    & .story-container {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      flex-wrap: wrap;
      & .story-card {
        width: 450px;
        height: 420px;
        margin-right: 25px;
        margin-bottom: 30px;
        display: flex;
        flex-direction: column;
        cursor: pointer;
        & .cover-img img {
          border-radius: 16px 16px 0 0;
          width: 450px;
          height: 240px;
        }
        & .text {
          height: 180px;
          border-radius: 0 0 16px 16px;
          color: white;
          padding: 32px;
          padding-top: 24px;
          display: flex;
          flex-direction: column;
          text-align: left;
          & .text-title {
            font-size: 24px;
            font-weight: bold;
          }
          & .text-content {
            margin-top: 12px;
            font-size: 15px;
            line-height: 27px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        &:nth-of-type(3n) {
          margin-right: 0;
        }
      }
    }
    & .channel-container {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      & .channel-card {
        width: 688px;
        height: 540px;
        margin-right: auto;
        margin-bottom: 40px;
        border: 1px solid #e8e8e8;
        display: flex;
        flex-direction: column;
        border-radius: 16px;
        cursor: pointer;
        & .cover-img img {
          border-radius: 16px 16px 0 0;
          width: 686px;
          height: 387px;
        }
        & .text {
          height: 154px;
          border-radius: 0 0 16px 16px;
          padding: 32px;
          padding-top: 24px;
          display: flex;
          flex-direction: column;
          text-align: left;
          & .text-title {
            font-size: 24px;
            font-weight: bold;
          }
          & .text-content {
            margin-top: 12px;
            font-size: 15px;
            line-height: 30px;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
            overflow: hidden;
          }
        }
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
    & .notice-container {
      width: 1400px;
      margin: 0 auto;
      display: flex;
      flex-flow: row wrap;
      border-top: 1px solid #e8e8e8;
      cursor: pointer;
      & .notice-card {
        width: 647px;
        height: 150px;
        margin-right: auto;
        display: flex;
        border-bottom: 1px solid #e8e8e8;
        & .date-container {
          width: 88px;
          height: 88px;
          margin: auto 0;
          background-color: #f4f4f4;
          border-radius: 8px;
          display: flex;
          flex-direction: column;
          font-family: Roboto;
          font-weight: bold;
          & .date {
            font-size: 28px;
            margin-top: auto;
            line-height: 34px;
          }
          & .month {
            margin-top: -3px;
            font-size: 16px;
            margin-bottom: auto;
          }
        }
        & .text {
          width: 530px;
          margin-top: 30px;
          text-align: left;
          margin-left: 28px;
          & .text-title {
            font-size: 20px;
            font-weight: bold;
          }
          & .text-content {
            margin-top: 6px;
            font-size: 15px;
            line-height: 30px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
          }
        }
        &:nth-of-type(2n) {
          margin-right: 0;
        }
      }
    }
    & .pagination {
      margin: 48px auto 120px;
      & button {
        color: #777777;
      }
      & .Mui-selected {
        background-color: white;
        color: #b7916b;
      }
    }
    // 입점몰 상세
    & .item {
      height: 70px;
      display: flex;
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
      padding: 24px 0;
      font-size: 14px;
      line-height: 28px;
      color: black;
      cursor: pointer;
      &.next {
        border-top: none;
      }
      & .item-title {
        font-weight: bold;
      }
      & .divider {
        height: 25px;
        width: 1px;
        background-color: #e8e8e8;
        margin: auto 20px;
      }
      & .date-info {
        margin-left: auto;
        color: #777777;
      }
      &.no-item {
        cursor: auto;
      }
    }
    & .button {
      width: 180px;
      height: 56px;
      border-radius: 8px;
      padding: 16px 20px;
      margin: 48px 0 120px auto;
      background: #b7916b 0% 0% no-repeat padding-box;
      color: white;
      & .button-text {
        display: flex;
        & .image {
          margin-left: auto;
          margin-top: 3px;
        }
      }
    }
    & .template-detail-container {
      display: flex;
      padding: 0;
      flex-direction: column;
      // background-color: #eef1fa;
      & .template-detail {
        width: 860px;
        padding-bottom: 80px;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        background-color: #eef1fa;
        & .template-title {
          font: normal normal bold 17.5px/13px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          margin: 30px 0 20px;
        }
        & .template-content {
          margin-top: 32px;
          font-size: 20px;
          line-height: 30px;
        }
        & .template-img,
        & .template-text,
        & .video {
          margin-top: 32px;
          font-size: 20px;
          white-space: pre-wrap;
          word-break: break-all;
          text-align: left;
          &.half {
            width: 420px;
          }
        }
        & .template-text {
          padding: 0 20px;
        }
        & .template-row {
          display: flex;
          justify-content: space-between;
        }
      }
      & .event-recommend-wrapper {
        & .recommend-comment {
          white-space: pre-wrap;
        }
      }
    }
    & .notice-detail-container {
      display: flex;
      padding: 32px;
      padding-bottom: 80px;
      background: #f8f8f8 0% 0% no-repeat padding-box;
      & .date-box {
        width: 88px;
        height: 88px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border-radius: 8px;
        font-family: roboto;
        color: #b7916b;
        font-weight: bold;
        & .day {
          margin-top: 18px;
          font-size: 28px;
          line-height: 34px;
        }
        & .month {
          font-size: 16px;
        }
      }
      & .notice-title {
        width: 350px;
        margin-left: 28px;
        font-weight: bold;
        font-size: 24px;
        text-align: left;
      }
      & .content {
        width: 842px;
        font-size: 18px;
        margin-left: 28px;
        text-align: left;
        white-space: pre-wrap;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .header-wrapper {
      width: 100%;
      margin: 0 auto;
      padding: 0;
    }
    & .tab-bar {
      min-width: 360px;
      max-width: 480px;
      width: 100%;
      margin: 0 auto;
      padding: 0;
      height: 60px;
      & .tab-item {
        height: 60px;
        font: normal normal medium 14px/20px Noto Sans KR;
        letter-spacing: 0px;
        color: #b7916b;
        opacity: 1;
      }
      & .tab-item.selected {
        height: 60px;
      }
    }
    & .community-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      margin: 0 auto;
      padding: 0;
      & .title {
        font: normal normal bold 17.5px/13px Noto Sans KR;
        letter-spacing: 0px;
        opacity: 1;
        padding: 0;
        margin: 30px auto;
        & .primary {
          color: #b7916b;
        }
      }
      & .story-container {
        width: 100%;
        margin: 0;
        & .story-card {
          margin: 0;
          margin-bottom: 30px;
          padding: 0;
          width: 100%;
          height: auto;
          & .cover-img {
            width: 100%;
            & .LazyLoad {
              width: 100%;
              & img {
                width: 100%;
                height: auto;
                display: flex;
              }
            }
          }
          & .text {
            height: auto;
            padding: 15px 15px 20px 15px;
            & .text-title {
              text-align: left;
              font: normal normal bold 17.5px/27px Noto Sans KR;
              letter-spacing: 0px;
              color: #ffffff;
              opacity: 1;
            }
            & .text-content {
              text-align: left;
              font: normal normal normal 12px/19px Noto Sans KR;
              letter-spacing: 0px;
              color: #ffffff;
              opacity: 1;
            }
          }
        }
      }
      & .channel-container {
        width: 100%;
        margin: 0;
        & .channel-card {
          width: 100%;
          height: auto;
          margin: 0;
          margin-bottom: 30px;
          & .cover-img {
          }
          & .text {
            padding: 12px 15px 20px;
            height: auto;
            & .text-title {
              font: normal normal bold 17.5px/20px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            & .text-content {
              font: normal normal normal 12px/19px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
          }
        }
      }
      & .notice-container {
        width: 100%;
        margin: 0;
        border: none;
        & .notice-card {
          width: 100%;
          height: auto;
          margin: 0;
          margin-bottom: 30px;
          display: flex;
          background-color: #f5f5f5;
          flex-direction: column;
          padding: 16px 16px 20px 16px;
          border-radius: 16px;
          & .date-container {
            margin-bottom: 12px;
            color: #b7916b;
            background-color: white;
            & .date {
              font: normal normal bold 24px/17px Roboto;
            }
            & .month {
              font: normal normal bold 12px/17px Roboto;
              padding-top: 8px;
            }
          }
          & .text {
            width: 100%;
            margin: 0;
            padding: 0;
            & .text-title {
              font: normal normal bold 17.5px/17px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            & .text-content {
              font: normal normal normal 12px/19px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
              margin-top: 12px;
            }
          }
        }
      }
      & .template-detail-container {
        margin: 0;
        & .template-detail {
          padding: 0;
          width: 100%;
          & .template-img {
            margin: 0;
            & .LazyLoad {
              display: flex;
              width: 100%;
              & img {
                max-width: 480px;
                width: 100%;
              }
            }
          }
          & .template-row {
            flex-direction: column;
          }
          & .template-text {
            // width: 100%;
            padding: 0 20px;
            margin: 20px 0;
            text-align: left;
            font: normal normal normal 12px/19px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .template-swiper {
            height: 280px;
          }
          & .template-title {
            margin: 30px 0;
            text-align: center;
            font: normal normal bold 17.5px/27.5px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .video {
            margin: 0;
            & iframe {
              width: 100%;
              min-height: 248px;
            }
          }
          & .template-content {
            font: normal normal normal 12px/19px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            margin: 20px 20px 30px;
          }
        }
        & .event-recommend-wrapper {
          flex-direction: column;
          width: 100%;
          padding: 0 20px;
          margin: 30px 0;
          & .recommend-title {
            text-align: left;
            font: normal normal bold 17.5px/13px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            height: auto;
            margin-bottom: 20px;
          }
          & .recommend-comment {
            width: 100%;
            margin: 0;
            text-align: left;
            font: normal normal normal 13px/21px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
      }
      & .notice-detail-container {
        flex-direction: column;
        padding: 0;
        & .date-box {
          margin: 30px auto 12px;
          width: 70px;
          height: 70px;
          & .day {
            font: normal normal bold 24px/17px Roboto;
            letter-spacing: 0px;
            color: #b7916b;
            opacity: 1;
          }
          & .month {
            font: normal normal bold 12px/17px Roboto;
            letter-spacing: 0px;
            color: #b7916b;
            opacity: 1;
            padding-top: 8px;
          }
        }
        & .notice-title {
          font: normal normal bold 17.5px/17px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          margin: 0 20px;
        }
        & .content {
          width: 100%;
          margin: 0;
          margin-top: 12px;
          padding: 0 20px 30px;
          font: normal normal normal 12px/19px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
      & .swiper {
        min-width: 320px;
        max-width: 440px;
        height: 500px;
        width: calc(100vw - 40px);
        & .swiper-pagination {
          // margin-top: 20px;
        }
        & .swiper-wrapper {
          position: relative;
          width: 100%;
          height: 100%;
          z-index: 1;
          display: flex;
          transition-property: transform;
          box-sizing: content-box;
          & .swiper-slide {
            & .image-box-wrapper {
              background: #f5f5f5 0% 0% no-repeat padding-box;
              border-radius: 16px;
              display: flex;
              flex-direction: column;
              margin-bottom: 20px;
              & .check-icon {
                display: flex;
                justify-content: end;
                margin-right: 10px;
                margin-top: 10px;
                & .LazyLoad {
                  margin: 0;
                  width: auto;
                  height: auto;
                  min-height: auto;
                  background: none;
                  & img {
                    width: 30px;
                    margin: 0;
                  }
                }
              }
              & .swiper-slide-img {
                margin-bottom: 40px;
                & .LazyLoad {
                  opacity: 1;
                  width: 100%;
                  display: flex;
                  justify-content: center;
                  align-self: center;
                  & img {
                    width: 130px;
                  }
                }
              }
            }
            & .event-hashtag-container {
              display: flex;
              flex-wrap: wrap;
              margin-bottom: 10px;
              overflow: hidden;
              max-height: 48px;

              & .hashtag-text {
                height: 16px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #e8e8e8;
                border-radius: 4px;
                opacity: 1;
                text-align: left;
                font: normal normal normal 10px/14.5px Noto Sans KR;
                letter-spacing: 0px;
                color: #0a0a0a;
                margin: 0 6px 6px 0;
                padding: 1px 4px;
              }
            }
            & .event-recommand-text {
              text-align: left;
              font: normal normal bold 15px/16px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
              margin-bottom: 16px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
            & .event-recommand-name {
              min-width: 80px;
              display: flex;
              text-align: left;
              font: normal normal normal 12px/24px Noto Sans KR;
              letter-spacing: 0px;
              color: #777777;
              opacity: 1;
              margin-bottom: 14px;
              & .text-box {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                max-width: 20%;
              }
              & .text-box:nth-child(3) {
                max-width: 30%;
              }
              & .text-box:last-child {
                max-width: 42%;
              }
              & .line-box {
                margin: auto 8px;
                width: 1px;
                height: 8px;
                background-color: #777777;
              }
            }
            & .event-recommand-priceContainer {
              display: flex;
              align-items: center;
              & .eventPer {
                background: #1e76af 0% 0% no-repeat padding-box;
                border-radius: 4px;
                opacity: 1;
                font: normal normal bold 11px/10px Noto Sans KR;
                letter-spacing: 0px;
                color: #ffffff;
                opacity: 1;
                width: 31.5px;
                height: 20px;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              & .eventprice {
                text-align: left;
                font-size: 14px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                font-weight: bold;
                margin-left: 12px;
              }
              & .eventPriceUnit {
                text-align: left;
                font-size: 12px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                margin-left: 2px;
              }
              & .eventBefPrice {
                text-align: center;
                text-decoration: line-through;
                font-size: 12px;
                letter-spacing: 0px;
                color: #777777;
                opacity: 1;
                margin-left: 12px;
              }
            }
            & .swiper-slide-active {
              min-width: 320px;
              width: calc(100vw - 40px);
              max-width: 440px;
              cursor: pointer;
            }
          }
        }
      }
      & .event-recommand-book-wrapper {
        width: 100%;
        margin-bottom: 20px;
        & .event-line {
          margin: 30px 20px;
        }
      }
      & .event-another-wrapper {
        width: 100%;
        margin: 0;
        padding: 0 20px;

        & .event-another-wrapper-title {
          text-align: left;
          font: normal normal bold 17.5px/13px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          height: auto;
        }
        & .event-another-wrapper-container {
          width: 100%;
          flex-direction: column;
          margin-bottom: 50px;
          & .event-another-wrapper-container-box {
            margin: 0;
            width: 100%;
            margin-bottom: 20px;
            height: auto;
            & .event-image {
              & .LazyLoad {
                width: 100%;
                & img {
                  width: 100%;
                }
              }
            }
            & .event-tag {
              margin-top: 16px;
              text-align: center;
              text-decoration: underline;
              font-size: 11px;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            & .event-title {
              width: 100%;
              margin-top: 12.5px;
              color: var(---000000-text-);
              text-align: center;
              font: normal normal bold 17.5px/25.5px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
              margin-bottom: 20px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
            }
          }
        }
      }
      & .button {
        display: none;
      }
      & .item-wrap {
        margin: 30px 0 50px;
      }
      & .pagination {
        margin: 0 auto 50px;
      }
    }
  }
}
