& .side-menu {
  display: flex;
  flex-direction: column;
  width: 126px;
  margin-left: auto;
  & .title {
    text-align: left;
    font: normal normal bold 24px/26px Noto Sans KR;
  }
  & .menu {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 32px;
    & .menu-item {
      cursor: pointer;
      text-align: left;
      font: normal normal bold 14px/28px Noto Sans KR;
      color: #000000;
      margin-bottom: 12px;
      &.selected {
        font-weight: bold;
        text-decoration: underline;
      }
    }
    & .menu-item-li {
      cursor: pointer;
      list-style: none;
      text-align: left;
      font: normal normal normal 12px/28px Noto Sans KR;
      color: #777777;
      &.selected {
        font-weight: bold;
        text-decoration: underline;
        color: #000000;
      }
    }
  }
}

& .mobile-side-menu {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: auto;
  padding-top: 48px;
  @media screen and (max-width: 1400px) {
    width: calc(100vw - 40px);
    min-width: 320px;
    max-width: 440px;
    margin: 0 auto;
    padding-top: 30px;
    & .mobile-side-menu-container {
      & .mobile-title-text {
        text-align: left;
        font: normal normal bold 17.5px Noto Sans KR;
      }
      & .show-sorting-container {
        height: 56px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        & .sorting-name {
          float: right;
          font-size: 15px;
          font-weight: bold;
        }
      }
    }
  }
}
