#NewBook {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .new-book-wrapper {
    height: auto;
    padding-bottom: 120px;
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .new-book-wrapper {
      display: flex;
      margin: 0 auto;
      min-width: 360px;
      max-width: 480px;
      width: 100%;
      padding: 0;
    }
  }
}
