& .introduction-container {
  margin-top: 76px;
  display: flex;
  & .left {
    width: 608px;
    margin-left: auto;
    margin-right: 100px;
    & .title {
      text-align: left;
      font-size: 24px;
      font-weight: bold;
      & span {
        color: #b7916b;
      }
    }
    & .hash-tag-container {
      margin-top: 20px;
      display: flex;
      & .hash-tag {
        background: #f4f4f4 0% 0% no-repeat padding-box;
        border-radius: 4px;
        margin-right: 8px;
        padding: 2px 8px;
      }
    }
    & .description {
      text-align: left;
      font-size: 15px;
      line-height: 30px;
      height: 145px;
      margin-top: 16px;
      white-space: pre-line;
      // 메인 상세 소개글 삐져나가지 마시오
      word-break: break-all;
      overflow: hidden;
    }
    & .address {
      margin-top: 32px;
      border-top: 1px solid #e8e8e8;
      border-bottom: 1px solid #e8e8e8;
      padding: 8px 0;
      & .row {
        display: flex;
        font-size: 15px;
        line-height: 32px;
        padding: 8px 0;
        & img {
          margin-right: 12px;
        }
        & .address-text {
          margin-left: 10px;
        }
      }
    }
    & .sns-link {
      display: flex;
      margin-top: 24px;
      & .sns-ico {
        width: 52px;
        height: 52px;
        border-radius: 8px;
        margin-right: 16px;
        cursor: pointer;
      }
    }
  }
  & .right {
    margin-right: auto;
    width: 692px;
    height: 460px;
    border-radius: 16px;
    & img {
      border-radius: 16px;
    }
  }

  @media screen and (max-width: 1400px) {
    display: inline;

    & .left {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      margin: 30px auto 0;
      & .title {
        font-size: 17.5px;
      }
      & .description {
        height: auto !important;
        white-space: pre-line;
        // 메인 상세 소개글 삐져나가지 마시오
        word-break: normal !important;
        overflow: visible !important;
        font-size: 13px;
        line-height: 21px;
      }
      & .address {
        margin-top: 18px !important;
        padding: 18px 0 6px 0;
        & .row {
          height: auto;
          margin-bottom: 12px;
          padding: 0;
          font-size: 13px;
          line-height: 24px;
          text-align: left;
          & .LazyLoad {
            width: 24px !important;
            height: 24px !important;
            & img {
              width: 24px;
              height: 24px;
              margin: 0;
            }
          }
        }
      }
      & .sns-link {
        width: 36px;
        height: 36px;
        margin-top: 18px !important;
        & .sns-ico {
          width: 36px !important;
          height: 36px !important;
          & .LazyLoad {
            width: 36px !important;
            height: 36px !important;
            & img {
              width: 36px;
              height: 36px;
            }
          }
        }
      }
      & .hash-tag-container {
        & .hash-tag {
          font-size: 11px;
        }
      }
    }

    & .right {
      display: none;
    }
  }
}
