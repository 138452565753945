& .special-exhibition-container {
  width: 1400px;
  margin: 80px auto 0;
  & .body-container {
    margin-top: 32px;
    display: flex;
    flex-direction: column;
    & .first-line-container {
      display: flex;
      justify-content: space-between;
      & .exhibition-card {
        width: 450px;
        height: 240px;
        & img {
          border-radius: 16px;
        }
        cursor: pointer;
        & .exhibition-text {
          color: white;
          margin-top: -157px;
          & .title {
            font-size: 28px;
            font-weight: bold;
          }
          & .content {
            font-size: 16px;
            margin-top: 12px;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    width: auto;
    max-width: 480px;
    margin-top: 50px;
    & .exhibition-swiper {
      margin-top: 20px;
      min-height: calc(320px * 260 / 750 + 40px);
      height: calc((100vw - 40px) * 260 / 750 + 40px);
      max-height: calc(440px * 260 / 750 + 40px);
      & img {
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        min-height: calc(320px * 260 / 750);
        height: calc((100vw - 40px) * 260 / 750);
        max-height: calc(440px * 260 / 750);
        border-radius: 8px;
      }
    }
  }
}
