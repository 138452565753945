& .notice-main-wrapper {
  margin-top: 80px;
  display: flex;
  @import "./SupportSideBar.scss";
  & .notice-main-container {
    margin-left: 150px;
    margin-right: auto;
    width: 1164px;
    & .notice-main-title {
      min-width: 88px;
      height: 35px;
      /* UI Properties */
      color: var(---000000-text-);
      text-align: left;
      font: normal normal bold 24px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      float: left;
    }
    & .notice-main-search-container {
      width: 1164px;
      display: flex;
      padding-top: 32px;
      & .search-selectbar {
        width: 180px;
        height: 56px;
        border: 1px solid var(---bababa-line_darker-);
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #bababa;
        border-radius: 8px;
        opacity: 1;
        text-align: left;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;

        & .search-selectbar-contents {
          background: var(---f3f8fb-color-bg-) 0% 0% no-repeat padding-box;
          background: #f3f8fb 0% 0% no-repeat padding-box;
          opacity: 1;
        }
      }
      & .search-bar {
        margin-left: 16px;
        width: 400px;
        height: 56px;
        /* UI Properties */
        // border: 1px solid var(---bababa-line_darker-);
        background: #ffffff 0% 0% no-repeat padding-box;
        // border: 1px solid #bababa;
        border-radius: 8px;
        opacity: 1;

        text-align: left;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
      }
      & .search-button {
        margin-left: 16px;
        width: 160px;
        height: 56px;
        /* UI Properties */
        background: var(---1e76af-key-color-) 0% 0% no-repeat padding-box;
        background: #1e76af 0% 0% no-repeat padding-box;
        color: #ffffff;
        border-radius: 8px;
        opacity: 1;

        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
      }
      & .search-text {
        margin-left: 324px;
        color: var(---000000-text-);
        text-align: right;
        font: normal normal bold 18px/32px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        margin-top: 24px;
      }
    }
    & .notice-center-line {
      width: 1164px;
      height: 0px;
      border: 1px solid var(---bababa-line_darker-);
      border: 1px solid #bababa;
      opacity: 1;
    }

    & .notice-main-table-container {
      max-width: 1164px;
      max-height: 1164px;
      min-height: 750px;
      margin-top: 32px;
      // border-top: 1px solid #bababa;
      //border-bottom: 1px solid #bababa;
      opacity: 1;
      & .notice-main-row-container {
        max-height: 660px;
        min-height: 660px;
        & .notice-main-tableHeader {
          color: var(---777777-sub-text-);
          text-align: left;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
          width: 15%;
        }
        & .notice-main-row {
          text-align: left;
          color: var(---000000-text-);
          text-align: left;
          font: normal normal normal 15px/27px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          cursor: pointer;
        }
        & .table-date {
          color: var(---777777-sub-text-);
          text-align: right;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
          width: 10%;
        }
      }
    }
    & .notice-main-pagination-container {
      margin: 48px auto 120px auto;
      width: 318px;
      height: 24px;
      opacity: 1;
      display: flex;
      justify-content: center;
      & .notice-main-pagination-container .pagination {
        color: var(---1e76af-key-color-);
        text-align: center;
        font: normal normal normal 13px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
        opacity: 1;
      }
    }
  }
}
// media screen
@media screen and (max-width: 1400px) {
  & .notice-main-wrapper {
    display: flex;
    min-width: 360px;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    & .notice-main-container {
      margin: 0 auto 50px;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      & .notice-main-title {
        position: relative;
        margin: 15px 0;
      }
      & .notice-main-search-container {
        min-width: 320px;
        max-width: 440px;
        width: 100%;
        height: 48px;
        padding: 0;
        margin: 15px 0 72px;
        & .search-selectbar {
          border: none;
        }
        & .MuiFormControl-root {
          min-width: 90px;
          min-height: 48px;
          & .MuiInputBase-root {
            width: 90px;
            height: 50px;
          }
        }
        & .MuiPaper-root {
          display: flex;
          width: 100%;
          min-width: 222px;
          min-height: 48px;
          margin-left: 8px;
          box-sizing: initial;
          box-shadow: none;
          & .mobile-searchbar {
            display: flex;
            min-width: 205px;
            width: 100%;
            height: 48px;
            box-shadow: none;
            border: 1px solid #bababa;
            border-radius: 8px;
            & .MuiInputBase-root {
              width: 100%;
              height: 48px;
              padding: 0;
              z-index: 0;
            }
            & .MuiButtonBase-root {
              padding: 12px;
              flex: 1;
            }
          }
        }
        & .search-text {
          margin: 0;
          padding: 0;
        }
      }
      & .notice-main-table-container {
        min-width: 320px;
        max-width: 440px;
        width: 100%;
        max-height: fit-content;
        min-height: auto;
        margin: 0;
        padding: 0;
        & .MuiPaper-root {
          width: 100%;
          max-height: none;
          overflow-x: initial;
          box-shadow: none;
          & .MuiTable-root {
            width: 100%;
            box-shadow: none;
            overflow-x: initial;
            & .MuiTableBody-root {
              width: 100%;
              overflow-x: initial;
              border-top: 1px solid #e8e8e8;
              & .MuiTableRow-root {
                width: 100%;
                & .table-seq-wrap {
                  display: none;
                }
                & .table-title-wrap {
                  width: 100%;
                  & .notice-main-row {
                    padding: 16px 0 0;
                    margin-bottom: 10px;
                    text-align: left;
                    font: normal normal normal 13px/13.5px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    overflow: hidden;
                    text-overflow: ellipsis;
                    white-space: nowrap;
                    min-width: 320px;
                    width: calc(100vw - 40px);
                    max-width: 440px;
                  }
                }
                & .table-date-wrap {
                  & .MuiTableCell-root {
                    padding: 0 0 16px;
                    text-align: left;
                    border: none;
                    border-bottom: 1px solid #e8e8e8;
                    text-align: left;
                    font: normal normal normal 12px/14px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #777777;
                    opacity: 1;
                  }
                }
              }
            }
          }
        }
        & .notice-main-pagination-container {
          align-items: center;
          margin: 12px auto 0;
        }
      }
    }
  }
}
