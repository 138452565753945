#Partner {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .partner-container {
    // 일원화 도서
    @import "./SingularBook.scss";
    // 조합원 도서
    @import "./PartnerBook.scss";
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .partner-container {
      min-width: 360px;
      max-width: 480px;
      width: 100%;
      margin: 0 auto;
      & .singular-wrapper {
        min-width: 360px;
        max-width: 480px;
        width: 100%;
        height: auto;
        margin: 0 auto;
        & .mobile {
          height: auto;
          margin-bottom: 20px;
          & .title-container {
            margin: 20px 20px 35px;
            text-align: left;
            font: normal normal bold 35px/26px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
        & .swiper {
          width: 100%;
          height: auto;
          & .swiper-wrapper {
            width: 100%;
            height: auto;
            & .swiper-slide {
              width: 165px;
              height: auto;
              & .book-cover {
                width: 100%;
                height: 179px;
                & .LazyLoad {
                  width: 100%;
                  height: 179px;
                  & img {
                    width: 100%;
                    height: 100%;
                    max-width: 125px;
                    max-height: 179px;
                    border-radius: 8px;
                    box-shadow: 0px 4px 8px #00000029;
                  }
                }
              }
              & .book-title {
                width: 125px;
                margin: 20px 10px 0 20px;
                padding: 0;
                text-align: left;
                font: normal normal bold 15px/22px Noto Sans KR;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                word-break: break-all;
              }
              & .sub-title-container {
                width: 125px;
                margin: 8.5px 10px 0 20px;
                padding: 0;
                text-align: left;
                font: normal normal normal 12.5px/12px Noto Sans KR;
                letter-spacing: 0px;
                color: #777777;
                opacity: 1;
                display: flex;
                & .divider {
                  margin: 0 3px;
                }
                & .sub-title-pubName {
                  min-width: 35px;
                  max-width: 35px;
                }
                & .sub-title-author {
                  overflow-wrap: anywhere;
                  width: auto;
                  max-width: 80px;
                }
              }
              & .price-info-container {
                display: flex;
                width: 100%;
                margin: 0 10px 0 20px;
                align-items: center;
                & .before-sale {
                  display: none;
                }
                & .sale-percentage {
                  display: flex;
                  font: normal normal bold 11px/10px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #ffffff;
                  opacity: 1;
                  align-items: center;
                  border: 1px solid #1e76af;
                  background-color: #1e76af;
                  min-width: 31.5px;
                  height: 20px;
                  justify-content: center;
                  align-items: center;
                  border-radius: 4px;
                  margin-right: 16px;
                }
                & .after-sale {
                  text-align: left;
                  font: normal normal bold 15px/44px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #000000;
                  opacity: 1;
                }
              }
            }
          }
        }
      }
      & .partner-wrapper {
        margin: 0 auto;
        padding: 0;
        min-width: 360px;
        width: 100%;
        max-width: 480px;
        height: auto;
        & .partner-container {
          margin: 0 auto;
          width: 100%;
          // & .btn-book-list-presenter {
          //   min-width: 320px;
          //   max-width: 440px;
          //   width: 100%;
          //   margin: 0;
          //   padding: 0;
          // }
        }
      }
    }
  }
}
