#Mall {
  //
  & .title-container {
    display: flex;
    width: 1400px;
    margin: 0 auto;
    & .title {
      font-size: 24px;
      font-weight: bold;
      & span {
        color: #b7916b;
      }
    }
    & button {
      margin-left: auto;
    }
  }
  @import "./MainBanner.scss";
  @import "./Introduction.scss";
  @import "./StoryBanner.scss";
  @import "./Channel.scss";
  @import "./Notice.scss";
  @import "./RecommendedBook.scss";
  @import "./MallSpecialExhibition.scss";
  @import "./UnivBookMenuBannerAsync.scss";
  @import "./UnivBookQuickSearchBanner.scss";
  // 구내서점 굿즈 배너 css
  @import "../UnivBook/UnivGoodsBanner.scss";

  & .univ-goods-banner-container {
    width: 1400px;
    margin: 0 auto;

    @media screen and (max-width: 1400px) {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
    }
  }

  & .footer-wrapper {
    margin-top: 80px;
  }
  // 메인 새소식 content 삐져나가지 마시오
  & .mall-notice-container {
    & .notice-body {
      & .left {
        & .content {
          word-break: break-all;
          max-height: 250px;
          overflow: hidden;
        }
      }
    }
  }
}
