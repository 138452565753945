.mobile-menu-wrapper {
  display: flex;
  font-size: 17.5px;
  font-weight: bold;
  z-index: 10001;
  & .toggle-button {
    margin: 5px 0 4px 18px;
    padding: 0;
  }
  & .rarr {
    margin: 3px 0 auto auto;
  }
}
