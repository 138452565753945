#pw-success-modal {
  display: flex;
  flex-direction: column;
  width: 418px;
  height: 288px;
  border-radius: 16px;
  background-color: white;
  & .header-container {
    display: flex;
    margin: 40px 40px 24px 77px;
    & .close-button {
      margin-left: auto;
      cursor: pointer;
    }
  }
  & .content-container {
    display: flex;
    width: 262px;
    height: 56px;
    margin: 0px 77px 40px 79px;
    line-height: 30px;
  }
  & .button {
    width: 162px;
    height: 56px;
    background: #1e76af 0% 0% no-repeat padding-box;
    border-radius: 8px;
    margin: 0px 128px 40px 128px;
    text-align: center;
    font: normal normal normal 16px/40px Noto Sans KR;
    letter-spacing: 0px;
    color: #ffffff;
  }
}
