& .order-history-detail-wrapper {
  display: flex;
  padding-top: 80px;
  @media screen and (max-width: 1400px) {
    flex-direction: column;
    padding: 50px 20px;
    margin: auto;
  }
  @import "./SideMenu.scss";
  & .history-detail-container {
    margin: 0px auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;
    @media screen and (max-width: 1400px) {
      margin: 0 auto;
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
    }
    .history-detail-title-container {
      display: flex;
      justify-content: space-between;
      margin-top: 80px;
      @media screen and (max-width: 1400px) {
        margin-top: -36px;
      }
    }
    .history-detail-title {
      width: 191px;
      height: 35px;
      text-align: left;
      font: normal normal bold 24px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      white-space: nowrap;
      @media screen and (max-width: 1400px) {
        font-size: 15px;
        line-height: 23px;
        margin-top: 50px;
        display: none;
      }
    }
    .history-detail-btn {
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #bababa;
      border-radius: 4px;
      opacity: 1;

      text-align: left;
      font: normal normal normal 13px/24px Noto Sans KR;
      letter-spacing: 0px;
      color: #777777;
      opacity: 1;
      margin: 0 8px;
      &:first-of-type {
        margin-left: auto;
      }
      @media screen and (max-width: 1400px) {
        z-index: 10002;
      }
    }
    .detail-box-container {
      width: 1164px;
      height: 60px;
      margin-top: 32px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
      align-items: baseline;
      opacity: 1;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        width: 100%;
        max-width: 440px;
        margin-bottom: 32px;
        margin-top: 64px;
        flex-direction: column;
        & .inquiry-button-container {
          margin-left: auto;
          margin-top: 12px;
          & button {
            height: 24px;
            margin-left: 12px;
            margin-right: 0;
            font-size: 11px;
          }
        }
      }
    }
    .header-text {
      padding: 20px 24px 20px 24px;
      text-align: left;
      font: normal normal normal 14px/20px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      @media screen and (max-width: 1400px) {
        padding: 15px;
        font-size: 12px;
        line-height: 17px;
        min-width: 320px;
        width: 100%;
        max-width: 440px;
        margin-top: 5px;
      }
    }
    .header-search-data {
      margin-left: 6px;
      color: var(---1e76af-key-color-);
      text-align: left;
      font: normal normal bold 14px/20px Noto Sans KR;
      letter-spacing: 0px;
      color: #1e76af;
      opacity: 1;
    }
    .divider-class {
      margin-top: 24px;
      border: 1px solid #bababa;
    }
    .table-body {
      margin-top: 20px;
      width: 100%;
      height: 60px;
      display: table;
      align-items: center;
      @media screen and (max-width: 1400px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin: 10px auto;
        height: fit-content;
        & .td-image-title-container {
          display: flex;
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          height: 35px;
          padding-bottom: 10px;
          border-bottom: 1px solid #e8e8e8;

          & button,
          & .shop-banner-title,
          & div,
          & .shop-banner-title.modoo {
            height: 24px;
            margin-right: 20px;
            font-size: 11px;
          }
          & .td-detail-btn {
            margin-top: 0;
            margin-left: auto;
            margin-right: 0;
          }
        }
        & .td-body-container {
          display: flex;
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          margin-top: 20px;
          & .td-image-container {
            margin-right: 27.5px;
          }
          & .td-info-container {
            min-width: 232.5px;
            width: calc(100vw - 127.5px);
            max-width: 352.5px;
            display: flex;
            flex-direction: column;
            text-align: left;
            & .td-info {
              font-size: 15px;
              font-weight: bold;
            }
          }
          & .td-count-container {
            font-size: 12.5px;
            margin-top: 40px;
            display: flex;
            & .td-price {
              margin-left: auto;
              font-weight: bold;
            }
          }
        }
        & .td-btn-box {
          flex-direction: row !important;
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          margin-top: 20px;
          padding-bottom: 20px;
          border-bottom: 1px solid #e8e8e8;
          & .td-one-btn,
          & .td-review-btn {
            height: 48px !important;
            min-width: 150px;
            width: calc(50vw - 30px) !important;
            border-radius: 8px !important;
            max-width: 210px;
          }
          & .td-one-btn {
            margin-left: auto;
          }
        }
      }
      .td-image-container img {
        border-radius: 8px;
        cursor: pointer;
      }
      .td-image-title-container {
        text-align: left;
        white-space: nowrap;
        & .shop-banner-title {
          color: #1e76af;
          border: 1px solid #1e76af;
          width: fit-content;
          border-radius: 4px;
          font-size: 14px;
          margin-bottom: 6px;
          padding: 2px 8px;
          &.modoo {
            color: #777777;
            border: 1px solid #bababa;
          }
        }
        .td-info {
          color: var(---000000-text-);
          text-align: left;
          font: normal normal bold 16px/23px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          cursor: pointer;
        }
        .td-count {
          text-align: left;
          font: normal normal normal 14px/23px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
      .td-price-container {
        display: flex;
        align-items: baseline;
        width: 120px;
        .td-price {
          text-align: left;
          font: normal normal bold 20px/142px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        .price-unit {
          text-align: left;
          font: normal normal medium 14px/142px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
      .td-send {
        text-align: left;
        font: normal normal normal 14px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      .td-detail-status {
        font: normal normal bold 14px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #1976af;
        opacity: 1;
      }
      .td-detail-date {
        margin-top: 10px;
        text-align: center;
        font: normal normal normal 14px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      .td-detail-btn {
        width: 80px;
        height: 32px;
        margin-top: 8px;
        text-align: left;
        font-size: 13px;
        // font: normal normal normal 13px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
        padding: 0;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #bababa;
        border-radius: 4px;
        opacity: 1;
      }
      .td-btn-box {
        display: flex;
        flex-direction: column;
        align-items: flex-end;
        .td-review-btn {
          width: 88px;
          height: 36px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #1e76af;
          border-radius: 4px;
          opacity: 1;

          text-align: center;
          font: normal normal normal 13px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
          opacity: 1;
        }
        .td-one-btn {
          margin-top: 4px;
          width: 88px;
          height: 36px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 4px;
          opacity: 1;

          font: normal normal normal 13px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
      }
    }
    // 결제 정보 box
    .box-container {
      display: flex;
      justify-content: flex-end;
      @media screen and (max-width: 1400px) {
        flex-direction: column;
      }
      .pay-info-container {
        margin-top: 40px;
        z-index: 1;
        width: 1164px;
        height: 200px;
        background: var(---f3f8fb-color-bg-) 0% 0% no-repeat padding-box;
        background: #f3f8fb 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        display: flex;
        @media screen and (max-width: 1400px) {
          flex-direction: column;
          height: fit-content;
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          padding: 20px;
          margin-top: 20px;
        }
        .pay-box-container {
          margin-top: 24px;
          width: 340px;
          margin-left: 20px;
          @media screen and (max-width: 1400px) {
            width: 100%;
            margin: 0;
          }
          .container-detail {
            display: flex;
            justify-content: space-between;
            .detail-text-title {
              text-align: left;
              font: normal normal bold 20px/40px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
            .detail-text-content {
              text-align: left;
              font: normal normal normal 14px/40px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
          }
        }
      }
      .pay-credit-container {
        margin-top: 33px;
        z-index: 2;
        width: 388px;
        // height: 216px;
        background: #1e76af 0% 0% no-repeat padding-box;
        box-shadow: 0px 5px 6px #00000029;
        border-radius: 6px;
        opacity: 1;
        padding: 24px;
        position: absolute;
        float: right;
        @media screen and (max-width: 1400px) {
          position: relative;
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          padding: 20px;
          height: fit-content;
        }
        .container-detail {
          display: flex;
          justify-content: space-between;
          .detail-text-title {
            font: normal normal bold 20px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
          }
          .detail-text-content {
            text-align: left;
            font: normal normal normal 14px/30px Noto Sans KR;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
          }
          .detail-credit-btn {
            background: #ffffff 0% 0% no-repeat padding-box;
            border-radius: 4px;
            opacity: 1;
            @media screen and (max-width: 1400px) {
              height: 32px;
            }
          }
        }
      }
    }

    // 배송지 정보
    .history-address-container {
      margin-top: 32px;
      width: 1164px;
      height: 188px;
      /* UI Properties */
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 8px;
      opacity: 1;
      padding: 24px;
      @media screen and (max-width: 1400px) {
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        height: fit-content;
        margin-top: 20px;
        display: flex;
        flex-direction: column;
      }
      .history-detail {
        display: flex;
        align-items: center;
        @media screen and (max-width: 1400px) {
          height: fit-content;
          align-items: flex-start;
          font-size: 13px;
          & .ship-name {
            font-weight: bold;
          }
          &:nth-of-type(2) {
            margin: 13.5px 0;
          }
          &:nth-of-type(3) {
            margin-bottom: 13.5px;
          }
        }
        .address-detail-title {
          min-width: 80px;
          text-align: left;
          font: normal normal normal 14px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        .address-detail-content {
          margin-left: 80px;
          text-align: left;
          font: normal normal medium 14px/20px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          @media screen and (max-width: 1400px) {
            margin-left: 12px;
          }
        }
        .address-detail-box {
          margin-left: 22px;
          width: 72px;
          height: 24px;
          background: #777777 0% 0% no-repeat padding-box;
          border-radius: 4px;

          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          opacity: 1;
        }
      }
    }
    .list-btn-container {
      float: right;
      .order-list-btn {
        float: right;
        margin-top: 48px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        opacity: 1;
        width: 180px;
        height: 56px;

        font: normal normal normal 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
      }
    }
  }
}
