& .notice-main-detail-wrapper {
  margin: 80px auto 0;
  width: 1400px;
  display: flex;

  @import "./SupportSideBar.scss";

  & .notice-main-detail-container {
    margin-left: 150px;
    width: 1164px;

    & .notice-main-detail-title {
      min-width: 88px;
      width: 1164px;
      height: 35px;
      /* UI Properties */
      color: var(---000000-text-);
      text-align: left;
      font: normal normal bold 24px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    & .notice-main-detail-center-line {
      margin-top: 32px;
      width: 1164px;
      height: 1px;
      /* UI Properties */
      border: 1px solid var(---bababa-line_darker-);
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #bababa;
      opacity: 1;
    }
    & .notice-line-container {
      display: flex;
      width: 1164px;
      align-items: baseline;
      justify-content: space-between;
      & .notice-main-detail-sub-title {
        margin-top: 24px;
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 16px/28px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .notice-detail-date {
        text-align: right;
        font: normal normal normal 13px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
        min-width: 70px;
      }
    }
    & .notice-main-detail-sub-line {
      margin-top: 24px;
      width: 1164px;
      height: 0px;
      border: 1px solid var(---e8e8e8-line-);
      border: 1px solid #e8e8e8;
      opacity: 1;
    }
    & .notice-main-detail-content {
      margin-top: 40px;
      min-height: 171px;
      max-width: 1164px;
      /* UI Properties */
      color: var(---000000-text-);
      text-align: left;
      font: normal normal normal 15px/30px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
      white-space: pre-wrap;
      word-break: break-word;
      & div p img {
        max-width: 1164px;
      }
    }
    & .notice-main-detail-box-container {
      margin-top: 80px;
      cursor: pointer;
      width: 1164px;
      height: 136px;
      .prev {
        display: flex;
        height: 68px;
        width: 100%;
        text-align: center;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        position: relative;

        .text {
          margin: auto 0;
          text-align: left;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
        }
        .side-line {
          margin: auto 0;
          margin-left: 22px;
          width: 0px;
          height: 28px;
          /* UI Properties */
          border: 1px solid #e8e8e8;
          opacity: 1;
        }
        .title-text {
          width: 973px;
          margin: auto 0 auto 20px;
          color: var(---000000-text-);
          text-align: left;
          font: normal normal normal 14px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .date {
          margin: auto 0 auto 40px;
          color: var(---777777-sub-text-);
          text-align: right;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
        }
      }
      .next {
        display: flex;
        height: 68px;
        width: 100%;
        text-align: center;
        border-top: 1px solid #e8e8e8;
        border-bottom: 1px solid #e8e8e8;
        position: relative;

        .text {
          margin: auto 0;
          text-align: left;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
        }
        .side-line {
          margin: auto 0 auto 22px;
          width: 0px;
          height: 28px;
          /* UI Properties */
          border: 1px solid #e8e8e8;
          opacity: 1;
        }
        .title-text {
          margin: auto 0 auto 20px;
          width: 973px;
          color: var(---000000-text-);
          text-align: left;
          font: normal normal normal 14px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
        .date {
          margin: auto 0 auto 40px;
          color: var(---777777-sub-text-);
          text-align: right;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
        }
      }
    }
    & .notice-main-list-btn-container {
      margin-left: 998px;
      margin-top: 48px;
      padding-bottom: 40px;
      .main-list-btn {
        margin-left: 24px;
        /* UI Properties */
        margin-left: 16px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        color: white;
      }
      .main-list-btn {
        width: 155px;
        height: 56px;
        display: flex;
        justify-content: space-between;
        & div {
          &:first-of-type {
            margin: auto 0 auto 20px;
          }
          &:last-of-type {
            margin: auto 10px auto auto;
          }
        }
      }
    }
  }
}

// media screen
@media screen and (max-width: 1400px) {
  & .notice-main-detail-wrapper {
    display: flex;
    min-width: 360px;
    max-width: 480px;
    width: 100%;
    margin: 0 auto;
    & .notice-main-detail-container {
      margin: 0 auto 50px;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      & .notice-main-detail-title {
        position: relative;
        margin: 15px 0;
      }
      & .notice-main-detail-center-line {
        margin: 15px 0 20px;
        width: 100%;
      }
      & .notice-line-container {
        flex-direction: column;
        & .notice-main-detail-sub-title {
          margin: 0;
          margin-bottom: 16px;
          text-align: left;
          font: normal normal bold 14px/24px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          min-width: 320px;
          width: calc(100vw - 40px);
          max-width: 440px;
          word-wrap: break-word;
        }
        & .notice-detail-date {
          margin: 0;
          text-align: left;
          font: normal normal normal 12px/14px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
        }
      }
      & .notice-main-detail-sub-line {
        width: 100%;
        margin: 0;
        margin-top: 20px;
      }
      & .notice-main-detail-content {
        width: 100%;
        word-wrap: break-word;
        margin-top: 30px;
        & div {
          width: 100%;
        }
        & p {
          width: 100%;
          margin: 0;
        }
        & img {
          width: 100%;
        }
        & table {
          border: 1px solid rgba(0, 0, 0, 0.1);
          margin: 12px 0 14px;
          color: #222;
          width: auto;
          border-collapse: collapse;
          box-sizing: border-box;
          word-wrap: break-word;
          min-width: 320px;
          max-width: 440px;
          width: 100%;
          & thead {
            background-color: #555555;
            line-height: 160%;
            box-sizing: content-box;
            min-width: 320px;
            max-width: 440px;
            width: 100%;
            & tr {
              line-height: 160%;
              box-sizing: content-box;
              // border: 1px solid black;
              & th {
                line-height: 160%;
                box-sizing: content-box;
                border: 1px solid black;
                & p {
                  width: 100%;
                  min-width: 18px;
                  font-size: 11px;
                  text-align: center;
                  word-break: break-all;
                }
              }
            }
          }
          & tbody {
            line-height: 160%;
            box-sizing: content-box;
            min-width: 320px;
            max-width: 440px;
            width: 100%;
            & tr {
              line-height: 160%;
              box-sizing: content-box;
              // border: 1px solid black;
              & td {
                line-height: 160%;
                box-sizing: content-box;
                border: 1px solid black;
                & p {
                  width: 100%;
                  min-width: 18px;
                  font-size: 11px;
                  text-align: center;
                  word-break: break-all;
                }
              }
            }
          }
        }
      }
      & .notice-main-detail-box-container {
        width: 100%;
        margin-top: 50px;
        height: 120px;
        border-top: 1px solid #e8e8e8;
        & .next {
          width: 100%;
          height: 60px;
          border: none;
          & .text {
            margin: 0;
            margin: 20px 5px;
            text-align: left;
            font: normal normal normal 12px/14px Noto Sans KR;
            letter-spacing: 0px;
            color: #777777;
            opacity: 1;
          }
          & .side-line {
            margin: 0;
            margin: 16px 0;
          }
          & .title-text {
            width: 100%;
            margin: 0;
            margin: 20px 0 20px 5px;
            text-align: left;
            font: normal normal normal 13px/13.5px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .date {
            margin: 0;
            display: none;
          }
        }
        & .prev {
          width: 100%;
          height: 60px;
          & .text {
            margin: 0;
            margin: 20px 5px;
            text-align: left;
            font: normal normal normal 12px/14px Noto Sans KR;
            letter-spacing: 0px;
            color: #777777;
            opacity: 1;
          }
          & .side-line {
            margin: 0;
            margin: 16px 0;
          }
          & .title-text {
            width: 100%;
            margin: 0;
            margin: 20px 0 20px 5px;
            text-align: left;
            font: normal normal normal 13px/13.5px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .date {
            margin: 0;
            display: none;
          }
        }
      }
      & .notice-main-list-btn-container {
        width: 100%;
      }
    }
  }
}
