#shipping-info-detail-modal {
  width: 800px;
  // height: 600px;
  background-color: white;
  padding: 32px;
  display: flex;
  flex-direction: column;
  border-radius: 16px;
  & .shipping-info-detail-title {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    border-bottom: 1px solid black;
    margin-bottom: 32px;
    padding-bottom: 16px;
    & .title-text {
      margin-left: 15px;
    }
    & .close-icon {
      margin-left: auto;
      cursor: pointer;
    }
  }
  & .not-found {
    margin-top: 100px;
    font-size: 18px;
  }

  // 배송조회 모달 배송정보 영역
  & .detail-body {
    // 헤더 공통
    & .info-heder {
      & .info-row {
        height: 50px;
        background-color: #f2f2f2;
        border-bottom: 1px solid #bec0c2;
        & .info-data {
          & span {
            line-height: 49px;
          }
        }
      }
    }

    // 정보 공통
    & .info-body {
      & .info-row {
        height: 45px;
        border-bottom: 1px solid #ebebeb;
        & .info-data {
          & span {
            line-height: 44px;
          }
        }
      }
    }

    // 정보 row 공통
    & .info-row {
      display: flex;
      justify-content: space-between;
      & .info-data {
        & span {
          font-size: 16px;
          text-align: center;
          display: inline-block;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
        }
      }
    }

    // 배송지 정보 영역
    & .shipping-info {
      margin-bottom: 35px;
      border-top: 3px solid #ffa715;
      & .info-row {
        & .info-data {
          width: 180px;
          & span {
            width: 170px;
          }
        }
      }
    }

    // 이동정보 영역
    & .move-info {
      border-top: 3px solid #61553e;
      & .info-row {
        & .info-data {
          width: 230px;
          & span {
            width: 220px;
          }
        }
      }
    }
  }
}
