#share-modal {
  width: 554px;
  height: 331px;
  background-color: white;
  border-radius: 16px;
  padding: 40px;
  display: flex;
  flex-direction: column;
  & .header-container {
    display: flex;
    font-size: 24px;
    font-weight: bold;
    & .close-button {
      margin-left: auto;
      cursor: pointer;
    }
  }
  & .sns-icon-container {
    display: flex;
    margin-top: 48px;
    justify-content: space-between;
    & .sns-ico {
      width: 52px;
      height: 52px;
      border-radius: 8px;
      background-color: #ffe600;
      cursor: pointer;
    }
  }
  & .copy-button {
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #2577ad;
    border-radius: 8px;
    margin-top: 36px;
    height: 56px;
    padding: 16px 20px;
    & div {
      color: #2577ad;
      display: flex;
      & .copy-ico {
        margin-left: auto;
      }
    }
  }
}
