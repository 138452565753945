#ProductDetail {
  @import "./BookDetailContainer.scss";
  & .book-detail-menu-container {
    width: 1400px;
    margin: 80px auto 40px;
    height: 56px;
    background: #f3f8fb 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    & .menu-item {
      cursor: pointer;
      width: 180px;
      border-radius: 8px 8px 0px 0px;
      background: #f3f8fb 0% 0% no-repeat padding-box;
      &.selected {
        color: #1e76af;
        background-color: white;
        border: 1px solid #1e76af;
        border-bottom: none;
      }
    }
    @media screen and (max-width: 1400px) {
      min-width: 320px;
      max-width: 440px;
      width: calc(100vw - 40px);
      height: fit-content;
      background-color: white;
      margin: 50px auto 0;
      & .menu-item {
        padding: 8px 18px;
        height: fit-content;
        width: fit-content;
        border-radius: 20px;
        background-color: white;
        border: 1px solid #1e76af;
        margin-right: 8px;
        &.selected {
          background-color: #1e76af;
          color: white;
        }
      }
    }
  }
  @import "./BookInfoPresenter.scss";
  @import "./InformationPresenter.scss";
  @import "./ReviewPresenter.scss";
  & .sticky-footer {
    position: fixed;
    top: 100%;
    left: 50%;
    transform: translate(-50%, 0%);
    transition: 0.5s all ease-in-out;
    height: 104px;
    background-color: white;
    color: black;
    z-index: 100;
    width: 1400px;
    box-shadow: 0px 3px 6px #00000029;
    border-radius: 16px 16px 0 0;
    display: flex;

    @media screen and (max-width: 1400px) {
      display: none;
    }
    &.sticky {
      transform: translate(-50%, -100%);
    }
    & .qty-text {
      margin: auto 0 auto 24px;
      font-size: 14px;
    }
    & .selection-container {
      line-height: 48px;
      height: 48px;
      display: flex;
      border: 1px solid #bababa;
      border-radius: 8px;
      margin: auto 0 auto 16px;
      & button {
        width: 48px;
        font-size: 32px;
        border-radius: 8px;
      }
      & div {
        width: 72px;
        font-size: 18px;
      }
    }
    & .total-amount {
      margin: auto 0;
      &.left {
        margin-left: auto;
      }
      &.number {
        font-size: 30px;
        font-weight: bold;
        margin: auto 8px auto 16px;
      }
      &.right {
        margin-right: 36px;
      }
    }

    & .button {
      border: 1px solid #1e76af;
      border-radius: 8px;
      height: 56px;
      margin-top: 20px;
      &.cart,
      &.buy {
        width: 155px;
        margin-left: 12px;
        padding: 16px 20px;
        color: #1e76af;
        & div {
          display: flex;
          & div {
            margin-left: auto;
            margin-top: 1px;
          }
        }
      }
      &.buy {
        background-color: #1e76af;
        color: white;
        margin-right: 36px;
      }
    }
  }
}
