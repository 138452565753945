#price-container {
  display: flex;
  & .sale-percent {
    background: #b7916b 0% 0% no-repeat padding-box;
    border-radius: 4px;
    padding: 4px 8px;
    color: white;
    font-size: 12px;
    height: fit-content;
    margin-top: 8px;
    margin-right: 12px;
  }
  & .price-after {
    // margin-left: 12px;
    font-weight: bold;
    font-size: 14px;
    line-height: 40px;
    & span {
      font-size: 28px;
      margin-right: 4px;
    }
  }
  & .price-before {
    margin-top: 12px;
    margin-left: 12px;
    color: #777777;
    text-decoration: line-through;
  }
}
