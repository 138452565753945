#main-exhibition-with-swiper {
  & .exhibition-main-card {
    margin-top: 20px;
    & img {
      border-radius: 20px;
      width: 1400px;
      object-fit: cover;
    }
    cursor: pointer;
  }
  & .recommended-book-swiper-container {
    margin-top: 30px;
    width: 1400px;
    height: 160px;

    & .swiper-pagination {
      display: none;
    }
    & .recommended-book {
      cursor: pointer;
      width: 444px;
      height: 160px;
      display: flex;
      & .book-img {
        width: 100px;
        margin: 5px 24px auto 40px;
        & img {
          width: 100px;
          border-radius: 8px;
          background-color: white;
          box-shadow: 0px 5px 10px #00000033;
        }
      }
      & .new-book-tag {
        border-radius: 0px 8px;
        background-color: black;
        color: white;
        padding: 4px 8px;
        position: absolute;
        margin-left: 40px;
        bottom: 10px;
      }
      & .description-container {
        display: flex;
        flex-direction: column;
        & .classification {
          width: fit-content;
          font-size: 12px;
          line-height: 17px;
          color: #0a0a0a;
          margin-top: 30px;
          border: 1px solid #e8e8e8;
          border-radius: 4px;
          padding: 4px 12px;
        }
        & .title {
          margin-top: 16px;
          font-size: 18px;
          font-weight: bold;
          text-align: left;
        }
        & .description {
          width: 238px;
          margin-top: 8px;
          font-size: 14px;
        }
      }
    }
  }
  & .swiper-button-container {
    display: flex;
    position: relative;
    width: 1448px;
    margin-left: -24px;
    bottom: 96px;
    & .nested-swiper-button-prev,
    & .nested-swiper-button-next {
      width: 24px;
      height: 24px;
      background: #e8e8e8 0% 0% no-repeat padding-box;
      border-radius: 4px;
      opacity: 1;
      cursor: pointer;
    }
    & .nested-swiper-button-next {
      margin-left: auto;
    }
  }
}
