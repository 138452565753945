#main {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .main-container {
    // 공통으로 페이지에서 재사용할 css
    & .title-container {
      display: flex;
      & .title-text {
        font-size: 24px;
        font-weight: bold;
      }
      & .more-button {
        margin-left: auto;
        font-size: 14px;
        background-color: white;
        color: black;
      }
    }
    // 메인배너
    @import "./MainBanner.scss";

    // 추천도서
    @import "./RecommendedBook.scss";

    // 기획/소식
    @import "./EventBanner.scss";

    // 새로 나온 책
    @import "./NewBookBanner.scss";

    // 중간배너
    @import "./MiddleBanner.scss";

    // 책광장 기획전
    @import "./SpecialExhibition.scss";

    // 모두같이
    @import "./ModooMall.scss";
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .title-text {
      margin-left: 20px;
      font-size: 17.5px !important;
    }
    & .more-button {
      height: 48px;
      margin: 20px auto 0;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #aaaaaa;
      border-radius: 8px;
    }
  }
}
