#my-page-mileage {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .myPage-wrapper {
    display: flex;
    padding-top: 80px;
    @import "./SideMenu.scss";
  }
  & .mypage-container {
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;

    & .title-container {
      display: flex;
      margin-bottom: 24px;
      // align-items: flex-end;
      & .title-text {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }

    & .myPage-body {
      margin-top: 40px;
      overflow: hidden;
      & .css-15wwp11-MuiTableHead-root {
        border-top: 2px solid #f5f5f5;
      }
      & .css-1hgcmx6-MuiTableCell-root {
        font-size: 14px;
        font-weight: medium;
      }
      & .css-1h0qttb-MuiTableCell-root {
        // width: 20%;
        font-size: 15px;
      }
      & .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
        box-shadow: none;
      }
      & .empty-cell {
        width: 5%;
      }
      & .header-cell {
        width: 20%;
        font-weight: bold;
      }
      & .cost-cell {
        width: 15%;
        padding-right: 60px;
      }
      & .content-cell {
        width: 20%;
      }
    }
    & .pagination-container {
      margin: 48px auto 10px auto;
      width: 318px;
      height: 24px;
      opacity: 1;
      display: flex;
      justify-content: center;
      & .pagination-container .pagination {
        color: var(---1e76af-key-color-);
        text-align: center;
        font: normal normal normal 13px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
        opacity: 1;
      }
    }
    & .more-btn {
      display: none;
    }

    & .bottom {
      margin-top: 80px;

      & .title-container {
        display: flex;
        margin-bottom: 24px;
        // align-items: flex-end;
        & .title-text {
          text-align: left;
          font: normal normal bold 24px/26px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
      & .info-container {
        height: 448px;
        padding: 32px;
        background: var(---f5f5f5-background-) 0% 0% no-repeat padding-box;
        background: #f4f4f4 0% 0% no-repeat padding-box;
        border-radius: 16px;
        text-align: left;
        font: normal normal normal 13px/28px Noto Sans KR;
        letter-spacing: 0px;
        & .info-title {
          text-align: left;
          font: normal normal bold 14px/28px Noto Sans KR;
          letter-spacing: 0px;
        }
      }
    }

    @import "./MyPageMainInfo.scss";
  }

  @media screen and (max-width: 1400px) {
    min-width: 320px;
    & .myPage-wrapper {
      padding: 20px 0;
      margin: 0 auto;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      & .mypage-container {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        & .title-container {
          margin-bottom: 20px;
          & .title-container {
            color: var(---000000-text-);
            text-align: left;
            font: normal normal bold 17.5px/13px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
        & .mainInfo-wrapper {
          display: flex;
          flex-direction: column;
          height: auto;
          // margin: 0 auto;
          // min-width: 320px;
          // width: calc(100% - 40px);
          // max-width: 440px;
          & .mainInfo-main {
            width: 100%;
            min-height: 75px;
            min-width: 320px;
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            & .user-info {
              width: 100%;
              padding: 25px 20px 15px 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              & .user-hello {
                font-size: 17.5px;
                font: normal normal normal 12px/40px Noto Sans KR;
                letter-spacing: 0px;
                line-height: 1;
                height: auto;
                width: 100%;
                text-align: left;
              }
              & .username-button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                & .username-wrapper {
                  padding: 0;
                  & .username {
                    line-height: 1.5;
                    font-size: 17.5px;
                    width: auto;
                  }
                  & .username-unit {
                    line-height: 1.5;
                    font-size: 17.5px;
                    margin-left: 4px;
                  }
                }
                & .modi-button-box {
                  padding-top: 0px;
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                  & .modi-button {
                    width: max-content;
                    height: 22.5px;
                    padding: 0 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & .button-title {
                      font-size: 10px;
                      line-height: 1;
                    }

                    & .userInfo-img {
                      display: flex;
                      align-items: center;
                      & .LazyLoad {
                        display: flex;
                        width: 10px !important;
                        height: 10px !important;

                        & img {
                          width: 10px;
                          height: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          & .myPage-info {
            width: 100%;
            display: flex;
            height: 85px;
            & .info-wrapper {
              width: 100%;
              padding: 12px;
              & .info-title {
                font-size: 12px;
                font-weight: bold;
              }
              & .info-content-wrapper {
                & .info-content {
                  font-size: 17.5px;
                }
                & .info-unit {
                  font-size: 10px;
                }
              }
            }
          }
        }
        & .myPage-body {
          margin-top: 20px;
          & .MuiTable-root {
            & thead {
              & tr {
                & th {
                  padding: 8px;
                  text-align: center;
                  font-size: 12px;
                  font-weight: bold;
                }
              }
            }
            & tbody {
              & tr {
                & td {
                  padding: 8px;
                  &:nth-last-of-type(1) {
                    padding-right: 15px;
                  }
                  font-size: 11px;
                  text-align: center;
                }
                & td:last-child {
                  text-align: right;
                }
              }
            }
          }
          & .pagination-container {
            margin-top: 20px;
            & .pagination {
              & ul {
                & li {
                  display: flex;
                  align-items: center;
                  & .MuiButtonBase-root {
                    min-width: 22px;
                    height: 22px;
                  }
                  & button {
                    font-size: 10px;
                    & svg {
                      width: 19px;
                      height: 16px;
                    }
                  }
                }
              }
            }
          }
        }
        & .bottom {
          margin-top: 10px;
          & .title-container {
            margin-bottom: 12px;
            & .title-text {
              font-size: 13px;
              font-weight: bold;
            }
          }
          & .info-container {
            padding: 15px;
            height: auto;
            font-size: 10px;
            & .info-title {
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }

  // @media screen and (max-width: 1400px) {
  //   min-width: 360px;
  //   // width: auto;
  //   // max-width: 430px;

  //   & .myPage-wrapper {
  //     display: flex;
  //     margin: 0 auto;
  //     padding: 0;
  //     min-width: 360px;
  //     width: 100%;
  //     max-width: 480px !important;
  //   }
  //   & .mypage-container {
  //     display: flex;
  //     min-width: 360px;
  //     margin: 25px 20px;
  //     flex-direction: column;
  //     & .mobile-header {
  //       margin: 0 0 20px 0;
  //       & .header {
  //         display: flex;
  //         justify-content: space-between;
  //         .home-ico {
  //           width: 24px;
  //           height: 24px;
  //           color: #000000;
  //         }
  //       }
  //       & .mobile-title {
  //         text-align: left;
  //         font-size: 17px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         margin-top: 20px;
  //       }
  //     }

  //     & .myPage-body {
  //       margin-top: 20px;
  //       overflow: hidden;
  //       & .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  //         height: 524px;
  //       }

  //       & .css-15wwp11-MuiTableHead-root {
  //         border-top: 2px solid #f5f5f5;
  //       }
  //       & .css-1hgcmx6-MuiTableCell-root {
  //         font-size: 14px;
  //         font-weight: medium;
  //       }
  //       & .css-1h0qttb-MuiTableCell-root {
  //         // width: 20%;
  //         font-size: 15px;
  //       }
  //       & .css-11xur9t-MuiPaper-root-MuiTableContainer-root {
  //         box-shadow: none;
  //       }
  //       & .empty-cell {
  //         width: 5%;
  //       }
  //       & .header-cell {
  //         width: 20%;
  //         text-align: left;
  //         font-size: 12px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         padding: 15px 0;
  //       }
  //       & .header-order-cell {
  //         width: 30%;
  //         text-align: left;
  //         font-size: 12px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         padding: 15px 0;
  //       }
  //       & .header-stat-cell {
  //         width: 15%;
  //         text-align: left;
  //         font-size: 12px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         padding: 15px 0;
  //       }
  //       & .content-cell {
  //         text-align: left;
  //         font-size: 11px;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         padding: 15px 0;
  //         // width: 20%;
  //       }
  //       & .cost-cell {
  //         // width: 15%;
  //         text-align: left;
  //         font-size: 11px;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         padding: 15px 0;
  //       }
  //     }
  //     & .more-btn {
  //       display: block;
  //       width: 100%;
  //       // width: 335px;
  //       height: 48px;
  //       background: #ffffff 0% 0% no-repeat padding-box;
  //       border: 1px solid #bababa;
  //       border-radius: 8px;
  //       text-align: center;
  //       font-size: 13px;
  //       letter-spacing: 0px;
  //       color: #777777;
  //       margin-top: 30px;
  //     }

  //     & .bottom {
  //       margin-top: 30px;
  //       margin-bottom: 50px;
  //       & .title-container {
  //         display: flex;
  //         margin-bottom: 15px;
  //         // align-items: flex-end;
  //         & .title-text {
  //           text-align: left;
  //           font-size: 13px;
  //           font-weight: bold;
  //           letter-spacing: 0px;
  //           color: #000000;
  //           opacity: 1;
  //         }
  //       }
  //       & .info-container {
  //         height: 525px;
  //         padding: 15px;
  //         background: #f4f4f4 0% 0% no-repeat padding-box;
  //         border-radius: 16px;
  //         text-align: left;
  //         font-size: 12px;
  //         letter-spacing: 0px;
  //         line-height: 1.7;
  //         color: #777777;
  //         & .info-title {
  //           text-align: left;
  //           font-size: 13px;
  //           font-weight: bold;
  //           letter-spacing: 0px;
  //           margin-top: 8px;
  //           color: #777777;
  //         }
  //       }
  //     }
  //   }
  // }
}
