#order-cancel-modal {
  width: 1000px;
  height: 814px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    width: 100vw;
    max-width: 480px;
    height: 80vh;
  }
  & .order-cancel-wrapper {
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (max-width: 1400px) {
      padding: 20px;
      height: 80vh;
    }
    & .order-cancel-container {
      @media screen and (max-width: 1400px) {
        display: flex;
        flex-direction: column;
        height: calc(80vh - 40px);
      }
      & .title {
        display: flex;
        justify-content: space-between;
        text-align: left;
        font-weight: bold;
        font-size: 24px;
        // font: normal normal normal 16px/32px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        @media screen and (max-width: 1400px) {
          font-size: 17.5px;
          height: 32px;
        }
        // margin: 0 19px;
        & .close-icon {
          align-self: end;
          color: #bababa;
          margin-bottom: 32px;
          cursor: pointer;
          @media screen and (max-width: 1400px) {
            margin-bottom: 0;
          }
        }
      }
      .inquiry-box-container {
        width: 920px;
        height: 60px;
        margin-top: 32px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        opacity: 1;
        padding-right: 23px;
        @media screen and (max-width: 1400px) {
          width: 100%;
          padding-right: 0;
          margin-top: 20px;
          & ol {
            display: none;
          }
        }

        .header-text {
          padding: 20px 24px 20px 24px;
          text-align: left;
          font: normal normal normal 14px/20px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          @media screen and (max-width: 1400px) {
            padding: 20px 12px;
          }
        }
        .header-search-data {
          margin-left: 6px;
          color: var(---1e76af-key-color-);
          text-align: left;
          font: normal normal bold 14px/20px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
          opacity: 1;
        }

        .header-breadcrumb {
          text-align: left;
          font: normal normal normal 16px/24px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        .separator-icon {
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 30px;
          opacity: 1;
        }
      }
      & .context-container {
        margin-top: 32px;
        .main-text {
          text-align: left;
          font: normal normal bold 16px/23px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        .caution-text {
          margin-top: 8px;
          text-align: left;
          font: normal normal normal 14px/26px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
        }
      }
      & .table-container {
      }
      & .step2-container {
        height: 450px;
        margin-top: 32px;
        display: flex;
        flex-direction: column;
        overflow: hidden;
        overflow-y: scroll;
        @media screen and (max-width: 1400px) {
          margin-top: 20px;
          height: auto;
        }
        .main-text {
          text-align: left;
          font: normal normal bold 16px/23px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        .select-box {
          margin-top: 20px;
          width: 540px;
          height: 56px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #d0d0d0;
          border-radius: 8px;
          opacity: 1;
          float: left;
          @media screen and (max-width: 1400px) {
            width: 100%;
          }
        }
        .multiline-textfield {
          margin-top: 20px;
          width: 540px;
          height: 124px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border-radius: 8px;
          opacity: 1;
          float: left;
          @media screen and (max-width: 1400px) {
            width: 100%;
          }
        }
        .step2-divider {
          margin-top: 60px;
          width: 920px;
          height: 1px;
          @media screen and (max-width: 1400px) {
            width: 100%;
          }
        }
        .sub-title {
          margin-top: 23px;
          text-align: left;
          font: normal normal bold 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        .caution-box {
          margin-top: 20px;
          padding: 32px;
          width: 920px;
          height: 146px;
          background: #f4f4f4 0% 0% no-repeat padding-box;
          border-radius: 8px;
          opacity: 1;
          white-space: nowrap;
          @media screen and (max-width: 1400px) {
            width: 100%;
            padding: 12px;
            height: fit-content;
            & div {
              white-space: pre-line;
              font-size: 12px;
            }
          }

          text-align: left;
          font: normal normal normal 14px/30px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
        }
      }
    }
    & .next-btn {
      margin-top: 48px;
      width: 160px;
      height: 56px;
      border-radius: 8px;
      opacity: 1;
      margin-left: auto;
      margin-right: auto;
      @media screen and (max-width: 1400px) {
        width: 100%;
        margin-top: 20px;
        margin-left: 0 !important;
      }
    }
  }
}
