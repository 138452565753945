#AboutModoo {
  display: flex;
  flex-direction: column;
  & span {
    color: #1e76af;
  }
  & .fit-content {
    width: fit-content;
    display: flex;
  }
  & .modoo-about-background {
    background-color: #f1fcff;
  }
  & .modoo-about-container {
    display: flex;
    flex-direction: column;
    padding: 40px 60px;
    border-radius: 20px;
    background-color: white;
    width: 1220px;
    margin: 40px auto;
    & .title {
      font-weight: bold;
      margin-top: 12px;
      &.first {
        font-size: 24px;
        display: flex;
        margin: 0 auto;
        & div {
          height: fit-content;
          margin-top: auto;
          margin-bottom: -10px;
        }
      }
      &.second {
        font-size: 32px;
        margin-bottom: 40px;
      }
    }
    & .description {
      display: flex;
      font-size: 18px;
      line-height: 36px;
      text-align: left;
      & .modoo-logo {
        margin-left: auto;
        margin-top: 20px;
      }
      & .divider {
        width: 1px;
        height: 164px;
        background-color: #1e76af;
        margin: auto 60px auto 60px;
      }
    }
  }
  & .text {
    margin-right: auto;
    line-height: 41px;
    & .is-visible {
      margin-top: auto;
      display: inline-block;
    }
  }
  & .under-text {
    font-size: 20px;
    line-height: 42px;
    max-width: 1220px;
    margin: 0 auto;
  }
  & .kpc-logo {
    margin: 40px auto 100px;
    & div {
      margin: auto;
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;

    & .modoo-about-background {
      width: 100%;

      & .modoo-banner-img {
        & .LazyLoad {
          min-height: 451.198px;
          max-height: 601.594px;
          & img {
            width: 100%;
            min-width: 360px;
            max-width: 480px;
          }
        }
      }

      & .modoo-about-container {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        padding: 0px;
        border-radius: 0;
        background-color: transparent;
        margin: 40px auto 20px;
        & .title {
          margin-top: 0px;
          font-weight: bold;
          text-align: center;
          &.first {
            font-size: 15px;
            margin-bottom: 8px;
          }
          &.second {
            font-size: 20px;
            margin-bottom: 20px;
          }
        }
        & .description {
          width: calc(100vw - 40px);
          min-width: 320px;
          max-width: 440px;
          display: inline;
          font-size: 18px;
          line-height: 36px;
          text-align: left;
          border-radius: 20px;
          background-color: white;
          padding: 15px 20px;
          & .modoo-logo {
            width: 100%;
            height: 77px;
            margin: 0px auto 15px;
            & .LazyLoad {
              margin: auto;
              display: block;
            }
          }
          & .text {
            font-size: 12px;
            line-height: 25px;
            text-align: center;
          }
        }
      }
      & .under-text {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        font-size: 12px;
        line-height: 25px;
        margin: 0 auto;
      }
      & .kpc-logo {
        margin-top: 20px;
        margin-bottom: 60px;
      }
    }
  }
}
