#UnivBook {
  @import "../Mall/UnivBookQuickSearchBanner.scss";

  display: flex;
  flex-direction: column;
  min-width: 1400px;

  // 구내서점 굿즈 배너 css
  @import "./UnivGoodsBanner.scss";

  & .univ-book-quick-search-banner-container {
    width: 200px;
    margin-top: 0px;
  }

  & .detail-wrapper {
    display: flex;
    padding-top: 80px;
    @media screen and (max-width: 1400px) {
      flex-direction: column;
    }
    @import "./UnivBookSideMenu.scss";
  }
  & .detail-container {
    margin: 0 auto 120px 60px;
    display: flex;
    width: 1164px;
    flex-direction: column;
    & .title-container {
      display: flex;
      justify-content: space-between;
      // margin-bottom: 10px;
      & .title {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
      }
    }
    & .sub-title-container {
      display: flex;
      gap: 20px;
      margin-bottom: 25px;
      font: normal normal bold 20px/28px Noto Sans KR;
      & .sub-title {
        text-align: left;
      }
      & .sub-title2 {
        color: #6a6a6a;
        font-size: 16px;
      }
    }
  }

  & .search-result-header-container {
    & .blue {
      color: #1e76af;
    }
    & .gray {
      color: #808080;
      font-size: 12px;
    }
    display: flex;
    font-size: 20px;
    font-weight: bold;
    text-align: left;
    margin-bottom: 24px;
    & .info-text {
      display: flex;
      align-items: end;
      height: 30px;
      margin-left: 30px;
      padding-bottom: 3px;
      // line-height: 37px;
    }
    @media screen and (max-width: 1400px) {
      font-size: 17.5px;
      display: unset;
      margin: 0 20px 12px 20px;
      & .info-text {
        height: unset;
        margin-left: unset;
      }
    }
  }

  // 학년, 학기
  & .time-select-box {
    display: flex;
    justify-content: start;
    margin: 12px 0 24px 0;
    @media screen and (max-width: 1400px) {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      margin: 12px auto;
    }
    // margin-top: 0;
    // 학년, 학기 공통 css
    & .MuiInputBase-root {
      border-radius: 8px;
      height: 32px;
      width: 90px;
      padding: 0;
      text-align: left;
      margin-right: 5px;
      & .MuiSelect-select {
        // outline: 1px solid red;
        padding: 5px 10px;
      }
    }
    // // 학년
    // & .grade-input {
    // }
    // // 학기
    // & .semester-input {
    // }
  }

  & .title-bar-container {
    border-top: 1px solid #e8e8e8;
    height: 64px;
    padding: 16px 0;
    display: flex;
    & button:first-of-type {
      margin-left: auto;
    }
    & .button {
      width: 80px;
      height: 32px;
      margin-left: 12px;
      cursor: pointer;
      &.cart {
        width: 88px;
      }
      &.excel-download {
        width: 130px;
      }
    }
    & .sort-button {
      color: #777777;
      &.first {
        margin-left: auto;
      }
      &.selected {
        color: black;
        font-weight: bold;
      }
    }
    & .divider {
      height: 12px;
      width: 1px;
      background-color: #010000;
      margin: auto 12px;
    }
  }

  & .list-row-container {
    display: flex;
    padding: 40px 0;
    & .book-img {
      width: 120px;
      margin-left: 40px;
      cursor: pointer;
      position: relative;
      // 오프라인 전용 도서
      // TODO book-img 클래스를 가지고 있는 오프라인 전용 도서에게 offline-book 클레스를 적용하면된다.
      &.offline-book {
        &::after {
          content: "";
          position: absolute;
          top: -8px;
          right: 50%;
          background: url(../../imgs/off_line.svg) no-repeat;
          width: 55px;
          height: 130px;
        }
      }
      & img {
        width: 120px;
        box-shadow: 0px 5px 10px #00000029;
        border-radius: 8px;
      }
    }
    & .book-info-container {
      display: flex;
      flex-direction: column;
      // width: 600px;
      margin: 0 48px;
      text-align: left;
    }

    & .book-status-info {
      width: 324px;
      display: flex;
      align-items: center;
      justify-content: end;
      font-size: 22px;
      font-weight: bold;
    }

    & .price-container {
      margin: auto;
      display: flex;
      flex-direction: column;
      text-align: center;
      justify-content: center;
      & .cancel-line {
        text-decoration: line-through;
        color: #777777;
        line-height: 20px;
      }
      & .sale-percentage {
        background: #1e76af 0% 0% no-repeat padding-box;
        color: white;
        padding: 4px 8px;
        font-size: 12px;
        width: fit-content;
        margin: 8px auto 8px;
        border-radius: 4px;
      }
      & .price {
        margin-top: 0;
        font-size: 22px;
        font-weight: bold;
      }
    }

    & .button-container {
      margin-left: auto;
      display: flex;
      flex-direction: column;
      width: 156px;
      & button {
        width: 156px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 20px;
        margin-right: 16px;
        border: 1px solid #1e76af;
        color: #1e76af;
        margin: auto 0 8px;
        & .button-text {
          display: flex;
          & .image {
            margin-left: auto;
            margin-top: 3px;
          }
        }
        &:nth-of-type(2) {
          background: #1e76af 0% 0% no-repeat padding-box;
          color: white;
          margin: 8px 0 auto;
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    @import "./UnivBookSideMenu.scss";
  }

  // mobile
  #field-presenter {
    padding-bottom: 30px;
    @media (max-width: 1400px) {
      width: 100vw;
      min-width: 320px;
    }
  }
}
@media (max-width: 1400px) {
  #UnivBook {
    height: 100vh;
    & .mobile-menu-name-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      margin: 0 auto;
      & .mobile-menu-name {
        text-align: left;
        font: normal normal bold 17.5px Noto Sans KR;
      }
    }
  }
}
