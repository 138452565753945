& .myPage-recentVeiw-wrapper {
  padding-top: 80px;
  & .myPage-recentVeiw-container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .myPage-recentVeiw-title-container {
      display: flex;
      & .title-text {
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
      }
    }
    & .myPage-recentVeiw-list-container {
      display: flex;
      justify-content: space-between;
      margin-top: 32px;
      & .book-card {
        width: 200px;
        display: flex;
        flex-direction: column;
        align-items: center;
        & .book-img {
          width: 200px;
          height: 286.46px;
          background-color: white;
          box-shadow: 0px 5px 10px #00000029;
          border-radius: 8px;
        }
        & .bookInfo {
          margin-top: 16.04px;
          & .book-title {
            color: var(---000000-text-);
            text-align: center;
            font: normal normal bold 16px/24px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
          }
          & .book-sub-info {
            display: flex;
            margin-top: 8px;
            & div {
              font-size: 12px;
              &:first-of-type {
                margin-left: auto;
              }
              &:nth-of-type(2) {
                width: 0px;
                height: 10px;
                border-left: 1px solid #000000;
                opacity: 0.5;
                margin: auto 8px;
              }
              &:last-of-type {
                margin-right: auto;
              }
            }
          }
          & .price-container {
            display: flex;
            width: auto;
            margin-top: 20px;
            & .sale-percentage {
              margin: auto;
              margin-right: 0;
              padding: 4px 8px;
              color: white;
              border-radius: 4px;
              background-color: #1e76af;
              font-size: 12px;
              width: 42px;
              height: 25px;
            }
            & .price {
              margin-left: 12px;
              font-size: 20px;
              font-weight: bold;
            }
            & .price-unit {
              font-size: 14px;
              margin: auto auto 3px 2px;
            }
          }
        }
      }
    }
    // & .myPage-orderHist-info-container {
    //   margin-top: 32px;
    //   display: flex;
    //   & .myPage-orderHist-info {
    //     top: 589px;
    //     left: 496px;
    //     width: 1164px;
    //     height: 67px;
    //     border: 1px solid var(---bababa-line_darker-);
    //     background: #ffffff 0% 0% no-repeat padding-box;
    //     border: 1px solid #bababa;
    //     border-radius: 8px;
    //     opacity: 1;
    //   }
    // }
  }
}
