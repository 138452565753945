#CouponList {
  display: flex;
  flex-direction: column;
  min-width: 1400px;

  & .myPage-wrapper {
    display: flex;
    padding-top: 80px;
    // flex-direction: column;
    @import "./SideMenu.scss";
  }
  & .mypage-container {
    // margin: 48px 260px 120px 370px;
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;

    & .title-container {
      display: flex;
      margin-bottom: 24px;
      // align-items: flex-end;
      & .title-text {
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }

    & .regi-coupon-container {
      height: 88px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 8px;
      margin-top: 40px;
      display: flex;
      align-items: center;
      padding: 16px 24px;

      & .num-input {
        height: 53px;
        width: 400px;
        border: 1px solid var(---bababa-line_darker-);
        background: #ffffff 0% 0% no-repeat padding-box;
        text-align: left;
        border-radius: 8px;
      }
      .css-9ddj71-MuiInputBase-root-MuiOutlinedInput-root {
        border-radius: 8px;
      }
      & .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
        text-align: left;
        font: normal normal normal 14px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
      }
      & .button {
        width: 160px;
        height: 53px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        text-align: center;
        font: normal normal normal 16px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
        padding: 16px 40px;
        margin-left: 16px;
      }
    }
    & .card-container {
      // height: 209px;
      margin-top: 32px;
      display: flex;
      flex-wrap: wrap;
      // justify-content: space-between;
      position: relative;
      height: 100%;
      & .card:nth-child(3n + 1) {
        margin-left: 0px;
      }
      & .card {
        // width: 372px;
        // width: 33%;
        height: 209px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1976af;
        border-top: 4px solid #1976af;
        border-radius: 0px 0px 8px 8px;
        padding: 20px 24px 24px 24px;
        width: 31.9%;
        margin-bottom: 32px;
        margin-left: 24px;
        & .card-top {
          height: 92px;
          display: flex;
          justify-content: space-between;
          // align-items: center;

          & .title-container {
            display: flex;
            padding-top: 22px;
            & .title {
              // margin-top: 24px;
              text-align: left;
              font: normal normal bold 16px/20px Noto Sans KR;
              letter-spacing: 0px;
              color: #1e76af;
            }
          }

          & .discout-container {
            display: flex;
            align-items: center;
            & .discount {
              text-align: right;
              min-width: 175px;
              font: normal normal bold 48px/33px Noto Sans KR;
              letter-spacing: 0px;
              color: #1e76af;
            }
          }
        }

        & .card-bottom {
          margin-top: 15px;
          & .term {
            text-align: left;
            font: normal normal normal 14px/26px Noto Sans KR;
            letter-spacing: 0px;
            color: #777777;
          }
          & .condition {
            text-align: left;
            font: normal normal normal 14px/26px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            display: flex;
            justify-content: space-between;
            & .button {
              width: 20px;
              height: 20px;
              background: #ffffff 0% 0% no-repeat padding-box;
              color: #707070;
              // border: 1px solid #707070;
              // border-radius: 2px;
              opacity: 0.6;
              margin-left: auto;
              margin-right: 0px;
              // margin-left: 92px;
            }
          }
        }
      }
    }
    & .bottom {
      margin-top: 80px;

      & .title-container {
        display: flex;
        margin-bottom: 24px;
        // align-items: flex-end;
        & .title-text {
          text-align: left;
          font: normal normal bold 24px/26px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
      }
      & .info-container {
        height: 184px;
        padding: 32px;
        background: var(---f5f5f5-background-) 0% 0% no-repeat padding-box;
        background: #f4f4f4 0% 0% no-repeat padding-box;
        border-radius: 16px;
        text-align: left;
        font: normal normal normal 14px/25px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        & .title-text-mui {
          display: none;
        }
      }
    }
    & .pagination-container {
      margin: 48px auto 120px auto;
      // width: 318px;
      height: 24px;
      opacity: 1;
      display: flex;
      justify-content: center;
      & .pagination-container .pagination {
        text-align: center;
        font-size: 13px;
        // font: normal normal normal 13px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
        opacity: 1;
      }
      & .css-wjh20t-MuiPagination-ul {
        justify-content: center;
      }
    }

    @import "./MyPageMainInfo.scss";
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;

    & .myPage-wrapper {
      padding: 20px 0;
      margin: 0 auto;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      & .mypage-container {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        & .title-container {
          margin-bottom: 20px;
          & .title-text {
            text-align: left;
            font: normal normal bold 17.5px/13px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
        & .mainInfo-wrapper {
          display: flex;
          flex-direction: column;
          height: auto;
          & .mainInfo-main {
            width: 100%;
            min-height: 75px;
            min-width: 320px;
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            & .user-info {
              width: 100%;
              padding: 25px 20px 15px 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              & .user-hello {
                font-size: 17.5px;
                font: normal normal normal 12px/40px Noto Sans KR;
                letter-spacing: 0px;
                line-height: 1;
                height: auto;
                width: 100%;
                text-align: left;
              }
              & .username-button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                & .username-wrapper {
                  padding: 0;
                  & .username {
                    line-height: 1.5;
                    font-size: 17.5px;
                    width: auto;
                  }
                  & .username-unit {
                    line-height: 1.5;
                    font-size: 17.5px;
                    margin-left: 4px;
                  }
                }
                & .modi-button-box {
                  padding-top: 0px;
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                  & .modi-button {
                    width: max-content;
                    height: 22.5px;
                    padding: 0 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & .button-title {
                      font-size: 10px;
                      line-height: 1;
                    }

                    & .userInfo-img {
                      display: flex;
                      align-items: center;
                      & .LazyLoad {
                        display: flex;
                        width: 10px !important;
                        height: 10px !important;

                        & img {
                          width: 10px;
                          height: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }
          & .myPage-info {
            width: 100%;
            display: flex;
            height: 85px;
            & .info-wrapper {
              width: 100%;
              padding: 12px;
              & .info-title {
                font-size: 12px;
                font-weight: bold;
              }
              & .info-content-wrapper {
                & .info-content {
                  font-size: 17.5px;
                }
                & .info-unit {
                  font-size: 10px;
                }
              }
            }
          }
        }
        & .card-container {
          margin-top: 20px;
          display: flex;
          flex-direction: column;
          & .MuiPaper-root {
            width: 100%;
            margin: 0;
            height: 187.5px;
            padding: 30px 20px;
            margin-bottom: 10px;
            & .card-top {
              height: auto;
              margin-bottom: 20px;
              & .title-container {
                padding: 0;
                & .title {
                  font-size: 14px;
                  font-weight: bold;
                }
              }
              & .discout-container {
                align-items: normal;
                & .discount {
                  font-size: 48px;
                  font-weight: bold;
                }
              }
            }
            & .card-bottom {
              font-size: 12px;
              & .term {
              }
              & .condition {
                display: flex;
                align-items: center;
                & .MuiButtonBase-root {
                  & .MuiSvgIcon-root {
                    width: 22px;
                    height: 22px;
                  }
                }
              }
            }
          }
        }
        & .pagination-container {
          margin: 20px 0;
          & .pagination {
            & ul {
              & li {
                display: flex;
                align-items: center;
                & .MuiButtonBase-root {
                  min-width: 22px;
                  height: 22px;
                }
                & button {
                  font-size: 10px;
                  & svg {
                    width: 19px;
                    height: 16px;
                  }
                }
              }
            }
          }
        }
        & .bottom {
          margin-top: 10px;
          & .title-container {
            margin-bottom: 12px;
            & .title-text {
              font-size: 13px;
              font-weight: bold;
            }
          }
          & .info-container {
            padding: 15px;
            height: auto;
            font-size: 10px;
            & .info-title {
              font-size: 12px;
              font-weight: bold;
            }
          }
        }
      }
    }
  }
}

// @media screen and (max-width: 1400px) {
//   min-width: 360px;
//   // width: auto;
//   // max-width: 430px;

//   & .myPage-wrapper {
//     display: flex;
//     min-width: 360px;
//     width: 100%;
//     margin: 0 auto;
//     max-width: 480px !important;
//     padding: 0;
//   }
//   & .mypage-container {
//     margin: 50px 20px;
//     min-width: 360px;
//     display: flex;
//     flex-direction: column;

//     & .mobile-header {
//       margin: 0 0 20px 0;
//       & .header {
//         display: flex;
//         justify-content: space-between;
//         .home-ico {
//           width: 24px;
//           height: 24px;
//           color: #000000;
//         }
//       }
//       & .mobile-title {
//         text-align: left;
//         font-size: 17px;
//         font-weight: bold;
//         letter-spacing: 0px;
//         color: #000000;
//         margin-top: 20px;
//       }
//     }

//     & .regi-coupon-container {
//       height: 116px;
//       background: #ffffff 0% 0% no-repeat padding-box;
//       width: 100%;
//       border-radius: 8px;
//       margin-top: 30px;
//       display: flex;
//       padding: 0;
//       flex-direction: column;
//       align-items: stretch;

//       & .num-input {
//         height: 48px;
//         width: 100%;
//         // width: 335px;
//         border: 1px solid var(---bababa-line_darker-);
//         background: #ffffff 0% 0% no-repeat padding-box;
//         text-align: left;
//         border-radius: 8px;
//       }
//       & .css-cyqju5-MuiInputBase-root-MuiOutlinedInput-root {
//         border-radius: 8px;
//       }
//       & .css-1t8l2tu-MuiInputBase-input-MuiOutlinedInput-input {
//         text-align: left;
//         font: normal normal normal 14px/40px Noto Sans KR;
//         letter-spacing: 0px;
//         color: #777777;
//       }
//       & .button {
//         width: 100%;
//         height: 48px;
//         background: #1e76af 0% 0% no-repeat padding-box;
//         border-radius: 8px;
//         text-align: center;
//         font: normal normal normal 16px/40px Noto Sans KR;
//         letter-spacing: 0px;
//         color: #ffffff;
//         padding: 16px 40px;
//         margin: 20px 0 30px 0;
//       }
//     }
//     & .card-container {
//       width: 100%;
//       // width: 335px;
//       height: 100%;
//       margin-top: 32px;
//       display: flex;
//       flex-direction: column;
//       position: relative;
//       & .card:not(:first-child) {
//         margin-left: 0px;
//       }
//       & .card {
//         width: 100%;
//         height: 188px;
//         background: #ffffff 0% 0% no-repeat padding-box;
//         border: 1px solid #1976af;
//         border-top: 4px solid #1976af;
//         border-radius: 0px 0px 8px 8px;
//         padding: 30px 20px 20px 22px;
//         margin-bottom: 20px;
//         & .card-top {
//           height: 72px;
//           display: flex;
//           justify-content: space-between;
//           // align-items: center;

//           & .title-container {
//             display: flex;
//             padding-top: 0;
//             & .title {
//               // margin-top: 24px;
//               text-align: left;
//               font-size: 14px;
//               letter-spacing: 0px;
//               color: #1e76af;
//             }
//           }

//           & .discout-container {
//             display: flex;
//             align-items: flex-start;
//             & .discount {
//               text-align: right;
//               font: normal normal bold 64px/44px Noto Sans KR;
//               letter-spacing: 0px;
//               color: #1e76af;
//             }
//           }
//         }

//         & .card-bottom {
//           margin-top: 15px;
//           & .term {
//             text-align: left;
//             font-size: 12px;
//             // letter-spacing: 0px;
//             color: #777777;
//           }
//           & .condition {
//             text-align: left;
//             font-size: 12px;
//             // letter-spacing: 0px;
//             color: #000000;
//             display: flex;
//             justify-content: space-between;
//             & .button {
//               width: 20px;
//               height: 20px;
//               background: #ffffff 0% 0% no-repeat padding-box;
//               color: #707070;
//               // border: 1px solid #707070;
//               // border-radius: 2px;
//               opacity: 0.6;
//               margin: 0;
//               // margin-left: 92px;
//             }
//           }
//         }
//       }
//     }
//     & .bottom {
//       margin-top: 0px;
//       margin-bottom: 25px;

//       & .title-container {
//         display: flex;
//         margin-bottom: 24px;
//         // align-items: flex-end;
//         & .title-text {
//           display: none;
//         }
//       }
//       & .info-container {
//         width: 100%;
//         height: 236px;
//         padding: 15px;
//         background: #f4f4f4 0% 0% no-repeat padding-box;
//         border-radius: 16px;
//         text-align: left;
//         font-size: 12px;
//         letter-spacing: 0px;
//         color: #000000;
//         line-height: 1.7;
//         & .title-text-mui {
//           display: block;
//           text-align: left;
//           font-size: 13px;
//           font-weight: bold;
//           letter-spacing: 0px;
//           color: #000000;
//           opacity: 1;
//         }
//       }
//     }
//     & .pagination-container {
//       margin: 0;
//       // width: 318px;
//       height: 24px;
//       opacity: 1;
//       display: flex;
//       justify-content: center;
//       & .pagination-container .pagination {
//         text-align: center;
//         font-size: 13px;
//         letter-spacing: 0px;
//         color: #1e76af;
//         opacity: 1;
//       }
//       & .css-wjh20t-MuiPagination-ul {
//         justify-content: center;
//       }
//     }
//   }
// }
