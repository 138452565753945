#btn-book-list-presenter {
  display: flex;
  flex-direction: column;
  max-width: 1400px;
  margin: auto;
  padding-top: 48px;
  & .title-container {
    display: flex;
    & .title-text {
      text-align: left;
      font: normal normal bold 24px/26px Noto Sans KR;
    }
  }
  & .date-btn {
    width: 1400px;
    height: 56px;
    margin-top: 32px;
    background: #f3f8fb 0% 0% no-repeat padding-box;
    border-radius: 8px 8px 0px 0px;
    display: flex;
    & .menu-item {
      cursor: pointer;
      width: 180px;
      border-radius: 8px 8px 0px 0px;
      background: #f3f8fb 0% 0% no-repeat padding-box;
      font: normal normal bold 16px/20px Noto Sans KR;
      &.selected {
        color: #1e76af;
        background-color: white;
        border: 1px solid #1e76af;
        border-bottom: none;
      }
    }
  }
  & .time-select-box {
    margin-top: 24px;
    margin-bottom: 24px;
    display: flex;
    & .years-input {
      width: 167px;
      height: 56px;
      margin-right: 8px;
      border: 1px solid #bababa;
      text-align: left;
    }
    & .month-input {
      width: 124px;
      height: 56px;
      margin-right: 8px;
      border: 1px solid #bababa;
      text-align: left;
    }
    & .day-input {
      width: 124px;
      height: 56px;
      border: 1px solid #bababa;
      text-align: left;
    }
  }
  & .divider {
    width: 1400px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #777777;
  }
  & .new-book-menu-container {
    display: flex;
    margin-top: 14px;
    margin-bottom: 24px;
    flex-wrap: wrap;
    width: 1400px;
    & .menu-item {
      min-width: 70px;
      width: auto;
      height: 40px;
      margin-right: 10px;
      margin-top: 10px;
      padding: 8px 20px;
      border-radius: 8px;
      font-size: 16px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      &.selected {
        background-color: black;
        color: white;
      }
    }
  }

  & .select-checkbox {
    height: 32px;
    width: 32px;
  }
  & .title-bar-container {
    border-top: 1px solid #e8e8e8;
    height: 64px;
    padding: 16px 0;
    display: flex;
    & .button {
      width: 80px;
      height: 32px;
      margin-left: 12px;
      cursor: pointer;
      &.cart {
        width: 88px;
      }
      &.excel-download {
        width: 130px;
      }
    }
    & .sort-button {
      color: #777777;
      &.first {
        margin-left: auto;
      }
      &.selected {
        color: black;
        font-weight: bold;
      }
    }
    & .divider {
      height: 12px;
      width: 1px;
      background-color: #e8e8e8;
      margin: auto 12px;
    }
  }
  & .list-row-container {
    display: flex;
    padding: 40px 0;
    border-top: 1px solid #e8e8e8;
    & .title-container.no-search-result {
      font-size: 24px;
      font-weight: bold;
      margin: auto;
      text-align: center;
    }
    & .book-img {
      margin-left: 40px;
      cursor: pointer;
      & img {
        box-shadow: 0px 5px 10px #00000029;
        border-radius: 8px;
        width: 120px;
        height: 171px;
      }
    }
    & .book-info-container {
      display: flex;
      flex-direction: column;
      width: 700px;
      margin-left: 48px;
      text-align: left;
      & .book-title-container {
        font-size: 18px;
        font-weight: bold;
        display: flex;
        cursor: pointer;
        & .more-button {
          width: 24px;
          height: 24px;
          margin: auto 0 auto 20px;
        }
      }
      & .book-info-text {
        margin-top: 8px;
        color: #777777;
        font-size: 12px;
        line-height: 24px;
        display: flex;
        & div {
          height: 8px;
          width: 1px;
          background-color: #777777;
          margin: auto 8px;
        }
      }
      & .book-content {
        width: 692px;
        height: 50px;
        margin-top: 24px;
        text-align: left;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
        font: normal normal normal 14px/28px Noto Sans KR;
      }
      & .bottom-line {
        margin-top: 43px;
        display: flex;
        & .heart-icon {
          width: 24px;
          height: 24px;
          display: flex;
          cursor: pointer;
          & div {
            display: flex;
            margin-top: auto;
          }
        }
        & .text {
          margin-left: 8px;
        }
        & .divider {
          height: 16px;
          width: 1px;
          background-color: #e8e8e8;
          margin: auto 16px;
        }
        & .comment-icon {
          width: 24px;
          height: 24px;
          display: flex;
          & div {
            display: flex;
            margin-top: auto;
          }
        }
      }
    }
    & .price-container {
      height: 97px;
      margin-top: 60px;
      margin-left: 120px;
      display: flex;
      flex-direction: column;
      text-align: center;
      & .cancel-line {
        text-decoration: line-through;
        color: #777777;
        line-height: 20px;
      }
      & .sale-percentage {
        background: #1e76af 0% 0% no-repeat padding-box;
        color: white;
        padding: 4px 8px;
        font-size: 12px;
        width: fit-content;
        margin: 8px auto 0;
        border-radius: 4px;
      }
      & .price {
        margin-top: 8px;
        font-size: 22px;
        font-weight: bold;
      }
    }
    & .button-container {
      margin-left: auto;
      margin-inline-end: 0%;
      display: flex;
      flex-direction: column;
      width: 156px;
      & button {
        width: 156px;
        height: 56px;
        border-radius: 8px;
        padding: 16px 20px;
        margin-right: 16px;
        border: 1px solid #1e76af;
        color: #1e76af;
        margin: auto 0 8px;
        & .button-text {
          display: flex;
          & .image {
            margin-left: auto;
            margin-top: 3px;
          }
        }
        &:nth-of-type(2) {
          background: #1e76af 0% 0% no-repeat padding-box;
          color: white;
          margin: 8px 0 auto;
        }
      }
    }
  }
  & .pagination-container {
    display: flex;
    & .pagination {
      margin: 16px auto 0;
      & button {
        color: #777777;
      }
      & .Mui-selected {
        background-color: white;
        color: #1e76af;
      }
    }
  }

  // @media screen and (max-width: 1400px) {
  //   padding: 0;
  //   margin: 0 auto;
  //   min-width: 360px;
  //   width: 100%;
  //   max-width: 480px;
  //   padding-top: 25px;
  //   & .title-container {
  //     padding: 0 20px;
  //     width: 100%;
  //     & .title-text {
  //       text-align: left;
  //       font-size: 17.5px;
  //       letter-spacing: 0px;
  //       color: #000000;
  //       opacity: 1;
  //     }
  //   }
  //   & .mobile-date-btn {
  //     padding: 0 20px;
  //     display: flex;
  //     margin-top: 22.5px;
  //     & .menu-item {
  //       width: 72px;
  //       height: 36px;
  //       background: #ffffff 0% 0% no-repeat padding-box;
  //       border: 1px solid #1e76af;
  //       border-radius: 40px;
  //       font-size: 14px;
  //       letter-spacing: 0px;
  //       color: #1e76af;
  //       margin-right: 8px;
  //     }
  //     & .selected {
  //       color: #fff;
  //       background: #1e76af 0% 0% no-repeat padding-box;
  //     }
  //   }
  //   & .time-select-box {
  //     padding: 0 20px;
  //     margin: 0;
  //     margin-top: 20px;
  //     text-align: left;
  //     font-size: 14px;
  //     letter-spacing: 0px;
  //     color: #777777;
  //     opacity: 1;

  //     & .MuiInputBase-root {
  //       height: 48px;
  //       margin-right: 10px;
  //       width: 30%;
  //       border: none;
  //     }
  //     & .MuiInputBase-root:nth-child(1) {
  //       height: 48px;
  //       width: 40%;
  //     }
  //     & .MuiInputBase-root:nth-child(3) {
  //       margin: 0;
  //     }
  //   }
  //   & .new-book-menu-container {
  //     margin: 0;
  //     margin-top: 12px;
  //     width: 100%;
  //     padding: 0 20px;
  //     & .MuiInputBase-root {
  //       text-align: left;
  //       width: 100%;
  //       height: 48px;
  //     }
  //   }
  // & .title-bar-container {
  //   padding: 0;
  //   margin: 20px 0;
  //   height: 48px;
  //   display: flex;
  //   justify-content: end;
  //   align-items: center;
  //   border: none;
  //   background-color: #f3f8fb;
  //   & .MuiInputBase-root {
  //     margin-left: auto;
  //     text-align: left;
  //     font-size: 14px;
  //     letter-spacing: 0px;
  //     color: #000000;
  //     opacity: 1;
  //     & .MuiSelect-select {
  //       padding-top: 0;
  //       padding-bottom: 0;
  //     }
  //     & .MuiOutlinedInput-notchedOutline {
  //       display: none;
  //     }
  //   }
  // }

  //   & .aa {

  //   }

  // & .pagination-container {
  //   margin-top: 20px;
  //   & .pagination {
  //     & ul {
  //       & li {
  //         display: flex;
  //         align-items: center;
  //         & .MuiButtonBase-root {
  //           min-width: 22px;
  //           height: 22px;
  //         }
  //         & button {
  //           font-size: 10px;
  //           & svg {
  //             width: 19px;
  //             height: 16px;
  //           }
  //         }
  //       }
  //     }
  //   }
  // }
  // }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    max-width: 480px;
    margin: 30px 0 50px;
    padding: 0;
    & .title-container {
      margin: 0 20px;
      & .title-text {
        text-align: left;
        font: normal normal bold 17.5px Noto Sans KR;
      }
    }
    & .mobile-date-btn {
      display: flex;
      width: 100vw;
      max-width: 480px;
      padding: 20px 20px 0;
      & .menu-item {
        margin-right: 8px;
        width: 60px;
        height: 35px;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #1e76af;
        border-radius: 40px;
        font: normal normal normal 14px/20px Noto Sans KR;
        color: #1e76af;
        &.selected {
          width: 60px;
          height: 35px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 40px;
          font: normal normal normal 14px/20px Noto Sans KR;
          color: #ffffff;
        }
      }
    }
    & .time-select-box {
      margin: 20px 20px 12px 20px;
      display: flex;
      min-width: 320px;
      max-width: 440px;
      & .MuiInputBase-root {
        border: none;
        border-radius: 16px;
      }
      & .years-input {
        width: 40%;
        height: 48px;
        margin-right: 8px;
        text-align: left;
      }
      & .month-input {
        width: 30%;
        height: 48px;
        margin-right: 8px;
        text-align: left;
      }
      & .day-input {
        width: 30%;
        height: 48px;
        text-align: left;
      }
    }
    & .new-book-menu-container {
      display: flex;
      margin: 0px 20px 12px;
      min-width: 320px;
      max-width: 440px;
      width: calc(100% - 40px);
      & .MuiInputBase-root {
        min-width: 320px;
        max-width: 440px;
        width: 100%;
        height: 48px;
        border-radius: 16px;
      }
    }
    & .title-bar-container {
      padding: 0;
      margin: 20px 0;
      height: 48px;
      display: flex;
      justify-content: end;
      align-items: center;
      border: none;
      background-color: #f3f8fb;
      & .MuiInputBase-root {
        margin-left: auto;
        text-align: left;
        font-size: 14px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        & .MuiSelect-select {
          padding-top: 0;
          padding-bottom: 0;
        }
        & .MuiOutlinedInput-notchedOutline {
          display: none;
        }
      }
    }
    & .list-row-container {
      border: none;
      & .title-container.no-search-result {
        font-size: 17.5px;
      }
    }
    & .show-sorting-container {
      background: #f3f8fb 0% 0% no-repeat padding-box;
      max-width: 480px;
      min-width: 360px;
      width: 100%;
      height: 56px;
      display: flex;
      justify-content: flex-end;
      align-items: center;
      margin: 0;
      & .sorting-name {
        float: right;
        font-size: 15px;
        font-weight: bold;
      }
    }
    & .new-book-img {
      padding: 20px 0;
      margin: 0 20px;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      display: flex;
      border-bottom: 1px solid #e8e8e8;
      & .book-img {
        width: 80px;
        & img {
          width: 80px;
          box-shadow: 0px 2.5px 5px #00000029;
          border-radius: 4px;
          cursor: pointer;
        }
      }
      & .book-info {
        margin-left: 25px;
        min-width: 215px;
        width: 100%;
        display: flex;
        flex-direction: column;
        & .book-title-container {
          display: flex;
          font-size: 15px;
          font-weight: bold;
          & .book-title-text {
            text-align: left;
            font: normal normal bold 17.5px Noto Sans KR;
          }
          & .icon {
            cursor: pointer;
          }
          & .heart-icon {
            margin-left: auto;
            margin-right: 24px;
          }
        }
        & .sub-info-container {
          display: flex;
          font-size: 12.5px;
          color: #777777;
          min-width: 200px;
          width: calc(100vw - 160px);
          max-width: 320px;
          margin-top: 16px;
          & .divider {
            height: 12px;
            width: 1px;
            background-color: black;
            opacity: 0.5;
            margin: auto 6px;
          }
          & div {
            white-space: break-spaces;
          }
        }
        & .open-date-info-container {
          font-size: 12.5px;
          text-align: left;
          color: #777777;
          margin-top: 3px;
          // width: 100%;
          min-width: 200px;
          max-width: 320px;
        }
        & .price-info-container {
          display: flex;
          margin-top: 14px;
          & .sale-percentage {
            font-size: 11px;
            font-weight: bold;
            color: white;
            background: #1e76af 0% 0% no-repeat padding-box;
            border-radius: 2px;
            padding: 2px 4px;
          }
          & .after-sale {
            margin-left: 12px;
            font-size: 14px;
            font-weight: bold;
            & span {
              font-size: 12px;
              font-weight: bold;
              margin-left: 2px;
            }
          }
          & .before-sale {
            margin-left: 12px;
            text-decoration: line-through;
            color: #777777;
            font-size: 12px;
            margin-top: 2px;
          }
        }
      }
    }
    & .pagination-container {
      margin-top: 20px;
      & .pagination {
        & ul {
          & li {
            display: flex;
            align-items: center;
            & .MuiButtonBase-root {
              min-width: 22px;
              height: 22px;
            }
            & button {
              font-size: 10px;
              & svg {
                width: 19px;
                height: 16px;
              }
            }
          }
        }
      }
    }
    // & .more-button {
    //   height: 48px;
    //   margin: 10px auto 0;
    //   width: calc(100% - 40px);
    //   background: #ffffff 0% 0% no-repeat padding-box;
    //   border: 1px solid #aaaaaa;
    //   border-radius: 8px;
    // }
  }
}
