#mobile-book-row-presenter {
  padding: 20px 0;
  margin: 0 20px;
  min-width: 320px;
  width: calc(100vw - 40px);
  max-width: 440px;
  display: flex;
  border-bottom: 1px solid #e8e8e8;
  & .MuiCheckbox-root {
    padding: 0 9px 9px 0;
  }

  & .mobile-book-img {
    width: 80px;
    position: relative;
    // 모바일 오프라인 전용 도서
    // TODO book-img 클래스를 가지고 있는 오프라인 전용 도서에게 mobile-offline-book 클레스를 적용하면된다.
    &.mobile-offline-book {
      &::after {
        content: "";
        position: absolute;
        top: -5px;
        right: 55%;
        background: url(../../imgs/off_line.svg) no-repeat;
        width: 35px;
        height: 85px;
      }
    }
    & img {
      width: 80px;
      box-shadow: 0px 2.5px 5px #00000029;
      border-radius: 4px;
      cursor: pointer;
    }
  }
  & .book-info {
    margin-left: 20px;
    min-width: 215px;
    width: 100%;
    display: flex;
    flex-direction: column;
    & .title-container {
      -webkit-tap-highlight-color: transparent;
      display: flex;
      font-size: 15px;
      font-weight: bold;
      cursor: pointer;
      & .book-title {
        margin-right: 12px;
      }
      & .icon {
        cursor: pointer;
      }
      & .heart-icon {
        margin-left: auto;
        margin-right: 12px;
      }
    }
    & .sub-info-container {
      display: flex;
      font-size: 12.5px;
      color: #777777;
      min-width: 200px;
      // width: 100%;
      max-width: 320px;
      margin-top: 25px;
      & .divider {
        height: 8px;
        width: 1px;
        background-color: black;
        opacity: 0.5;
        margin: auto 6px;
      }
      & div {
        white-space: nowrap;
      }
    }
    & .open-date-info-container {
      font-size: 12.5px;
      text-align: left;
      color: #777777;
      margin-top: 3px;
      // width: 100%;
      min-width: 200px;
      max-width: 320px;
    }
    & .price-info-container {
      display: flex;
      margin-top: 14px;
      & .book-status-info {
        font-size: 14px;
        font-weight: bold;
      }
      & .sale-percentage {
        font-size: 11px;
        font-weight: bold;
        color: white;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 2px;
        padding: 2px 4px;
      }
      & .after-sale {
        margin-left: 12px;
        font-size: 14px;
        font-weight: bold;
        & span {
          font-size: 12px;
          font-weight: bold;
          margin-left: 2px;
        }
        &.list {
          margin-left: 0px;
        }
      }
      & .before-sale {
        margin-left: 12px;
        text-decoration: line-through;
        color: #777777;
        font-size: 12px;
        margin-top: 2px;
      }
    }
  }
  &:last-of-type {
    border-bottom: none;
  }
}
