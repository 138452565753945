#shop-selection-modal {
  width: 1000px;
  height: 811px;
  background-color: white;
  border-radius: 16px;
  display: flex;
  flex-direction: column;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    width: 100vw;
    height: 80vh;
    max-width: 480px;
    padding: 20px;
    border-radius: 8px;
  }
  & .title-container {
    padding: 36px 36px 20px 36px;
    display: flex;
    @media screen and (max-width: 1400px) {
      padding: 0;
    }
    & .title {
      font-weight: bold;
      font-size: 20px;
    }
    & .x-icon {
      margin-left: auto;
      cursor: pointer;
    }
  }
  & .description-container {
    background-color: #f3f8fb;
    padding: 24px;
    border-radius: 8px;
    margin: 0 36px;
    @media screen and (max-width: 1400px) {
      padding: 20px;
      margin: 20px 0 0 0;
    }
    & .main {
      font-size: 24px;
      font-weight: bold;
      color: #1e76af;
      @media screen and (max-width: 1400px) {
        font-size: 15px;
      }
    }
    & .sub {
      color: #aaa;
      font-size: 14px;
      margin: 12px 0 6px;
      @media screen and (max-width: 1400px) {
        font-size: 12px;
        margin-bottom: 0;
      }
    }
  }
  & .modoo-search-input {
    width: 500px;
    margin: 20px auto;
    @media screen and (max-width: 1400px) {
      width: 100%;
    }
  }
  & .modoo-list-container {
    width: 928px;
    height: 340px;
    @media screen and (max-width: 1400px) {
      width: 100%;
      height: max-content;
    }
    margin: 0 auto;
    display: flex;
    flex-wrap: wrap;
    overflow-y: scroll;
    align-content: flex-start;
    & .not-found {
      margin: 12px auto;
    }
    &::-webkit-scrollbar {
      width: 5px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: #bababa;
      border-radius: 2px;
    }
    &::-webkit-scrollbar-track {
      background-color: white;
    }
    & .shop-list-card-container {
      cursor: pointer;
      width: 450px;
      height: 120px;
      border-radius: 8px;
      background-color: #f5f5f5;
      margin-right: 20px;
      margin-bottom: 20px;
      padding: 20px;
      display: flex;
      &:nth-of-type(2n) {
        margin-right: 0;
      }
      @media screen and (max-width: 1400px) {
        margin: 0;
        background-color: white;
        border-bottom: 1px solid #e8e8e8;
        padding: 20px 0;
      }
      & .radio {
        height: 42px;
        margin: auto 10px auto -10px;
      }
      & .mall-img img {
        border-radius: 8px;
      }
      & .shop-info {
        display: flex;
        flex-direction: column;
        margin-left: 20px;
        & .shop-title {
          font-size: 18px;
          font-weight: bold;
          text-align: left;
        }
        & .location-container {
          display: flex;
          margin-top: 12px;
          font-size: 14px;
          text-align: left;
          & .location-icon {
            margin-right: 8px;
            margin-top: 3px;
          }
        }
      }
    }
  }
  & .button-container {
    padding: 40px 0;
    border-top: 1px solid #bababa;
    @media screen and (max-width: 1400px) {
      padding: 20px 0 40px;
    }
    & .button {
      background-color: #1e76af;
      color: white;
      border: 1px solid #1e76af;
      width: 160px;
      height: 48px;
      margin: 0 4px;
      border-radius: 8px;
      @media screen and (max-width: 1400px) {
        min-width: 152px;
        width: calc(50vw - 28px);
        max-width: 212px;
      }
      &.cancel {
        background-color: white;
        color: #1e76af;
      }
    }
  }
}
