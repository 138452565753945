#maintenance {
  & .maintenance-body {
    padding: 8%;
    font-family: verdana;
    text-align: center;
    line-height: 30px;
    background-color: white;
    height: 100vh;
  }
  & .txtblue {
    font-size: 16px;
    font-weight: 400;
    color: #0094ff;
  }
  & .txtblack {
    font-size: 16px;
    color: black;
  }
  & .txtgreen {
    font-size: 16px;
    color: green;
  }
  & .imgcenter {
    width: 20%;
  }
}
