& .onok-modal-wrapper {
  width: 418px;
  height: 256px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;

  & .ok-text {
    padding-top: 66px;
    text-align: center;
    font: normal normal normal 16px/32px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  & .ok-btn {
    margin-top: 56px;
    width: 162px;
    height: 56px;
    background: #1e76af 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;

    font: normal normal normal 16px/40px Noto Sans KR;
    letter-spacing: 0px;
    color: #ffffff;
  }
}

& .confirm-modal-wrapper {
  width: 418px;
  height: 256px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  opacity: 1;

  & .ok-text {
    padding-top: 66px;
    text-align: center;
    font: normal normal normal 16px/32px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  & .ok-btn {
    margin-left: 8px;
    margin-top: 56px;
    width: 162px;
    height: 56px;
    background: #1e76af 0% 0% no-repeat padding-box;
    border-radius: 8px;
    opacity: 1;

    font: normal normal normal 16px/40px Noto Sans KR;
    letter-spacing: 0px;
    color: #ffffff;
  }

  & .cancel-btn {
    margin-top: 56px;
    width: 162px;
    height: 56px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #1e76af;
    border-radius: 8px;
    opacity: 1;

    font: normal normal normal 16px/40px Noto Sans KR;
    letter-spacing: 0px;
    color: #1e76af;
  }
}
