#my-page {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .myPage-wrapper {
    display: flex;
    padding-top: 80px;
    @import "./SideMenu.scss";
  }
  & .mypage-container {
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;

    @import "./MyPageMainInfo.scss";
    @import "./MyPageOrderHist.scss";
    & .recent-book-container {
      display: flex;
      flex-direction: column;
      margin-top: 48px;
      & .title {
        font-size: 24px;
        font-weight: bold;
        text-align: left;
      }
      & .recent-book-list {
        margin-top: 32px;
        display: flex;
        & .recent-book-item {
          display: flex;
          flex-direction: column;
          margin-right: 40px;
          cursor: pointer;
          & .book-image {
            width: 200px;
            min-height: 200px;
            cursor: pointer;
            & img {
              width: 200px;
              height: auto !important;
              border-radius: 10px;
              box-shadow: 0px 5px 10px #00000029;
            }
          }
          & .book-title {
            margin-top: 16px;
            font-size: 16px;
            font-weight: bold;
            max-width: 200px;
          }
          & .book-author-publisher {
            width: 200px;
            margin-top: 8px;
            font-size: 12px;
            color: #777777;
          }
          & .price-container {
            display: flex;
            width: auto;
            margin-top: 20px;
            & .sale-percentage {
              margin: auto;
              margin-right: 0;
              padding: 4px 8px;
              color: white;
              border-radius: 4px;
              background-color: #1e76af;
              font-size: 12px;
            }
            & .price {
              margin-left: 12px;
              font-size: 20px;
              font-weight: bold;
            }
            & .price-unit {
              font-size: 14px;
              margin: auto auto 3px 2px;
            }
          }
        }
      }
    }
    @import "./MyPageRecentView.scss";
    @import "./MyPageModooMall.scss";
    @import "./MyPageLikeList.scss";
    @import "./MyPageNotice.scss";
  }

  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .myPage-wrapper {
      display: flex;
      padding-top: 20px;
      margin: 0 auto;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      & .mypage-container {
        width: 100%;
        margin: 0;
        display: flex;
        flex-direction: column;
        & .mainInfo-wrapper {
          display: flex;
          flex-direction: column;
          height: auto;
          margin-top: 20px;
          & .mainInfo-main {
            width: 100%;
            min-height: 75px;
            min-width: 320px;
            display: flex;
            align-items: center;
            margin-bottom: 12px;

            & .user-info {
              width: 100%;
              padding: 25px 20px 15px 20px;
              display: flex;
              flex-direction: column;
              align-items: center;
              & .user-hello {
                font-size: 17.5px;
                font: normal normal normal 12px/40px Noto Sans KR;
                letter-spacing: 0px;
                line-height: 1;
                height: auto;
                width: 100%;
                text-align: left;
              }
              & .username-button {
                width: 100%;
                display: flex;
                justify-content: space-between;
                & .username-wrapper {
                  padding: 0;
                  & .username {
                    line-height: 1.5;
                    font-size: 17.5px;
                    width: auto;
                  }
                  & .username-unit {
                    line-height: 1.5;
                    font-size: 17.5px;
                    margin-left: 4px;
                  }
                }
                & .modi-button-box {
                  padding-top: 0px;
                  width: 100%;
                  display: flex;
                  justify-content: flex-end;
                  & .modi-button {
                    width: max-content;
                    height: 22.5px;
                    padding: 0 8px;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    & .button-title {
                      font-size: 10px;
                      line-height: 1;
                    }
                    &:has(.logout-button-title) {
                      margin-right: 8px;
                    }
                    & .logout-button-title {
                      line-height: 1;
                      text-align: left;
                      font: normal normal normal 13px / 24px Noto Sans KR;
                      letter-spacing: 0px;
                      color: #ffffff;
                      font-size: 10px;
                    }

                    & .userInfo-img {
                      display: flex;
                      align-items: center;
                      & .LazyLoad {
                        display: flex;
                        width: 10px !important;
                        height: 10px !important;

                        & img {
                          width: 10px;
                          height: 10px;
                        }
                      }
                    }
                  }
                }
              }
            }
          }

          & .myPage-info {
            width: 100%;
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-template-rows: 1fr 1fr;
            height: auto;

            & .vl {
              display: none;
            }
            & .info-wrapper {
              padding: 0;
              padding: 16px;
              & .info-title {
                font-size: 12px;
                font-weight: bold;
                text-align: left;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
              }
              & .info-content-wrapper {
                justify-content: end;

                & .info-content {
                  width: 90%;
                  max-width: 127px;
                  font-size: 17.5px;
                  text-align: right;
                  letter-spacing: 0px;
                  color: #1e76af;
                  opacity: 1;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  white-space: nowrap;
                }
                & .info-unit {
                  font-size: 10px;
                  text-align: left;
                  letter-spacing: 0px;
                  color: #000000;
                  opacity: 1;
                }
              }
            }
            & .info-wrapper:nth-child(1),
            .info-wrapper:nth-child(5) {
              border-right: 1px solid #e8e8e8;
            }
            & .info-wrapper:nth-child(1),
            .info-wrapper:nth-child(3) {
              border-bottom: 1px solid #e8e8e8;
            }
          }
        }
        & .recent-book-container {
          margin-top: 50px;
          & .title {
            text-align: left;
            font-size: 15px;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .recent-book-list {
            margin: 0;
            flex-direction: column;
            & .recent-book-item {
              width: 100%;
              margin: 0;
              display: block;
              padding: 20px 0;
              border-bottom: 1px solid #e8e8e8;
              &:last-of-type {
                border-bottom: none;
                padding-bottom: 0px;
              }
              & .book-image {
                display: inline;
                width: 30%;
                margin-right: 24px;
                min-height: auto;
                float: left;
                & .LazyLoad {
                  width: 10%;
                  margin: 0;
                  & img {
                    width: 90px;
                  }
                }
              }
              & .book-title {
                margin: 0;
                width: 100%;
                text-align: left;
                font-size: 15px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                height: 46px;
                max-width: 62%;
                overflow: hidden;
                white-space: normal;
              }
              & .book-author-publisher {
                width: 100%;
                text-align: left;
                font-size: 12.5px;
                letter-spacing: 0px;
                color: #777777;
                opacity: 1;
                margin-top: 12px;
                overflow: hidden;
                white-space: normal;
                max-height: 40px;
                max-width: 62%;
              }
              & .price-container {
                margin-top: 12px;
                align-items: center;
                & .sale-percentage {
                  margin: 0;
                  padding: 2px 4px;
                  font-size: 11px;
                  letter-spacing: 0px;
                  color: #ffffff;
                  opacity: 1;
                }
                & .price {
                  text-align: left;
                  font-size: 14px;
                  letter-spacing: 0px;
                  color: #000000;
                  opacity: 1;
                  margin-left: 16px;
                  margin-right: 2px;
                }
                & .price-unit {
                  text-align: left;
                  font-size: 12px;
                  letter-spacing: 0px;
                  color: #000000;
                  opacity: 1;
                  margin: 0;
                }
              }
            }
          }
        }
        & .myPage-likeList-wrapper {
          padding: 0;
          margin-top: 50px;
          & .myPage-likeList-title-container {
            & .title-text {
              text-align: left;
              font-size: 15px;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
          }
          & .body-container {
            margin: 0;
            width: 100%;
            height: auto;
            & .mall-card {
              width: 100%;
              padding: 0;
              background: none;
              margin: 0;
              border-bottom: 1px solid #e8e8e8;
              height: 180px;
              padding-top: 20px;
              padding-bottom: 20px;
              &:last-of-type {
                border-bottom: none;
                padding-bottom: 0px;
              }

              & .mall-img {
                width: 30%;
                margin-right: 24px;
                & .LazyLoad {
                  width: auto;
                  & img {
                    width: 90px;
                    height: auto;
                  }
                }
              }
              & .mall-description-container {
                margin: 0;
                width: 100%;
                text-align: left;
                font-size: 15px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
                max-width: 62%;
                overflow: hidden;
                white-space: normal;
                & .mall-name-container {
                  display: flex;
                  width: 100%;
                  & .mall-name {
                    width: 90%;
                    height: 46px;
                    text-align: left;
                    font: normal normal bold 15px/24px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    overflow: hidden;
                    white-space: normal;
                    text-overflow: ellipsis;
                    max-height: 48px;
                  }
                  & .check-icon {
                    display: flex;
                    align-items: start;
                    justify-content: end;
                    margin: 0;
                    margin-left: 18px;
                  }
                }
                & .book-info {
                  margin-top: 11px;
                  & .mall-author {
                    width: 85%;
                    text-align: left;
                    font: normal normal normal 12.5px/12px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #777777;
                    opacity: 1;
                    overflow: hidden;
                    white-space: normal;
                    text-overflow: ellipsis;
                    max-height: 24px;
                  }
                }
                & .book-price-container {
                  & .book-price {
                    margin-top: 14px;
                    text-align: left;
                    font: normal normal bold 15px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                    max-width: 86%;
                  }
                }
              }
            }
            & .mobile-more-button {
              margin-top: 20px;
              width: 100%;
              height: 48px;
              background: #ffffff 0% 0% no-repeat padding-box;
              border: 1px solid #bababa;
              border-radius: 8px;
              text-align: center;
              font: normal normal normal 13px/12px Noto Sans KR;
              letter-spacing: 0px;
              color: #777777;
              opacity: 1;
            }
          }
        }
        & .modoo-mall-wrapper {
          margin-top: 50px;
          & .modoo-mall-container {
            padding: 0;
            & .title-container {
              & .title-text {
                text-align: left;
                font-size: 15px;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
              }
            }
            & .body-container {
              margin: 0;
              & .mall-card {
                width: 100%;
                padding: 20px 0;
                margin: 0;
                background: none;
                height: auto;
                border-bottom: 1px solid #e8e8e8;
                &:last-of-type {
                  border-bottom: none;
                  padding-bottom: 0px;
                }
                & .mall-img {
                  & img {
                    width: 100px;
                    height: 100px;
                  }
                }
                & .mall-description-container {
                  margin-left: 16px;
                  width: 100%;
                  & .mall-name-container {
                    align-items: center;
                    & .mall-name {
                      text-align: left;
                      font: normal normal bold 15px/12px Noto Sans KR;
                      letter-spacing: 0px;
                      color: #000000;
                      opacity: 1;
                      width: 90%;
                    }
                    & .check-icon {
                      margin: 0;
                      & .LazyLoad {
                        & img {
                          width: 20px;
                          height: 20px;
                        }
                      }
                    }
                  }
                  & .mall-description {
                    text-align: left;
                    font: normal normal normal 13px/21px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #777777;
                    opacity: 1;
                    margin-top: 8px;
                  }
                  & .hashtag-container {
                    margin-top: 17px;
                    flex-wrap: wrap;
                    & .hashtag {
                      // padding: 0;
                      text-align: left;
                      font: normal normal normal 11px/20px Noto Sans KR;
                      letter-spacing: 0px;
                      color: #000000;
                      opacity: 1;
                      border: 1px solid #e8e8e8;
                    }
                  }
                }
              }
              & .mobile-more-button {
                margin-top: 20px;
                margin-bottom: 50px;
                width: 100%;
                height: 48px;
                background: #ffffff 0% 0% no-repeat padding-box;
                border: 1px solid #bababa;
                border-radius: 8px;
                text-align: center;
                font: normal normal normal 13px/12px Noto Sans KR;
                letter-spacing: 0px;
                color: #777777;
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  // @media screen and (max-width: 1400px) {
  //   min-width: 360px;
  //   // width: auto;
  //   // max-width: 430px;
  //   & .myPage-wrapper {
  //     display: flex;
  //     // margin-top: 20px;
  //     padding: 0;
  //     min-width: 360px;
  //     width: 100%;
  //     margin: 0 auto;
  //     max-width: 480px !important;
  //   }
  //   & .mypage-container {
  //     margin: 50px 20px;
  //     min-width: 360px;
  //     display: flex;
  //     flex-direction: column;
  //     & .mobile-header {
  //       margin: 0 0 20px 0;
  //       & .header {
  //         display: flex;
  //         justify-content: space-between;
  //         .home-ico {
  //           width: 24px;
  //           height: 24px;
  //           color: #000000;
  //         }
  //       }
  //       & .mobile-title {
  //         text-align: left;
  //         font-size: 17px;
  //         font-weight: bold;
  //         letter-spacing: 0px;
  //         color: #000000;
  //         margin-top: 20px;
  //       }
  //     }
  //   }
  // }
}
