& .side-menu {
  display: flex;
  flex-direction: column;
  width: 126px;
  margin-left: auto;
  & .title {
    text-align: left;
    font: normal normal bold 24px/26px Noto Sans KR;
  }
  & .menu {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-top: 32px;
    & .menu-item {
      cursor: pointer;
      text-align: left;
      font: normal normal bold 14px/28px Noto Sans KR;
      color: #000000;
      margin-bottom: 12px;
      &.selected {
        font-weight: bold;
        text-decoration: underline;
      }
    }
  }
}
