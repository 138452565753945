#FieldDetail {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  & .detail-wrapper {
    display: flex;
    padding-top: 80px;
    @media screen and (max-width: 1400px) {
      flex-direction: column;
    }
    @import "./FieldDetailSideMenu.scss";
    @import "./FieldNewBookBannerContainer.scss";
    @import "./FieldEventBanner.scss";
  }
  & .detail-container {
    margin: 0 auto 120px 120px;
    display: flex;
    width: 1164px;
    flex-direction: column;
    & .title-container {
      margin-bottom: 32px;
      & .title {
        text-align: left;
        font: normal normal bold 24px/26px Noto Sans KR;
      }
    }
    & .sub-title-container {
      margin-bottom: 25px;
      & .sub-title {
        text-align: left;
        font: normal normal bold 20px/28px Noto Sans KR;
      }
    }
  }
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    @import "./FieldDetailSideMenu.scss";
    @import "./FieldNewBookBannerContainer.scss";
    @import "./FieldEventBanner.scss";
  }
}
