.no-search-title-container {
  text-align: center;
  margin: auto;
  & .no-search-result {
    font-size: 24px;
    font-weight: bold;
    text-align: left;
    @media screen and (max-width: 1400px) {
      padding: 20px;
      font-size: 17.5px;
    }
  }
}
