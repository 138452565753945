#Support {
  // 메인 Wrapper
  display: flex;
  flex-direction: column;
  & .support-main-wrapper {
    margin: 80px auto;
    display: flex;
    width: 1400px;
    @import "./SupportSideBar.scss";
    & .support-main-container {
      margin-left: 150px;
      width: 100%;
      height: auto;

      & .box-container {
        display: flex;
        & .box {
          margin-left: 15px;
          padding-left: 32px;
          width: 376px;
          height: 224px;
          /* UI Properties */
          background: #f5f5f5 0% 0% no-repeat padding-box;
          border-radius: 16px;
          opacity: 1;
          & .box-title {
            align-items: center;
            display: flex;
            margin-top: 32px;
            text-align: left;
            font: normal normal bold 20px/28px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
            .box-icons {
              margin-left: 8px;
              color: #777777;
              opacity: 1;
            }
          }
          & .box-content {
            margin-top: 16px;
            height: 51px;
            text-align: left;
            font: normal normal normal 15px/30px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .box-number {
            margin-top: 24px;
            height: 35px;
            text-align: left;
            font: normal normal bold 24px/24px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .box-button {
            width: 132px;
            height: 40px;
            margin-top: 24px;
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #1e76af;
            border-radius: 4px;
            opacity: 1;
            text-align: center;
            font: normal normal normal 13px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #1e76af;
            float: left;
          }
        }
      }
      & .qna-container {
        margin-top: 80px;
        .qna-main-title {
          margin-bottom: 30px;
          text-align: left;
          font: normal normal bold 24px/26px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        & .table-container {
          & .table-title {
            text-align: left;
            font: normal normal normal 13px/28px Noto Sans KR;
            letter-spacing: 0px;
            color: #777777;
            opacity: 1;
            width: 15%;
          }
          & .table-rows {
            text-align: left;
            font: normal normal normal 15px/27px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          .arrow {
            text-align: right;
          }
          .css-1rymryn-MuiTableCell-root {
            border-bottom: 1px;
          }
          .detail-content {
            background: #f5f5f5 0% 0% no-repeat padding-box;
            & .detail-text {
              text-align: left;
              font: normal normal normal 14px/28px Noto Sans KR;
              letter-spacing: 0px;
              color: #000000;
              opacity: 1;
            }
          }
        }
      }
      & .one-to-one-container {
        margin-top: 80px;
        .one-header {
          display: flex;
          justify-content: space-between;
          .one-main-title {
            margin-bottom: 30px;
            text-align: left;
            font: normal normal bold 24px/40px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          .one-all {
            text-align: right;
            font: normal normal normal 14px/26px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
        }
        .table-title {
          text-align: left;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
        }
        .table-rows {
          text-align: left;
          font: normal normal normal 15px/27px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        .css-1rymryn-MuiTableCell-root {
          border-bottom: 0px;
        }
        .table-status-clear {
          text-align: center;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #ffffff;
          .box {
            width: 64px;
            max-height: 24px;
            background: #000000 0% 0% no-repeat padding-box;
            border-radius: 4px;
            opacity: 1;
          }
        }
        .table-status-stay {
          text-align: center;
          font: normal normal normal 13px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #777777;
          opacity: 1;
          .box {
            width: 64px;
            max-height: 24px;
            background: #e8e8e8 0% 0% no-repeat padding-box;
            border-radius: 4px;
            opacity: 1;
          }
        }
        & .one-button {
          margin-left: 16px;
          background: #1e76af 0% 0% no-repeat padding-box;
          border-radius: 8px;
          color: white;
        }
        & .one-button {
          margin-top: 30px;
          margin-left: 1000px;
          width: 180px;
          height: 56px;
          display: flex;
          justify-content: space-between;
          font-size: 16px;
          & div {
            &:first-of-type {
              margin: auto 0 auto 20px;
            }
            &:last-of-type {
              margin: auto 10px auto auto;
            }
          }
        }
      }
    }
  }
  @import "./Notice.scss";
  @import "./NoticeDetail.scss";
  @import "./Faq.scss";
  @import "./OneToOne.scss";
  @import "./OneInquiry.scss";

  @media screen and (max-width: 1400px) {
    min-width: 320px;
    & .inquiry-main-wrapper {
      padding: 20px 0;
      margin: 0 auto;
      min-width: 320px;
      width: calc(100% - 40px);
      max-width: 440px;
      height: auto;
      margin-bottom: 50px;
      & .inquiry-main-container {
        margin: 0;
        & .main-title {
          text-align: left;
          font: normal normal bold 17.5px/13px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          height: auto;
        }
        & .inquiry-divider {
          width: 100%;
          margin-top: 30px;
          margin-bottom: 30px;
        }
        & .form-container {
          margin: 0;
          & form {
            & .MuiGrid-container {
              flex-direction: column;
              & .MuiGrid-item {
                max-width: 100%;
                margin-bottom: 20px;
                & .form {
                  & .MuiFormLabel-root {
                    color: var(---000000-text-);
                    text-align: left;
                    font: normal normal normal 14px/20px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #000000;
                    opacity: 1;
                  }
                  & .MuiFormControl-root {
                    margin-top: 12px;
                    width: 100%;
                    height: 48px;
                    & .MuiInputBase-root {
                      width: 100%;
                      height: 48px;
                      & input {
                        padding: 13.5px 12px;
                        color: var(---777777-sub-text-);
                        text-align: left;
                        font: normal normal normal 12px/20px Noto Sans KR;
                        letter-spacing: 0px;
                        color: #777777;
                        opacity: 1;
                      }
                    }
                  }
                  & .email-wrapper {
                    flex-direction: column;
                    & .MuiFormControl-root {
                      margin-bottom: 12px;
                    }
                    & .MuiButtonBase-root {
                      width: 100%;
                      border: 2px solid var(---1e76af-key-color-);
                      background: #ffffff 0% 0% no-repeat padding-box;
                      border: 2px solid #1e76af;
                      border-radius: 16px;
                      opacity: 1;
                      height: 48px;
                      color: var(---1e76af-key-color-);
                      text-align: left;
                      font: normal normal normal 14px/20px Noto Sans KR;
                      letter-spacing: 0px;
                      color: #1e76af;
                      opacity: 1;
                    }
                  }
                }
              }
            }
            & .form:nth-child(2) {
              margin-bottom: 20px;
              & .MuiFormControl-root {
                & .MuiFormLabel-root {
                  color: var(---000000-text-);
                  text-align: left;
                  font: normal normal normal 14px/20px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #000000;
                  opacity: 1;
                }
                & .MuiInputBase-root {
                  margin-top: 12px;
                  width: 150px;
                  height: 48px;
                  color: var(---000000-text-);
                  text-align: left;
                  font: normal normal normal 14px/20px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #000000;
                  opacity: 1;
                  & .MuiSelect-select {
                    padding: 13.5px 12px;
                  }
                }
                & .MuiFormHelperText-root {
                  margin: 0;
                  margin-top: 10px;
                  text-align: left;
                  font: normal normal normal 13px/20px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #ec1f2d;
                  opacity: 1;
                }
              }
            }
            & .form:nth-child(3) {
              margin-bottom: 20px;
              & .MuiFormLabel-root {
                margin: 0;
                color: var(---000000-text-);
                text-align: left;
                font: normal normal normal 14px/20px Noto Sans KR;
                letter-spacing: 0px;
                color: #000000;
                opacity: 1;
              }
              & .qna-title-field {
                margin-top: 12px;
                width: 100%;
                height: 48px;
                & .MuiInputBase-root {
                  width: 100%;
                  height: 48px;
                  & input {
                    padding: 13.5px 12px;
                    color: var(---777777-sub-text-);
                    text-align: left;
                    font: normal normal normal 12px/20px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #777777;
                    opacity: 1;
                  }
                }
              }
              & .MuiFormHelperText-root {
                margin: 0;
                margin-top: 10px;
                text-align: left;
                font: normal normal normal 13px/20px Noto Sans KR;
                letter-spacing: 0px;
                color: #ec1f2d;
                opacity: 1;
              }
              & .qna-text-area {
                margin-top: 12px;
                width: 100%;
                & .MuiInputBase-root {
                  padding: 13.5px 12px;
                  & .MuiInputBase-input {
                    color: var(---777777-sub-text-);
                    text-align: left;
                    font: normal normal normal 14px/20px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #777777;
                    opacity: 1;
                    height: 230px !important;
                  }
                }
              }
            }
            & .form:nth-child(4) {
              & .img-container {
                justify-content: space-between;
                & .MuiButtonBase-root {
                  margin: 0;
                  color: var(---1e76af-key-color-);
                  text-align: center;
                  font: normal normal normal 14px/20px Noto Sans KR;
                  letter-spacing: 0px;
                  opacity: 1;
                  height: 48px;
                  width: 160px;
                }
                & button:first-child {
                  color: #1e76af;
                }
                & button:last-child {
                  color: #ffffff;
                }
              }
            }
          }
        }
      }
    }
    ul {
      margin: 0;
      padding: 0;
      list-style-type: none;
    }
    & .one-main-wrapper {
      margin: 0;
      & .one-main-container {
        margin: 0 auto;
        min-width: 320px;
        width: calc(100% - 40px);
        max-width: 440px;
        padding-top: 20px;
        & .one-main-title {
          text-align: left;
          font: normal normal bold 17.5px/13px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          height: auto;
        }
        & .one-grid-container {
          margin-top: 30px;
          border-top: 1px solid rgba(0, 0, 0, 0.2);
          & .list-box {
            & .MuiPaper-root {
              border: none;
              box-shadow: none;
              border-radius: 0;
              overflow-x: initial;
              transition: none;
              & .MuiTable-root {
                border: none;
                box-shadow: none;
                border-radius: 0;
                overflow-x: initial;
                transition: none;
              }
            }
          }
          & .mobile-container {
            display: flex;
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            & .detail-btn {
              width: 10%;
              display: flex;
              align-items: center;
              justify-content: end;
              & button {
                padding: 0;
              }
            }
            & ul {
              width: 90%;
              & li {
                display: flex;
                align-items: start;
              }
              & li:nth-child(1) {
                margin-top: 16px;
                margin-bottom: 10px;
                & .list-type-name {
                  color: var(---777777-sub-text-);
                  text-align: left;
                  font: normal normal normal 12px/14px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #777777;
                  opacity: 1;
                }
              }
              & li:nth-child(2) {
                margin-bottom: 11px;
                & .list-title {
                  color: var(---000000-text-);
                  text-align: left;
                  font: normal normal normal 13px/24px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #000000;
                  opacity: 1;
                }
              }
              & li:nth-child(3) {
                margin-bottom: 17.5px;
                & .list-reg-date {
                  color: var(---777777-sub-text-);
                  text-align: left;
                  font: normal normal normal 12px/14px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #777777;
                  opacity: 1;
                  margin-right: 12px;
                }
                & .table-status-clear {
                  text-align: center;
                  font: normal normal normal 7.5px/14px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #ffffff;
                  & .box {
                    width: 45px;
                    max-height: 14px;
                    background: #000000 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                    opacity: 1;
                  }
                }
                & .table-status-stay {
                  text-align: center;
                  font: normal normal normal 7.5px/14px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #777777;
                  & .box {
                    width: 45px;
                    max-height: 14px;
                    background: #f5f5f5 0% 0% no-repeat padding-box;
                    border-radius: 4px;
                    opacity: 1;
                  }
                }
              }
            }
          }
          & .MuiCollapse-wrapperInner {
            border-bottom: 1px solid rgba(0, 0, 0, 0.2);
            & .qna-container {
              padding: 20px;
              & .qna-box {
                & .q-box {
                  color: var(---000000-text-);
                  text-align: left;
                  font: normal normal normal 12px/23px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #000000;
                  opacity: 1;
                  & .letter-Q {
                    color: var(---1e76af-key-color-);
                    text-align: left;
                    font: normal normal normal 13px/15px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #1e76af;
                    opacity: 1;
                    margin-right: 14.5px;
                  }
                }
                & .middle-line {
                  width: 100%;
                  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
                  margin-top: 30px;
                  margin-bottom: 20px;
                }
                & .a-box {
                  color: var(---000000-text-);
                  text-align: left;
                  font: normal normal normal 12px/23px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #000000;
                  opacity: 1;
                  & .letter-A {
                    color: var(---1e76af-key-color-);
                    text-align: left;
                    font: normal normal normal 13px/15px Noto Sans KR;
                    letter-spacing: 0px;
                    color: #1e76af;
                    opacity: 1;
                    margin-right: 14.5px;
                  }
                }
              }
              & .btn-container {
                display: flex;
                justify-content: end;
                margin-top: 30px;
                & .mod-btn {
                  margin-right: 8px;
                }
                & .detail-btn {
                  width: 50px;
                  height: 24px;
                  border: 1px solid var(---bababa-line_darker-);
                  background: #ffffff 0% 0% no-repeat padding-box;
                  border: 1px solid #bababa;
                  border-radius: 4px;
                  opacity: 1;
                  color: var(---777777-sub-text-);
                  text-align: center;
                  font: normal normal normal 11px/14px Noto Sans KR;
                  letter-spacing: 0px;
                  color: #777777;
                  opacity: 1;
                }
              }
            }
          }
          & .one-button {
            margin: 0;
            margin-top: 30px;
            margin-bottom: 50px;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            text-align: center;
            font: normal normal normal 15px/20px Noto Sans KR;
            letter-spacing: 0px;
            color: #ffffff;
            opacity: 1;
            height: 48px;
          }
        }
      }
    }
  }
}
