& .setMainDest-modal {
  width: 418px;
  height: 256px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 48px 48px 40px 48px;
  & .modal-body {
    text-align: center;
    font: normal normal normal 16px/32px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    & .close-icon {
      align-self: end;
      color: #bababa;
      margin-bottom: 32px;
      cursor: pointer;
    }
    & .btn {
      width: 162px;
      height: 56px;
      background: #1e76af 0% 0% no-repeat padding-box;
      border-radius: 8px;
      text-align: center;
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #ffffff;
      margin-top: 32px;
    }
  }
}
