.footer-wrapper {
  display: flex;
  flex-direction: column;
  background-color: black;
  color: white;
  & button {
    background-color: black;
    color: white;
    padding: 10px;
  }
  & .first-line-container {
    width: 1400px;
    margin: 0 auto;
    height: 65px;
    display: flex;
    & .first-line-left {
      display: flex;
      & .divider {
        margin: auto 5px;
        width: 0;
        height: 10px;
        border-left: 1px solid rgba(#ffffff, 0.3);
      }
    }
    & .first-line-right {
      display: flex;
      margin-left: auto;
    }
  }
  & .divider {
    border-bottom: 1px solid rgba(#ffffff, 0.3);
  }
  & .second-line-container {
    width: 1400px;
    margin: 0 auto;
    height: 200px;
    padding: 40px 0 80px;
    background-color: black;
    display: flex;
    & .second-line-left {
      display: flex;
      flex-direction: column;
      & .title {
        font-size: 36px;
        font-weight: bold;
        text-align: left;
      }
      & .copyright {
        margin-top: 22px;
        font-size: 10px;
        opacity: 0.5;
      }
    }
    & .second-line-right {
      display: flex;
      flex-direction: row;
      text-align: left;
      font-size: 12px;
      & .first,
      & .second,
      & .third {
        & div {
          display: flex;
          & span {
            width: 10px;
            margin-right: 26px;
            & img {
              margin-top: 2px;
              margin-right: -16px;
            }
          }
        }
      }
      & .first {
        margin-left: 200px;
        & div {
          &:nth-of-type(2) {
            margin-top: 8px;
          }
          &:nth-of-type(3) {
            margin-top: 20px;
          }
        }
      }
      & .second {
        margin-left: 70px;
        & div {
          &:nth-of-type(2) {
            margin-top: 20px;
          }
          &:nth-of-type(3) {
            margin-top: 8px;
          }
        }
      }
      & .third {
        margin-left: 70px;
        font-size: 10px;
        & div {
          &:nth-of-type(2) {
            margin: 17px 0;
          }
        }
      }
    }
  }
  @media screen and (max-width: 1400px) {
    //
    padding: 20px;
    & .mobile {
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
      margin: 0 auto;
    }

    & .mobile-first-line {
      & .menu {
        width: 100%;
        border-radius: 16px;
        color: #777777;
        text-align: left;
        & .MuiOutlinedInput-notchedOutline {
          display: none;
        }
      }
    }
    & .mobile-second-line {
      display: flex;
      margin: 20px auto 0;
      padding-bottom: 20px;
      border-bottom: 1px solid #555555;
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
      font-size: 11px;
      & button {
        text-align: left;
        font-size: 11px;
      }
      & .left {
        display: flex;
        flex-direction: column;
      }
      & .right {
        margin-left: auto;
        width: 140px;
        display: flex;
        flex-direction: column;
        & button {
          color: #bababa;
        }
      }
    }
    & .mobile-third-line {
      display: flex;
      flex-direction: column;
      padding: 20px 0 10px;
      text-align: left;
      & .title {
        font-size: 15px;
        font-weight: bold;
      }
      & .copyright {
        margin-top: 10px;
        font-size: 10px;
        opacity: 0.5;
      }
    }
  }
}
