& .top-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
& .title-text {
  margin: 32px 0;
  text-align: left;
  font: normal normal bold 16px/32px Noto Sans KR;
  letter-spacing: 0px;
}
& .select {
  width: 180px;
  height: 48px;
  background: #ffffff 0% 0% no-repeat padding-box;
  // border: 1px solid #BABABA;
  border-radius: 8px;
}

& .title-bar-container {
  // border-top: 1px solid #e8e8e8;
  height: 32px;
  align-items: center;
  // padding: 16px 0;
  display: flex;
  & .button {
    width: 80px;
    height: 32px;
    margin-left: 12px;
    cursor: pointer;
    &.cart {
      width: 88px;
    }
    &.excel-download {
      width: 130px;
    }
  }
  & .sort-button {
    color: #777777;
    &.first {
      margin-left: auto;
    }
    &.selected {
      color: black;
      font-weight: bold;
    }
  }
  & .divider {
    height: 12px;
    width: 1px;
    background-color: #e8e8e8;
    margin: auto 12px;
  }
}

& .btn-container {
  display: flex;
  margin: 12px 0;

  & .deleteBtn {
    background: #ffffff 0% 0% no-repeat padding-box;
    width: 64px;
    height: 32px;
    text-align: left;
    font: normal normal normal 13px/24px Noto Sans KR;
    letter-spacing: 0px;
    color: #333333;
    padding: 0;
    cursor: pointer;
  }
  & .cartBtn {
    width: 88px;
    height: 32px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #bababa;
    border-radius: 4px;
    text-align: left;
    font: normal normal normal 13px/24px Noto Sans KR;
    letter-spacing: 0px;
    color: #333333;
    padding: 0;
    margin: 0 12px;
  }
  & .fileDownBtn {
    width: 130px;
    height: 32px;
    background: #ffffff 0% 0% no-repeat padding-box;
    border: 1px solid #bababa;
    border-radius: 4px;
    text-align: left;
    font: normal normal normal 13px/24px Noto Sans KR;
    letter-spacing: 0px;
    color: #333333;
    padding: 0;
  }
}
& .body-container {
  margin-top: 32px;
  // height: 144px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  overflow: hidden;
  height: auto;
  & .mall-card {
    width: 572px;
    height: 184px;
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 8px;
    display: flex;
    padding: 16px;
    margin-bottom: 20px;
    & .check-card {
      margin: 0 16px 0 0;
    }
    & .css-12wnr2w-MuiButtonBase-root-MuiCheckbox-root {
      padding: 0;
    }
    & .book-img img {
      width: 100px;
      height: 148px;
      box-shadow: 0px 5px 10px #00000029;
      border-radius: 8px;
      cursor: pointer;
      // margin-left: 56px;
    }
    & .mall-img img {
      width: 140px;
      height: 140px;
      box-shadow: 0px 5px 10px #00000029;
      border-radius: 8px;
      cursor: pointer;
      // margin-left: 56px;
    }
    & .desc-container {
      margin-left: 24px;
      display: flex;
      flex-direction: column;
      width: 476px;
      & .close-icon {
        display: flex;
        justify-content: flex-end;
        cursor: pointer;
      }
      & .mall-name-container {
        display: flex;
        cursor: pointer;
        justify-content: space-between;
        & .mall-name-box {
          display: flex;
          & .mall-name {
            color: var(---000000-text-);
            text-align: left;
            font: normal normal bold 16px/24px Noto Sans KR;
            letter-spacing: 0px;
            color: #000000;
            opacity: 1;
          }
          & .check-icon {
            margin-left: auto;
            cursor: pointer;
          }
        }
        & .mall-visit-container {
          cursor: auto;
          display: flex;
          text-align: left;
          font: normal normal normal 12px/22px Noto Sans KR;
          letter-spacing: 0px;
          color: #999999;
          & .vl {
            border-left: 1px solid#BABABA;
            width: 0;
            height: 12px;
            margin: auto 8.5px;
          }
        }
      }
      & .mall-author {
        color: var(---000000-text-);
        text-align: left;
        font: normal normal normal 14px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .mall-price {
        margin-top: 12px;
        color: var(---000000-text-);
        text-align: left;
        font: normal normal bold 16px/24px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
      & .mall-description {
        color: var(---000000-text-);
        text-align: left;
        font: normal normal normal 14px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        line-height: 1.6;
        max-width: 310px;
        // margin-top: 16px;
      }
      & .location-container {
        display: flex;
        color: var(---000000-text-);
        text-align: left;
        font: normal normal normal 14px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        & .location-icon {
          margin-right: 8.81px;
          background: var(---bababa-line_darker-) 0% 0% no-repeat padding-box;
        }
        & .ellipsis {
          max-width: 300px;
        }
      }
      & .hashtag-container {
        display: flex;
        margin-top: 10px;
        & .hashtag {
          height: 23px;
          line-height: 17px;
          margin-right: 8px;
          border-radius: 4px;
          padding: 2px 8px;
          background-color: white;
          font-size: 11px;
          cursor: pointer;
        }
      }
    }
  }
}
& .pagination-container {
  margin: 48px auto 120px auto;
  // width: 318px;
  height: 24px;
  opacity: 1;
  display: flex;
  justify-content: center;
  & .pagination-container .pagination {
    text-align: center;
    font-size: 13px;
    // font: normal normal normal 13px/24px Noto Sans KR;
    letter-spacing: 0px;
    color: #1e76af;
    opacity: 1;
  }
  & .css-wjh20t-MuiPagination-ul {
    justify-content: center;
  }
}

// @media screen and (max-width: 1400px) {
//   & .top-container {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     & .select {
//       width: 180px;
//       height: 48px;
//       background: #ffffff 0% 0% no-repeat padding-box;
//       border-radius: 8px;
//     }
//   }
//   & .title-text {
//     margin: 30px 0;
//     text-align: left;
//     font-size: 15px;
//     font-weight: bold;
//     letter-spacing: 0px;
//   }

//   & .body-container {
//     margin-top: 32px;
//     display: flex;
//     flex-wrap: wrap;
//     justify-content: space-between;
//     overflow: hidden;
//     height: auto;
//     border-bottom: 1px solid #e8e8e8;

//     & .mall-card {
//       width: 100%;
//       height: 195px;
//       background: #ffffff 0% 0% no-repeat padding-box;
//       border-radius: 0;
//       display: flex;
//       padding: 16px;
//       margin-bottom: 0;
//       padding: 40px 0;
//       border-top: 1px solid #e8e8e8;

//       & .book-img img {
//         box-shadow: 0px 5px 10px #00000029;
//         border-radius: 8px;
//         cursor: pointer;
//         width: 80px;
//         height: 115px;
//       }
//       & .mall-img img {
//         height: 100px;
//         width: 100px;
//         box-shadow: 0px 5px 10px #00000029;
//         border-radius: 8px;
//         opacity: 1;
//         cursor: pointer;
//       }
//       & .mall-description-container {
//         margin-left: 24px;
//         width: 292px;
//         & .mall-name-container {
//           align-items: center;
//           display: flex;
//           justify-content: space-between;
//           cursor: pointer;
//           & .icon-container {
//             display: flex;
//             & .close-icon {
//               margin-left: 20px;
//               cursor: pointer;
//             }
//             & .cart-icon {
//               margin-top: 1px;
//             }
//           }
//           & .mall-name {
//             text-align: left;
//             font-size: 15px;
//             font-weight: bold;
//             letter-spacing: 0px;
//             color: #000000;
//             opacity: 1;
//             // display: flex;
//             // & .close-icon {
//             // }
//           }
//         }
//         & .mall-description {
//           text-align: left;
//           font-size: 13px;
//           font-weight: normal;
//           letter-spacing: 0px;
//           color: #000000;
//           margin-top: 13px;
//         }
//         & .hashtag-container {
//           display: flex;
//           margin-top: 10px;
//           & .mobile-hashtag {
//             height: 18px;
//             background: #ffffff 0% 0% no-repeat padding-box;
//             border: 1px solid #cccccc;
//             border-radius: 2px;
//             text-align: left;
//             font-size: 11px;
//             letter-spacing: 0px;
//             color: #000000;
//             padding: 1px 4px;
//             margin-right: 6px;
//           }
//         }
//         & .book-info {
//           display: flex;
//           align-items: center;
//           justify-content: space-between;
//           margin-top: 15px;
//           & .mall-author {
//             text-align: left;
//             font-size: 12px;
//             letter-spacing: 0px;
//             color: #777777;
//             opacity: 1;
//           }
//           & .mobile-mall-info {
//             text-align: left;
//             font-size: 12px;
//             letter-spacing: 0px;
//             color: #777777;
//           }
//           & .divider {
//             height: 12px;
//             border-left: 1px solid #c2c2c2;
//             margin: auto 0;
//           }
//         }
//         & .book-price-container {
//           display: flex;
//           align-items: center;
//           margin-top: 13px;
//           & .mobile-discount {
//             width: 31px;
//             height: 20px;
//             background: #1e76af 0% 0% no-repeat padding-box;
//             border-radius: 4px;
//             text-align: left;
//             font-size: 11px;
//             font-weight: bold;
//             letter-spacing: 0px;
//             color: #ffffff;
//             display: flex;
//             justify-content: space-around;
//             align-items: center;
//           }
//           & .book-price {
//             margin-left: 12px;
//             margin-top: 0;
//             text-align: left;
//             font-size: 12px;
//             letter-spacing: 0px;
//             font-weight: bold;
//             color: #000000;
//             opacity: 1;
//           }
//           & .mobile-price {
//             text-align: center;
//             text-decoration: line-through;
//             font-size: 12px;
//             letter-spacing: 0px;
//             color: #777777;
//             margin-left: 10px;
//           }
//         }
//       }
//     }

//     // & .mall-card {
//     //   width: 100%;
//     //   height: 195px;
//     //   background: #ffffff 0% 0% no-repeat padding-box;
//     //   border-radius: 0;
//     //   display: flex;
//     //   padding: 16px;
//     //   margin-bottom: 0;
//     //   padding: 40px 0;
//     //   border-top: 1px solid #e8e8e8;
//     //   & .check-card {
//     //     margin: 0 16px 0 0;
//     //   }
//     //   & .book-img img {
//     //     width: 80px;
//     //     height: 115px;
//     //     box-shadow: 0px 5px 10px #00000029;
//     //     border-radius: 8px;
//     //     cursor: pointer;
//     //     // margin-left: 56px;
//     //   }
//     //   & .mall-img img {
//     //     width: 140px;
//     //     height: 140px;
//     //     box-shadow: 0px 5px 10px #00000029;
//     //     border-radius: 8px;
//     //     cursor: pointer;
//     //     // margin-left: 56px;
//     //   }
//     //   & .desc-container {
//     //     margin-left: 24px;
//     //     display: flex;
//     //     flex-direction: column;
//     //     width: 476px;
//     //     & .close-icon {
//     //       display: flex;
//     //       justify-content: flex-end;
//     //       cursor: pointer;
//     //     }
//     //     & .mall-name-container {
//     //       display: flex;
//     //       cursor: pointer;
//     //       justify-content: space-between;
//     //       & .mall-name-box {
//     //         display: flex;
//     //         & .mall-name {
//     //           text-align: left;
//     //           font-size: 15px;
//     //           font-weight: bold;
//     //           letter-spacing: 0px;
//     //           color: #000000;
//     //           opacity: 1;
//     //         }
//     //         & .check-icon {
//     //           margin-left: auto;
//     //           cursor: pointer;
//     //         }
//     //       }
//     //       & .mall-visit-container {
//     //         cursor: auto;
//     //         display: flex;
//     //         text-align: left;
//     //         font: normal normal normal 12px/22px Noto Sans KR;
//     //         letter-spacing: 0px;
//     //         color: #999999;
//     //         & .vl {
//     //           border-left: 1px solid#BABABA;
//     //           width: 0;
//     //           height: 12px;
//     //           margin: auto 8.5px;
//     //         }
//     //       }
//     //     }
//     //     & .mall-author {
//     //       color: var(---000000-text-);
//     //       text-align: left;
//     //       font: normal normal normal 14px/24px Noto Sans KR;
//     //       letter-spacing: 0px;
//     //       color: #000000;
//     //       opacity: 1;
//     //     }
//     //     & .mall-price {
//     //       margin-top: 12px;
//     //       color: var(---000000-text-);
//     //       text-align: left;
//     //       font: normal normal bold 16px/24px Noto Sans KR;
//     //       letter-spacing: 0px;
//     //       color: #000000;
//     //       opacity: 1;
//     //     }
//     //     & .mall-description {
//     //       color: var(---000000-text-);
//     //       text-align: left;
//     //       font: normal normal normal 14px/40px Noto Sans KR;
//     //       letter-spacing: 0px;
//     //       color: #000000;
//     //       opacity: 1;
//     //       // margin-top: 16px;
//     //     }
//     //     & .location-container {
//     //       display: flex;
//     //       color: var(---000000-text-);
//     //       text-align: left;
//     //       font: normal normal normal 14px/40px Noto Sans KR;
//     //       letter-spacing: 0px;
//     //       color: #000000;
//     //       & .location-icon {
//     //         margin-right: 8.81px;
//     //         background: var(---bababa-line_darker-) 0% 0% no-repeat padding-box;
//     //       }
//     //     }
//     //     & .hashtag-container {
//     //       display: flex;
//     //       margin-top: 10px;
//     //       & .hashtag {
//     //         height: 23px;
//     //         line-height: 17px;
//     //         margin-right: 8px;
//     //         border-radius: 4px;
//     //         padding: 2px 8px;
//     //         background-color: white;
//     //         font-size: 11px;
//     //         cursor: pointer;
//     //       }
//     //     }
//     //   }
//     // }
//   }
// }
