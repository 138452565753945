$primaryColor: #1e76af;

#CartView {
  & .cartView-main-container {
    padding: 84px 0 80px 0;
    margin: auto;
    display: flex;
    @media screen and (max-width: 1400px) {
      padding: unset;
      margin: 35px auto 35px auto;
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      flex-direction: column;
    }
    & * {
      text-align: initial;
    }
    & .title {
      font-size: 24px;
      color: #000;
      font-weight: 600;
      line-height: 1.2;
      margin: 0 0 37px 0;
    }

    & .cart-list {
      /* cart */
      margin-left: auto;
      @media screen and (max-width: 1400px) {
        margin: 0;
      }
      @import "./Cart.scss";
    }
    & .perchase {
      /* order steps container*/
      margin-right: auto;
      margin-left: 56px;
      position: sticky;
      height: fit-content;
      top: 80px;
      @media screen and (max-width: 1400px) {
        margin: 0;
      }
      @import "./Purchase.scss";
    }
  }
}
