& .sign-out-modal {
  width: 418px;
  height: 288px;
  background: #ffffff 0% 0% no-repeat padding-box;
  border-radius: 20px;
  padding: 40px 48px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  & .modal-text {
    display: flex;
    flex-direction: column;
    text-align: center;
    font-weight: normal;
    font-size: 16px;
    // font: normal normal normal 16px/32px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    margin: 0 19px;
    & .close-icon {
      align-self: end;
      color: #bababa;
      margin-bottom: 32px;
      cursor: pointer;
    }
  }
  & .btn-container {
    display: flex;
    justify-content: space-between;
    & .cancel-button {
      width: 155px;
      height: 56px;
      background: #ffffff 0% 0% no-repeat padding-box;
      border: 1px solid #1e76af;
      border-radius: 8px;
    }
    & .button {
      width: 155px;
      height: 56px;
      background: #1e76af 0% 0% no-repeat padding-box;
      border-radius: 8px;
      text-align: center;
      font: normal normal normal 16px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #ffffff;
    }
  }
}
