& .side-menu {
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  width: 186px;
  margin-left: auto;
  margin-bottom: 120px;
  & .title {
    text-align: left;
    font: normal normal bold 24px/26px Noto Sans KR;
  }
  & .menu {
    // display: flex;
    // flex-direction: column;
    // text-align: left;
    margin-top: 32px;
    // 1dep 감싸고 있는 요소
    text-align: start;

    // 공통 메뉴 list
    & .MuiTreeItem-content {
      // outline: 1px solid red;
      &:hover {
        min-width: 100%;
        width: max-content;
        z-index: 100;
        & .MuiTreeItem-label {
        }
      }
      display: flex;
      flex-direction: row-reverse;
      padding: 5px 8px;
      position: relative;
      &:hover {
        background: none;
      }
      // 클릭 요소
      &.Mui-selected {
        &::after {
          content: "";
          // outline: 1px solid red;
          position: absolute;
          width: 100%;
          height: 100%;
          left: 0;
          bottom: 0;
          background-color: rgba(199, 199, 199, 0.25);
          animation: moveBorder 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            alternate both;
        }
        background-color: #fff;
        // 클릭한 요소 label 색 변경
        & .MuiTreeItem-label {
          color: #000;
        }
        // outline: 1px solid red;
      }
      // 공통 메뉴의 lable
      & .MuiTreeItem-label {
        text-overflow: ellipsis !important;
        overflow: hidden !important;
        white-space: nowrap !important;
        // outline: 1px solid red;
        width: 100%;
        font: normal normal bold 14px/28px Noto Sans KR;
      }
    }
    // 1dep을 제외한 다른 메뉴 label 공통 css
    & .MuiCollapse-root {
      padding: 0;
      & .MuiCollapse-wrapper {
        & .MuiCollapse-wrapperInner {
          & .MuiTreeItem-content {
            & .MuiTreeItem-label {
              font: normal normal normal 12px/28px Noto Sans KR;
              font-size: 12px;
              color: #5f5f5f;
            }
          }
        }
      }
    }

    // 메뉴
    & .MuiSimpleTreeView-root {
      // 공통 클릭한 요소
      & .Mui-expanded {
        &::after {
          content: "";
          // outline: 1px solid red;
          position: absolute;
          width: 100%;
          height: 1px;
          left: 0;
          bottom: 0;
          background-color: #000000;
          animation: moveBorder 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
            alternate both;
        }
        & .MuiTreeItem-label {
          color: #000;
        }
      }
      // 1dep
      & > li {
        margin-bottom: 12px;
        // 1dep content
        & > .MuiTreeItem-content {
          background-color: #fff;
          // &:hover {
          //   min-width: 100%;
          //   width: max-content;
          //   & .MuiTreeItem-label {
          //   }
          // }
        }
        // 2dep 감싸고 있는 요소
        & > ul {
          & > .MuiCollapse-wrapper {
            & > .MuiCollapse-wrapperInner {
              & > li {
                //  2dep content
                & > .MuiTreeItem-content {
                  background-color: #fff;
                  & .MuiTreeItem-label {
                    margin-left: 12px;
                  }
                }
                // 3dep 감싸고 있는 요소
                & > ul {
                  & > .MuiCollapse-wrapper {
                    & > .MuiCollapse-wrapperInner {
                      & > li {
                        //  3dep content
                        background-color: #fafafa;
                        & > .MuiTreeItem-content {
                          background-color: #fafafa;
                          // outline: 1px solid red;
                          & .MuiTreeItem-label {
                            margin-left: 24px;
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

& .mobile-side-menu {
  // display: flex;
  // flex-direction: column;
  // max-width: 1400px;
  // margin: auto;
  // padding-top: 48px;
  -webkit-tap-highlight-color: transparent;
  cursor: pointer;
  user-select: none;
  display: flex;
  flex-direction: column;
  // width: 186px;
  max-width: 1400px;
  margin: auto;
  // margin-left: auto;
  // margin-bottom: 120px;
  @media screen and (max-width: 1400px) {
    width: 100vw;
    min-width: 320px;
    max-width: 440px;
    margin: 0 auto;
    // padding-top: 30px;
    & .mobile-menu {
      text-align: left;
      // 공통 메뉴 list
      & .MuiTreeItem-content {
        height: 45px;
        display: flex;
        flex-direction: row-reverse;
        padding: 5px 20px;
        position: relative;
        &:hover {
          background: none;
        }
        // 클릭 요소
        &.Mui-selected {
          background-color: rgba(199, 199, 199, 0);
          color: #000;
          &::after {
            content: "";
            // outline: 1px solid red;
            position: absolute;
            width: 100%;
            height: 100%;
            left: 0;
            bottom: 0;
            background-color: rgba(199, 199, 199, 0.25);
            animation: moveBorder 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
              alternate both;
          }
          // 클릭한 요소 label 색 변경
          & .MuiTreeItem-label {
            color: #000;
          }
          // outline: 1px solid red;
        }
        // 공통 메뉴의 lable
        & .MuiTreeItem-label {
          text-overflow: ellipsis !important;
          overflow: hidden !important;
          white-space: nowrap !important;
          // outline: 1px solid red;
          width: 100%;
          font: normal normal bold 14px/28px Noto Sans KR;
        }
      }
      // 1dep을 제외한 다른 메뉴 label 공통 css
      & .MuiCollapse-root {
        padding: 0;
        & .MuiCollapse-wrapper {
          & .MuiCollapse-wrapperInner {
            & .MuiTreeItem-content {
              & .MuiTreeItem-label {
                font: normal normal normal 12px/28px Noto Sans KR;
                font-size: 12px;
                color: #5f5f5f;
              }
            }
          }
        }
      }

      // 메뉴
      & .MuiSimpleTreeView-root {
        // 공통 클릭한 요소
        & .Mui-expanded {
          &::after {
            content: "";
            // outline: 1px solid red;
            position: absolute;
            width: 100%;
            height: 1px;
            left: 0;
            bottom: 0;
            background-color: #000000;
            animation: moveBorder 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94)
              alternate both;
          }
          & .MuiTreeItem-label {
            color: #000;
          }
        }
        // 1dep
        & > li {
          margin-bottom: 12px;
          // 1dep content
          & > .MuiTreeItem-content {
            &::after {
              content: "";
              // outline: 1px solid red;
              display: none;
            }
          }
          // 2dep 감싸고 있는 요소
          & > ul {
            & > .MuiCollapse-wrapper {
              & > .MuiCollapse-wrapperInner {
                & > li {
                  //  2dep content
                  & > .MuiTreeItem-content {
                    & .MuiTreeItem-label {
                      margin-left: 12px;
                    }
                  }
                  // 3dep 감싸고 있는 요소
                  & > ul {
                    background-color: #fafafa;
                    & > .MuiCollapse-wrapper {
                      & > .MuiCollapse-wrapperInner {
                        & > li {
                          //  3dep content
                          & > .MuiTreeItem-content {
                            & .MuiTreeItem-label {
                              // outline: 1px solid red;
                              margin-left: 24px;
                            }
                          }
                          // 4dep 감싸고 있는 요소
                          & > ul {
                            background-color: #f1f1f1;
                            & > .MuiCollapse-wrapper {
                              & > .MuiCollapse-wrapperInner {
                                & > li {
                                  //  4dep content
                                  & > .MuiTreeItem-content {
                                    & .MuiTreeItem-label {
                                      // outline: 1px solid red;
                                      margin-left: 36px;
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
@keyframes moveBorder {
  from {
    width: 0%;
  }
  to {
    width: 100%;
  }
}
// side menu 닫았을때 border
@keyframes BorderComeBack {
  from {
    width: 100%;
  }
  to {
    width: 0%;
  }
}
