& .support-subMenu-container {
  width: 88px;
  margin-left: auto;
  float: left;
  & .sub-menu-header {
    color: var(---000000-text-);
    text-align: left;
    font: normal normal bold 24px/26px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    margin-bottom: 32px;
    white-space: nowrap;
  }
  & button {
    padding: 1px 0;
  }
  & .sub-menu-list-item {
    /* UI Properties */
    color: var(---000000-text-);
    text-align: left;
    font: normal normal bold 14px/28px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
    white-space: nowrap;
    width: 88px;
    &.selected {
      font-weight: bold;
      text-decoration: underline;
    }
  }
  & .sub-menu-text1 {
    margin-top: 16px;
    color: var(---000000-text-);
    text-align: left;
    font: normal normal normal 13px/28px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  & .sub-menu-phone {
    margin-top: 8px;
    color: var(---000000-text-);
    text-align: left;
    font: normal normal bold 14px/28px Noto Sans KR;
    letter-spacing: 0px;
    color: #000000;
    opacity: 1;
  }
  & .sub-menu-noti {
    margin-top: 10px;
    text-align: left;
    font: normal normal normal 12px/20px Noto Sans KR;
    letter-spacing: 0px;
    color: #6a6a6a;
    opacity: 1;
    white-space: nowrap;
  }
}
