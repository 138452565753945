#UnivGoods {
  // 이미지 드래그 방지
  img {
    -webkit-user-drag: none;
    -khtml-user-drag: none;
    -moz-user-drag: none;
    -o-user-drag: none;
    user-drag: none;
  }
  // 드래그 방지
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-use-select: none;
  user-select: none;
  & .univ-goods-page-wrapper {
    margin-top: 80px;
    // 굿즈 title
    & .univ-goods-title-container {
      width: 1400px;
      display: flex;
      margin: 0 auto;
      & .univ-goods-title-text {
        text-align: left;
        font: normal normal bold 24px / 26px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }

    ::-webkit-scrollbar {
      width: 10px;
    }
    ::-webkit-scrollbar-track {
      background: rgba(189, 189, 189, 0.1); /*스크롤바 뒷 배경 색상*/
    }
    ::-webkit-scrollbar-thumb {
      background: rgba(176, 176, 176, 0.5); /* 스크롤바 색상 */
      border-radius: 10px; /* 스크롤바 둥근 테두리 */
      &:hover {
        background: rgba(
          176,
          176,
          176,
          1
        ); /* 스크롤이 hover 됬을때 스크롤바 색상 */
      }
    }

    // 굿즈 소개 content
    & .univ-goods-content-wrap {
      margin-top: 20px;
      margin-bottom: 80px;
      & .univ-goods-container {
        // outline: 1px solid red;
        width: 1400px;
        height: 800px;
        overflow: hidden;
        margin: 0 auto;
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        display: flex;
        //  소개 content
        & .univ-goods-content-box {
          & .univ-goods-content {
            // width: 100%;
            width: 960px;
            margin: 0 auto;
            height: 800px;
            // display: flex;
            // flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            border-top: 1.5px solid #e8e8e8;
            border-bottom: 1.5px solid #e8e8e8;
            padding: 20px 10px;
            & .univ-goods-slide-img {
              width: 650px;
              margin-bottom: 10px;
              display: flex;
              justify-content: center;
              align-items: center;
              & .swiper-button-prev {
                &::after {
                  content: url("../../imgs/prev_btn.png");
                }
              }
              & .swiper-button-next {
                &::after {
                  content: url("../../imgs/next_btn.png");
                }
              }
              & .swiper-button-disabled {
                // outline: 1px solid red
                opacity: 0.35;
              }
              & .univ-goods-img-swiper {
                width: 500px;
                height: 500px;
                overflow: hidden;
                display: flex;
                justify-content: center;
                align-items: center;
                & img {
                  max-width: 500px;
                  max-height: 500px;
                  border-radius: 8px;
                  margin: auto;
                }
              }
            }
            & .univ-goods-info-one-container {
              width: 500px;
              margin: auto;
              & .univ-goods-title {
                width: 500px;
                font-size: 32px;
                font-weight: bold;
                height: 52px;
                margin: 0 auto 10px;
              }
              & .univ-goods-info-container {
                width: 100%;
                height: 180px;
                overflow-x: hidden;
                overflow-y: auto;
                padding-right: 10px;
                & .info-text-container {
                  display: flex;
                  text-align: left;
                  margin-bottom: 5px;
                  & .info-title {
                    min-width: 70px;
                    text-align: justify;
                    margin-right: 5px;
                    height: 24px;
                    &:after {
                      content: "";
                      display: inline-block;
                      width: 100%;
                    }
                  }
                  & .info-text {
                    margin-left: 5px;
                    min-width: calc(100% - 75px);
                  }
                }

                & .univ-goods-text {
                  margin-top: 10px;
                  overflow: hidden;
                  text-overflow: ellipsis;
                  text-align: left;
                  display: -webkit-box;
                  line-clamp: 5;
                  -webkit-line-clamp: 5;
                  -webkit-box-orient: vertical;
                }
              }
            }
          }
        }
        // 소개 content 옆에 있는 다음 content들의 slide
        & .univ-goods-slide-wrap {
          border-top: 1.5px solid #e8e8e8;
          border-bottom: 1.5px solid #e8e8e8;
          height: 800px;
          scroll-behavior: smooth;
          overflow-y: auto;
          // transition-duration: 300ms;
          -ms-overflow-style: none;

          & .univ-goods-slide-box {
            width: 100%;
            height: 200px;
            display: flex;
            align-items: center;
            border-top: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            padding: 20px 10px;
            transition: all 0.3s ease;
            opacity: 1;
            &.selected {
              transition: all 0.3s ease;
              opacity: 0.3;
            }

            & .univ-goods-slide-img {
              width: 160px;
              height: 160px;
              display: flex;
              align-items: center;
              & img {
                height: 160px;
                width: 160px;
                object-fit: cover;
                border-radius: 8px;
              }
            }
            & .univ-goods-slide-content {
              height: 160px;
              width: 50%;
              margin-left: 15px;
              & .univ-goods-title {
                font-size: 20px;
                font-weight: bold;
                height: 32px;
                display: flex;
                align-items: flex-start;
                margin-bottom: 5px;
              }
              & .univ-goods-price {
                text-align: left;
                margin-bottom: 10px;
              }
              & .univ-goods-text {
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                display: -webkit-box;
                line-clamp: 3;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
          }
        }
      }
    }
  }

  & .mobile-univ-goods-page-wrapper {
    min-width: 320px;
    width: calc(100vw - 40px);
    max-width: 440px;
    margin: 0 auto;
    padding-top: 40px;
    // 굿즈 title
    & .mobile-univ-goods-title-container {
      display: flex;
      margin: 0 auto;
      & .mobile-univ-goods-title-text {
        text-align: left;
        font: normal normal bold 24px / 26px Noto Sans KR;
        font-size: 17.5px;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
      }
    }
    // 굿즈 소개 content
    & .mobile-univ-goods-content-wrap {
      margin: 20px 0;
      & .mobile-univ-goods-container {
        // outline: 1px solid red;
        height: auto;
        overflow: hidden;
        margin: 0 auto;
        // display: grid;
        // grid-template-columns: repeat(2, 1fr);
        display: flex;
        // 소개 content
        & .mobile-univ-goods-slide-wrap {
          border-top: 1.5px solid #e8e8e8;
          border-bottom: 1.5px solid #e8e8e8;
          height: auto;
          scroll-behavior: smooth;
          overflow-y: auto;
          & .mobile-univ-goods-slide-box {
            width: 100%;
            max-height: 200px;
            display: flex;
            align-items: flex-start;
            border-top: 1px solid #e8e8e8;
            border-bottom: 1px solid #e8e8e8;
            padding: 15px 10px;
            transition: all 0.5s ease-in-out;
            opacity: 1;
            &.selected {
              overflow: hidden;
              max-height: 100%;
              transition: all 0.5s ease-in-out;
              flex-direction: column;
              justify-content: space-between;
              align-items: center;
              width: 100%;
              & .mobile-univ-goods-slide-img {
                width: 300px;
                height: 300px;
                margin-bottom: 10px;
                & .mobile-univ-goods-img-swiper {
                  width: 300px;
                  height: 300px;
                  overflow: hidden;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  & img {
                    width: unset;
                    height: unset;
                    max-width: 300px;
                    max-height: 300px;
                    border-radius: 8px;
                    margin: auto;
                  }
                }
              }
              & .univ-goods-info-one-container {
                & .univ-goods-title {
                  font-size: 27px;
                  font-weight: bold;
                  margin: 0 auto 10px;
                }
                & .univ-goods-info-container {
                  width: 100%;
                  overflow-x: hidden;
                  overflow-y: auto;
                  & .info-text-container {
                    display: flex;
                    text-align: left;
                    margin-bottom: 5px;
                    font-size: 13px;
                    & .info-title {
                      min-width: 60px;
                      text-align: justify;
                      margin-right: 5px;
                      height: 24px;
                      &:after {
                        content: "";
                        display: inline-block;
                        width: 100%;
                      }
                    }
                    & .info-text {
                      margin-left: 5px;
                    }
                  }
                  & .univ-goods-text {
                    font-size: 13px;
                    margin-top: 10px;
                    text-align: left;
                    // overflow: hidden;
                    // text-overflow: ellipsis;
                    // display: -webkit-box;
                    // line-clamp: 5;
                    // -webkit-line-clamp: 5;
                    // -webkit-box-orient: vertical;
                  }
                }
              }

              & .mobile-univ-goods-slide-content {
                width: 300px;
                padding-top: 15px;
                margin: 0;
                & .mobile-univ-goods-title {
                  width: 300px;
                }
                & .mobile-univ-goods-text {
                  width: 300px;
                  height: auto;
                  overflow-y: auto;
                }
              }
            }
            & .mobile-univ-goods-slide-img {
              min-width: 140px;
              min-height: 140px;
              display: flex;
              align-items: center;
              & .mobile-univ-goods-img-swiper {
                & img {
                  height: 140px;
                  width: 140px;
                  object-fit: cover;
                  border-radius: 8px;
                }
              }
              & .swiper-pagination {
                & .swiper-pagination-bullet {
                  &.swiper-pagination-bullet-active-main {
                    width: 20px;
                    border-radius: 10px;
                    background-color: #000;
                  }
                }
              }
            }
            & .mobile-univ-goods-slide-content {
              max-height: 300px;
              margin-left: 20px;
              width: calc(100% - 140px);
              overflow: hidden;
              & .mobile-univ-goods-title {
                font-size: 15px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                font-weight: bold;
                height: 32px;
                text-align: left;
              }
              & .mobile-univ-goods-price {
                text-align: left;
                font-size: 13px !important;
                margin-bottom: 5px;
              }
              & .mobile-univ-goods-text {
                font-size: 13px;
                overflow: hidden;
                text-overflow: ellipsis;
                text-align: left;
                display: -webkit-box;
                line-clamp: 3;
                -webkit-line-clamp: 3;
                -webkit-box-orient: vertical;
              }
            }
          }
          // transition-duration: 300ms;
          -ms-overflow-style: none;
          &::-webkit-scrollbar {
            width: 0px;
          }
        }
      }
    }
  }
}
