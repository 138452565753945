#Inquiry {
  display: flex;
  flex-direction: column;
  min-width: 1400px;
  @media screen and (max-width: 1400px) {
    min-width: 360px;
    & .mobile-menu-wrapper {
      margin: 20px;
    }
  }
  & .inquiry-main-wrapper {
    display: flex;
    padding-top: 80px;
    @media screen and (max-width: 1400px) {
      padding: 20px 0;
      margin: auto;
      min-width: 320px;
      width: calc(100vw - 40px);
      max-width: 440px;
    }
    @import "./SideMenu.scss";
    & .inquiry-container {
      margin: 0px auto 120px 120px;
      display: flex;
      width: 1164px;
      flex-direction: column;
      @media screen and (max-width: 1400px) {
        margin: 0;
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
      }
      .title {
        width: 191px;
        height: 35px;
        text-align: left;
        font: normal normal bold 24px/40px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        white-space: nowrap;
      }
      .inquiry-box-container {
        width: 1164px;
        height: 60px;
        margin-top: 32px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        border-radius: 8px;
        display: flex;
        justify-content: space-between;
        align-items: baseline;
        opacity: 1;
        @media screen and (max-width: 1400px) {
          width: auto;
          height: fit-content;
        }

        .header-text {
          padding: 20px 24px 20px 24px;
          text-align: left;
          font: normal normal normal 14px/20px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
          @media screen and (max-width: 1400px) {
            padding: 12px;
            font: normal normal normal 12px/17px Noto Sans KR;
          }
        }
        .header-search-data {
          margin-left: 6px;
          color: var(---1e76af-key-color-);
          text-align: left;
          font: normal normal bold 14px/20px Noto Sans KR;
          letter-spacing: 0px;
          color: #1e76af;
          opacity: 1;
        }
      }
      & .inquiry-form-container {
        margin-top: 20px;
        display: flex;
        text-align: left;
        flex-direction: column;

        .title-field {
          width: 688px;
          height: 56px;
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #d0d0d0;
          border-radius: 8px;
          opacity: 1;
          @media screen and (max-width: 1400px) {
            width: auto;
          }
        }
        .content-textarea-field {
          margin-top: 20px;
          width: 688px;
          height: 124px;
          background: #ffffff 0% 0% no-repeat padding-box;

          border-radius: 8px;
          opacity: 1;
          @media screen and (max-width: 1400px) {
            width: auto;
          }
        }
      }
      .name-field-container {
        margin-top: 24px;
        .form {
          display: flex;
          flex-direction: column;
        }
        .text {
          text-align: left;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        .field {
          width: 336px;
          height: 56px;
          border: 1px solid var(---bababa-line_darker-);
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #bababa;
          border-radius: 8px;
          opacity: 1;
        }
      }
      .address-field-container {
        margin-top: 34px;
        .text {
          text-align: left;
          font: normal normal normal 16px/40px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }

        .form {
          display: flex;
          flex-direction: column;
        }
        .address-wrapper {
          display: flex;
          align-items: flex-end;
          .address-btn {
            margin-left: 16px;
            width: 160px;
            height: 56px;
            border: 1px solid var(---1e76af-key-color-);
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #1e76af;
            border-radius: 8px;
            opacity: 1;
          }
          .field {
            width: 336px;
            height: 56px;
            border: 1px solid var(---bababa-line_darker-);
            background: #ffffff 0% 0% no-repeat padding-box;
            border: 1px solid #bababa;
            border-radius: 8px;
            opacity: 1;
          }
        }
        .address-detail {
          margin-top: 16px;
          width: 848px;
          height: 56px;
          border: 1px solid var(---bababa-line_darker-);
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #bababa;
          border-radius: 8px;
          opacity: 1;
          @media screen and (max-width: 1400px) {
            width: auto;
          }
        }
      }
      .divider {
        margin-top: 40px;
        width: 1164px;
        @media screen and (max-width: 1400px) {
          width: auto;
        }
      }
      .caution-text {
        margin-top: 30px;
        white-space: nowrap;
        text-align: left;
        font: normal normal normal 14px/28px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
        @media screen and (max-width: 1400px) {
          & div {
            white-space: pre-wrap;
          }
        }
      }
      .btn-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 40px;
        .btn {
          width: 160px;
          height: 56px;
          border: 1px solid var(---1e76af-key-color-);

          border: 1px solid #1e76af;
          border-radius: 8px;
          opacity: 1;
        }
      }
    }
  }
}
