& .channel-container {
  display: flex;
  flex-direction: column;
  margin: 80px auto 0;
  width: 1400px;
  & .video {
    margin-top: 32px;
    height: 480px;
    & img {
      width: 854px;
      height: 480px;
      border-radius: 16px 16px 0px 0px;
    }
  }
  & .description {
    background: #f5f5f5 0% 0% no-repeat padding-box;
    border-radius: 16px;
    height: 83px;
    padding: 24px;
    margin-top: 16px;
    display: flex;
    & .play-ico {
      display: flex;
      margin-left: 8px;
      margin-right: 24px;
      & div {
        margin: auto;
      }
    }
    & .title {
      font-size: 24px;
      font-weight: bold;
    }
    & .more {
      background-color: #f5f5f5;
      margin-left: auto;
    }
  }

  @media screen and (max-width: 1400px) {
    width: calc(100vw - 40px);
    min-width: 320px;
    max-width: 440px;
    height: auto;
    margin-top: 50px;

    & .title-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      & .title {
        font-size: 17.5px;
      }
    }

    & .video {
      width: calc(100vw - 40px) !important;
      min-width: 320px;
      max-width: 440px;
      min-height: 180px !important;
      max-height: 247.5px !important;
      margin-top: 20px;
      & iframe {
        width: calc(100vw - 40px) !important;
        min-width: 320px;
        max-width: 440px;
        min-height: 180px !important;
        max-height: 247.5px !important;
      }
      & img {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        border-radius: 16px 16px 0px 0px;
      }
    }
    & .description {
      height: 50px;
      padding: 0;
      margin-top: 0;
      border-radius: 0px 0px 8px 8px;
      justify-content: space-between;
      & .play-ico {
        margin-left: 10px;
      }
      & .title {
        font-size: 15px;
        line-height: 50px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      & .more {
        min-width: 62px !important;
        height: 20px;
        margin: auto 10px auto 15px;
        padding: 0;
        line-height: 20px;
      }
    }
  }
}
