& .mall-notice-container {
  margin: 80px auto 0;
  width: 1400px;
  display: flex;
  flex-direction: column;

  & .date {
    width: 88px;
    height: 88px;
    display: flex;
    flex-direction: column;
    border-radius: 8px;
    font-weight: bold;
    text-align: center;
    & .day {
      margin-top: 18px;
      font-size: 28px;
      line-height: 34px;
    }
    & .month {
      font-size: 16px;
    }
  }

  & .notice-body {
    margin-top: 32px;
    display: flex;
    & .left {
      cursor: pointer;
      display: flex;
      flex-direction: column;
      padding: 32px;
      height: 456px;
      background: #f5f5f5 0% 0% no-repeat padding-box;
      border-radius: 16px;
      width: 692px;
      margin-right: 60px;
      text-align: left;
      & .date {
        background-color: white;
        color: #b7916b;
      }
      & .title {
        margin-top: 40px;
        font-size: 24px;
        font-weight: bold;
      }
      & .content {
        margin-top: 24px;
        font-size: 15px;
        line-height: 30px;
      }
    }
    & .right {
      border-top: 1px solid #eeeeee;
      width: 640px;
      & .notice {
        padding: 32px 0;
        border-bottom: 1px solid #eeeeee;
        display: flex;
        height: 152px;
        cursor: pointer;
        & .date {
          background: #f4f4f4 0% 0% no-repeat padding-box;
          margin-right: 28px;
        }
        & .description {
          display: flex;
          flex-direction: column;
          text-align: left;
          & .title {
            font-size: 20px;
            font-weight: bold;
            line-height: 34px;
          }
          & .content {
            width: 510px;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 2;
            font-size: 15px;
            line-height: 30px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    width: calc(100vw - 40px);
    min-width: 320px;
    max-width: 440px;
    margin-top: 50px;

    & .title-container {
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      & .title {
        font-size: 17.5px;
      }
    }
    & .notice-body {
      display: inline;
      width: calc(100vw - 40px);
      min-width: 320px;
      max-width: 440px;
      margin-top: 20px;
      & .left {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        height: auto;
        padding: 15px;
        border-radius: 8px;
        & .date {
          width: 70px;
          height: 70px;
          border-radius: 4px;
          & .day {
            width: 70px;
            margin-top: 8px;
            font-size: 24px;
          }
          & .month {
            width: 70px;
            margin-bottom: 10px;
            font-size: 12px;
          }
        }
        & .title {
          margin-top: 15px;
          font-size: 17.5px;
        }
        & .content {
          margin-top: 15px;
          font-size: 12px;
          line-height: 19px;
        }
      }
      & .right {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        border-top: none;
        & .notice {
          height: auto;
          padding: 20px 0;
          &:last-of-type {
            border-bottom: none;
          }
          & .date {
            width: 70px;
            height: 70px;
            margin-right: 15px;
            border-radius: 4px;
            & .day {
              width: 70px;
              margin-top: 8px;
              font-size: 24px;
            }
            & .month {
              width: 70px;
              margin-bottom: 10px;
              font-size: 12px;
            }
          }
          & .description {
            & .title {
              line-height: 20px;
              font-size: 14px;
              margin-bottom: 12px;
            }
            & .content {
              width: 100%;
              min-width: 235px;
              max-width: 355px;
              font-size: 12px;
              line-height: 19px;
            }
          }
        }
      }
    }
  }
}
