& .new-book-list-wrapper {
  height: auto;
  padding-top: 80px;
  margin-bottom: 70px;
  & .new-book-list-container {
    max-width: 1400px;
    margin: auto;
    display: flex;
    flex-direction: column;
    & .title-container {
      display: flex;
      & .title-text {
        font-size: 24px;
        font-weight: bold;
      }
      & .more-button {
        margin-left: auto;
        font-size: 14px;
        color: black;
      }
    }
    & .new-book-menu-container {
      display: flex;
      margin-top: 32px;
      flex-wrap: wrap;
      width: 1400px;
      & .menu-item {
        min-width: 70px;
        width: auto;
        height: 40px;
        margin-right: 10px;
        margin-top: 10px;
        padding: 8px 20px;
        border-radius: 8px;
        font-size: 16px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        &.selected {
          background-color: black;
          color: white;
        }
      }
    }
    & .new-book-body {
      padding-top: 40px;
      display: flex;
      flex-wrap: wrap;
      width: 1400px;
      & .new-book-img {
        flex-direction: column;
        width: 200px;
        &:nth-child(n) {
          margin-right: 40px;
        }
        &:nth-child(6n) {
          margin-right: 0px;
        }
        & .book-img {
          cursor: pointer;
          & img {
            width: 200px;
            border-radius: 8px;
            box-shadow: 0px 5px 10px #00000029;
          }
        }
        & .book-title {
          width: 190px;
          margin-top: 16px;
          font-size: 16px;
          font-weight: bold;
        }
        & .book-author-publisher {
          margin-top: 8px;
          font-size: 12px;
          color: #777777;
        }
        & .price-container {
          display: flex;
          max-width: 200px;
          padding: 20px 0px 55px 22px;
          & :nth-of-type(1) {
            margin: auto;
            margin-right: 0;
            margin-left: 0px;
            width: 42px;
            height: 25px;
            padding: 2px 8px 4px;
            color: white;
            border-radius: 4px;
            background: #1e76af 0% 0% no-repeat padding-box;
            font: normal normal bold 12px/20px Noto Sans KR;
          }
          & :nth-of-type(2) {
            margin-left: 12px;
            font-size: 20px;
            font-weight: bold;
          }
          & :nth-of-type(3) {
            width: 13px;
            height: 20px;
            font: normal normal medium 14px/142px Noto Sans KR;
            margin: auto auto 7px 4px;
          }
        }
      }
    }
  }

  @media screen and (max-width: 1400px) {
    padding-top: 0px;
    // padding-top: 50px;
    // min-width: 360px;
    // max-width: 480px;
    margin: 0 auto;
    margin-bottom: 100px;
    & .mobile {
      margin-top: 50px;
      // margin-left: 20px;
      // margin-right: 20px;
      & .title-container {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        margin: auto;
        & .title-text {
          text-align: left;
          font: normal normal bold 17.5px Noto Sans KR;
        }
        // & .heart-icon {
        //   margin-left: auto;
        //   margin-right: 24px;
        // }
      }
      & .new-book-menu-container {
        width: 100vw;
        min-width: 360px;
        max-width: 480px;
        margin: auto;
        margin-top: 20px;
        background: #f5f5f5 0% 0% no-repeat padding-box;
        display: flex;
        & .new-book-menu {
          width: calc(100vw - 68px);
          min-width: 292px;
          max-width: 412px;
          height: 48px;
          margin: auto;
          margin-left: 20px;
          display: flex;
          overflow: hidden;
          flex-wrap: nowrap;
          transition: 0.3s all ease-in-out;
          & .menu-item {
            height: 48px;
            padding: 16px;
            padding: 8px;
            white-space: nowrap;
            cursor: pointer;
            font-size: 12px;
            line-height: 32px;
            &.selected {
              font-weight: bold;
              padding-bottom: 12px;
              color: #1e76af;
            }
          }
          &.opened {
            flex-wrap: wrap;
            width: calc(100vw - 48px);
            min-width: 292px;
            max-width: 412px;
            height: auto;
            max-height: 240px;
            // margin: 0;
            margin-left: 20px;
          }
        }
        & .arrow {
          width: 48px;
          height: 48px;
          // margin: 20px 0 -56px auto;
          display: flex;
          z-index: 10000;
          background-color: #f5f5f5;
          // position: relative;
          cursor: pointer;
          & div {
            margin: auto;
          }
        }
      }
      & .more-button {
        width: calc(100vw - 40px);
        min-width: 320px;
        max-width: 440px;
        height: 48px;
        margin: 20px 20px 0;
        background: #ffffff 0% 0% no-repeat padding-box;
        border: 1px solid #aaaaaa;
        border-radius: 8px;
      }

      & .new-book-img {
        padding: 20px 0;
        margin: 0 auto;
        min-width: 320px;
        width: calc(100vw - 40px);
        max-width: 440px;
        display: flex;
        border-bottom: 1px solid #e8e8e8;
        & .book-img {
          width: 80px;
          & img {
            width: 80px;
            box-shadow: 0px 2.5px 5px #00000029;
            border-radius: 4px;
            cursor: pointer;
          }
        }
        & .book-info {
          margin-left: 25px;
          min-width: 215px;
          width: 100%;
          display: flex;
          flex-direction: column;
          & .title-container {
            width: calc(100vw - 145px);
            min-width: 215px;
            max-width: 335px;
            height: 24px;
            margin: 0;
            display: flex;
            font-size: 15px;
            font-weight: bold;
            & .book-title {
              width: 100%;
              min-width: 143px;
              max-width: 263px;
              text-align: left;
            }
            & .icon {
              cursor: pointer;
            }
            & .heart-icon {
              margin-left: auto;
              margin-right: 24px;
            }
          }
          & .sub-info-container {
            display: flex;
            font-size: 12.5px;
            color: #777777;
            min-width: 200px;
            width: calc(100vw - 160px);
            max-width: 320px;
            margin-top: 38px;
            & .divider {
              height: 8px;
              width: 1px;
              background-color: black;
              opacity: 0.5;
              margin: auto 6px;
            }
            & div {
              white-space: nowrap;
            }
          }
          & .open-date-info-container {
            font-size: 12.5px;
            text-align: left;
            color: #777777;
            margin-top: 3px;
            // width: 100%;
            min-width: 200px;
            max-width: 320px;
          }
          & .price-info-container {
            display: flex;
            margin-top: 14px;
            & .sale-percentage {
              font-size: 11px;
              font-weight: bold;
              color: white;
              background: #1e76af 0% 0% no-repeat padding-box;
              border-radius: 2px;
              padding: 2px 4px;
            }
            & .after-sale {
              margin-left: 12px;
              font-size: 14px;
              font-weight: bold;
              & span {
                font-size: 12px;
                font-weight: bold;
                margin-left: 2px;
              }
            }
            & .before-sale {
              margin-left: 12px;
              text-decoration: line-through;
              color: #777777;
              font-size: 12px;
              margin-top: 2px;
            }
          }
        }
        &:last-of-type {
          border-bottom: none;
        }
      }
    }
  }
}
