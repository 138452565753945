& .one-main-wrapper {
  margin-top: 80px;
  display: flex;
  min-height: 60vh;
  @import "./SupportSideBar.scss";
  & .one-main-container {
    margin-left: 150px;
    margin-right: auto;
    width: 1164px;
    & .one-main-title {
      min-width: 132px;
      height: 35px;
      text-align: left;
      font: normal normal bold 24px/40px Noto Sans KR;
      letter-spacing: 0px;
      color: #000000;
      opacity: 1;
    }
    & .one-grid-container {
      margin-top: 80px;
      .table-title {
        text-align: left;
        font: normal normal normal 13px/28px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
        width: 10%;
      }
      .table-rows {
        text-align: left;
        font: normal normal normal 15px/27px Noto Sans KR;
        letter-spacing: 0px;
        color: #000000;
        opacity: 1;
        width: 60%;
      }
      .detail-content {
        background: #f5f5f5 0% 0% no-repeat padding-box;
        & .detail-text {
          word-wrap: break-word;
          word-break: keep-all;
          text-align: left;
          font: normal normal normal 14px/28px Noto Sans KR;
          letter-spacing: 0px;
          color: #000000;
          opacity: 1;
        }
        & .detail-btn {
          background: #ffffff 0% 0% no-repeat padding-box;
          border: 1px solid #bababa;
          border-radius: 4px;
          opacity: 1;
          width: 54px;
          height: 36px;
        }
      }
      .table-status-clear {
        text-align: center;
        font: normal normal normal 13px/28px Noto Sans KR;
        letter-spacing: 0px;
        color: #ffffff;
        .box {
          width: 64px;
          max-height: 24px;
          background: #000000 0% 0% no-repeat padding-box;
          border-radius: 4px;
          opacity: 1;
        }
      }
      .table-status-stay {
        text-align: center;
        font: normal normal normal 13px/28px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
        .box {
          width: 64px;
          max-height: 24px;
          background: #e8e8e8 0% 0% no-repeat padding-box;
          border-radius: 4px;
          opacity: 1;
        }
      }

      .letter-Q {
        text-align: left;
        font: normal normal normal 15px/30px Noto Sans KR;
        letter-spacing: 0px;
        color: #1e76af;
        opacity: 1;
      }
      .letter-A {
        text-align: left;
        font: normal normal normal 16px/28px Noto Sans KR;
        letter-spacing: 0px;
        color: #777777;
        opacity: 1;
      }

      & .one-button {
        margin-left: 16px;
        background: #1e76af 0% 0% no-repeat padding-box;
        border-radius: 8px;
        color: white;
      }
      & .one-button {
        margin: 30px 0 120px auto;
        width: 180px;
        height: 56px;
        display: flex;
        justify-content: space-between;
        font-size: 16px;
        & div {
          &:first-of-type {
            margin: auto 0 auto 20px;
          }
          &:last-of-type {
            margin: auto 10px auto auto;
          }
        }
      }
    }
  }
  // @media screen and (max-width: 1400px) {
  //   min-width: 320px;

  //   & .one-main-wrapper {
  //     & .one-main-container {
  //       margin: 0;
  //       min-width: 320px;
  //       width: calc(100% - 40px);
  //       max-width: 440px;
  //     }
  //   }
  // }
}
